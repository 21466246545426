import React from 'react';
import classNames from 'classnames';
import { BaseUser } from '@fanadise/common-types';
import { truncate } from '@fanadise/common-utils';
import Avatar from 'components/atoms/Avatar';

import styles from './VCard.module.css';

export interface VCardProps {
  user: Pick<BaseUser, 'avatarUrl' | 'username'>;
  variant?: 'horizontal' | 'vertical';
  size?: 'sm' | 'md';
  hasAtSign?: boolean;
  className?: string;
}

const VCard: React.FC<VCardProps> = ({
  user,
  variant = 'horizontal',
  size = 'md',
  hasAtSign = true,
  children,
  className,
  ...rest
}) => (
  <div
    data-testid="vcard"
    className={classNames(
      styles.root,
      styles[`variant--${variant}`],
      styles[`size--${size}`],
      className,
    )}
    {...rest}
  >
    <Avatar size={size} url={user.avatarUrl} />
    <div className={styles.wrap}>
      <p title={user.username} className={styles.username}>
        {hasAtSign && `@`}
        {truncate(user.username, 10)}
      </p>
      {children && <div className={styles.inner}>{children}</div>}
    </div>
  </div>
);

export default VCard;
