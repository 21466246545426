import React, { useCallback, useEffect } from 'react';
import type { SizeGrade, Theme } from '@fanadise/common-types';
import ThemeContext from 'contexts/ThemeContext';
import {
  convertThemeBorderRadius,
  convertThemeColor,
  convertThemeFontFamily,
  convertThemeFontSize,
  convertThemeFontWeight,
  convertThemeLineHeight,
  convertThemeShadow,
  convertThemeSize,
} from 'utils/convertThemeProperty';
import { DEFAULT_THEME } from 'consts/defaultTheme';
import { ResponsiveProperty } from 'types/responsiveProperty';

export interface ThemeProviderProps {
  theme?: Theme;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({
  theme = DEFAULT_THEME,
  children,
}) => {
  const convertSize = useCallback(convertThemeSize, [theme.size]);

  const convertColor = useCallback(convertThemeColor, [theme.color]);

  const convertBorderRadius = useCallback(convertThemeBorderRadius, [
    theme.borderRadius,
  ]);

  const convertShadow = useCallback(convertThemeShadow, [theme.shadow]);

  const convertFontFamily = useCallback(convertThemeFontFamily, [
    theme.fontFamily,
  ]);

  const convertFontSize = useCallback(convertThemeFontSize, [theme.fontSize]);

  const convertFontWeight = useCallback(convertThemeFontWeight, [
    theme.fontWeight,
  ]);

  const convertLineHeight = useCallback(convertThemeLineHeight, [
    theme.lineHeight,
  ]);

  const covertResponsiveProperty = useCallback(
    <T extends any>(
      prop: ResponsiveProperty<T> | undefined,
      convert: (value: any) => any = (value) => value,
    ) => {
      if (!prop) {
        return undefined;
      }
      if (typeof prop === 'object') {
        return Object.entries(prop as Partial<{ [key in SizeGrade]: T }>)
          .map(([size, value]) => ({
            [size in theme.size ? theme.size[size as SizeGrade] : size]:
              convert(value),
          }))
          .reduce(
            (prev, curr) => ({
              ...prev,
              ...curr,
            }),
            {},
          );
      }
      return convert(prop);
    },
    [theme.size],
  );

  useEffect(() => {
    const getScrollbarWidth = () => {
      const outer = document.createElement('div');
      outer.style.visibility = 'hidden';
      outer.style.overflow = 'scroll';
      // @ts-ignore
      outer.style.msOverflowStyle = 'scrollbar';
      document.body.appendChild(outer);

      const inner = document.createElement('div');
      outer.appendChild(inner);

      const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

      outer.parentNode!.removeChild(outer);

      return scrollbarWidth;
    };

    document.documentElement.style.setProperty(
      '--scroll-width',
      `${getScrollbarWidth()}px`,
    );
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        convertSize,
        convertColor,
        convertBorderRadius,
        convertShadow,
        convertFontFamily,
        convertFontSize,
        convertFontWeight,
        convertLineHeight,
        covertResponsiveProperty,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
