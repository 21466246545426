import React, { useContext, useEffect, useState } from 'react';
import {
  ArrowSmRightIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/outline';
import {
  Badge,
  Box,
  Button,
  Icon,
  Loading,
  ProcessingMessage,
  Separator,
  SnackbarContext,
  Stack,
} from '@fanadise/common-ui';
import { profileApi, usersApi } from '@fanadise/common-data-access';
import { BaseUser, User } from '@fanadise/common-types';
import { shortenAddress } from '@fanadise/common-utils';
import useTranslation from 'hooks/useTranslation';
import { WalletContextValue } from 'contexts/WalletContext';
import { AuthContextValue } from 'contexts/AuthContext';

import styles from './AssignWallet.module.css';

export interface AssignWalletProps {
  walletContext: React.Context<WalletContextValue>;
  authContext: React.Context<AuthContextValue<User>>;
}

const AssignWallet: React.FC<AssignWalletProps> = ({
  walletContext,
  authContext,
}) => {
  const { translate } = useTranslation();
  const { addErrorAlert } = useContext(SnackbarContext)!;
  const { address, disconnect } = useContext(walletContext);
  const { updateUser, signOut } = useContext(authContext);
  const [isChecking, setIsChecking] = useState(true);
  const [existingUser, setExistingUser] = useState<BaseUser>();

  const handleAssignClick = async () => {
    try {
      const updatedUser = await profileApi.setProfileWalletAddress(address!);
      updateUser(updatedUser);
    } catch (err: any) {
      addErrorAlert(err.message || translate('error:default'));
    }
  };

  const handleDisconnectClick = () => {
    disconnect();
  };

  const handleSignOutClick = () => {
    disconnect();
    signOut();
  };

  useEffect(() => {
    const fetchUsersByAddress = async () => {
      try {
        const [matchedUser] = (
          await usersApi.fetchUsers({
            walletAddress: address!,
          })
        ).users;

        if (matchedUser) {
          setExistingUser(matchedUser);
        }
      } finally {
        setIsChecking(false);
      }
    };

    fetchUsersByAddress();
  }, []);

  return (
    <div className={styles.root}>
      <Loading isLoading={isChecking} minH={15} isOpaque>
        {existingUser ? (
          <>
            <ProcessingMessage
              title={translate('assignWallet:alreadyAssigned:title')}
              description=""
              icon={<Icon icon={ExclamationCircleIcon} />}
              className={styles.message}
            />

            <Stack
              dir="row"
              gap="4"
              mt="4"
              mb="8"
              justify="center"
              align="center"
              className={styles.body}
            >
              <Badge size="lg" variant="secondary">
                {shortenAddress(address || '', 3)}
              </Badge>
              <Icon icon={ArrowSmRightIcon} />
              <Badge size="lg" variant="secondary">
                @{existingUser.username}
              </Badge>
            </Stack>

            <Button
              color="primary"
              size="lg"
              onClick={handleDisconnectClick}
              className={styles.actionButton}
            >
              {translate('common:disconnectWallet')}
            </Button>

            <Separator label={translate('common:or')} />

            <Button
              color="secondary"
              size="lg"
              onClick={handleSignOutClick}
              className={styles.actionButton}
            >
              {translate('common:signOut')}
            </Button>
          </>
        ) : (
          <>
            <ProcessingMessage
              title={translate('assignWallet:canAssign:title')}
              description=""
              icon={<Icon icon={CheckCircleIcon} />}
              className={styles.message}
            />

            <Box mb="4" className={styles.body}>
              <Badge size="lg" variant="secondary">
                {shortenAddress(address || '', 8)}
              </Badge>
            </Box>

            <Button
              color="primary"
              size="lg"
              onClick={handleAssignClick}
              className={styles.actionButton}
            >
              {translate('assignWallet:canAssign:assign')}
            </Button>

            <Separator label={translate('common:or')} />

            <Button
              color="secondary"
              size="lg"
              onClick={handleDisconnectClick}
              className={styles.actionButton}
            >
              {translate('common:disconnectWallet')}
            </Button>
          </>
        )}
      </Loading>
    </div>
  );
};

export default AssignWallet;
