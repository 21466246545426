import React, { HTMLAttributes } from 'react';
import LocaleContext from 'contexts/LocaleContext';
import ReactTimeAgo from 'react-time-ago';

export interface TranslatedTimeAgoProps extends HTMLAttributes<HTMLElement> {
  date: Date;
}

const TranslatedTimeAgo: React.FC<TranslatedTimeAgoProps> = (props) => (
  <LocaleContext.Consumer>
    {(locale) => <ReactTimeAgo locale={locale} {...props} />}
  </LocaleContext.Consumer>
);

export default TranslatedTimeAgo;
