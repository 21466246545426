import {
  Stack,
  Alert,
  Icon,
  Button,
  Box,
  Text,
  Separator,
  TinyHeading,
} from '@fanadise/common-ui';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';

export interface PurchaseErrorProps {
  purchaseId?: string;
  refReqId?: string;
}

const PurchaseError: React.FC<PurchaseErrorProps> = ({
  purchaseId,
  refReqId,
}) => {
  const { translate } = useTranslation();

  return (
    <Stack gap="4" align="center">
      <Alert
        message={translate('purchaseError:message')}
        icon={<Icon icon={ExclamationCircleIcon} size="lg" />}
        type="error"
        size="lg"
      />

      <a href="/">
        <Button tag="span" size="lg">
          {translate('common:backToHome')}
        </Button>
      </a>

      {(purchaseId || refReqId) && (
        <>
          <Box w="full">
            <Separator spacing="4" />
          </Box>
          <Box>
            <Stack gap="2">
              {purchaseId && (
                <Stack gap="none">
                  <TinyHeading>
                    {translate('purchaseError:purchaseId')}
                  </TinyHeading>
                  <Text color="gray.800">{purchaseId}</Text>
                </Stack>
              )}

              {refReqId && (
                <Stack gap="none">
                  <TinyHeading>
                    {translate('purchaseError:requestId')}
                  </TinyHeading>
                  <Text color="gray.800">{refReqId}</Text>
                </Stack>
              )}
            </Stack>
          </Box>
        </>
      )}
    </Stack>
  );
};

export default PurchaseError;
