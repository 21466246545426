import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { EyeOffIcon, EyeIcon } from '@heroicons/react/outline';
import Input, { InputProps } from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';

import styles from './PasswordInput.module.css';

export interface PasswordInputProps
  extends Omit<
    InputProps,
    'type' | 'step' | 'min' | 'max' | 'rows' | 'autoComplete' | 'isReadOnly'
  > {}

const PasswordInput: React.FC<PasswordInputProps> = ({
  className,
  ...rest
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const ToggleIcon = isVisible ? EyeOffIcon : EyeIcon;

  const handleToggleClick = useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible]);

  return (
    <div
      data-testid="passwordInput"
      className={classNames(styles.root, className)}
    >
      <Input {...rest} type={isVisible ? 'text' : 'password'} />

      <Button
        variant="icon"
        icon={<Icon icon={ToggleIcon} />}
        type="button"
        onClick={handleToggleClick}
        className={styles.toggleButton}
      />
    </div>
  );
};

export default PasswordInput;
