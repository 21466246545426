import React, { useCallback, useContext, useState } from 'react';
import {
  FileInput,
  Icon,
  SnackbarContext,
  Stack,
  Input,
} from '@fanadise/common-ui';
import { commonFilesApi } from '@fanadise/common-data-access';
import { useTranslation } from '@fanadise/common-logic';
import { UploadIcon } from '@heroicons/react/solid';

interface UploadFileFormProps {
  onSuccess: () => void;
}

const UploadFileForm: React.FC<UploadFileFormProps> = ({ onSuccess }) => {
  const { translate } = useTranslation();
  const { addErrorAlert, addSuccessAlert } = useContext(SnackbarContext)!;
  const [name, setName] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const handleNameChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  >((event) => {
    setName(event.target.value);
  }, []);

  const handleUpload = useCallback(
    async (file: any) => {
      const data = new FormData();
      data.append('file', file);
      data.append('name', name);
      setIsUploading(true);

      try {
        await commonFilesApi.uploadCommonFile(data);
        addSuccessAlert(translate('success:added'));
        onSuccess();
      } catch (err: any) {
        addErrorAlert(err.message || translate('error:default'));
      } finally {
        setIsUploading(false);
      }
    },
    [name, onSuccess, addSuccessAlert, setIsUploading],
  );

  return (
    <Stack dir="row" mb="8">
      <Input
        name="name"
        value={name}
        placeholder={translate('files:list:namePlaceholder')}
        onChange={handleNameChange}
      />

      <div>
        <FileInput
          label={translate('files:list:upload')}
          color="primary"
          icon={<Icon icon={UploadIcon} size="sm" />}
          isDisabled={!name.length}
          isLoading={isUploading}
          onFileChange={handleUpload}
        />
      </div>
    </Stack>
  );
};

export default UploadFileForm;
