import React from 'react';

import Button, { ButtonProps } from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import { XIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

import styles from './CloseButton.module.css';

export interface CloseButtonProps
  extends Omit<
    ButtonProps,
    'icon' | 'variant' | 'fixedIcon' | 'type' | 'children'
  > {
  variant?: 'transparent' | 'filled' | 'frosted';
}

const CloseButton: React.FC<CloseButtonProps> = ({
  variant = 'transparent',
  size,
  className,
  ...rest
}) => (
  <Button
    className={classNames(
      styles.root,
      styles[`variant--${variant}`],
      className,
    )}
    variant="icon"
    icon={<Icon icon={XIcon} size={size} />}
    {...rest}
  />
);

export default CloseButton;
