import React from 'react';
import { useFormikContext } from 'formik';
import Button, { ButtonProps } from 'components/atoms/Button';

export interface ConnectedSubmitProps extends ButtonProps {}

const ConnectedSubmit: React.FC<ConnectedSubmitProps> = ({
  isDisabled,
  ...rest
}) => {
  const { isSubmitting } = useFormikContext();

  return (
    <Button
      {...rest}
      type="submit"
      tag="button"
      isDisabled={isDisabled || isSubmitting}
      isLoading={isSubmitting}
    />
  );
};

export default ConnectedSubmit;
