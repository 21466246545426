import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  createThemeStyles,
  SnackbarProvider,
  DEFAULT_THEME,
  ThemeProvider,
} from '@fanadise/common-ui';
import AppAuthProvider from 'providers/AppAuthProvider';
import PrivateRoute from 'components/shared/PrivateRoute';
import UnauthenticatedRoute from 'components/shared/UnauthenticatedRoute';
import AccountPage from 'pages/AccountPage';
import AuthPage from 'pages/AuthPage';
import HomePage from 'pages/HomePage';
import {
  mergeTranslations,
  TranslationsProvider,
} from '@fanadise/common-logic';
import { Locale } from '@fanadise/common-consts';

import en from '../public/locales/en.json';

const APP_LOCALES = {
  en,
};

const App: React.FC = () => (
  <>
    <Helmet>
      <style>{createThemeStyles(DEFAULT_THEME)}</style>
    </Helmet>

    <ThemeProvider theme={DEFAULT_THEME}>
      <AppAuthProvider>
        <TranslationsProvider
          translations={mergeTranslations(APP_LOCALES[Locale.En], Locale.En)}
        >
          <SnackbarProvider>
            <Routes>
              <Route
                path="/auth/*"
                element={
                  <UnauthenticatedRoute>
                    <AuthPage />
                  </UnauthenticatedRoute>
                }
              />
              <Route
                path="/account/*"
                element={
                  <PrivateRoute>
                    <AccountPage />
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<HomePage />} />
            </Routes>
          </SnackbarProvider>
        </TranslationsProvider>
      </AppAuthProvider>
    </ThemeProvider>
  </>
);

export default App;
