import React, { forwardRef, HTMLAttributes } from 'react';
import Styled, { StyledProps } from 'components/atoms/Styled';
import classNames from 'classnames';

import styles from './Text.module.css';

export type TextProps = Partial<
  Pick<
    StyledProps,
    | 'tag'
    | 'color'
    | 'fontFamily'
    | 'fontSize'
    | 'fontWeight'
    | 'lineHeight'
    | 'textAlign'
    | 'textTransform'
    | 'maxW'
    | 'minW'
    | 'letterSpacing'
  >
> &
  Omit<HTMLAttributes<HTMLElement>, 'color' | 'dir'> & {
    isTruncated?: boolean;
    noOfLines?: number;
  };

const Text = forwardRef<HTMLElement, TextProps>(
  ({ tag = 'p', isTruncated, noOfLines, style, className, ...rest }, ref) => (
    <Styled
      tag={tag}
      // @ts-ignore
      ref={ref}
      className={classNames(
        className,
        isTruncated && styles.isTruncated,
        noOfLines && styles.isClamped,
      )}
      style={{
        ...(noOfLines ? { '--line-clamp': noOfLines } : {}),
        ...(style || {}),
      }}
      {...rest}
    />
  ),
);

export default Text;
