import React from 'react';
import { useField } from 'formik';
import FileInput, { FileInputProps } from 'components/atoms/FileInput';

export interface ConnectedFileInputProps
  extends Omit<FileInputProps, 'file' | 'onFileChange'> {
  name: string;
}

const ConnectedFileInput: React.FC<ConnectedFileInputProps> = ({
  name,
  ...rest
}) => {
  const [field, , { setValue }] = useField<File>({ name, type: 'file' });
  return <FileInput file={field.value} onFileChange={setValue} {...rest} />;
};

export default ConnectedFileInput;
