import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { SortOrder } from '@fanadise/common-types';

import styles from './SortOrderToggle.module.css';

export interface SortOrderToggleProps extends HTMLAttributes<HTMLElement> {
  order?: SortOrder;
  onOrderChange: (order?: SortOrder) => void;
}

const SortOrderToggle: React.FC<SortOrderToggleProps> = ({
  order,
  onOrderChange,
  className,
  ...rest
}) => (
  <button
    data-testid="sortOrderToggle"
    type="button"
    aria-label="sort"
    onClick={() => {
      let newOrder: SortOrder | undefined = 'asc';
      if (order === 'asc') {
        newOrder = 'desc';
      } else if (order === 'desc') {
        newOrder = undefined;
      }

      onOrderChange(newOrder);
    }}
    className={classNames(className, styles.root)}
    {...rest}
  >
    <ChevronUpIcon className={classNames(order === 'asc' && styles.isActive)} />
    <ChevronDownIcon
      className={classNames(order === 'desc' && styles.isActive)}
    />
  </button>
);

export default SortOrderToggle;
