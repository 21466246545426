import classNames from 'classnames';
import React from 'react';

import styles from './Logo.module.css';

export interface LogoProps {
  url: string;
  alt?: string;
  id?: string;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  url,
  alt,
  id,
  size = 'md',
  className,
}) => (
  <img
    id={id}
    data-testid="logo"
    src={url}
    alt={alt}
    className={classNames(styles.root, styles[`size--${size}`], className)}
  />
);

export default Logo;
