import React, { useMemo, useState } from 'react';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import {
  Box,
  Button,
  Heading,
  Icon,
  NftSupply,
  Stack,
  Text,
  VCard,
  MediaLightboxPanel,
  NftRarityBadge,
} from '@fanadise/common-ui';
import TranslatedTimeAgo from 'components/shared/TranslatedTimeAgo';
import Translate from 'components/shared/Translate';
import useProfileNftFetch from 'hooks/useProfileNftFetch';
import { getCurrentNetworkDetails } from 'utils/networkUtils';
import NftDetailsLightboxMedia from './NftDetailsLightboxMedia';

import styles from './NftDetailsLightbox.module.css';

const NETWORK = getCurrentNetworkDetails();

interface NftDetailsLightboxProps {
  nftId: string;
}

const NftDetailsLightbox: React.FC<NftDetailsLightboxProps> = ({ nftId }) => {
  const [isMediaLoaded, setIsMediaLoaded] = useState(false);
  const [nft, isLoading] = useProfileNftFetch(true, nftId);

  const externalUrl = useMemo(
    () => (nft?.txnHash ? `${NETWORK.explorerUrls[0]}/tx/${nft.txnHash}` : ''),
    [nft?.txnHash],
  );

  const handleMediaLoad = () => {
    setIsMediaLoaded(true);
  };

  return (
    <MediaLightboxPanel
      isLoaded={!isLoading && isMediaLoaded}
      details={
        nft ? (
          <>
            <Box p="4" mb="2">
              <VCard user={nft.config.creator}>
                <Text lineHeight="sm" fontSize="sm" color="gray">
                  <TranslatedTimeAgo
                    date={new Date(nft.config.createdAt)}
                    className={styles.timeAgo}
                  />
                </Text>
              </VCard>
            </Box>

            <Stack px="4" gap="2">
              <Heading fontSize="xl">{nft.config.name}</Heading>

              <Stack dir="row">
                <NftRarityBadge rarity={nft.config.rarity} />

                <NftSupply
                  rarity={nft.config.rarity}
                  copyNumber={nft.tokenId - nft.config.firstTokenId + 1}
                  totalSupply={nft.config.totalSupply}
                  className={styles.totalSupply}
                />
              </Stack>
            </Stack>

            <Stack p="4" gap="4" mt="2" className={styles.body}>
              <p>{nft.config.description}</p>
              {nft.config.extendedDescription && (
                <p>{nft.config.extendedDescription}</p>
              )}
            </Stack>

            {nft.txnHash && (
              <Box px="4" py="2">
                <a
                  href={externalUrl}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.externalLink}
                >
                  <Button
                    size="sm"
                    variant="text"
                    icon={<Icon icon={ExternalLinkIcon} size="sm" />}
                  >
                    <Translate id="nftDetails:explorerLinkLabel" />
                  </Button>
                </a>
              </Box>
            )}
          </>
        ) : undefined
      }
    >
      {nft && <NftDetailsLightboxMedia nft={nft} onLoad={handleMediaLoad} />}
    </MediaLightboxPanel>
  );
};

export default NftDetailsLightbox;
