import { formatPrice } from 'conversions/formatPrice';
import { Currency, FIAT_CURRENCY_SYMBOLS } from '@fanadise/common-consts';

export const formatFiatPrice = (value: number | string, currency: Currency) => {
  const fixed = formatPrice(value);

  return currency === Currency.Pln
    ? `${fixed}${FIAT_CURRENCY_SYMBOLS[currency]}`
    : `${FIAT_CURRENCY_SYMBOLS[currency]}${fixed}`;
};
