export const createMediaQueryString = (minWidth: string, maxWidth?: string) => {
  const hasMinWidth = parseInt(minWidth, 10) >= 0;

  if (!hasMinWidth && !maxWidth) {
    return '';
  }

  let query = `(min-width: ${minWidth})`;

  if (!maxWidth) {
    return query;
  }

  if (query) {
    query += ' and ';
  }

  query += `(max-width: ${maxWidth})`;

  return query;
};
