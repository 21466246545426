import React from 'react';
import classNames from 'classnames';
import { BaseNftConfig } from '@fanadise/common-types';
import Ratio from 'components/atoms/Ratio';
import ImageWrapper from 'components/atoms/ImageWrapper';
import Avatar from 'components/atoms/Avatar';

import styles from './NftCard.module.css';

export interface NftCardProps {
  nftConfig: BaseNftConfig;
  ratio?: number;
  isDisabled?: boolean;
  onClick?: () => void;
}

const NftCard: React.FC<NftCardProps> = ({
  nftConfig,
  ratio = 0.75,
  isDisabled = false,
  onClick,
}) => (
  <Ratio
    ratio={ratio}
    className={classNames(styles.root, {
      [styles.isDisabled]: isDisabled,
    })}
  >
    <div role="link" tabIndex={0} onClick={isDisabled ? undefined : onClick}>
      <ImageWrapper src={nftConfig.coverUrl} alt={nftConfig.name} fit="cover" />

      <div className={styles.creator}>
        <Avatar url={nftConfig.creator.avatarUrl} size="md" />
      </div>

      <div className={styles.footer}>
        <p className={styles.name}>{nftConfig.name}</p>
        <p className={styles.username}>@{nftConfig.creator.username}</p>
      </div>
    </div>
  </Ratio>
);

export default NftCard;
