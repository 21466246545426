import { Purchase } from '@fanadise/common-types';
import {
  Box,
  Stack,
  Alert,
  Icon,
  Spinner,
  Separator,
} from '@fanadise/common-ui';
import PurchaseSummary from 'components/payment/PurchaseSummary';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/outline';

export interface PurchaseProcessingProps {
  purchase?: Purchase;
}

const PurchaseProcessing: React.FC<PurchaseProcessingProps> = ({
  purchase,
}) => {
  const { translate } = useTranslation();

  return (
    <Stack gap="4" align="center">
      <Alert
        message={translate('purchaseProcessing:message')}
        icon={<Icon icon={InformationCircleIcon} size="lg" />}
        type="info"
        size="lg"
      />

      <Box display="flex" justify="center">
        <Spinner />
      </Box>

      {purchase && (
        <>
          <Box w="full">
            <Separator spacing="4" />
          </Box>

          <PurchaseSummary purchase={purchase} />
        </>
      )}
    </Stack>
  );
};

export default PurchaseProcessing;
