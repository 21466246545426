import React from 'react';
import AppAuthContext from 'contexts/AppAuthContext';
import { Admin } from '@fanadise/common-types';
import * as adminsApi from 'api/adminsApi';

import {
  AuthProvider,
  AUTH_ACCESS_TOKEN_KEY,
  AUTH_REFRESH_TOKEN_KEY,
} from '@fanadise/common-logic';

const AppAuthProvider: React.FC = ({ children }) => (
  <AuthProvider<Admin>
    tokenKey={AUTH_ACCESS_TOKEN_KEY}
    context={AppAuthContext}
    fetchUserPromise={adminsApi.fetchAdmin}
    onSignOut={() => {
      localStorage.removeItem(AUTH_REFRESH_TOKEN_KEY);
    }}
  >
    {children}
  </AuthProvider>
);

export default AppAuthProvider;
