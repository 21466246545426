import React from 'react';
import classNames from 'classnames';

import { IconProps } from 'components/atoms/Icon';
import styles from './Alert.module.css';

export interface AlertProps {
  message: string;
  icon?: React.ReactElement<IconProps>;
  size?: 'sm' | 'md' | 'lg';
  type?: 'error' | 'success' | 'info';
  className?: string;
}

const Alert: React.FC<AlertProps> = ({
  message,
  icon,
  size = 'sm',
  type,
  className,
}) => (
  <div
    data-testid="alert"
    role="alert"
    className={classNames(
      styles.root,
      styles[`size--${size}`],
      type && styles[`type--${type}`],
      className,
    )}
  >
    {icon &&
      React.cloneElement(icon, {
        className: classNames(styles.icon, icon.props.className),
      })}
    <p>{message}</p>
  </div>
);

export default Alert;
