export const DEFAULT_BACKGROUND_URL = '/images/default_background.png';
export const DEFAULT_AVATAR_URL = '/images/default_avatar.png';

export const DEFAULT_COVER_URL = '/images/default_cover.png';
export const DEFAULT_ICON_URL = '/images/default_icon.png';

export const DEFAULT_DESKTOP_BANNER_URL = '/images/default_desktop_banner.svg';
export const DEFAULT_MOBILE_BANNER_URL = '/images/default_mobile_banner.svg';

export const DEFAULT_NFT_DESKTOP_BANNER_URL =
  '/images/default_nft_desktop_banner.svg';
export const DEFAULT_NFT_MOBILE_BANNER_URL =
  '/images/default_nft_mobile_banner.svg';
