import React, { useCallback, useContext } from 'react';
import { Form, Formik, FormikConsumer, FormikHelpers } from 'formik';
import * as yup from 'yup';
import {
  SnackbarContext,
  ConnectedLoading,
  ConnectedSubmit,
  ConnectedFormItem,
  ConnectedInput,
  Box,
  Avatar,
  ConnectedFileInput,
  ImageWrapper,
  Ratio,
  Stack,
} from '@fanadise/common-ui';
import FormGrid from 'components/shared/FormGrid';
import FormSubmitBar from 'components/shared/FormSubmitBar';
import { User } from '@fanadise/common-types';
import { useTranslation } from '@fanadise/common-logic';
import { usersApi } from '@fanadise/common-data-access';
import { DEFAULT_BACKGROUND_URL } from '@fanadise/common-consts';
import { useNavigate } from 'react-router-dom';

interface EditUserFormValues {
  email: string;
  username: string;
  bio?: string;
  avatarFile?: File;
  backgroundFile?: File;
  password?: string;
}

export interface EditUserFormProps {
  user?: User;
  onChange?: (user: User) => void;
}

const EditUserForm: React.FC<EditUserFormProps> = ({ user, onChange }) => {
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const { addSuccessAlert, addErrorAlert } = useContext(SnackbarContext)!;

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(translate('validation:invalid'))
      .required(translate('validation:required')),
    username: yup
      .string()
      .required(translate('validation:required'))
      .matches(/^[a-z0-9-_]*$/i, translate('validation:invalid'))
      .matches(/^(?!.*(__|--))/, translate('validation:invalid'))
      .max(64, translate('validation:invalid')),
    bio: yup.string().max(256, translate('validation:invalid')),
    ...(!user
      ? { password: yup.string().required(translate('validation:required')) }
      : {}),
  });

  const initialValues: EditUserFormValues = {
    email: user?.email || '',
    username: user?.username || '',
    bio: user?.bio,
    ...(!user ? { password: '' } : {}),
  };

  const handleSubmit = useCallback(
    async (
      values: EditUserFormValues,
      { setFieldValue }: FormikHelpers<EditUserFormValues>,
    ) => {
      try {
        let savedUser: User;
        let message;

        if (user) {
          savedUser = await usersApi.updateUser(user.id, {
            username: values.username,
            email: values.email,
            bio: values.bio,
          });
          message = translate('success:saved');
        } else {
          savedUser = await usersApi.createUser({
            username: values.username,
            email: values.email,
            bio: values.bio,
            password: values.password!,
          });
          message = translate('success:added');
        }

        try {
          if (values.avatarFile) {
            const data = new FormData();
            data.append('avatar', values.avatarFile);
            savedUser = await usersApi.uploadUserAvatar(savedUser.id, data);
          }

          if (values.backgroundFile) {
            const data = new FormData();
            data.append('file', values.backgroundFile);
            savedUser = await usersApi.uploadUserBackground(savedUser.id, data);
          }
        } catch (err) {
          if (user) {
            throw err;
          }
        }

        setFieldValue('avatarFile', null);
        setFieldValue('backgroundFile', null);

        addSuccessAlert(message);

        if (!user) {
          navigate(`/account/users/edit/${savedUser.id}`, {
            replace: true,
          });
        } else if (onChange) {
          onChange(savedUser);
        }
      } catch (err: any) {
        addErrorAlert(err.message || translate('error:default'));
      }
    },
    [translate, addSuccessAlert, addErrorAlert, navigate, onChange, user?.id],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <ConnectedLoading>
        <Form noValidate>
          <FormGrid>
            <ConnectedFormItem
              fieldId="username"
              fieldName="username"
              label={translate('common:username')}
              isRequired
            >
              <ConnectedInput name="username" />
            </ConnectedFormItem>

            <ConnectedFormItem
              fieldId="email"
              fieldName="email"
              label={translate('common:email')}
              isRequired
            >
              <ConnectedInput
                name="email"
                type="email"
                placeholder={translate('common:emailPlaceholder')}
              />
            </ConnectedFormItem>

            <ConnectedFormItem
              fieldId="avatarFile"
              fieldName="avatarFile"
              label={translate('common:avatar')}
            >
              <Stack dir="row" gap="4" align="center">
                <Avatar size="xl" url={user?.avatarUrl} />

                <ConnectedFileInput
                  name="avatarFile"
                  size="sm"
                  accept="image/png, image/jpeg, image/gif"
                  label={translate('common:selectFile')}
                />
              </Stack>
            </ConnectedFormItem>

            <ConnectedFormItem
              fieldId="backgroundFile"
              fieldName="backgroundFile"
              label={translate('common:background')}
            >
              <Stack dir="row" gap="4" align="center">
                <Box
                  w="full"
                  maxW="60"
                  borderRadius="md"
                  overflow="hidden"
                  shrink="0"
                >
                  <Ratio ratio={3}>
                    <ImageWrapper
                      src={user?.backgroundUrl || DEFAULT_BACKGROUND_URL}
                      fit="cover"
                    />
                  </Ratio>
                </Box>

                <ConnectedFileInput
                  name="backgroundFile"
                  size="sm"
                  accept="image/png, image/jpeg, image/gif"
                  label={translate('common:selectFile')}
                />
              </Stack>
            </ConnectedFormItem>
          </FormGrid>

          <FormikConsumer>
            {({ values }) => (
              <ConnectedFormItem
                fieldId="bio"
                fieldName="bio"
                label={translate('common:bio')}
                hint={`${values.bio?.length || 0} / 256`}
              >
                <ConnectedInput type="textarea" name="bio" rows={3} />
              </ConnectedFormItem>
            )}
          </FormikConsumer>

          {!user && (
            <ConnectedFormItem
              fieldId="password"
              fieldName="password"
              label={translate('common:password')}
              isRequired
            >
              <ConnectedInput
                name="password"
                type="password"
                placeholder={translate('common:passwordPlaceholder')}
              />
            </ConnectedFormItem>
          )}

          <FormSubmitBar>
            <ConnectedSubmit>{translate('common:save')}</ConnectedSubmit>
          </FormSubmitBar>
        </Form>
      </ConnectedLoading>
    </Formik>
  );
};

export default EditUserForm;
