import type { AxiosInstance } from 'axios';
import type { AsyncStorage } from 'types/asyncStorage';
import type { BaseJwtTokenPayload } from 'types/jwtTokenPayload';
import jwtDecode from 'jwt-decode';
import {
  AUTH_ACCESS_TOKEN_KEY,
  AUTH_REFRESH_TOKEN_KEY,
} from 'consts/authTokens';

export const applyJwtAuthInterceptors = (
  axios: AxiosInstance,
  storage: AsyncStorage,
  matchPath: (path: string) => boolean,
  getNewToken?: (refreshToken: string) => Promise<string>,
) => {
  axios.interceptors.request.use(async (request) => {
    if (!matchPath(request.url!)) {
      return request;
    }

    let token = await storage.get(AUTH_ACCESS_TOKEN_KEY);
    if (!token) {
      return request;
    }

    if (getNewToken) {
      let tokenPayload: BaseJwtTokenPayload;
      try {
        tokenPayload = jwtDecode(token) as BaseJwtTokenPayload;
      } catch {
        return request;
      }

      const { exp } = tokenPayload;
      if (exp * 1000 < Date.now() + 10 * 60 * 1000) {
        const refreshToken = await storage.get(AUTH_REFRESH_TOKEN_KEY);
        if (!refreshToken) {
          return request;
        }

        token = await getNewToken(refreshToken);
      }
    }

    return {
      ...request,
      headers: {
        ...request.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  });

  axios.interceptors.response.use(async (response) => {
    const { token, refreshToken } = response.data || {};

    if (token) {
      await storage.set(AUTH_ACCESS_TOKEN_KEY, token);
    }
    if (refreshToken) {
      await storage.set(AUTH_REFRESH_TOKEN_KEY, refreshToken);
    }
    return response;
  });
};
