import React, { forwardRef } from 'react';
import classNames from 'classnames';
import Ratio, { RatioProps } from 'components/atoms/Ratio';

import styles from './Banner.module.css';

export interface BannerProps extends Pick<RatioProps, 'ratio'> {
  url: RatioProps['backgroundImage'];
  className?: string;
}

const Banner = forwardRef<HTMLElement, BannerProps>(
  ({ url, className, ...rest }, ref) => (
    <Ratio
      data-testid="banner"
      className={classNames(styles.root, className)}
      backgroundImage={url}
      ref={ref}
      {...rest}
    />
  ),
);

export default Banner;
