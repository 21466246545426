import React from 'react';
import classNames from 'classnames';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';

import styles from './Pagination.module.css';

export interface PaginationProps
  extends Pick<ReactPaginateProps, 'pageCount' | 'initialPage'> {
  disabled?: boolean;
  align?: 'left' | 'center' | 'right';
  className?: string;
  forcePage?: number;
  onPageChange?: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  align = 'center',
  disabled = false,
  pageCount,
  initialPage,
  onPageChange,
  className,
  ...rest
}) =>
  pageCount > 1 ? (
    <div
      className={classNames(styles.root, styles[`align-${align}`], className, {
        [styles.busy]: disabled,
      })}
    >
      <ReactPaginate
        initialPage={initialPage}
        previousLabel={<ChevronLeftIcon />}
        nextLabel={<ChevronRightIcon />}
        breakLabel="..."
        breakClassName={styles.break}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={({ selected }) => onPageChange?.(selected)}
        containerClassName={styles.items}
        activeClassName={styles.active}
        disabledClassName={styles.disabled}
        previousClassName={styles.previous}
        nextClassName={styles.next}
        {...rest}
      />
    </div>
  ) : null;

export default Pagination;
