import { camelToKebabCase, isObject } from '@fanadise/common-utils';
import { THEME_PREFIX } from 'consts/themePrefix';

export const createThemeCssVariables = (
  obj: { [key: string]: any },
  prefix: string = THEME_PREFIX,
): string =>
  Object.entries(obj)
    .map(([key, value]) => {
      const propName = (prefix ? `${prefix}-` : '') + key;

      if (isObject(value)) {
        return createThemeCssVariables(value, propName);
      }

      return `--${camelToKebabCase(
        propName.replace('-base', '').replace('.', '-'),
      )}: ${value};`;
    })
    .join('');
