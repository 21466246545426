import React from 'react';
import { Routes, Route } from 'react-router-dom';

import AccountsRouteAdd from './AccountsRouteAdd';
import AccountsRouteEdit from './AccountsRouteEdit';
import AccountsRouteList from './AccountsRouteList';

const AccountsRoute: React.FC = () => (
  <Routes>
    <Route index element={<AccountsRouteList />} />
    <Route path="add" element={<AccountsRouteAdd />} />
    <Route path="edit/:id" element={<AccountsRouteEdit />} />
  </Routes>
);

export default AccountsRoute;
