import axios, { AxiosError, AxiosInstance } from 'axios';
import { ApiConfig } from 'types/apiConfig';
import {
  BaseException,
  NotFoundException,
  ForbiddenException,
  ServerErrorException,
} from 'exceptions';
import { serializeParams } from 'utils/serializeParams';

class Api {
  public axiosInstance!: AxiosInstance;

  private config!: ApiConfig;

  readonly defaultHeaders: Record<string, string> = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Device: 'device',
  };

  public get isInitialized() {
    return Boolean(this.axiosInstance);
  }

  setDefaultHeader(key: string, value: string) {
    this.defaultHeaders[key] = value;
  }

  deleteDefaultHeader(key: string) {
    delete this.defaultHeaders[key];
  }

  public initialize(config: ApiConfig = { baseUrl: '/api', isBrowser: true }) {
    this.config = config;

    this.axiosInstance = axios.create({
      baseURL: config.baseUrl,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Device: 'device',
      },
      paramsSerializer: serializeParams,
    });

    this.configureInterceptors();
  }

  private configureInterceptors() {
    this.axiosInstance.interceptors.response.use(
      undefined,
      (err: AxiosError) => {
        const message = err.response?.data.message;
        const status = err.response?.status;

        switch (status) {
          case ServerErrorException.status:
            return Promise.reject(new ServerErrorException(message));
          case NotFoundException.status:
            return Promise.reject(new NotFoundException(message));
          case ForbiddenException.status:
            return Promise.reject(new ForbiddenException(message));
          default:
            return Promise.reject(new BaseException(message, status));
        }
      },
    );
  }

  public get get() {
    return this.axiosInstance.get;
  }

  public get post() {
    return this.axiosInstance.post;
  }

  public get put() {
    return this.axiosInstance.put;
  }

  public get delete() {
    return this.axiosInstance.delete;
  }
}

export const api = new Api();
