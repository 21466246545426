import React, { useCallback, useContext } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import {
  SnackbarContext,
  ConnectedLoading,
  ConnectedSubmit,
  ConnectedFormItem,
  ConnectedInput,
} from '@fanadise/common-ui';
import FormSectionTitle from 'components/shared/FormSectionTitle';
import FormGrid from 'components/shared/FormGrid';
import FormSubmitBar from 'components/shared/FormSubmitBar';
import { Translate, useTranslation } from '@fanadise/common-logic';

interface AdminPasswordFormValues {
  oldPassword: string;
  newPassword: string;
}

export interface ChangePasswordFormProps {
  changePassword: (values: AdminPasswordFormValues) => Promise<void>;
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  changePassword,
}) => {
  const { translate } = useTranslation();
  const { addSuccessAlert, addErrorAlert } = useContext(SnackbarContext)!;

  const validationSchema = yup.object().shape({
    oldPassword: yup.string().required(translate('validation:required')),
    newPassword: yup.string().required(translate('validation:required')),
  });

  const initialValues: AdminPasswordFormValues = {
    oldPassword: '',
    newPassword: '',
  };

  const handleSubmit = useCallback(
    async (
      values: AdminPasswordFormValues,
      { resetForm }: FormikHelpers<AdminPasswordFormValues>,
    ) => {
      try {
        await changePassword(values);
        resetForm();
        addSuccessAlert(translate('success:saved'));
      } catch (err: any) {
        addErrorAlert(err.message || translate('error:default'));
      }
    },
    [translate, addSuccessAlert, addErrorAlert, changePassword],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <ConnectedLoading>
        <Form noValidate>
          <FormSectionTitle>
            <Translate id="accounts:form:password:title" />
          </FormSectionTitle>

          <FormGrid>
            <ConnectedFormItem
              fieldId="oldPassword"
              fieldName="oldPassword"
              label={translate('common:oldPassword')}
              isRequired
            >
              <ConnectedInput
                name="oldPassword"
                type="password"
                placeholder={translate('common:passwordPlaceholder')}
              />
            </ConnectedFormItem>

            <ConnectedFormItem
              fieldId="newPassword"
              fieldName="newPassword"
              label={translate('common:newPassword')}
              isRequired
            >
              <ConnectedInput
                name="newPassword"
                type="password"
                placeholder={translate('common:passwordPlaceholder')}
              />
            </ConnectedFormItem>
          </FormGrid>

          <FormSubmitBar>
            <ConnectedSubmit>{translate('common:change')}</ConnectedSubmit>
          </FormSubmitBar>
        </Form>
      </ConnectedLoading>
    </Formik>
  );
};

export default ChangePasswordForm;
