import React from 'react';
import { BasePurchaseWithUser } from '@fanadise/common-types';
import {
  FiatPrice,
  Pop,
  Table,
  Box,
  Text,
  Badge,
  CopyableText,
} from '@fanadise/common-ui';
import useTranslation from 'hooks/useTranslation';
import Translate from 'components/shared/Translate';
import TranslatedTimeAgo from 'components/shared/TranslatedTimeAgo';

export interface PurchasesTableProps {
  purchases: BasePurchaseWithUser[];
}

const PurchasesTable: React.FC<PurchasesTableProps> = ({ purchases }) => {
  const { translate } = useTranslation();

  return (
    <Table>
      <tbody>
        {purchases.map((purchase) => (
          <tr key={purchase.id}>
            <td>
              <CopyableText
                text={purchase.id}
                maxW="20"
                isTruncated
                tooltipText={translate('common:copy')}
                copiedMessage={translate('common:copied')}
              />
            </td>
            <td>
              <Box maxW="60">
                <Text color="gray.800" isTruncated>
                  {purchase.description}
                </Text>
              </Box>
            </td>
            <td>
              <Badge variant="secondary">
                <Translate
                  id={`common:purchaseContentType:${purchase.contentType}`}
                />
              </Badge>
            </td>
            {purchase.user && (
              <td>
                <Pop trigger={<span>@{purchase.user.username}</span>}>
                  <Box p="2">
                    #{purchase.userId}
                    <br />
                    <a href={`mailto:${purchase.user.email}`}>
                      {purchase.user.email}
                    </a>
                  </Box>
                </Pop>
              </td>
            )}
            <td>
              <TranslatedTimeAgo date={new Date(purchase.createdAt)} />
            </td>
            <td>
              {purchase.paid ? (
                <Badge variant="primary">
                  <Translate id="common:purchaseStatus:paid" />
                </Badge>
              ) : (
                <Badge variant="secondary">
                  <Translate id="common:purchaseStatus:unpaid" />
                </Badge>
              )}
            </td>
            <td>
              <Text fontWeight="semibold" color="gray.800" textAlign="right">
                <FiatPrice
                  value={purchase.price}
                  currency={purchase.currency}
                />
              </Text>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default PurchasesTable;
