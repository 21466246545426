import React, { useContext } from 'react';
import { Button, Icon, Menu, MenuItem, VCard } from '@fanadise/common-ui';
import { LogoutIcon, UserIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { Admin } from '@fanadise/common-types';
import AppAuthContext from 'contexts/AppAuthContext';
import { Translate } from '@fanadise/common-logic';

const ExecutorLayoutMenu: React.FC = () => {
  const { user, signOut } = useContext(AppAuthContext);

  return (
    <Menu
      trigger={
        <Button variant="text" tag="span">
          <VCard user={user as Admin} hasAtSign={false} />
        </Button>
      }
      placement="top"
      minW={10}
    >
      <Link to="/account/admin/settings">
        <MenuItem tag="a" icon={<Icon icon={UserIcon} size="sm" />}>
          <Translate id="common:settings" />
        </MenuItem>
      </Link>
      <MenuItem icon={<Icon icon={LogoutIcon} size="sm" />} onClick={signOut}>
        <Translate id="common:signOut" />
      </MenuItem>
    </Menu>
  );
};

export default ExecutorLayoutMenu;
