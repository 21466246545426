import React from 'react';
import type { Theme } from '@fanadise/common-types';
import { createThemeStyles } from 'utils/createThemeStyles';
import { DEFAULT_THEME } from 'consts/defaultTheme';

export interface ThemeStylesProps {
  theme?: Theme;
}

const ThemeStyles = ({ theme = DEFAULT_THEME }: ThemeStylesProps) => (
  <style data-testid="theme-styles">{createThemeStyles(theme)}</style>
);

export default ThemeStyles;
