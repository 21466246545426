import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';

import styles from './ToggleSwitch.module.css';

export interface ToggleSwitchProps extends HTMLAttributes<HTMLInputElement> {
  isChecked?: boolean;
  isDisabled?: boolean;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  id,
  className,
  isChecked,
  isDisabled,
  children,
  ...rest
}) => (
  <label
    data-testid="toggleSwitch"
    htmlFor={id}
    className={classNames(
      styles.root,
      {
        [styles.isChecked]: isChecked,
        [styles.isDisabled]: isDisabled,
      },
      className,
    )}
  >
    <div className={styles.switch}>
      <input
        type="checkbox"
        id={id}
        className={styles.bg}
        checked={isChecked}
        disabled={isDisabled}
        {...rest}
      />
      <span className={styles.dot} />
    </div>

    <span className={styles.label}>{children}</span>
  </label>
);

export default ToggleSwitch;
