import {
  POLYGON_MAINNET,
  POLYGON_TESTNET,
  FAN_POLYGON_TESTNET,
  FAN_POLYGON_MAINNET,
} from '@fanadise/common-consts';
import { EvmNetwork, Token } from '@fanadise/common-types';
import { isEnvFlagOn } from '@fanadise/common-utils';

export const getCurrentTokenDetails = (): Token =>
  isEnvFlagOn(process.env.NEXT_PUBLIC_TESTNET_NETWORK)
    ? FAN_POLYGON_TESTNET
    : FAN_POLYGON_MAINNET;

export const getCurrentNetworkDetails = (): EvmNetwork =>
  isEnvFlagOn(process.env.NEXT_PUBLIC_TESTNET_NETWORK)
    ? POLYGON_TESTNET
    : POLYGON_MAINNET;
