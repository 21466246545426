import React from 'react';
import Input, { InputProps } from 'components/atoms/Input';

export interface FiatPriceInputProps extends InputProps {}

const FiatPriceInput: React.FC<FiatPriceInputProps> = ({
  step = 0.01,
  min = 0,
  ...rest
}) => (
  <Input
    data-testid="fiatPriceInput"
    type="number"
    step={step}
    min={min}
    {...rest}
  />
);

export default FiatPriceInput;
