import SmartSelect, { SmartSelectProps } from 'components/shared/SmartSelect';
import React from 'react';
import { GroupBase, useStateManager } from 'react-select';
import { AsyncProps, useAsync } from 'react-select/async';

export interface AsyncSmartSelectProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> extends AsyncProps<Option, IsMulti, Group>,
    SmartSelectProps<Option, IsMulti, Group> {}

const AsyncSmartSelect = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: AsyncSmartSelectProps<Option, IsMulti, Group>,
) => {
  const stateManagedProps = useAsync(props);
  const selectProps = useStateManager(stateManagedProps);

  return <SmartSelect {...selectProps} />;
};

export default AsyncSmartSelect;
