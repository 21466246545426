import React, { useMemo } from 'react';
import {
  Sidebar,
  SidebarProps,
  SidebarTab,
  FanadiseLogo,
  Stack,
  IconProps,
  Icon,
} from '@fanadise/common-ui';
import {
  AdjustmentsIcon,
  CashIcon,
  CogIcon,
  CollectionIcon,
  CubeTransparentIcon,
  MenuAlt2Icon,
  UploadIcon,
  UserGroupIcon,
  UsersIcon,
  ViewGridIcon,
} from '@heroicons/react/outline';
import { MenuItem } from '@fanadise/common-types';
import ActiveLink from 'components/shared/ActiveLink';
import ExecutorLayoutMenu from 'components/shared/ExecutorLayout/ExecutorLayoutMenu';
import { useTranslation } from '@fanadise/common-logic';
import ActiveRoute from 'components/shared/ActiveRoute';
import { Link } from 'react-router-dom';

export interface ExecutorLayoutSidebarTab extends MenuItem {
  icon?: React.ReactElement<IconProps>;
  subItems?: MenuItem[];
}

interface ExecutorLayoutSidebarProps
  extends Omit<SidebarProps, 'header' | 'footer' | 'children'> {}

const ExecutorLayoutSidebar: React.FC<ExecutorLayoutSidebarProps> = (props) => {
  const { translate } = useTranslation();

  const menuItems = useMemo<ExecutorLayoutSidebarTab[]>(
    () => [
      {
        url: '/account/configs',
        text: translate('account:sidebar:configs'),
        icon: <Icon icon={AdjustmentsIcon} />,
      },
      {
        url: '/account/nfts',
        text: translate('account:sidebar:nfts'),
        icon: <Icon icon={CubeTransparentIcon} />,
      },
      {
        url: '/account/listings',
        text: translate('account:sidebar:listings'),
        icon: <Icon icon={ViewGridIcon} />,
      },
      {
        url: '/account/purchases',
        text: translate('account:sidebar:purchases'),
        icon: <Icon icon={CashIcon} />,
      },
      {
        url: '/account/accounts',
        text: translate('account:sidebar:accounts'),
        icon: <Icon icon={UserGroupIcon} />,
      },
      {
        url: '/account/users',
        text: translate('account:sidebar:users'),
        icon: <Icon icon={UsersIcon} />,
      },
      {
        url: '/account/categories',
        text: translate('account:sidebar:categories'),
        icon: <Icon icon={MenuAlt2Icon} />,
      },
      {
        url: '/account/collections',
        text: translate('account:sidebar:collections'),
        icon: <Icon icon={CollectionIcon} />,
      },
      {
        url: '/account/settings',
        text: translate('common:settings'),
        icon: <Icon icon={CogIcon} />,
      },
      {
        url: '/account/files',
        text: translate('account:sidebar:files'),
        icon: <Icon icon={UploadIcon} />,
      },
    ],
    [translate],
  );

  return (
    <Sidebar
      header={
        <Link to="/">
          <FanadiseLogo size="xl" color="gray.800" />
        </Link>
      }
      footer={<ExecutorLayoutMenu />}
      {...props}
    >
      <Stack gap="2" pr="2">
        {menuItems.map((item) => (
          <div key={item.url}>
            <ActiveLink to={item.url}>
              {(isActive) => (
                <SidebarTab
                  tag="span"
                  isActive={isActive}
                  // @ts-ignore
                  icon={item.icon}
                >
                  {item.text}
                </SidebarTab>
              )}
            </ActiveLink>

            <ActiveRoute path={item.url}>
              {(isActive) =>
                isActive && (
                  <>
                    {item.subItems && (
                      <Stack gap="2" my="2" ml="2">
                        {item.subItems.map((subItem) => (
                          <ActiveLink key={subItem.url} to={subItem.url}>
                            {(isSubItemActive) => (
                              <SidebarTab
                                variant="sub"
                                tag="span"
                                isActive={isSubItemActive}
                              >
                                {subItem.text} {isSubItemActive}
                              </SidebarTab>
                            )}
                          </ActiveLink>
                        ))}
                      </Stack>
                    )}
                  </>
                )
              }
            </ActiveRoute>
          </div>
        ))}
      </Stack>
    </Sidebar>
  );
};

export default ExecutorLayoutSidebar;
