import React from 'react';
import { useField } from 'formik';
import FiatPriceInput, {
  FiatPriceInputProps,
} from 'components/molecules/FiatPriceInput';

export interface ConnectedFiatPriceInputProps extends FiatPriceInputProps {
  name: string;
}

const ConnectedFiatPrice: React.FC<ConnectedFiatPriceInputProps> = ({
  name,
  ...rest
}) => {
  const [field, { error, touched }] = useField({ name });
  const isInvalid = touched && Boolean(error);

  return <FiatPriceInput isInvalid={isInvalid} {...field} {...rest} />;
};

export default ConnectedFiatPrice;
