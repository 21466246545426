import React, { useContext } from 'react';
import useAuthRedirect from 'hooks/useAuthRedirect';
import AppAuthContext from 'contexts/AppAuthContext';

const UnauthenticatedRoute: React.FC = ({ children }) => {
  useAuthRedirect();
  const { isAuthenticated, isTried } = useContext(AppAuthContext);

  return (isTried && !isAuthenticated ? children : null) as React.ReactElement;
};

export default UnauthenticatedRoute;
