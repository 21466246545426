import React from 'react';
import { Toolbar, Panel } from '@fanadise/common-ui';
import { Translate } from '@fanadise/common-logic';
import SettingsForm from 'components/dashboardSettings/DashboardSettingsForm';

const DashboardSettingsRoute: React.FC = () => (
  <>
    <Toolbar title={<Translate id="common:settings" />} mb="8" />

    <Panel>
      <SettingsForm />
    </Panel>
  </>
);

export default DashboardSettingsRoute;
