import React from 'react';
import classNames from 'classnames';
import { getFileExt } from '@fanadise/common-utils';
import Image, { ImageProps } from 'components/atoms/Image';

import styles from './FilePreview.module.css';

export interface FilePreviewProps {
  url: string;
  fit?: ImageProps['fit'];
  size?: 'sm' | 'md';
  className?: string;
}

const IMAGE_FILE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'webp'];

const FilePreview: React.FC<FilePreviewProps> = ({
  url,
  size = 'md',
  fit = 'cover',
  className,
}) => {
  const fileExt = getFileExt(url);
  const isImage = IMAGE_FILE_EXTENSIONS.includes(fileExt);

  return (
    <div
      data-testid="filePreview"
      className={classNames(styles.root, styles[`size--${size}`], className)}
    >
      {isImage ? <Image src={url} fit={fit} align="center" /> : `.${fileExt}`}
    </div>
  );
};

export default FilePreview;
