const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

export const abbreviateNumber = (number: number) => {
  // eslint-disable-next-line
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;

  if (tier === 0) return number;

  const suffix = SI_SYMBOL[tier];
  // eslint-disable-next-line
  const scale = Math.pow(10, tier * 3);

  const scaled = number / scale;

  return scaled.toFixed(1) + suffix;
};
