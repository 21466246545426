import React from 'react';
import classNames from 'classnames';

import { IconProps } from 'components/atoms/Icon';
import styles from './Tab.module.css';

export interface TabProps {
  tag?: 'li' | 'button' | 'span' | 'a';
  icon?: React.ReactElement<IconProps>;
  isActive?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const Tab: React.FC<TabProps> = ({
  tag: Tag = 'button',
  icon,
  isActive = false,
  children,
  className,
  ...rest
}) => (
  <Tag
    data-testid="tab"
    className={classNames(styles.root, className, {
      [styles.isActive]: isActive,
    })}
    {...(typeof children === 'string' ? { title: children } : {})}
    {...rest}
  >
    <span className={styles.wrap}>
      {icon &&
        React.cloneElement(icon, {
          className: classNames(styles.icon, icon.props.className),
        })}

      {children && <span className={styles.label}>{children}</span>}
    </span>
  </Tag>
);

export default Tab;
