import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import Chip from 'components/atoms/Chip';

import styles from './ChipsSelect.module.css';

export interface ChipsSelectProps<T extends string | string[]> {
  options: { value: string; label: string; imageUrl?: string }[];
  value: T;
  onChange: (value: T) => void;
  className?: string;
}

const ChipsSelect = <T extends string | string[]>({
  options,
  value,
  onChange,
  className,
}: PropsWithChildren<ChipsSelectProps<T>>) => {
  const isMultiple = Array.isArray(value);

  return (
    <div data-testid="tagSelect" className={classNames(styles.root, className)}>
      {options.map((option) => {
        const isActive = isMultiple
          ? value.includes(option.value)
          : value === option.value;

        const handleChange = () => {
          if (isMultiple) {
            onChange(
              (isActive
                ? value.filter((v) => v !== option.value)
                : [...value, option.value]) as T,
            );
          } else {
            onChange(option.value as T);
          }
        };

        return (
          <Chip
            key={option.value}
            onClick={handleChange}
            isActive={isActive}
            imageUrl={option.imageUrl}
            className={styles.tag}
          >
            {option.label}
          </Chip>
        );
      })}
    </div>
  );
};

export default ChipsSelect;
