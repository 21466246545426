import { purchasesApi } from '@fanadise/common-data-access';
import { Purchase } from '@fanadise/common-types';
import { useRef, useState, useEffect } from 'react';

const usePurchaseStatusChecker = (
  purchaseId: string,
): [Purchase | undefined, any] => {
  const [purchase, setPurchase] = useState<Purchase>();
  const [error, setError] = useState<any>();
  const statusInterval = useRef<ReturnType<Window['setInterval']>>();

  useEffect(() => {
    if (!purchaseId) {
      return;
    }

    const fetchPurchase = async () => {
      try {
        setPurchase(await purchasesApi.fetchPurchase(purchaseId));
      } catch (err: any) {
        setError(err);
      }
    };

    statusInterval.current = window.setInterval(fetchPurchase, 3000);
    fetchPurchase();

    return () => {
      window.clearInterval(statusInterval.current);
    };
  }, [purchaseId]);

  useEffect(() => {
    if (purchase?.paid) {
      window.clearInterval(statusInterval.current);
    }
  }, [purchase?.paid]);

  return [purchase, error];
};

export default usePurchaseStatusChecker;
