import { VIDEO_FILE_EXTENSIONS } from '@fanadise/common-consts';

export const getFileExtFromUrl = (url: string) => {
  const ext = url.split('.').pop();
  return ext!.replace(/\?.+/, '');
};

export const isVideoUrl = (url: string): boolean => {
  const ext = getFileExtFromUrl(url);

  if (VIDEO_FILE_EXTENSIONS.includes(ext)) {
    return true;
  }

  return false;
};
