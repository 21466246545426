import React, { useEffect, useState } from 'react';
import Stack from 'components/atoms/Stack';
import Text from 'components/atoms/Text';
import Box from 'components/atoms/Box';
import { ResponsiveProperty } from 'types/responsiveProperty';
import { ThemeProperties } from 'types/themeProperty';

import styles from './PasswordStrengthBar.module.css';

export interface PasswordStrengthBarProps {
  password: string;
  minLength?: number;
  onChangeScore?: (score: number) => void;
  renderLabel: (
    score: number,
    isTooShort?: boolean,
  ) => React.ReactNode | string;
}

const PasswordStrengthBar: React.FC<PasswordStrengthBarProps> = ({
  password,
  minLength = 6,
  onChangeScore,
  renderLabel,
}) => {
  const [score, setScore] = useState(0);
  const isTooShort = password.length < minLength;
  const scoreRegexps = [
    new RegExp(`.{${minLength},}`),
    /[A-Z]/,
    /[0-9]/,
    /[!@#$%^&*]/,
  ];
  const scoreColors = ['error', 'warning', 'success'] as ResponsiveProperty<
    ThemeProperties['color']
  >[];

  useEffect(() => {
    if (isTooShort) {
      setScore(0);
    } else {
      const newScore = scoreRegexps.reduce(
        (sum, regex) => sum + (regex.test(password) ? 1 : 0),
        0,
      );
      setScore(Math.min(newScore, scoreColors.length));
    }
  }, [password]);

  useEffect(() => {
    onChangeScore?.(score);
  }, [score]);

  return (
    <Stack dir="column" gap="1">
      <Stack dir="row" gap="2">
        {new Array(scoreColors.length).fill(null).map((_, i) => (
          <Box
            key={i}
            w="full"
            borderRadius="md"
            h="0.5"
            bg={i >= score ? 'gray.200' : scoreColors[score - 1]}
            className={styles.bar}
          />
        ))}
      </Stack>
      <Text
        fontSize="sm"
        color={scoreColors[Math.max(0, score - 1)]}
        lineHeight="sm"
      >
        {renderLabel(score - 1, isTooShort)}
      </Text>
    </Stack>
  );
};

export default PasswordStrengthBar;
