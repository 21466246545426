import React from 'react';
import classNames from 'classnames';

import styles from './Radio.module.css';

export interface RadioProps {
  isChecked?: boolean;
  id?: string;
  name?: string;
  value?: string;
  isInvalid?: boolean;
  isDisabled?: boolean;
  isAutofocusable?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
  children?: React.ReactNode;
}

const Radio: React.FC<RadioProps> = ({
  id,
  className,
  isChecked,
  isInvalid = false,
  isDisabled = false,
  isReadOnly = false,
  isRequired = false,
  isAutofocusable = false,
  children,
  ...rest
}) => (
  <label
    htmlFor={id}
    className={classNames(
      styles.root,
      { [styles.isChecked]: isChecked },
      { [styles.isDisabled]: isDisabled },
      { [styles.isInvalid]: isInvalid },
      className,
    )}
  >
    <input
      data-testid="radio"
      type="radio"
      id={id}
      checked={isChecked}
      disabled={isDisabled}
      autoFocus={isAutofocusable}
      readOnly={isReadOnly}
      required={isRequired}
      {...rest}
    />

    {children}
  </label>
);

export default Radio;
