import React from 'react';
import { Nft } from '@fanadise/common-types';
import { ImageWrapper } from '@fanadise/common-ui';
import { isVideoUrl } from 'utils/urlUtils';

interface NftDetailsMediaProps {
  nft: Nft;
  onLoad?: () => void;
}

const NftDetailsMedia: React.FC<NftDetailsMediaProps> = ({ nft, onLoad }) =>
  isVideoUrl(nft.config.imageUrl) ? (
    <video
      src={nft.config.imageUrl}
      controls
      autoPlay
      preload="metadata"
      controlsList="nodownload"
      onLoadedData={onLoad}
    />
  ) : (
    <ImageWrapper
      src={nft.config.imageUrl}
      alt={nft.config.name}
      onLoad={onLoad}
    />
  );

export default NftDetailsMedia;
