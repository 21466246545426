import React, { useContext } from 'react';
import LinkContext, { LinkContextValue } from 'contexts/LinkContext';

const Link: React.FC<Required<LinkContextValue['link']['defaultProps']>> = (
  props,
) => {
  const { link: LinkComponent } = useContext(LinkContext)!;

  return <LinkComponent {...props} />;
};

export default Link;
