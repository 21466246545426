import React, { forwardRef } from 'react';

import Text, { TextProps } from 'components/atoms/Text';

export interface TinyHeadingProps extends TextProps {}

const TinyHeading = forwardRef<HTMLElement, TinyHeadingProps>(
  ({ tag = 'h6', fontWeight = 'semibold', fontSize = 'sm', ...rest }, ref) => (
    <Text
      tag={tag}
      fontWeight={fontWeight}
      fontSize={fontSize}
      letterSpacing="0.025em"
      color="gray"
      textTransform="uppercase"
      ref={ref}
      {...rest}
    />
  ),
);

export default TinyHeading;
