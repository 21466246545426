import React, { HTMLAttributes, memo } from 'react';

export interface HtmlContentProps extends HTMLAttributes<HTMLElement> {
  content: string;
}

const HtmlContent: React.FC<HtmlContentProps> = ({ content, ...rest }) => (
  <div
    data-testid="htmlContent"
    /* eslint-disable-next-line react/no-danger */
    dangerouslySetInnerHTML={{ __html: content }}
    {...rest}
  />
);

export default memo(HtmlContent);
