import React from 'react';
import classNames from 'classnames';
import { CheckIcon } from '@heroicons/react/outline';

import styles from './Checkbox.module.css';

export interface CheckboxProps {
  isChecked?: boolean;
  id?: string;
  name?: string;
  isInvalid?: boolean;
  isDisabled?: boolean;
  isAutofocusable?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  className?: string;
  children?: React.ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  isChecked,
  isInvalid = false,
  isDisabled = false,
  isReadOnly = false,
  isRequired = false,
  isAutofocusable = false,
  className,
  children,
  ...rest
}) => (
  <label
    htmlFor={id}
    className={classNames(
      styles.root,
      { [styles.isChecked]: isChecked },
      { [styles.isInvalid]: isInvalid },
      { [styles.isDisabled]: isDisabled },
      className,
    )}
  >
    <input
      data-testid="checkbox"
      type="checkbox"
      id={id}
      checked={isChecked}
      disabled={isDisabled}
      autoFocus={isAutofocusable}
      readOnly={isReadOnly}
      required={isRequired}
      {...rest}
    />
    <span className={styles.box}>{isChecked && <CheckIcon />}</span>

    {children}
  </label>
);

export default Checkbox;
