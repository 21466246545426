import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/outline';
import Icon from 'components/atoms/Icon';
import { SnackbarAlertOptions } from './types/snackbarAlertOptions';

import styles from './Snackbar.module.css';

interface SnackbarMessageProps {
  options: SnackbarAlertOptions;
  onClose: (id: string) => void;
  autoCloseDuration: number;
}

const SnackbarMessage: React.FC<SnackbarMessageProps> = ({
  options,
  onClose,
  autoCloseDuration,
}) => {
  const mountDate = useRef(new Date());
  const CurrentIcon =
    options.type &&
    (options.type === 'success' ? CheckCircleIcon : ExclamationCircleIcon);

  useEffect(() => {
    const autoCloseDurationTimeout = setTimeout(
      onClose,
      mountDate.current.getTime() + autoCloseDuration - Date.now(),
      options.id,
    );

    return () => {
      clearTimeout(autoCloseDurationTimeout);
    };
  }, [options.id, onClose, autoCloseDuration]);

  return (
    <li
      className={classNames(
        styles.message,
        options.type && styles[`type--${options.type}`],
      )}
    >
      {CurrentIcon && <Icon icon={CurrentIcon} />}
      <p>{options.message}</p>
    </li>
  );
};

export default SnackbarMessage;
