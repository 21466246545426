import React from 'react';
import type { Currency } from '@fanadise/common-consts';

type CurrencyContextValue = Currency;

const CurrencyContext = React.createContext<CurrencyContextValue>(
  // @ts-ignore
  null,
);

export default CurrencyContext;
