import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';

import styles from './Table.module.css';

export interface TableProps extends HTMLAttributes<HTMLDivElement> {}

const Table: React.FC<TableProps> = ({ className, children, ...rest }) => (
  <div
    className={classNames(styles.root, className)}
    data-testid="table"
    {...rest}
  >
    <table className={styles.table}>{children}</table>
  </div>
);

export default Table;
