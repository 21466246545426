import React from 'react';
import Radio, { RadioProps } from 'components/atoms/Radio';
import { useField } from 'formik';

export interface ConnectedRadioProps extends RadioProps {
  name: string;
}

const ConnectedRadio: React.FC<ConnectedRadioProps> = ({
  name,
  value,
  ...rest
}) => {
  const [field] = useField({ name });

  return (
    <Radio
      isChecked={value === field.value}
      {...field}
      value={value}
      {...rest}
    />
  );
};

export default ConnectedRadio;
