import { api } from 'api';
import type {
  BaseAccount,
  Nft,
  Post,
  Subscription,
  Transaction,
  User,
  SingleResultDto,
  MultiResultsDto,
  BasePurchaseWithUser,
  BaseNft,
} from '@fanadise/common-types';
import type { PaginatedRequestQuery } from 'types/paginatedRequestQuery';
import type { PaginatedResponseData } from 'types/paginatedResponseData';
import { NftStatus, TransactionType } from '@fanadise/common-consts';

export const fetchProfile = async () => {
  const res = await api.get<SingleResultDto<'user', User>>('/profile');
  return res.data.user;
};

export const updateProfile = async (data: Pick<User, 'username' | 'bio'>) => {
  const res = await api.put<SingleResultDto<'user', User>>('/profile', data);
  return res.data.user;
};

export const updateProfilePassword = async (data: { password: string }) => {
  await api.put('/profile/password', data);
};

export const uploadProfileAvatar = async (data: FormData) => {
  const res = await api.post<SingleResultDto<'user', User>>(
    '/profile/avatar',
    data,
  );
  return res.data.user;
};

export const uploadProfileBackground = async (data: FormData) => {
  const res = await api.post<SingleResultDto<'user', User>>(
    '/profile/background',
    data,
  );
  return res.data.user;
};

export const deleteProfilePaymentMethod = async () => {
  const res = await api.delete<SingleResultDto<'user', User>>(
    '/profile/payment-method',
  );
  return res.data.user;
};

export const updateProfileSubscription = async (data: { planId: string }) => {
  const res = await api.put<SingleResultDto<'user', User>>(
    '/profile/subscription',
    data,
  );
  return res.data.user;
};

export const cancelProfileSubscription = async () => {
  const res = await api.delete<SingleResultDto<'user', User>>(
    '/profile/subscription',
  );
  return res.data.user;
};

export const fetchProfilePurchases = async (
  params: PaginatedRequestQuery = { page: 1, size: 30 },
) => {
  const res = await api.get<
    MultiResultsDto<'purchases', BasePurchaseWithUser> & PaginatedResponseData
  >('/profile/purchases', { params });
  return res.data;
};

export const fetchProfileTransactions = async (
  type: TransactionType,
  params: PaginatedRequestQuery = { page: 1, size: 30 },
) => {
  const res = await api.get<
    MultiResultsDto<'transactions', Transaction> & PaginatedResponseData
  >(`/profile/transactions/${type}`, {
    params,
  });
  return res.data;
};

export const fetchProfileFavourites = async () => {
  const res = await api.get<MultiResultsDto<'posts', Post>>(
    '/profile/favourites',
  );
  return res.data;
};

export const heartPost = async (postId: String) => {
  const res = await api.post<SingleResultDto<'user', User>>(
    '/profile/favourites',
    {
      postId,
    },
  );
  return res.data.user;
};

export const unheartPost = async (postId: String) => {
  const res = await api.delete<SingleResultDto<'user', User>>(
    `/profile/favourites/${postId}`,
  );
  return res.data.user;
};

export const likePost = async (postId: String) => {
  const res = await api.post<SingleResultDto<'user', User>>('/profile/likes', {
    postId,
  });
  return res.data.user;
};

export const unlikePost = async (postId: String) => {
  const res = await api.delete<SingleResultDto<'user', User>>(
    `/profile/likes/${postId}`,
  );
  return res.data.user;
};

export const fetchProfileConnectedAccounts = async () => {
  const res = await api.get<MultiResultsDto<'accounts', BaseAccount>>(
    '/profile/accounts',
  );
  return res.data;
};

export const fetchProfileSubscriptions = async () => {
  const res = await api.get<MultiResultsDto<'subscriptions', Subscription>>(
    '/profile/subscriptions',
  );
  return res.data;
};

export const fetchProfileNfts = async (status?: NftStatus | NftStatus[]) => {
  const res = await api.get<MultiResultsDto<'nfts', BaseNft>>('/profile/nfts', {
    params: { status },
  });

  return res.data;
};

export const fetchProfileNft = async (nftId: string) => {
  const res = await api.get<SingleResultDto<'nft', Nft>>(
    `/profile/nfts/${nftId}`,
  );
  return res.data.nft;
};

export const claimProfileNft = async (nftId: string) => {
  const res = await api.post<SingleResultDto<'nft', Nft>>(
    `/profile/nfts/${nftId}/claim`,
  );
  return res.data;
};

export const claimAllProfileNfts = async () => {
  const res = await api.post<MultiResultsDto<'nfts', Nft>>(
    '/profile/nfts/claim',
  );
  return res.data;
};

export const setProfileWalletAddress = async (address: string) => {
  const res = await api.put<SingleResultDto<'user', User>>('/profile/wallet', {
    address,
  });
  return res.data.user;
};

export const verifyProfileEmail = async (): Promise<void> => {
  await api.post('/profile/email/verify');
};

export const confirmProfileEmail = async (token: string) => {
  const res = await api.post<SingleResultDto<'user', User>>(
    '/profile/email/confirm',
    {
      token,
    },
  );

  return res.data.user;
};
