import React, {
  forwardRef,
  CSSProperties,
  ImgHTMLAttributes,
  useContext,
} from 'react';
import classNames from 'classnames';

import ThemeContext from 'contexts/ThemeContext';
import { ThemeProperties } from 'types/themeProperty';
import styles from './Image.module.css';

export interface ImageProps extends ImgHTMLAttributes<HTMLElement> {
  src: string;
  alt?: string;
  display?: CSSProperties['display'];
  fit?: CSSProperties['objectFit'];
  align?: CSSProperties['objectPosition'];
  w?: ThemeProperties['size'];
  h?: ThemeProperties['size'];
  maxW?: ThemeProperties['size'];
  minW?: ThemeProperties['size'];
  borderRadius?: ThemeProperties['borderRadius'];
}

const Image = forwardRef<HTMLElement, ImageProps>(
  (
    {
      src,
      alt,
      display,
      fit,
      align,
      w,
      maxW,
      minW,
      h,
      borderRadius,
      style,
      className,
      ...rest
    },
    ref,
  ) => {
    const { convertSize, convertBorderRadius } = useContext(ThemeContext);

    const convertedStyle: { [key: string]: any } = {
      display,
      objectFit: fit,
      objectPosition: align,
      width: convertSize(w),
      maxWidth: convertSize(maxW),
      minWidth: convertSize(minW),
      height: convertSize(h),
      borderRadius: convertBorderRadius(borderRadius),
    };

    return (
      <img
        // @ts-ignore
        ref={ref}
        src={src}
        alt={alt}
        style={{
          ...convertedStyle,
          ...style,
        }}
        className={classNames(styles.root, className)}
        {...rest}
      />
    );
  },
);

export default Image;
