import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ExecutorLayout from 'components/shared/ExecutorLayout';

import AccountsRoute from 'components/accounts/AccountsRoute';
import UsersRoute from 'components/users/UsersRoute/UsersRoute';
import AdminSettingsRoute from 'components/adminSettings/AdminSettingsRoute';
import FilesRouteList from 'components/files/FilesRouteList';
import CategoriesRoute from 'components/categories/CategoriesRoute';
import { ConfirmDialogProvider } from '@fanadise/common-ui';
import { useTranslation } from '@fanadise/common-logic';
import CollectionsRoute from 'components/collections/CollectionsRoute';
import DashboardSettingsRoute from 'components/dashboardSettings/DashboardSettingsRoute';
import NftConfigsRoute from 'components/nftConfigs/NftConfigsRoute';
import NftListingsRoute from 'components/nftListings/NftListingsRoute';
import NftsRoute from 'components/nfts/NftsRoute';
import PurchasesRoute from 'components/purchases/PurchasesRoute';

const AccountPage: React.FC = () => {
  const { translate } = useTranslation();

  return (
    <ConfirmDialogProvider
      defaultTitle={translate('common:confirm:title')}
      defaultCancelLabel={translate('common:cancel')}
      defaultConfirmLabel={translate('common:confirm')}
    >
      <ExecutorLayout>
        <Routes>
          <Route path="configs/*" element={<NftConfigsRoute />} />
          <Route path="listings/*" element={<NftListingsRoute />} />
          <Route path="nfts/*" element={<NftsRoute />} />
          <Route path="accounts/*" element={<AccountsRoute />} />
          <Route path="users/*" element={<UsersRoute />} />
          <Route path="categories/*" element={<CategoriesRoute />} />
          <Route path="collections/*" element={<CollectionsRoute />} />
          <Route path="files/*" element={<FilesRouteList />} />
          <Route path="settings" element={<DashboardSettingsRoute />} />
          <Route path="purchases/*" element={<PurchasesRoute />} />
          <Route path="admin/settings" element={<AdminSettingsRoute />} />
          <Route path="*" element={<Navigate to="configs" />} />
        </Routes>
      </ExecutorLayout>
    </ConfirmDialogProvider>
  );
};

export default AccountPage;
