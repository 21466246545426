import React from 'react';

const MaticCurrencyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
      fill="#763FE1"
    />
    <g clipPath="url(#maticClipPath)">
      <path
        d="M13.0715 7.94711C12.8521 7.82173 12.57 7.82173 12.3192 7.94711L10.564 8.98144L9.37298 9.63964L7.6491 10.674C7.42969 10.7993 7.1476 10.7993 6.89686 10.674L5.5491 9.85905C5.32969 9.73367 5.17298 9.48293 5.17298 9.20084V7.63367C5.17298 7.38293 5.29835 7.13218 5.5491 6.97547L6.89686 6.19188C7.11626 6.06651 7.39835 6.06651 7.6491 6.19188L8.99686 7.00681C9.21626 7.13218 9.37298 7.38293 9.37298 7.66502V8.69935L10.564 8.00979V6.94412C10.564 6.69338 10.4386 6.44263 10.1879 6.28591L7.68044 4.81278C7.46104 4.68741 7.17895 4.68741 6.9282 4.81278L4.35805 6.31726C4.10731 6.44263 3.98193 6.69338 3.98193 6.94412V9.89039C3.98193 10.1411 4.10731 10.3919 4.35805 10.5486L6.89686 12.0217C7.11626 12.1471 7.39835 12.1471 7.6491 12.0217L9.37298 11.0187L10.564 10.3292L12.2879 9.32621C12.5073 9.20084 12.7894 9.20084 13.0401 9.32621L14.3879 10.1098C14.6073 10.2352 14.764 10.4859 14.764 10.768V12.3352C14.764 12.5859 14.6386 12.8367 14.3879 12.9934L13.0715 13.777C12.8521 13.9023 12.57 13.9023 12.3192 13.777L10.9715 12.9934C10.7521 12.868 10.5954 12.6173 10.5954 12.3352V11.3322L9.40432 12.0217V13.0561C9.40432 13.3068 9.52969 13.5576 9.78044 13.7143L12.3192 15.1874C12.5386 15.3128 12.8207 15.3128 13.0715 15.1874L15.6103 13.7143C15.8297 13.5889 15.9864 13.3382 15.9864 13.0561V10.0785C15.9864 9.8277 15.861 9.57696 15.6103 9.42024L13.0715 7.94711Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="maticClipPath">
        <rect
          width="12.0358"
          height="10.5"
          fill="white"
          transform="translate(3.98193 4.75)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default MaticCurrencyIcon;
