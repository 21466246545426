import React, { useCallback, useState } from 'react';
import {
  Badge,
  Box,
  CopyableText,
  Icon,
  MenuItem,
  Panel,
  Text,
} from '@fanadise/common-ui';
import { BaseNft, Nft } from '@fanadise/common-types';
import ResourcesList from 'components/shared/ResourcesList';
import { useTranslation } from '@fanadise/common-logic';
import { capitalize, shortenAddress } from '@fanadise/common-utils';
import { NftStatus } from '@fanadise/common-consts';
import {
  LinkIcon,
  PencilIcon,
  ViewGridAddIcon,
} from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import AssignUserModal from 'components/nfts/AssignUserModal';
import ListNftModal from 'components/nfts/ListNftModal';
import { nftsApi } from '@fanadise/common-data-access';
import UnassignUserModal from 'components/nfts/UnassignUserModal';

const NftsList: React.FC = () => {
  const { translate } = useTranslation();

  const [selectedNft, setSelectedNft] =
    useState<{ nft: BaseNft; action: 'assign' | 'unassign' | 'list' } | null>(
      null,
    );

  const handleAssign = useCallback((nft: BaseNft) => {
    setSelectedNft({ action: 'assign', nft });
  }, []);

  const handleUnassign = useCallback(async (nft: BaseNft) => {
    setSelectedNft({ action: 'unassign', nft });
  }, []);

  const handleListNft = useCallback((nft: BaseNft) => {
    setSelectedNft({ action: 'list', nft });
  }, []);

  return (
    <>
      <Panel>
        <ResourcesList<Nft, 'nfts'>
          dataKey="nfts"
          fetchData={nftsApi.fetchNfts}
          sortedProps={['createdAt']}
          columns={[
            {
              prop: 'id',
              label: translate('common:id'),
              renderCell: ({ id }) => (
                <CopyableText
                  maxW="16"
                  text={id}
                  tooltipText={translate('common:copy')}
                  copiedMessage={translate('common:copied')}
                />
              ),
            },
            {
              prop: 'config.name',
              label: translate('nfts:list:name'),
              renderCell: ({ config }) =>
                config ? (
                  <Box maxW="60">
                    <Text color="gray.800" fontWeight="semibold" isTruncated>
                      {config.name}
                    </Text>
                  </Box>
                ) : (
                  '--'
                ),
            },
            {
              prop: 'config.accountId',
              label: translate('nfts:list:accountId'),
              renderCell: ({ config }) =>
                config ? (
                  <CopyableText
                    maxW="16"
                    text={config.accountId}
                    tooltipText={translate('common:copy')}
                    copiedMessage={translate('common:copied')}
                  />
                ) : (
                  '--'
                ),
            },
            {
              prop: 'userId',
              label: translate('nfts:list:userId'),
              renderCell: ({ userId }) =>
                userId ? (
                  <CopyableText
                    maxW="16"
                    text={userId}
                    tooltipText={translate('common:copy')}
                    copiedMessage={translate('common:copied')}
                  />
                ) : (
                  '--'
                ),
            },
            {
              prop: 'status',
              label: translate('common:status'),
              renderCell: ({ status }) =>
                status === NftStatus.Claimed ? (
                  <Badge variant="primary">{capitalize(status)}</Badge>
                ) : (
                  <Badge variant="secondary">
                    {status ? capitalize(status) : '--'}
                  </Badge>
                ),
            },
            {
              prop: 'address',
              label: translate('nfts:list:address'),
              renderCell: ({ address }) =>
                address ? (
                  <CopyableText
                    text={address!}
                    tooltipText={translate('common:copy')}
                    copiedMessage={translate('common:copied')}
                  >
                    {shortenAddress(address!, 3)}
                  </CopyableText>
                ) : (
                  '--'
                ),
            },
            {
              prop: 'tokenId',
              label: translate('nfts:list:tokenId'),
              renderCell: ({ tokenId }) => (
                <CopyableText
                  maxW="16"
                  text={tokenId.toString()}
                  tooltipText={translate('common:copy')}
                  copiedMessage={translate('common:copied')}
                />
              ),
            },
            {
              prop: 'createdAt',
              label: translate('common:createdAt'),
              renderCell: ({ createdAt }) => (
                <time dateTime={new Date(createdAt).toISOString()}>
                  {new Date(createdAt).toLocaleDateString()}
                </time>
              ),
            },
          ]}
          renderMenu={(item) => (
            <>
              {item.status === NftStatus.Minted && (
                <MenuItem
                  icon={<Icon icon={ViewGridAddIcon} size="sm" />}
                  onClick={() => handleListNft(item)}
                >
                  {translate('nfts:list:list')}
                </MenuItem>
              )}

              {[NftStatus.Minted, NftStatus.Listed].includes(item.status) && (
                <MenuItem
                  icon={<Icon icon={LinkIcon} size="sm" />}
                  onClick={() => handleAssign(item)}
                >
                  {translate('nfts:list:assign')}
                </MenuItem>
              )}

              {item.status === NftStatus.Assigned && (
                <MenuItem
                  icon={<Icon icon={LinkIcon} size="sm" />}
                  onClick={() => handleUnassign(item)}
                >
                  {translate('nfts:list:unassign')}
                </MenuItem>
              )}

              <Link to={`/account/configs/edit/${item.configId}`}>
                <MenuItem
                  tag="span"
                  icon={<Icon icon={PencilIcon} size="sm" />}
                >
                  {translate('common:edit')}
                </MenuItem>
              </Link>
            </>
          )}
        />
      </Panel>

      {selectedNft && (
        <>
          {selectedNft.action === 'assign' && (
            <AssignUserModal
              nft={selectedNft.nft}
              onSuccess={() => {
                setSelectedNft(null);
                window.location.reload();
              }}
              onClose={() => setSelectedNft(null)}
            />
          )}

          {selectedNft.action === 'unassign' && (
            <UnassignUserModal
              nft={selectedNft.nft}
              onSuccess={() => {
                setSelectedNft(null);
                window.location.reload();
              }}
              onClose={() => setSelectedNft(null)}
            />
          )}

          {selectedNft.action === 'list' && (
            <ListNftModal
              nft={selectedNft.nft}
              onSuccess={() => {
                setSelectedNft(null);
                window.location.reload();
              }}
              onClose={() => setSelectedNft(null)}
            />
          )}
        </>
      )}
    </>
  );
};

export default NftsList;
