import React, { useState, useRef } from 'react';
import { Icon, Input, InputProps } from '@fanadise/common-ui';
import useTranslation from 'hooks/useTranslation';
import classNames from 'classnames';
import { SearchIcon } from '@heroicons/react/outline';

import styles from './SearchInput.module.css';

export interface SearchInputProps
  extends Omit<InputProps, 'value' | 'onChange'> {
  defaultValue?: string | null;
  onChange: (value: string) => void;
  debounce?: number;
}

const SearchInput: React.FC<SearchInputProps> = ({
  defaultValue,
  onChange,
  debounce = 500,
  className,
  ...rest
}) => {
  const { translate } = useTranslation();
  const [value, setValue] = useState(defaultValue || '');
  const debounceRef = useRef<number>();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newValue = e.target.value;
    setValue(newValue);

    window.clearTimeout(debounceRef.current);
    debounceRef.current = window.setTimeout(
      () => onChange(newValue),
      newValue.length === 0 ? 0 : debounce,
    );
  };

  return (
    <div className={classNames(styles.root, className)}>
      <Input
        value={value}
        onChange={handleChange}
        placeholder={translate('common:search')}
        icon={<Icon icon={SearchIcon} />}
        className={styles.input}
        {...rest}
      />
    </div>
  );
};

export default SearchInput;
