import React from 'react';
import classNames from 'classnames';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/outline';
import { useFormikContext } from 'formik';
import Alert from 'components/atoms/Alert';

import Icon from 'components/atoms/Icon';
import styles from './ConnectedMessages.module.css';

export interface ConnectedMessagesProps {
  className?: string;
}

const ConnectedMessages: React.FC<ConnectedMessagesProps> = ({ className }) => {
  const { status } = useFormikContext();

  if (!status) {
    return null;
  }

  const successMessages = (status.successMessages || []) as string[];
  const errorMessages = (status.errorMessages || []) as string[];

  return (
    <div className={classNames(styles.root, className)}>
      {successMessages.map((message, i) => (
        <Alert
          key={i}
          icon={<Icon icon={CheckCircleIcon} />}
          message={message}
          type="success"
        />
      ))}
      {errorMessages.map((message, i) => (
        <Alert
          key={i}
          icon={<Icon icon={ExclamationCircleIcon} />}
          message={message}
          type="error"
        />
      ))}
    </div>
  );
};

export default ConnectedMessages;
