import React from 'react';
import { BaseNftListing, Category } from '@fanadise/common-types';
import classNames from 'classnames';
import Ratio from 'components/atoms/Ratio';
import ImageWrapper from 'components/atoms/ImageWrapper';
import NftSupply from 'components/atoms/NftSupply';
import Tooltip from 'components/atoms/Tooltip';
import NftRarityBadge from 'components/atoms/NftRarityBadge';
import Avatar from 'components/atoms/Avatar';
import FiatPrice from 'components/atoms/FiatPrice';
import Badge from 'components/atoms/Badge';

import { DEFAULT_ICON_URL, NftListingStatus } from '@fanadise/common-consts';
import styles from './NftListingCard.module.css';

export interface NftListingCardProps {
  nftListing: BaseNftListing;
  category?: Category;
  ratio?: number;
  isDisabled?: boolean;
  onClick?: () => void;
}

const NftListingCard: React.FC<NftListingCardProps> = ({
  nftListing,
  category,
  ratio = 0.75,
  isDisabled = false,
  onClick,
}) => {
  const { name, rarity, coverUrl, creator, firstTokenId, totalSupply } =
    nftListing.nft.config;

  return (
    <Ratio
      ratio={ratio}
      className={classNames(styles.root, {
        [styles.isDisabled]: isDisabled,
      })}
    >
      <div role="link" tabIndex={0} onClick={isDisabled ? undefined : onClick}>
        <div className={styles.body}>
          <div className={styles.cover}>
            <ImageWrapper src={coverUrl} alt={name} fit="contain" />
            <NftRarityBadge
              rarity={rarity}
              size="sm"
              className={styles.rarity}
            />
            {nftListing.status === NftListingStatus.Purchased && (
              <Badge variant="danger" size="xl" className={styles.soldOutBadge}>
                Sold Out
              </Badge>
            )}
          </div>

          <div className={styles.details}>
            <div className={styles.info}>
              <p className={styles.name}>{name}</p>
            </div>

            <div className={styles.footer}>
              <div className={styles.icons}>
                {category && (
                  <Tooltip text={`#${category.name}`}>
                    <Avatar
                      url={category.iconUrl || DEFAULT_ICON_URL}
                      alt={category.name}
                      size="xs"
                      className={styles.icon}
                    />
                  </Tooltip>
                )}
                <Tooltip text={`@${creator.username}`}>
                  <Avatar
                    url={creator.avatarUrl}
                    alt={creator.username}
                    size="xs"
                    className={styles.icon}
                  />
                </Tooltip>
              </div>

              <div className={styles.priceAndSupply}>
                <FiatPrice
                  value={nftListing.price}
                  currency={nftListing.currency}
                  className={styles.price}
                />
                <NftSupply
                  rarity={rarity}
                  copyNumber={nftListing.nft.tokenId - firstTokenId + 1}
                  totalSupply={totalSupply}
                  hasIcon={false}
                  className={styles.supply}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Ratio>
  );
};

export default NftListingCard;
