import {
  BaseNft,
  BaseUserWithEmail,
  BaseUserWithWallet,
  MultiResultsDto,
  SingleResultDto,
  User,
} from '@fanadise/common-types';
import { api } from 'api';
import { PaginatedRequestQuery } from 'types/paginatedRequestQuery';
import { PaginatedResponseData } from 'types/paginatedResponseData';

export const fetchUsers = async (
  params?: {
    username?: string;
    email?: string;
    walletAddress?: string;
    search?: string;
  } & PaginatedRequestQuery,
) => {
  const res = await api.get<
    MultiResultsDto<'users', BaseUserWithEmail & BaseUserWithWallet>
  >(`/users`, { params });

  return res.data;
};

export const fetchUser = async (id: string) => {
  const res = await api.get<SingleResultDto<'user', User>>(`/users/${id}`);
  return res.data.user;
};

export const fetchUserByUsername = async (username: string) => {
  const res = await api.get<SingleResultDto<'user', User>>(
    `/users/by-username/${username}`,
  );
  return res.data.user;
};

export const regenerateUserNonce = async (id: string) => {
  const res = await api.post<{ user: { nonce: string } }>(
    `/users/regenerate-nonce`,
    {
      id,
    },
  );

  return res.data.user.nonce;
};

export const createUser = async (data: {
  email: string;
  username: string;
  password: string;
  bio?: string;
}) => {
  const res = await api.post<SingleResultDto<'user', User>>('/users', data);
  return res.data.user;
};

export const updateUser = async (
  id: string,
  data: {
    email: string;
    username: string;
    bio?: string;
  },
) => {
  const res = await api.put<SingleResultDto<'user', User>>(
    `/users/${id}`,
    data,
  );
  return res.data.user;
};

export const updateUserPassword = async (
  id: string,
  data: { password: string },
) => {
  await api.put(`/users/${id}/password`, data);
};

export const uploadUserAvatar = async (id: string, data: FormData) => {
  const res = await api.post<SingleResultDto<'user', User>>(
    `/users/${id}/avatar`,
    data,
  );
  return res.data.user;
};

export const uploadUserBackground = async (id: string, data: FormData) => {
  const res = await api.post<SingleResultDto<'user', User>>(
    `/users/${id}/background`,
    data,
  );
  return res.data.user;
};

export const fetchUserNfts = async (
  id: string,
  params: PaginatedRequestQuery,
) => {
  const res = await api.get<
    MultiResultsDto<'nfts', BaseNft> & PaginatedResponseData
  >(`/users/${id}/nfts`, { params });

  return res.data;
};
