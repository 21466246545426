import { useField } from 'formik';
import PasswordStrengthBar, {
  PasswordStrengthBarProps,
} from 'components/molecules/PasswordStrengthBar';
import React from 'react';

export interface ConnectedPasswordStrengthBarProps
  extends Omit<PasswordStrengthBarProps, 'password' | 'onChangeScore'> {}

const ConnectedPasswordStrengthBar: React.FC<ConnectedPasswordStrengthBarProps> =
  ({ renderLabel, ...rest }) => {
    const [{ value: password }, { touched }] = useField('password');
    const [, , { setValue: onChangeScore }] = useField('score');

    return (
      <PasswordStrengthBar
        password={password}
        onChangeScore={onChangeScore}
        renderLabel={touched ? renderLabel : () => null}
        {...rest}
      />
    );
  };

export default ConnectedPasswordStrengthBar;
