import React, { useCallback, useContext } from 'react';
import {
  CategoryTile,
  ConfirmDialogContext,
  CopyableText,
  Icon,
  MenuItem,
  Panel,
  SnackbarContext,
} from '@fanadise/common-ui';
import { Category } from '@fanadise/common-types';
import ResourcesList from 'components/shared/ResourcesList';
import { useTranslation } from '@fanadise/common-logic';
import { categoriesApi } from '@fanadise/common-data-access';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

const CategoriesList: React.FC = () => {
  const { translate } = useTranslation();
  const { addSuccessAlert, addErrorAlert } = useContext(SnackbarContext)!;
  const { activateConfirmDialog } = useContext(ConfirmDialogContext);

  const handleDeleteCategory = useCallback(async (category: Category) => {
    const isConfirmed = await activateConfirmDialog();
    if (!isConfirmed) {
      return;
    }

    try {
      await categoriesApi.deleteCategory(category.id);
      addSuccessAlert(translate('success:removed'));
      window.location.reload();
    } catch (err: any) {
      addErrorAlert(err.message || translate('error:default'));
    }
  }, []);

  return (
    <Panel>
      <ResourcesList<Category, 'categories'>
        dataKey="categories"
        fetchData={categoriesApi.fetchCategories}
        sortedProps={['createdAt']}
        columns={[
          {
            prop: 'name',
            label: translate('categories:list:name'),
            renderCell: (category) => <CategoryTile category={category} />,
          },
          {
            prop: 'id',
            label: translate('common:id'),
            renderCell: ({ id }) => (
              <CopyableText
                maxW="30"
                text={id}
                tooltipText={translate('common:copy')}
                copiedMessage={translate('common:copied')}
              />
            ),
          },
          {
            prop: 'createdAt',
            label: translate('common:createdAt'),
            renderCell: ({ createdAt }) => (
              <time dateTime={new Date(createdAt).toISOString()}>
                {new Date(createdAt).toLocaleDateString()}
              </time>
            ),
          },
        ]}
        renderMenu={(item) => (
          <>
            <Link to={`/account/categories/edit/${item.id}`}>
              <MenuItem tag="span" icon={<Icon icon={PencilIcon} size="sm" />}>
                {translate('common:edit')}
              </MenuItem>
            </Link>

            <MenuItem
              tag="span"
              icon={<Icon icon={TrashIcon} size="sm" />}
              onClick={() => handleDeleteCategory(item)}
            >
              {translate('common:delete')}
            </MenuItem>
          </>
        )}
      />
    </Panel>
  );
};

export default CategoriesList;
