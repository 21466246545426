import React from 'react';
import classNames from 'classnames';

import styles from './FormGrid.module.css';

const FormGrid: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ className, ...rest }) => (
  <div className={classNames(styles.root, className)} {...rest} />
);

export default FormGrid;
