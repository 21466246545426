import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import {
  SnackbarContext,
  ConnectedLoading,
  ConnectedSubmit,
  ConnectedFormItem,
  Loading,
  ConnectedInput,
  Box,
  Separator,
} from '@fanadise/common-ui';
import FormSubmitBar from 'components/shared/FormSubmitBar';

import { useTranslation } from '@fanadise/common-logic';
import {
  accountsApi,
  dashboardApi,
  nftListingsApi,
  usersApi,
} from '@fanadise/common-data-access';
import ConnectedAsyncSmartSelect from 'components/shared/ConnectedAsyncSmartSelect';
import FormSectionTitle from 'components/shared/FormSectionTitle';
import { DashboardSettings } from '@fanadise/common-types';
import FormGrid from 'components/shared/FormGrid';

interface DashboardSettingsFormValues {
  homePage: {
    mobileBannerUrl: string;
    desktopBannerUrl: string;
  };
  nftsPage: {
    mobileBannerUrl: string;
    desktopBannerUrl: string;
  };
  featuredUserIds: string[];
  featuredAccountIds: string[];
  featuredNftListingIds: string[];
}

const DashboardSettingsForm: React.FC = () => {
  const { translate } = useTranslation();
  const { addSuccessAlert, addErrorAlert } = useContext(SnackbarContext)!;

  const [settings, setSettings] = useState<DashboardSettings>();

  const validationSchema = yup.object().shape({
    homePage: yup.object().shape({
      mobileBannerUrl: yup.string().required(translate('validation:required')),
      desktopBannerUrl: yup.string().required(translate('validation:required')),
    }),
    nftsPage: yup.object().shape({
      mobileBannerUrl: yup.string().required(translate('validation:required')),
      desktopBannerUrl: yup.string().required(translate('validation:required')),
    }),
    featuredUserIds: yup.array().required(translate('validation:required')),
    featuredAccountIds: yup.array().required(translate('validation:required')),
    featuredNftListingIds: yup
      .array()
      .required(translate('validation:required')),
  });

  const initialValues: DashboardSettingsFormValues = {
    homePage: settings?.homePage || {
      mobileBannerUrl: '',
      desktopBannerUrl: '',
    },
    nftsPage: settings?.nftsPage || {
      mobileBannerUrl: '',
      desktopBannerUrl: '',
    },
    featuredUserIds: settings?.featuredUsers.map(({ id }) => id) || [],
    featuredAccountIds: settings?.featuredAccounts.map(({ id }) => id) || [],
    featuredNftListingIds:
      settings?.featuredNftListings.map(({ id }) => id) || [],
  };

  const handleSubmit = useCallback(
    async (values: DashboardSettingsFormValues) => {
      try {
        setSettings(await dashboardApi.updateDashboardSettings(values));
        addSuccessAlert(translate('success:saved'));
      } catch (err: any) {
        addErrorAlert(err.message || translate('error:default'));
      }
    },
    [translate, addSuccessAlert, addErrorAlert],
  );

  useEffect(() => {
    const fetchSettings = async () => {
      setSettings(await dashboardApi.fetchDashboardSettings());
    };

    fetchSettings();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      <ConnectedLoading>
        <Form noValidate>
          <Loading isLoading={!settings} minH={14.625}>
            {settings && (
              <>
                <Box mb="8">
                  <FormSectionTitle>
                    {translate('settings:home:title')}
                  </FormSectionTitle>
                  <FormGrid>
                    <ConnectedFormItem
                      fieldId="homePage.mobileBannerUrl"
                      fieldName="homePage.mobileBannerUrl"
                      label={translate('settings:mobileBannerUrl')}
                      isRequired
                    >
                      <ConnectedInput name="homePage.mobileBannerUrl" />
                    </ConnectedFormItem>

                    <ConnectedFormItem
                      fieldId="homePage.desktopBannerUrl"
                      fieldName="homePage.desktopBannerUrl"
                      label={translate('settings:desktopBannerUrl')}
                      isRequired
                    >
                      <ConnectedInput name="homePage.desktopBannerUrl" />
                    </ConnectedFormItem>
                  </FormGrid>

                  <Separator spacing="none" />

                  <Box mt="6">
                    <FormSectionTitle>
                      {translate('settings:nfts:title')}
                    </FormSectionTitle>
                    <FormGrid>
                      <ConnectedFormItem
                        fieldId="nftsPage.mobileBannerUrl"
                        fieldName="nftsPage.mobileBannerUrl"
                        label={translate('settings:mobileBannerUrl')}
                        isRequired
                      >
                        <ConnectedInput name="nftsPage.mobileBannerUrl" />
                      </ConnectedFormItem>

                      <ConnectedFormItem
                        fieldId="nftsPage.desktopBannerUrl"
                        fieldName="nftsPage.desktopBannerUrl"
                        label={translate('settings:desktopBannerUrl')}
                        isRequired
                      >
                        <ConnectedInput name="nftsPage.desktopBannerUrl" />
                      </ConnectedFormItem>
                    </FormGrid>
                  </Box>

                  <Separator spacing="none" />

                  <Box mt="6">
                    <FormSectionTitle>
                      {translate('settings:global:title')}
                    </FormSectionTitle>

                    <ConnectedFormItem
                      fieldId="featuredUserIds"
                      fieldName="featuredUserIds"
                      label={translate('settings:featuredUsers')}
                      isRequired
                    >
                      <ConnectedAsyncSmartSelect
                        name="featuredUserIds"
                        placeholder={translate(
                          'settings:featuredUsers:placeholder',
                        )}
                        cacheOptions
                        isMulti
                        isSortable
                        defaultValue={settings?.featuredUsers.map((user) => ({
                          value: user.id,
                          label: `@${user.username}`,
                        }))}
                        loadOptions={async (value, callback) => {
                          const result = await usersApi.fetchUsers({
                            search: value,
                          });

                          callback(
                            result.users
                              ? result.users.map((user) => ({
                                  value: user.id,
                                  label: `@${user.username}`,
                                }))
                              : [],
                          );
                        }}
                      />
                    </ConnectedFormItem>

                    <ConnectedFormItem
                      fieldId="featuredAccountIds"
                      fieldName="featuredAccountIds"
                      label={translate('settings:featuredAccounts')}
                      isRequired
                    >
                      <ConnectedAsyncSmartSelect
                        name="featuredAccountIds"
                        placeholder={translate(
                          'settings:featuredAccounts:placeholder',
                        )}
                        cacheOptions
                        isMulti
                        isSortable
                        defaultValue={settings?.featuredAccounts.map(
                          (account) => ({
                            value: account.id,
                            label: `${account.name} (${account.id})`,
                          }),
                        )}
                        loadOptions={async (value, callback) => {
                          const result = await accountsApi.fetchAccounts({
                            search: value,
                          });

                          callback(
                            result.accounts
                              ? result.accounts.map((account) => ({
                                  value: account.id,
                                  label: `${account.name} (${account.id})`,
                                }))
                              : [],
                          );
                        }}
                      />
                    </ConnectedFormItem>

                    <ConnectedFormItem
                      fieldId="featuredNftListingIds"
                      fieldName="featuredNftListingIds"
                      label={translate('settings:featuredNftListings')}
                      isRequired
                    >
                      <ConnectedAsyncSmartSelect
                        name="featuredNftListingIds"
                        placeholder={translate(
                          'settings:featuredNftListings:placeholder',
                        )}
                        cacheOptions
                        isMulti
                        isSortable
                        defaultValue={settings?.featuredNftListings.map(
                          (nftListing) => ({
                            value: nftListing.id,
                            label: `${nftListing.nft.config.name} (#${nftListing.nft.tokenId})`,
                          }),
                        )}
                        loadOptions={async (value, callback) => {
                          const result = await nftListingsApi.fetchNftListings({
                            search: value,
                          });

                          callback(
                            result.nftListings
                              ? result.nftListings.map((nftListing) => ({
                                  value: nftListing.id,
                                  label: `${nftListing.nft.config.name} (#${nftListing.nft.tokenId})`,
                                }))
                              : [],
                          );
                        }}
                      />
                    </ConnectedFormItem>
                  </Box>
                </Box>
              </>
            )}
          </Loading>

          <FormSubmitBar>
            <ConnectedSubmit>{translate('common:save')}</ConnectedSubmit>
          </FormSubmitBar>
        </Form>
      </ConnectedLoading>
    </Formik>
  );
};

export default DashboardSettingsForm;
