import classNames from 'classnames';
import React, { ReactNode } from 'react';
import Panel from 'components/atoms/Panel';
import Heading from 'components/atoms/Heading';
import Text from 'components/atoms/Text';

import styles from './StepScaffold.module.css';

export interface StepScaffoldProps {
  title: string | ReactNode;
  description?: string | ReactNode;
  header: ReactNode;
  footer?: ReactNode;
  isCentered?: boolean;
  className?: string;
}

const StepScaffold: React.FC<StepScaffoldProps> = ({
  title,
  description,
  header,
  footer,
  isCentered,
  className,
  children,
}) => (
  <div
    data-testid="stepScaffold"
    className={classNames(
      styles.root,
      isCentered && styles.centered,
      className,
    )}
  >
    <div className={styles.header}>{header}</div>

    <Panel w="sm">
      <Heading fontSize="xl" className={styles.title}>
        {title}
      </Heading>
      {description && <Text className={styles.description}>{description}</Text>}

      <div>{children}</div>
    </Panel>

    {footer && <div className={styles.footer}>{footer}</div>}
  </div>
);

export default StepScaffold;
