import { api } from 'api';
import {
  NftConfig,
  SingleResultDto,
  MultiResultsDto,
} from '@fanadise/common-types';
import type { PaginatedRequestQuery } from 'types/paginatedRequestQuery';
import type { PaginatedResponseData } from 'types/paginatedResponseData';

export const fetchNftConfigs = async (
  params: { search?: string } & PaginatedRequestQuery = {
    page: 1,
    size: 50,
  },
) => {
  const res = await api.get<
    MultiResultsDto<'nftConfigs', NftConfig> & PaginatedResponseData
  >('/nft-configs', {
    params,
  });
  return res.data;
};

export const fetchNftConfig = async (id: string) => {
  const res = await api.get<SingleResultDto<'nftConfig', NftConfig>>(
    `/nft-configs/${id}`,
  );
  return res.data.nftConfig;
};

type NftConfigFormFields = Pick<
  NftConfig,
  'name' | 'description' | 'type' | 'totalSupply' | 'accountId'
>;

export const createNftConfig = async (data: NftConfigFormFields) => {
  const res = await api.post<SingleResultDto<'nftConfig', NftConfig>>(
    '/nft-configs',
    data,
  );
  return res.data.nftConfig;
};

export const updateNftConfig = async (
  id: string,
  data: NftConfigFormFields,
) => {
  const res = await api.put<SingleResultDto<'nftConfig', NftConfig>>(
    `/nft-configs/${id}`,
    data,
  );
  return res.data.nftConfig;
};

export const uploadNftConfigCover = async (id: string, data: FormData) => {
  const res = await api.post<SingleResultDto<'nftConfig', NftConfig>>(
    `/nft-configs/${id}/upload-cover`,
    data,
  );
  return res.data.nftConfig;
};

export const uploadNftConfigFile = async (id: string, data: FormData) => {
  const res = await api.post<SingleResultDto<'nftConfig', NftConfig>>(
    `/nft-configs/${id}/upload-file`,
    data,
  );
  return res.data.nftConfig;
};

export const mintNftConfig = async (id: string) => {
  const res = await api.post<SingleResultDto<'nftConfig', NftConfig>>(
    `/nft-configs/${id}/mint`,
  );
  return res.data.nftConfig;
};

export const deleteNftConfig = async (id: string) => {
  await api.delete(`/nft-configs/${id}`);
};
