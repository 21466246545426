import React from 'react';
import { AttributeStat, AttributeStatProps } from '@fanadise/common-ui';
import { NftAttribute } from '@fanadise/common-types';
import { NftAttributeDisplayType } from '@fanadise/common-consts';

export interface NftAttributeStatProps
  extends Omit<AttributeStatProps, 'label' | 'color'> {
  attribute: NftAttribute;
}

const NftAttributeStat: React.FC<NftAttributeStatProps> = ({
  attribute: { displayType, traitType, value },
  ...rest
}) => {
  const isBoost =
    displayType === NftAttributeDisplayType.BoostNumber ||
    displayType === NftAttributeDisplayType.BoostPercentage;

  return (
    <AttributeStat
      label={traitType}
      color={isBoost ? 'success' : undefined}
      {...rest}
    >
      {isBoost ? '+' : ''}
      {displayType === NftAttributeDisplayType.BoostPercentage
        ? `${Number.parseFloat(((value as number) * 100).toFixed(2))}%`
        : value}
    </AttributeStat>
  );
};

export default NftAttributeStat;
