import { Currency, NftRarity, NftType } from '@fanadise/common-consts';
import {
  BaseNftListing,
  MultiResultsDto,
  NftListing,
  SingleResultDto,
} from '@fanadise/common-types';
import { api } from 'api';
import { PaginatedRequestQuery } from 'types/paginatedRequestQuery';
import { PaginatedResponseData } from 'types/paginatedResponseData';

export const fetchNftListings = async (
  params: {
    categoryId?: string | string[];
    accountId?: string;
    rarity?: NftRarity;
    type?: NftType[];
    search?: string;
    sort?: string;
  } & PaginatedRequestQuery = {
    page: 1,
    size: 30,
  },
) => {
  const res = await api.get<
    MultiResultsDto<'nftListings', BaseNftListing> & PaginatedResponseData
  >(`/nft-listings`, {
    params,
  });

  return res.data;
};

export const fetchNftListing = async (id: string) => {
  const res = await api.get<SingleResultDto<'nftListing', NftListing>>(
    `/nft-listings/${id}`,
  );

  return res.data.nftListing;
};

export const updateNftListing = async (id: string, data: { price: string }) => {
  const res = await api.put<SingleResultDto<'nftListing', NftListing>>(
    `/nft-listings/${id}`,
    data,
  );
  return res.data.nftListing;
};

export const createNftListing = async (data: {
  nftId: string;
  price: number;
  currency: Currency;
}) => {
  const res = await api.post<SingleResultDto<'nftListing', NftListing>>(
    `/nft-listings`,
    data,
  );
  return res.data.nftListing;
};

export const deleteNftListing = async (id: string) => {
  await api.delete(`/nft-listings/${id}`);
};
