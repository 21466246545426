import React from 'react';
import classNames from 'classnames';
import Modal, { Props as ModalProps } from 'react-modal';

import CloseButton from 'components/atoms/CloseButton';
import styles from './Lightbox.module.css';

export interface LightboxProps
  extends Omit<
    ModalProps,
    | 'overlayClassName'
    | 'bodyOpenClassName'
    | 'bodyOpenClassName'
    | 'shouldCloseOnOverlayClick'
  > {
  isClosable?: boolean;
}

const Lightbox: React.FC<LightboxProps> = ({
  isClosable = true,
  onRequestClose,
  className,
  children,
  ...rest
}) => (
  <Modal
    className={classNames(styles.root, className)}
    overlayClassName={styles.overlay}
    shouldCloseOnOverlayClick={isClosable}
    htmlOpenClassName="no-scroll"
    onRequestClose={onRequestClose}
    {...rest}
  >
    {children}
    {isClosable && (
      <CloseButton
        size="lg"
        variant="transparent"
        className={styles.closeButton}
        onClick={onRequestClose}
      />
    )}
  </Modal>
);

export default Lightbox;
