import React from 'react';
import classNames from 'classnames';
import Spinner from 'components/atoms/Spinner';

import styles from './Loading.module.css';

export interface LoadingProps {
  isLoading: boolean;
  isOpaque?: boolean;
  minH?: number;
  className?: string;
}

const Loading: React.FC<LoadingProps> = ({
  isLoading,
  isOpaque = false,
  minH = 24,
  children,
  className,
}) => (
  <div
    data-testid="loading"
    className={classNames(styles.root, className, {
      [styles.isLoading]: isLoading,
      [styles.isOpaque]: isOpaque,
    })}
    style={{ minHeight: `${minH}rem` }}
  >
    {isLoading && (
      <div className={styles.indicator}>
        <Spinner size="md" />
      </div>
    )}
    <div className={styles.wrap}>{children}</div>
  </div>
);

export default Loading;
