import React from 'react';
import classNames from 'classnames';

import styles from './Select.module.css';

export interface SelectProps {
  size?: 'sm' | 'md';
  isInvalid?: boolean;
  isDisabled?: boolean;
  options: { value: string; label: string }[];
  placeholder?: string;
  className?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
}

const Select: React.FC<SelectProps> = ({
  size = 'md',
  isInvalid,
  isDisabled,
  options,
  placeholder,
  className,
  ...rest
}) => (
  <select
    data-testid="select"
    className={classNames(styles.root, styles[`size--${size}`], className, {
      [styles.isInvalid]: isInvalid,
      [styles.isDisabled]: isDisabled,
    })}
    disabled={isDisabled}
    {...rest}
  >
    {placeholder && (
      <option value="" selected disabled hidden>
        {placeholder}
      </option>
    )}

    {options.map((opt) => (
      <option key={opt.value} value={opt.value}>
        {opt.label}
      </option>
    ))}
  </select>
);

export default Select;
