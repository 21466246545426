import {
  CryptoCurrency,
  Currency,
  PurchaseContentType,
  PurchasePaymentType,
} from '@fanadise/common-consts';
import {
  BasePurchaseWithUser,
  MultiResultsDto,
  Purchase,
  SingleResultDto,
} from '@fanadise/common-types';
import { api } from 'api';
import { PaginatedRequestQuery } from 'types/paginatedRequestQuery';
import { PaginatedResponseData } from 'types/paginatedResponseData';

export const createFiatPurchase = async (data: {
  contentId: string;
  contentType: PurchaseContentType;
  currency: Currency;
  token: string;
  mask: string;
}) => {
  const res = await api.post<{
    id: string;
    redirectUrl?: string;
  }>('/purchases', {
    ...data,
    paymentType: PurchasePaymentType.Fiat,
  });

  return res.data;
};

export const createCryptoPurchase = async (data: {
  contentId: string;
  contentType: PurchaseContentType;
  cryptoCurrency: CryptoCurrency;
}) => {
  const res = await api.post<{
    id: string;
    redirectUrl: string;
    txns: {
      to: string;
      data: string;
      value: {
        type: string;
        hex: string;
      };
      gasLimit: number;
    }[];
  }>('/purchases', {
    ...data,
    paymentType: PurchasePaymentType.Crypto,
  });

  return res.data;
};

export const fetchPurchase = async (id: string) => {
  const res = await api.get<SingleResultDto<'purchase', Purchase>>(
    `/purchases/${id}`,
  );
  return res.data.purchase;
};

export const fetchPurchases = async (
  params: { search?: string } & PaginatedRequestQuery = {
    page: 1,
    size: 50,
  },
) => {
  const res = await api.get<
    MultiResultsDto<'purchases', BasePurchaseWithUser> & PaginatedResponseData
  >(`/purchases`, { params });

  return res.data;
};
