import React from 'react';
import { Purchase } from '@fanadise/common-types';
import { Stack, FiatPrice, Text, TinyHeading } from '@fanadise/common-ui';
import useTranslation from 'hooks/useTranslation';

export interface PurchaseSummaryProps {
  purchase: Purchase;
}

const PurchaseSummary: React.FC<PurchaseSummaryProps> = ({ purchase }) => {
  const { translate } = useTranslation();

  return (
    <Stack gap="2">
      <Stack gap="none">
        <TinyHeading>{translate('purchaseSummary:purchaseId')}</TinyHeading>
        <Text color="gray.800">{purchase.id}</Text>
      </Stack>

      {purchase && (
        <>
          <Stack gap="none">
            <TinyHeading>
              {translate('purchaseSummary:description')}
            </TinyHeading>
            <Text color="gray.800">{purchase.description}</Text>
          </Stack>

          <Stack gap="none">
            <TinyHeading>{translate('purchaseSummary:price')}</TinyHeading>
            <Text color="gray.800">
              <FiatPrice value={purchase.price} currency={purchase.currency} />
            </Text>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default PurchaseSummary;
