import { useField } from 'formik';
import React from 'react';
import Checkbox, { CheckboxProps } from 'components/atoms/Checkbox';

export interface ConnectedCheckboxProps extends CheckboxProps {
  name: string;
  value?: string;
}

const ConnectedCheckbox: React.FC<ConnectedCheckboxProps> = ({
  name,
  value,
  ...rest
}) => {
  const [{ checked: isChecked, ...field }] = useField({
    name,
    value,
    type: 'checkbox',
  });

  return <Checkbox isChecked={isChecked} {...field} {...rest} />;
};

export default ConnectedCheckbox;
