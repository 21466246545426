import React from 'react';
import { FAN_POLYGON_MAINNET, POLYGON_MAINNET } from '@fanadise/common-consts';
import { EvmNetwork, Token } from '@fanadise/common-types';

export type EvmNetworkContextValue = {
  network: EvmNetwork;
  token: Token;
};

const EvmNetworkContext = React.createContext<EvmNetworkContextValue>({
  network: POLYGON_MAINNET,
  token: FAN_POLYGON_MAINNET,
});

export default EvmNetworkContext;
