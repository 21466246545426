import React, { useCallback, useState } from 'react';
import {
  ViewScaffold,
  Header,
  FanadiseLogo,
  Contain,
} from '@fanadise/common-ui';
import ExecutorLayoutSidebar from './ExecutorLayoutSidebar';

const ExecutorLayout: React.FC = ({ children }) => {
  const [isDrawerActive, setIsDrawerActive] = useState(false);

  const handleToggleClick = useCallback(() => {
    setIsDrawerActive(!isDrawerActive);
  }, [isDrawerActive]);

  return (
    <ViewScaffold
      header={
        <Header
          logo={<FanadiseLogo color="primary" />}
          onMenuToggle={handleToggleClick}
        />
      }
      sidebar={<ExecutorLayoutSidebar />}
      isDrawerActive={isDrawerActive}
      onDrawerClose={handleToggleClick}
    >
      <Contain
        size="xl"
        py="8"
        px={{
          base: '4',
          md: '8',
        }}
      >
        {children}
      </Contain>
    </ViewScaffold>
  );
};

export default ExecutorLayout;
