import SnackbarContext from 'contexts/SnackbarContext';
import Text, { TextProps } from 'components/atoms/Text';
import Tooltip from 'components/atoms/Tooltip';
import React, { useCallback, useContext } from 'react';
import copy from 'copy-to-clipboard';

import classnames from 'classnames';
import styles from './CopyableText.module.css';

export interface CopyableTextProps extends TextProps {
  text: string;
  tooltipText?: string;
  copiedMessage?: string;
  className?: string;
}

const CopyableText: React.FC<CopyableTextProps> = ({
  text,
  maxW,
  tooltipText,
  copiedMessage,
  children,
  className,
  ...rest
}) => {
  const { addSuccessAlert } = useContext(SnackbarContext)!;

  const handleCopy = useCallback(() => {
    copy(text);
    if (copiedMessage) {
      addSuccessAlert(copiedMessage);
    }
  }, [copy, text, copiedMessage, addSuccessAlert]);

  return (
    <Tooltip text={tooltipText} placement="left">
      <Text
        maxW={maxW}
        data-testid="copyable-text"
        isTruncated
        onClick={handleCopy}
        className={classnames(styles.root, className)}
        {...rest}
      >
        {children || text}
      </Text>
    </Tooltip>
  );
};

export default CopyableText;
