import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Badge,
  Box,
  CopyableText,
  EmptyStateMessage,
  Icon,
  MenuItem,
  SmartTable,
  SnackbarContext,
  Text,
} from '@fanadise/common-ui';
import { BaseNft } from '@fanadise/common-types';
import { useTranslation } from '@fanadise/common-logic';
import { capitalize, shortenAddress } from '@fanadise/common-utils';
import { NftStatus } from '@fanadise/common-consts';
import { LinkIcon, PencilIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { usersApi } from '@fanadise/common-data-access';
import UnassignUserModal from 'components/nfts/UnassignUserModal';

interface UserNftsListProps {
  userId: string;
}

const UserNftsList: React.FC<UserNftsListProps> = ({ userId }) => {
  const { translate } = useTranslation();
  const { addErrorAlert } = useContext(SnackbarContext)!;
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(50);
  const [total, setTotal] = useState(0);
  const [nfts, setNfts] = useState<BaseNft[]>();
  const [selectedNft, setSelectedNft] = useState<BaseNft | null>(null);

  const handleUnassign = useCallback((nft: BaseNft) => {
    setSelectedNft(nft);
  }, []);

  const fetchNfts = useCallback(async () => {
    try {
      const data = await usersApi.fetchUserNfts(userId, {
        page: page + 1,
        size: pageSize,
      });
      setNfts(data.nfts);
      setTotal(data.totalCount);
    } catch (err: any) {
      addErrorAlert(err.message || translate('error:default'));
    }
  }, [userId, page, pageSize]);

  useEffect(() => {
    fetchNfts();
  }, [fetchNfts]);

  useEffect(() => {
    if (!nfts || !nfts.some((nft) => nft.status === NftStatus.Claiming)) {
      return;
    }

    const interval = setTimeout(fetchNfts, 5000);
    return () => {
      clearTimeout(interval);
    };
  }, [nfts]);

  return (
    <>
      {nfts &&
        (nfts.length === 0 ? (
          <EmptyStateMessage message={translate('common:noResults')} />
        ) : (
          <SmartTable<BaseNft>
            data={nfts}
            page={page}
            onPageChange={setPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            total={total}
            pageSizesLabel={translate('table:pageSizePrefix')}
            paginationOfLabel={translate('table:of')}
            columns={[
              {
                prop: 'id',
                label: translate('common:id'),
                renderCell: ({ id }) => (
                  <CopyableText
                    maxW="16"
                    text={id}
                    tooltipText={translate('common:copy')}
                    copiedMessage={translate('common:copied')}
                  />
                ),
              },
              {
                prop: 'config.name',
                label: translate('nfts:list:name'),
                renderCell: ({ config }) =>
                  config ? (
                    <Box maxW="60">
                      <Text color="gray.800" fontWeight="semibold" isTruncated>
                        {config.name}
                      </Text>
                    </Box>
                  ) : (
                    '--'
                  ),
              },
              {
                prop: 'config.accountId',
                label: translate('nfts:list:accountId'),
                renderCell: ({ config }) =>
                  config ? (
                    <CopyableText
                      maxW="16"
                      text={config.accountId}
                      tooltipText={translate('common:copy')}
                      copiedMessage={translate('common:copied')}
                    />
                  ) : (
                    '--'
                  ),
              },
              {
                prop: 'status',
                label: translate('common:status'),
                renderCell: ({ status }) =>
                  status === NftStatus.Claimed ? (
                    <Badge variant="primary">{capitalize(status)}</Badge>
                  ) : (
                    <Badge variant="secondary">
                      {status ? capitalize(status) : '--'}
                    </Badge>
                  ),
              },
              {
                prop: 'address',
                label: translate('nfts:list:address'),
                renderCell: ({ address }) =>
                  address ? (
                    <CopyableText
                      text={address!}
                      tooltipText={translate('common:copy')}
                      copiedMessage={translate('common:copied')}
                    >
                      {shortenAddress(address!, 3)}
                    </CopyableText>
                  ) : (
                    '--'
                  ),
              },
              {
                prop: 'tokenId',
                label: translate('nfts:list:tokenId'),
                renderCell: ({ tokenId }) => (
                  <CopyableText
                    maxW="16"
                    text={tokenId.toString()}
                    tooltipText={translate('common:copy')}
                    copiedMessage={translate('common:copied')}
                  />
                ),
              },
            ]}
            renderMenu={(item) => (
              <>
                {item.status === NftStatus.Assigned && (
                  <MenuItem
                    icon={<Icon icon={LinkIcon} size="sm" />}
                    onClick={() => handleUnassign(item)}
                  >
                    {translate('nfts:list:unassign')}
                  </MenuItem>
                )}

                <Link to={`/account/configs/edit/${item.configId}`}>
                  <MenuItem
                    tag="span"
                    icon={<Icon icon={PencilIcon} size="sm" />}
                  >
                    {translate('common:edit')}
                  </MenuItem>
                </Link>
              </>
            )}
          />
        ))}

      {selectedNft && (
        <UnassignUserModal
          nft={selectedNft}
          onSuccess={() => {
            setSelectedNft(null);
            window.location.reload();
          }}
          onClose={() => setSelectedNft(null)}
        />
      )}
    </>
  );
};

export default UserNftsList;
