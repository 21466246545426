import React, { useCallback, useMemo } from 'react';
import TranslationsContext from 'contexts/TranslationsContext';
import { substitute } from '@fanadise/common-utils';

export interface TranslationsProviderProps {
  translations: Record<string, string>;
}

const TranslationsProvider: React.FC<TranslationsProviderProps> = ({
  translations,
  children,
}) => {
  const translate = useCallback(
    (id: string, values?: { [key: string]: any }) =>
      substitute(translations[id] || '', values),
    [],
  );

  const contextValue = useMemo(
    () => ({
      translate,
      translations,
    }),
    [translations],
  );

  return (
    <TranslationsContext.Provider value={contextValue}>
      {children}
    </TranslationsContext.Provider>
  );
};

export default TranslationsProvider;
