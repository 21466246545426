import React, { useCallback, useContext } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import {
  SnackbarContext,
  ConnectedFormItem,
  ConnectedFiatPriceInput,
  ConnectedFormDialog,
  ConnectedToggleSwitch,
} from '@fanadise/common-ui';
import { BaseNftListing } from '@fanadise/common-types';
import { useTranslation } from '@fanadise/common-logic';
import { nftListingsApi } from '@fanadise/common-data-access';
import { Currency, NftListingStatus } from '@fanadise/common-consts';
import ConnectedSmartSelect from 'components/shared/ConnectedSmartSelect';
import { capitalize } from '@fanadise/common-utils';

interface EditNftListingModalFormValues {
  price: string;
  currency: Currency;
  status: NftListingStatus;
  active: boolean;
}

export interface EditNftListingModalProps {
  nftListing: BaseNftListing;
  onClose: () => void;
  onSuccess: (nftListing: BaseNftListing) => void;
}

const EditNftListingModal: React.FC<EditNftListingModalProps> = ({
  nftListing,
  onClose,
  onSuccess,
}) => {
  const { translate } = useTranslation();
  const { addSuccessAlert, addErrorAlert } = useContext(SnackbarContext)!;

  const validationSchema = yup.object().shape({
    price: yup
      .number()
      .transform((value) => parseFloat(value) || 0)
      .min(0, translate('validation:invalid')),
    currency: yup.string().required(translate('validation:required')),
  });

  const initialValues: EditNftListingModalFormValues = {
    price: nftListing?.price || '',
    currency: nftListing?.currency || Currency.Usd,
    status: nftListing?.status || NftListingStatus.Created,
    active: nftListing?.active ?? true,
  };

  const handleSubmit = useCallback(
    async (values: EditNftListingModalFormValues) => {
      try {
        const savedNftListing = await nftListingsApi.updateNftListing(
          nftListing.id,
          values,
        );

        addSuccessAlert(translate('success:saved'));
        if (onSuccess) {
          onSuccess(savedNftListing);
        }
      } catch (err: any) {
        addErrorAlert(err.message || translate('error:default'));
      }
    },
    [translate, addSuccessAlert, addErrorAlert, nftListing.id],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <ConnectedFormDialog
        isOpen
        submitLabel={translate('common:save')}
        cancelLabel={translate('common:cancel')}
        onRequestClose={onClose}
        title={translate('nftListings:edit:title')}
      >
        <Form noValidate>
          <ConnectedFormItem
            fieldId="price"
            fieldName="price"
            label={translate('common:price')}
            isRequired
          >
            <ConnectedFiatPriceInput name="price" />
          </ConnectedFormItem>

          <ConnectedFormItem
            fieldId="currency"
            fieldName="currency"
            label={translate('common:currency')}
            isRequired
          >
            <ConnectedSmartSelect
              name="currency"
              isSearchable={false}
              options={Object.values(Currency).map((currency) => ({
                value: currency,
                label: currency.toUpperCase(),
              }))}
              isDisabled
            />
          </ConnectedFormItem>

          <ConnectedFormItem
            fieldId="status"
            fieldName="status"
            label={translate('common:status')}
            isRequired
          >
            <ConnectedSmartSelect
              name="status"
              isSearchable={false}
              options={Object.values(NftListingStatus).map((status) => ({
                value: status,
                label: capitalize(status),
              }))}
            />
          </ConnectedFormItem>

          <ConnectedFormItem
            fieldId="active"
            fieldName="active"
            label={translate('common:active')}
          >
            <ConnectedToggleSwitch name="active" />
          </ConnectedFormItem>
        </Form>
      </ConnectedFormDialog>
    </Formik>
  );
};

export default EditNftListingModal;
