import React from 'react';
import { MenuIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import Button from 'components/atoms/Button';

import Contain from 'components/atoms/Contain';
import styles from './Header.module.css';

export interface HeaderProps {
  logo: React.ReactNode;
  variant?: 'default' | 'centered';
  actions?: React.ReactNode;
  trailing?: React.ReactNode;
  hasShadow?: boolean;
  onMenuToggle: () => void;
  className?: string;
}

const Header: React.FC<HeaderProps> = ({
  logo,
  actions,
  variant = 'default',
  hasShadow = false,
  trailing,
  onMenuToggle,
  className,
}) => (
  <header
    className={classNames(
      styles.root,
      styles[`variant--${variant}`],
      {
        [styles.hasShadow]: hasShadow,
      },
      className,
    )}
  >
    <Contain display="flex" align="center" className={styles.wrap}>
      <Button
        variant="text"
        icon={<MenuIcon />}
        onClick={onMenuToggle}
        className={styles.menuToggle}
      />

      <div className={styles.logo}>{logo}</div>

      {actions && <div className={styles.actions}>{actions}</div>}
      {trailing && <div className={styles.trailing}>{trailing}</div>}
    </Contain>
  </header>
);

export default Header;
