import React, { PropsWithChildren } from 'react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DotsHorizontalIcon,
} from '@heroicons/react/solid';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Box from 'components/atoms/Box';
import SortOrderToggle from 'components/atoms/SortOrderToggle';
import { useTable, CellProps } from 'react-table';
import classNames from 'classnames';
import { SortOrder } from '@fanadise/common-types';
import Menu from 'components/atoms/Menu';

import styles from './SmartTable.module.css';

export interface SmartTableProps<Item> {
  columns: {
    label: string;
    prop: keyof Item | string;
    renderCell: (item: Item) => React.ReactNode;
    fitContent?: boolean;
  }[];
  data: Item[];
  renderMenu?: (item: Item) => React.ReactNode;
  sortedProps?: (keyof Item | string)[];
  sorting?: { prop: keyof Item | string; order: SortOrder }[];
  onSortingChange?: (
    sorting: { prop: keyof Item | string; order: SortOrder }[],
  ) => void;
  page: number;
  pageSize: number;
  total: number;
  pageSizes?: number[];
  pageSizesLabel: string;
  paginationOfLabel: string;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  className?: string;
}

const SmartTable = <Item extends object>({
  columns,
  data,
  renderMenu,
  sortedProps,
  sorting,
  onSortingChange,
  page,
  onPageChange,
  pageSize,
  onPageSizeChange,
  total,
  pageSizes = [25, 50, 75, 100],
  pageSizesLabel,
  paginationOfLabel,
  className,
}: PropsWithChildren<SmartTableProps<Item>>) => {
  const tableColumns = React.useMemo(
    () => [
      ...columns.map((column) => ({
        id: column.prop as string,
        Header: () => {
          const isSorted = sortedProps?.find((prop) => prop === column.prop);

          return (
            <Box position="relative" display="inline-block" mr="6">
              {column.label}
              {isSorted && (
                <SortOrderToggle
                  order={
                    sorting?.find(({ prop }) => prop === column.prop)?.order
                  }
                  onOrderChange={(order) => {
                    if (order) {
                      onSortingChange?.([
                        ...(sorting ?? []).filter(
                          ({ prop }) => prop !== column.prop,
                        ),
                        { prop: column.prop, order },
                      ]);
                    } else {
                      onSortingChange?.(
                        (sorting ?? []).filter(
                          ({ prop }) => prop !== column.prop,
                        ),
                      );
                    }
                  }}
                />
              )}
            </Box>
          );
        },
        Cell: ({ row }: CellProps<Item>) => column.renderCell(row.original),
        className: column.fitContent && styles.fitContent,
      })),
      ...(renderMenu
        ? [
            {
              id: 'menu',
              Cell: ({ row }: CellProps<Item>) => (
                <Menu
                  placement="bottom-start"
                  trigger={
                    <Button
                      variant="icon"
                      size="sm"
                      color="secondary"
                      icon={<Icon icon={DotsHorizontalIcon} />}
                    />
                  }
                >
                  {renderMenu(row.original)}
                </Menu>
              ),
              className: styles.fitContent,
            },
          ]
        : []),
    ],
    [columns, renderMenu, sorting, onSortingChange],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<Item>({ columns: tableColumns, data });

  return (
    <div
      data-testid="smartTable"
      className={classNames(styles.root, className)}
    >
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps([
                      // @ts-ignore
                      { className: cell.column.className },
                    ])}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className={styles.footer}>
        <div>
          {pageSizesLabel}
          <select
            value={pageSize}
            onChange={(e) => onPageSizeChange(parseInt(e.target.value, 10))}
          >
            {pageSizes.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div>
          <p>
            {Math.max(page * pageSize, 1)}-
            {Math.min((page + 1) * pageSize, total)} {paginationOfLabel} {total}
          </p>
          <Button
            variant="icon"
            icon={<Icon icon={ChevronLeftIcon} />}
            size="sm"
            isDisabled={page === 0}
            onClick={() => onPageChange(page - 1)}
          />
          <Button
            variant="icon"
            icon={<Icon icon={ChevronRightIcon} />}
            size="sm"
            isDisabled={(page + 1) * pageSize >= total}
            onClick={() => onPageChange(page + 1)}
          />
        </div>
      </div>
    </div>
  );
};

export default SmartTable;
