import { Placement, PositioningStrategy } from '@popperjs/core';
import classNames from 'classnames';
import useOutsideClick from 'hooks/useOutsideClick';
import React, { useCallback, useState } from 'react';
import { usePopper } from 'react-popper';

import styles from './Pop.module.css';

export interface PopProps {
  trigger: React.ReactNode;
  placement?: Placement;
  strategy?: PositioningStrategy;
  className?: string;
  isClosable?: boolean;
}

const Pop: React.FC<PopProps> = ({
  placement = 'auto',
  strategy = 'fixed',
  trigger,
  isClosable = false,
  className,
  children,
}) => {
  const [isActive, setIsActive] = useState(false);

  const [triggerRef, setTriggerRef] = useState<HTMLElement | null>(null);
  const [popperRef, setPopperRef] = useState<HTMLSpanElement | null>(null);

  const { styles: popperStyles, attributes } = usePopper(
    triggerRef,
    popperRef,
    {
      modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
      placement,
      strategy,
    },
  );

  const handleTriggerClick = useCallback(() => {
    setTimeout(() => {
      setIsActive(!isActive);
    });
  }, [isActive]);

  const popTrigger = React.cloneElement(
    trigger as React.DetailedReactHTMLElement<any, HTMLElement>,
    {
      onClick: handleTriggerClick,
      ref: setTriggerRef,
    },
  );

  useOutsideClick({ current: popperRef }, handleTriggerClick, isClosable);

  return (
    <>
      {popTrigger}

      {isActive && (
        <div
          data-testid="pop"
          className={classNames(styles.body, className)}
          ref={setPopperRef}
          style={popperStyles.popper}
          {...attributes.popper}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default Pop;
