import React from 'react';
import { Button, Icon, Panel, Toolbar } from '@fanadise/common-ui';
import { Link } from 'react-router-dom';
import { ArrowSmLeftIcon } from '@heroicons/react/solid';
import { Translate } from '@fanadise/common-logic';
import EditCollectionForm from 'components/collections/EditCollectionForm';

const CollectionsRouteAdd: React.FC = () => (
  <>
    <Toolbar
      title={<Translate id="collections:add:title" />}
      leading={
        <Link to="/account/collections">
          <Button
            tag="span"
            variant="icon"
            icon={<Icon icon={ArrowSmLeftIcon} size="lg" />}
          />
        </Link>
      }
      mb="8"
    />
    <Panel>
      <EditCollectionForm />
    </Panel>
  </>
);

export default CollectionsRouteAdd;
