import React from 'react';
import Box, { BoxProps } from '../Box';

export interface SimpleGridProps extends Omit<BoxProps, 'display'> {}

const SimpleGrid: React.FC<SimpleGridProps> = (props) => (
  <Box display="grid" {...props} />
);

export default SimpleGrid;
