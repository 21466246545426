import React, { useContext } from 'react';
import TranslationsContext from 'contexts/TranslationsContext';

export interface TranslateProps {
  id: string;
  values?:
    | {
        [key: string]: any;
      }
    | undefined;
}

const Translate: React.FC<TranslateProps> = ({ id, values }) => {
  const { translate } = useContext(TranslationsContext);
  const msg = translate(id, values);
  return msg ? <>{msg}</> : null;
};

export default Translate;
