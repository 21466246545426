import React from 'react';
import { useField } from 'formik';
import PasswordInput from 'components/molecules/PasswordInput';
import InputText, { InputProps } from 'components/atoms/Input';

export interface ConnectedInputProps extends InputProps {
  name: string;
}

const ConnectedInput: React.FC<ConnectedInputProps> = ({
  name,
  type,
  ...rest
}) => {
  const [field, { error, touched }] = useField({ name, type });
  const isInvalid = touched && Boolean(error);
  const Input = type === 'password' ? PasswordInput : InputText;

  return <Input type={type} isInvalid={isInvalid} {...field} {...rest} />;
};

export default ConnectedInput;
