import React, {
  useState,
  useEffect,
  useContext,
  PropsWithChildren,
} from 'react';
import {
  Icon,
  SmartTable,
  SnackbarContext,
  EmptyStateMessage,
  SmartTableProps,
  Box,
  MenuItem,
} from '@fanadise/common-ui';
import { MultiResultsDto } from '@fanadise/common-types';
import { PencilIcon } from '@heroicons/react/outline';
import { Link, useNavigate } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import { SearchInput, useTranslation } from '@fanadise/common-logic';
import { PaginatedRequestQuery } from '@fanadise/common-data-access/src/types/paginatedRequestQuery';

interface ResourcesListProps<Item, Key extends string>
  extends Pick<
    SmartTableProps<Item>,
    'columns' | 'renderMenu' | 'sortedProps'
  > {
  dataKey: Key;
  fetchData: (
    params: {
      search?: string;
      sort?: string;
    } & PaginatedRequestQuery,
  ) => Promise<MultiResultsDto<Key, Item>>;
  fetchDate?: Date;
  hasEditAction?: boolean;
  hasSearch?: boolean;
  extraParams?: Record<string, string>;
}

const ResourcesList = <
  Item extends { id: string | number },
  Key extends string,
>({
  dataKey,
  fetchData,
  fetchDate,
  columns,
  sortedProps,
  renderMenu,
  hasEditAction = true,
  hasSearch = true,
  extraParams = {},
}: PropsWithChildren<ResourcesListProps<Item, Key>>) => {
  const { translate } = useTranslation();
  const { addErrorAlert } = useContext(SnackbarContext)!;
  const search = useQuery().get('search');
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(50);
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState<Item[]>();
  const [sorting, setSorting] = useState<
    NonNullable<SmartTableProps<Item>['sorting']>
  >([]);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const data = await fetchData({
          page: page + 1,
          size: pageSize,
          ...(hasSearch
            ? {
                search: search || '',
              }
            : {}),
          ...(sorting.length > 0
            ? {
                sort: sorting
                  .map(({ prop, order }) => `${prop},${order}`)
                  .join(','),
              }
            : {}),
          ...extraParams,
        });
        setItems(data[dataKey]);
        // @ts-ignore
        setTotal(data.totalCount);
      } catch (err: any) {
        addErrorAlert(err.message || translate('error:default'));
      }
    };

    fetchItems();
  }, [fetchDate, hasSearch, page, pageSize, search, sorting, fetchData]);

  return (
    <>
      {items && (
        <>
          {hasSearch && (
            <Box mb="4" maxW="72">
              <SearchInput
                defaultValue={search}
                onChange={(value) => {
                  navigate(value ? `?search=${value}` : '?', { replace: true });
                }}
              />
            </Box>
          )}

          {items.length === 0 ? (
            <EmptyStateMessage message={translate('common:noResults')} />
          ) : (
            <SmartTable<Item>
              columns={columns}
              sortedProps={sortedProps}
              data={items}
              sorting={sorting}
              onSortingChange={setSorting}
              page={page}
              onPageChange={setPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
              total={total}
              pageSizesLabel={translate('table:pageSizePrefix')}
              paginationOfLabel={translate('table:of')}
              renderMenu={
                renderMenu ||
                (hasEditAction
                  ? (item) => (
                      <Link to={`edit/${item.id}`}>
                        <MenuItem
                          tag="span"
                          icon={<Icon icon={PencilIcon} size="sm" />}
                        >
                          {translate('common:edit')}
                        </MenuItem>
                      </Link>
                    )
                  : undefined)
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default ResourcesList;
