import React from 'react';
import EvmNetworkContext, {
  EvmNetworkContextValue,
} from 'contexts/EvmNetworkContext';

export interface EvmNetworkProviderProps {
  value: EvmNetworkContextValue;
}

const EvmNetworkProvider: React.FC<EvmNetworkProviderProps> = ({
  value,
  children,
}) => (
  <EvmNetworkContext.Provider value={value}>
    {children}
  </EvmNetworkContext.Provider>
);

export default EvmNetworkProvider;
