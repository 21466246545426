import React, { useContext } from 'react';
import usePrivateRoute from 'hooks/usePrivateRoute';
import AppAuthContext from 'contexts/AppAuthContext';

const PrivateRoute: React.FC = ({ children }) => {
  usePrivateRoute();
  const { isAuthenticated } = useContext(AppAuthContext);

  return (isAuthenticated ? children : null) as React.ReactElement;
};

export default PrivateRoute;
