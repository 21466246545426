import classNames from 'classnames';
import Box, { BoxProps } from 'components/atoms/Box';
import React from 'react';

import styles from './Panel.module.css';

export interface PanelProps extends BoxProps {}

const Panel: React.FC<PanelProps> = ({ p, className, ...rest }) => (
  <Box
    data-testid="panel"
    className={classNames(styles.root, className)}
    p={p}
    py={p ? '' : '8'}
    px={
      p
        ? ''
        : {
            base: '4',
            md: '8',
          }
    }
    bg="white"
    borderRadius="md"
    border="1px"
    borderColor="gray.100"
    borderStyle="solid"
    {...rest}
  />
);

export default Panel;
