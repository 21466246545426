import React, { useState, useContext, useEffect } from 'react';
import classNames from 'classnames';
import {
  Anchor,
  Button,
  Dialog,
  Icon,
  IntroLink,
  MetaMaskIcon,
  SnackbarContext,
  TrustWalletIcon,
  WalletConnectIcon,
} from '@fanadise/common-ui';
import Link from 'components/shared/Link';
import { UserRejectedRequestError } from '@web3-react/walletconnect-connector';
import log from 'loglevel';
import { useEthers } from '@usedapp/core';
import { WalletContextValue } from 'contexts/WalletContext';
import useTranslation from 'hooks/useTranslation';
import walletConnectConnector from 'connectors/walletConnect';
import injectedConnector from 'connectors/injected';
import Translate from 'components/shared/Translate';

import styles from './WalletConnect.module.css';

export interface WalletConnectProps {
  context: React.Context<WalletContextValue>;
  metamaskUrl?: string;
  trustWalletUrl?: string;
  className?: string;
}

const WalletConnect: React.FC<WalletConnectProps> = ({
  context,
  metamaskUrl = 'https://metamask.app.link',
  trustWalletUrl = 'https://link.trustwallet.com',
  className,
}) => {
  const { connect, isEthereumSupported } = useContext(context);
  const { addErrorAlert } = useContext(SnackbarContext)!;
  const { translate } = useTranslation();
  const { activate } = useEthers();

  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [injectedProvider, setInjectedProvider] =
    useState<{ isMetaMask: boolean; isTrust: boolean } | null>(null);

  const redirectTo = (url: string) => {
    window.location.href = url;
  };

  const handleMetaMaskConnect = () => {
    if (!injectedProvider || !injectedProvider.isMetaMask) {
      redirectTo(metamaskUrl);
      return;
    }

    connect();
  };

  const handleTrustConnect = () => {
    if (!injectedProvider || !injectedProvider.isTrust) {
      redirectTo(trustWalletUrl);
      return;
    }

    connect();
  };

  const handleWalletConnect = async () => {
    try {
      await activate(walletConnectConnector, undefined, true);
    } catch (err: any) {
      if (err instanceof UserRejectedRequestError) {
        walletConnectConnector.walletConnectProvider = undefined;
      } else {
        addErrorAlert(err.message || translate('error:default'));
      }
    }
  };

  const handleIntroLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();
    setIsInfoModalVisible(true);
    return false;
  };

  const handleInfoModalClose = () => {
    setIsInfoModalVisible(false);
  };

  useEffect(() => {
    if (!isEthereumSupported) {
      return;
    }

    const getCurrentProvider = async () => {
      try {
        const provider = await injectedConnector.getProvider();
        setInjectedProvider(provider);
      } catch (err) {
        log.error(err);
      }
    };

    getCurrentProvider();
  }, [isEthereumSupported]);

  return (
    <>
      <div className={classNames(styles.root, className)}>
        <Button
          size="lg"
          variant="outlined"
          icon={<Icon icon={MetaMaskIcon} size="lg" />}
          iconPlacement="fixedLeft"
          onClick={handleMetaMaskConnect}
          className={styles.connectButton}
        >
          <Translate id="connectWallet:connectWith" />
          Metamask
        </Button>

        {injectedProvider && injectedProvider.isTrust && (
          <Button
            size="lg"
            variant="outlined"
            icon={<Icon icon={TrustWalletIcon} size="lg" />}
            iconPlacement="fixedLeft"
            onClick={handleTrustConnect}
            className={styles.connectButton}
          >
            <Translate id="connectWallet:connectWith" />
            Trust Wallet
          </Button>
        )}

        <Button
          size="lg"
          variant="outlined"
          icon={<Icon icon={WalletConnectIcon} size="lg" />}
          iconPlacement="fixedLeft"
          onClick={handleWalletConnect}
          className={styles.connectButton}
        >
          <Translate id="connectWallet:connectWith" />
          Mobile Wallet
        </Button>

        <Link href="https://fanadise.com/" passHref>
          <IntroLink
            introText={<Translate id="connectWallet:howTo:prefix" />}
            linkText={<Translate id="connectWallet:howTo:suffix" />}
            isBroken
            onClick={handleIntroLinkClick}
          />
        </Link>
      </div>

      <Dialog
        title={translate('connectWallet:learn:title')}
        size="sm"
        description={
          <>
            {translate('connectWallet:learn:description')}
            <Anchor inline href="https://fanadise.com/" target="_blank">
              {translate('connectWallet:learn:readMore')}
            </Anchor>
          </>
        }
        isOpen={isInfoModalVisible}
        ariaHideApp={false}
        onRequestClose={handleInfoModalClose}
      />
    </>
  );
};

export default WalletConnect;
