import { useEffect, RefObject } from 'react';

const useOutsideClick = (
  ref: RefObject<HTMLElement>,
  handler: (event: MouseEvent) => void,
  isClosable = false,
) => {
  const handleClick = (event: MouseEvent) => {
    if (
      ref?.current &&
      (isClosable || !ref?.current.contains(event.target as HTMLElement))
    ) {
      handler(event);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);
};

export default useOutsideClick;
