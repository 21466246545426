import { api } from 'api';
import {
  BasePage,
  MultiResultsDto,
  Page,
  SingleResultDto,
} from '@fanadise/common-types';

export const fetchPage = async (id: string) => {
  const res = await api.get<SingleResultDto<'page', Page>>(`/pages/${id}`);
  return res.data.page;
};

export const fetchPages = async () => {
  const res = await api.get<MultiResultsDto<'pages', BasePage>>('/pages');
  return res.data;
};

export const fetchPageBySlug = async (slug: string) => {
  const res = await api.get<SingleResultDto<'page', Page>>(
    `/pages/${slug}/slug`,
  );
  return res.data.page;
};

export const createPage = async (data: SingleResultDto<'page', Page>) => {
  const res = await api.post<SingleResultDto<'page', Page>>('/pages', data);
  return res.data.page;
};

export const updatePage = async (id: string, data: { page: Partial<Page> }) => {
  const res = await api.put<SingleResultDto<'page', Page>>(
    `/pages/${id}`,
    data,
  );
  return res.data.page;
};

export const deletePage = async (id: string) => {
  await api.delete(`/pages/${id}`);
};
