/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  AnchorHTMLAttributes,
  forwardRef,
  MouseEventHandler,
} from 'react';
import classNames from 'classnames';

import styles from './Anchor.module.css';

export interface AnchorProps {
  variant?: 'primary' | 'subtle';
  size?: 'sm' | 'md';
  inline?: boolean;
  onClick?: MouseEventHandler;
  href?: string;
  children?: React.ReactNode;
  className?: string;
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target'];
}

const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>(
  (
    { children, variant = 'primary', size = 'md', inline, className, ...rest },
    ref,
  ) => (
    <a
      data-testid="anchor"
      ref={ref}
      className={classNames(
        styles.root,
        styles[`variant--${variant}`],
        styles[`size--${size}`],
        inline && styles.inline,
        className,
      )}
      {...rest}
    >
      {children}
    </a>
  ),
);

export default Anchor;
