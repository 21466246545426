const loadedScriptsCache = new Set();

export const loadExternalScript = (
  scriptId: string,
  scriptSrc: string,
  parentElement?: HTMLElement,
): Promise<void> =>
  new Promise((resolve, reject) => {
    const parent = parentElement ?? document.head;
    const scriptElement = document.createElement('script');
    scriptElement.id = scriptId;
    scriptElement.src = scriptSrc;
    scriptElement.onload = () => {
      loadedScriptsCache.add(scriptId);
      resolve();
    };
    scriptElement.onerror = reject;
    parent.appendChild(scriptElement);
  });

export const isExternalScriptAlreadyLoaded = (scriptId: string) =>
  loadedScriptsCache.has(scriptId);
