import { Theme } from '@fanadise/common-types';

export const DEFAULT_THEME: Theme = {
  color: {
    primary: {
      base: '#7554f9',
      '50': '#bfa8ff',
      '100': '#f3edff',
      '200': '#bfa8ff',
      '300': '#bfa8ff',
      '400': '#9d80ff',
      '500': '#7554f9',
      '600': '#563dd4',
      '700': '#3b2aad',
      '800': '#251a87',
      '900': '#171161',
    },
    gray: {
      base: '#6B7280',
      '50': '#F9F9FB',
      '100': '#F3F4F6',
      '200': '#E5E7EB',
      '300': '#C3C8D0',
      '400': '#9CA3AF',
      '500': '#6B7280',
      '600': '#4B5563',
      '700': '#374151',
      '800': '#1F2937',
      '900': '#111827',
    },
    error: {
      base: '#fa5454',
      '50': '#fff4c7',
      '100': '#fde7e4',
      '200': '#ffd6d1',
      '300': '#ad2a32',
      '400': '#ff8480',
      '500': '#fa5454',
      '600': '#d43d42',
      '700': '#871a25',
      '800': '#61111c',
      '900': '#171161',
    },
    warning: {
      base: '#fab224',
      '50': '#fff4c7',
      '100': '#fff4c7',
      '200': '#ffe89e',
      '300': '#ffda75',
      '400': '#ffc94d',
      '500': '#fab224',
      '600': '#d48d13',
      '700': '#ad6b07',
      '800': '#ad6b07',
      '900': '#613400',
    },
    success: {
      base: '#1db073',
      '50': '#e1f0e7',
      '100': '#b6e3ca',
      '200': '#89d6ae',
      '300': '#61c997',
      '400': '#3cbd83',
      '500': '#1db073',
      '600': '#0f8a5b',
      '700': '#066343',
      '800': '#013d2a',
      '900': '#001710',
    },
  },
  fontFamily: {
    base: 'Inter, sans-serif',
    heading: 'Inter, sans-serif',
  },
  fontWeight: {
    base: '400',
    normal: '400',
    semibold: '500',
    bold: '600',
    heading: '600',
    black: '900',
  },
  fontSize: {
    base: '0.875rem',
    xs: '0.625rem',
    sm: '0.75rem',
    md: '0.875rem',
    lg: '1rem',
    xl: '1.125rem',
    '2xl': '1.25rem',
    '3xl': '1.5rem',
    '4xl': '1.875rem',
    '5xl': '2.25rem',
    '6xl': '3rem',
  },
  lineHeight: {
    base: '1.5',
    xs: '1',
    sm: '1.25',
    md: '1.5',
    lg: '1.75',
    xl: '2',
  },
  size: {
    inverse: '-1',
    full: '100%',
    '4xs': '5rem',
    '3xs': '10rem',
    '2xs': '15rem',
    xs: '20rem',
    sm: '30rem',
    md: '48rem',
    lg: '62rem',
    xl: '72rem',
    '2xl': '80rem',
    '3xl': '96rem',
    '4xl': '110rem',
    '5xl': '120rem',
    '6xl': '140rem',
    0.5: '0.125rem',
    1: '0.25rem',
    1.5: '0.375rem',
    2: '0.5rem',
    2.5: '0.625rem',
    3: '0.75rem',
    3.5: '0.875rem',
    4: '1rem',
    4.5: '1.125rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem',
    12: '3rem',
    14: '3.5rem',
    16: '4rem',
    20: '5rem',
    24: '6rem',
    28: '7rem',
    32: '8rem',
    36: '9rem',
    40: '10rem',
    44: '11rem',
    48: '12rem',
    52: '13rem',
    56: '14rem',
    60: '15rem',
    64: '16rem',
    72: '18rem',
    80: '20rem',
    96: '24rem',
  },
  borderRadius: {
    xs: '0.25rem',
    sm: '0.5rem',
    md: '1rem',
    lg: '2rem',
    xl: '3rem',
    full: '9999px',
  },
  shadow: {
    xs: '0px 2px 6px -2px rgba(24, 39, 75, 0.08), 0px 8px 8px -4px rgba(24, 39, 75, 0.08)',
    sm: '0px 6px 8px -6px rgba(24, 39, 75, 0.08), 0px 8px 16px -6px rgba(24, 39, 75, 0.08)',
    md: '0px 6px 12px -6px rgba(24, 39, 75, 0.08), 0px 8px 24px -4px rgba(24, 39, 75, 0.04)',
    lg: '0px 6px 14px -6px rgba(24, 39, 75, 0.08), 0px 10px 32px -4px rgba(24, 39, 75, 0.1)',
    xl: '0px 8px 18px -6px rgba(24, 39, 75, 0.08), 0px 12px 42px -4px rgba(24, 39, 75, 0.12)',
    '2xl':
      '0px 8px 22px -6px rgba(24, 39, 75, 0.08), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)',
  },
};
