import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';

import styles from './Overlay.module.css';

export type OverlayProps = HTMLAttributes<HTMLElement>;

const Overlay: React.FC<OverlayProps> = ({ className, ...rest }) => (
  <div
    data-testid="overlay"
    role="button"
    tabIndex={0}
    className={classNames(styles.root, className)}
    {...rest}
  />
);

export default Overlay;
