import React from 'react';
import { useField } from 'formik';
import { GroupBase, MultiValue } from 'react-select';
import AsyncSmartSelect, {
  AsyncSmartSelectProps,
} from 'components/shared/AsyncSmartSelect';

export interface ConnectedAsyncSmartSelectProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> extends Omit<AsyncSmartSelectProps<Option, IsMulti, Group>, 'isInvalid'> {
  name: string;
}

const ConnectedAsyncSmartSelect = <
  Option extends { value: string },
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  name,
  options,
  isMulti,
  ...rest
}: ConnectedAsyncSmartSelectProps<Option, IsMulti, Group>) => {
  const [field, { error, touched }, helpers] = useField({ name });
  const isInvalid = touched && Boolean(error);

  return (
    <AsyncSmartSelect
      options={options}
      name={field.name}
      onChange={(option): void => {
        if (!option) {
          helpers.setValue(helpers);
        }

        if (isMulti) {
          helpers.setValue(
            (option as MultiValue<Option>).map(({ value }) => value),
          );
        } else {
          helpers.setValue((option as Option).value);
        }
      }}
      onBlur={field.onBlur}
      isInvalid={isInvalid}
      isMulti={isMulti}
      {...rest}
    />
  );
};

export default ConnectedAsyncSmartSelect;
