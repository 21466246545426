import React from 'react';
import Drawer from 'components/templates/Drawer';

import styles from './ViewScaffold.module.css';

export interface ViewScaffoldProps {
  header?: React.ReactElement;
  sidebar: React.ReactElement;
  isDrawerActive: boolean;
  onDrawerClose: () => void;
}

const ViewScaffold: React.FC<ViewScaffoldProps> = ({
  header,
  sidebar,
  isDrawerActive,
  onDrawerClose,
  children,
}) => (
  <div data-testid="viewScaffold" className={styles.root}>
    <Drawer
      panel={sidebar}
      isActive={isDrawerActive}
      onOverlayClick={onDrawerClose}
    >
      {header && React.cloneElement(header, { className: styles.header })}

      <div className={styles.wrap}>
        {React.cloneElement(sidebar, { className: styles.sidebar })}

        <div className={styles.body}>{children}</div>
      </div>
    </Drawer>
  </div>
);

export default ViewScaffold;
