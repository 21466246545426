export const BINANCE_SMART_CHAIN_MAINNET = {
  id: 56,
  name: 'Binance Smart Chain Mainnet',
  rpcUrls: ['https://bsc-dataseed.binance.org'],
  explorerUrls: ['https://bscscan.com'],
  nativeCurrency: {
    name: 'Binance Chain Native Token',
    symbol: 'bnb',
    decimals: 18,
  },
};

export const BINANCE_SMART_CHAIN_TESTNET = {
  id: 97,
  name: 'Binance Smart Chain Testnet',
  rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
  explorerUrls: ['https://testnet.bscscan.com'],
  nativeCurrency: {
    name: 'Binance Chain Native Token',
    symbol: 'bnb',
    decimals: 18,
  },
};

export const POLYGON_MAINNET = {
  id: 137,
  name: 'Polygon Mainnet',
  rpcUrls: ['https://polygon-rpc.com'],
  explorerUrls: ['https://polygonscan.com'],
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'matic',
    decimals: 18,
  },
};

export const POLYGON_TESTNET = {
  id: 80001,
  name: 'Polygon Testnet Mumbai',
  rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
  explorerUrls: ['https://mumbai.polygonscan.com'],
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'matic',
    decimals: 18,
  },
};
