import React from 'react';
import { Button, Icon, Toolbar } from '@fanadise/common-ui';
import { Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/solid';
import { Translate } from '@fanadise/common-logic';
import CollectionsList from 'components/collections/CollectionsList';

const CollectionsRouteList: React.FC = () => (
  <>
    <Toolbar
      mb="8"
      title={<Translate id="collections:list:title" />}
      actions={
        <Link to="add">
          <Button tag="span" icon={<Icon icon={PlusIcon} />}>
            <Translate id="collections:add:title" />
          </Button>
        </Link>
      }
    />

    <CollectionsList />
  </>
);

export default CollectionsRouteList;
