import { api } from '@fanadise/common-data-access';

export const signIn = async (data: { email: string; password: string }) => {
  const res = await api.post<{ token: string; refreshToken: string }>(
    '/admins/auth/sign-in',
    data,
  );
  return res.data;
};

export const refreshToken = async (data: { refreshToken: string }) => {
  const res = await api.post<{ token: string; refreshToken: string }>(
    '/admins/auth/refresh-token',
    data,
  );
  return res.data;
};

export const resetPassword = async (data: { email: string }) => {
  await api.post('/admins/auth/reset-password', data);
};

export const changePassword = async (data: {
  token: string;
  password: string;
}) => {
  await api.post('/admins/auth/change-password', data);
};
