import React, {
  Context,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { WalletContextValue } from 'contexts/WalletContext';
import injected from 'connectors/injected';
import log from 'loglevel';
import { substitute } from '@fanadise/common-utils';
import { WALLET_TOKEN_KEY } from 'consts/walletTokens';
import { UserRejectedRequestError } from '@web3-react/walletconnect-connector';
import walletConnect from 'connectors/walletConnect';
import { AuthContextValue } from 'contexts/AuthContext';
import { User } from '@fanadise/common-types';
import { useEthers } from '@usedapp/core';
import { getCurrentNetworkDetails } from 'utils/networkUtils';

const NETWORK = getCurrentNetworkDetails();

interface WalletProviderProps {
  context: Context<WalletContextValue>;
  authContext: Context<AuthContextValue<User>>;
  getUserNonceByAddressPromise: (address: string) => Promise<string>;
  signInWithSignaturePromise: (
    address: string,
    signature: string,
  ) => Promise<User>;
  getSignMessage: () => string;
}

const WalletProvider = ({
  context: WalletContext,
  authContext: AuthContext,
  getSignMessage,
  getUserNonceByAddressPromise,
  signInWithSignaturePromise,
  children,
}: PropsWithChildren<WalletProviderProps>) => {
  const {
    isTried: isAuthTried,
    isAuthenticated,
    user,
    setUser,
  } = useContext(AuthContext);

  const {
    active: isConnected,
    account: address,
    chainId,
    activate,
    deactivate,
    connector,
    library,
    error,
    setError,
  } = useEthers();

  const [isTried, setIsTried] = useState(isConnected);
  const [isEthereumSupported, setIsEthereumSupported] =
    useState<boolean | undefined>();

  const isSupportedChain = chainId ? NETWORK.id === chainId : false;
  const isMatchingUserWalletAddress =
    address && user?.wallet?.address ? address === user.wallet.address : false;
  const isReady =
    isConnected && isSupportedChain && isMatchingUserWalletAddress;

  const authenticate = useCallback(async () => {
    const nonce = await getUserNonceByAddressPromise(address!);
    const message = substitute(getSignMessage(), { nonce, address });
    let signature: string;
    try {
      signature = await library!.getSigner(address!).signMessage(message);
    } catch (err) {
      setError(err as Error);
      return;
    }

    const authUser = await signInWithSignaturePromise(address!, signature);
    setUser(authUser);
  }, [address, connector, library]);

  const connect = useCallback(async () => {
    if (isConnected) {
      return authenticate();
    }

    try {
      await activate(injected, undefined, true);
    } catch (err) {
      setError(err as Error);
      log.error(err);
    }
  }, [authenticate, activate]);

  const disconnect = useCallback(() => {
    localStorage.removeItem(WALLET_TOKEN_KEY);
    deactivate();
  }, [connector, deactivate]);

  useEffect(() => {
    const checkStatus = async () => {
      try {
        if (isConnected && isAuthTried && !isAuthenticated) {
          authenticate();
        }
      } catch (err) {
        log.error(err);
      }
    };

    checkStatus();
  }, [isConnected, isAuthTried, isAuthenticated, address]);

  useEffect(() => {
    injected
      .isAuthorized()
      .then((isAuthorized) => {
        if (isAuthorized) {
          activate(injected, undefined, true);
        }
      })
      .finally(() => {
        setIsTried(true);
      });

    if (localStorage.getItem(WALLET_TOKEN_KEY)) {
      activate(walletConnect, (err) => {
        log.error(err);

        if (err instanceof UserRejectedRequestError) {
          walletConnect.walletConnectProvider = undefined;
          localStorage.removeItem(WALLET_TOKEN_KEY);
        }
      });
    }
  }, []);

  useEffect(() => {
    const handleWindowLoad = () => {
      setIsEthereumSupported(Boolean((window as any).ethereum));
    };
    if (document.readyState === 'complete') {
      setIsEthereumSupported(Boolean((window as any).ethereum));
    } else {
      window.addEventListener('load', handleWindowLoad);
    }

    return () => {
      window.removeEventListener('load', handleWindowLoad);
    };
  }, []);

  const contextValue: WalletContextValue = useMemo(
    () => ({
      isTried,
      isConnected,
      isEthereumSupported,
      isReady,
      isSupportedChain,
      isMatchingUserWalletAddress,
      address,
      chainId,
      library,
      connector,
      connect,
      disconnect,
      error,
      setError,
    }),
    [
      isTried,
      isReady,
      isConnected,
      isEthereumSupported,
      isSupportedChain,
      isMatchingUserWalletAddress,
      address,
      chainId,
      library,
      connector,
      connect,
      disconnect,
      error,
    ],
  );

  return (
    <WalletContext.Provider value={contextValue}>
      {children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
