import { useContext, useState, useEffect } from 'react';
import { profileApi } from '@fanadise/common-data-access';
import { Nft } from '@fanadise/common-types';
import { SnackbarContext } from '@fanadise/common-ui';
import useTranslation from 'hooks/useTranslation';

const useProfileNftFetch = (
  isAuthenticated: boolean,
  id: string,
): [Nft | null, boolean] => {
  const { translate } = useTranslation();
  const { addErrorAlert } = useContext(SnackbarContext)!;
  const [nft, setNft] = useState<Nft | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    setIsLoading(true);

    const fetchNft = async () => {
      try {
        setNft(await profileApi.fetchProfileNft(id));
      } catch (err: any) {
        const message = err.message || translate('error:default');
        addErrorAlert(message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNft();
  }, [isAuthenticated, id]);

  return [nft, isLoading];
};

export default useProfileNftFetch;
