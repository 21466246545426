import React from 'react';
import { NftRarity } from '@fanadise/common-consts';

export interface NftRarityIconProps extends React.SVGProps<SVGSVGElement> {
  rarity: NftRarity;
}

const rarityToSvgPaths: Record<NftRarity, React.ReactNode> = {
  [NftRarity.Unlimited]: (
    <>
      <path
        opacity=".1"
        d="M25 8.889l-2.5-1.222-5-2.445L15 4l-2.5 1.222-5 2.445L5 8.889V21.11l2.5 1.222 5 2.445L15 26l2.5-1.222 5-2.445 2.5-1.222V8.89z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M25 8.75L22.5 10M25 8.75L22.5 7.5M25 8.75v3.125M17.5 5L15 3.75 12.5 5M5 8.75L7.5 7.5M5 8.75L7.5 10M5 8.75v3.125M15 26.25L12.5 25m2.5 1.25L17.5 25M15 26.25v-3.125M7.5 22.5L5 21.25v-3.125M22.5 22.5l2.5-1.25v-3.125"
        stroke="url(#paint1_linear)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.291 12.633c-1.495 0-2.71 1.202-2.71 2.651 0 1.466 1.215 2.65 2.71 2.65 1.074 0 1.863-.532 2.374-.992.132-.132.222-.226.332-.354.094.12.184.209.337.354.528.481 1.308.993 2.37.993 1.5 0 2.714-1.185 2.714-2.65 0-1.45-1.215-2.652-2.715-2.652-1.04 0-1.85.52-2.39 1.028a3.24 3.24 0 00-.316.336 4.643 4.643 0 00-.323-.336c-.529-.5-1.309-1.027-2.383-1.027zm-1.116 2.651c0-.575.503-1.065 1.116-1.065.49 0 .925.255 1.27.58.12.114.341.353.444.485-.09.128-.226.307-.444.503-.379.34-.78.562-1.27.562-.613 0-1.116-.473-1.116-1.065zm7.636 0c0 .592-.49 1.065-1.108 1.065-.498 0-.907-.23-1.265-.562a3.173 3.173 0 01-.443-.503 7.62 7.62 0 01.443-.486c.324-.31.767-.58 1.265-.58.618 0 1.108.49 1.108 1.066z"
        fill="url(#paint2_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="5"
          y1="26"
          x2="25"
          y2="26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7554FA" />
          <stop offset="1" stopColor="#EF4444" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="5"
          y1="26.25"
          x2="25"
          y2="26.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7554FA" />
          <stop offset="1" stopColor="#EF4444" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="9.581"
          y1="17.935"
          x2="20.418"
          y2="17.935"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7554FA" />
          <stop offset="1" stopColor="#EF4444" />
        </linearGradient>
      </defs>
    </>
  ),
  [NftRarity.Common]: (
    <>
      <path
        d="M25 8.75L22.5 10M25 8.75L22.5 7.5M25 8.75v3.125M17.5 5L15 3.75 12.5 5M5 8.75L7.5 7.5M5 8.75L7.5 10M5 8.75v3.125M15 26.25L12.5 25m2.5 1.25L17.5 25M15 26.25v-3.125M7.5 22.5L5 21.25v-3.125M22.5 22.5l2.5-1.25v-3.125"
        stroke="#B8BFCF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.569 10.273h-1.735l-2.16 1.368v1.636l1.998-1.253h.051V19h1.846v-8.727z"
        fill="#B8BFCF"
      />
    </>
  ),
  [NftRarity.Rare]: (
    <>
      <path
        d="M25 8.75L22.5 10M25 8.75L22.5 7.5M25 8.75v3.125M17.5 5L15 3.75 12.5 5M5 8.75L7.5 7.5M5 8.75L7.5 10M5 8.75v3.125M15 26.25L12.5 25m2.5 1.25L17.5 25M15 26.25v-3.125M7.5 22.5L5 21.25v-3.125M22.5 22.5l2.5-1.25v-3.125"
        stroke="#919BB4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.928 19h6.222v-1.509h-3.665v-.06l1.274-1.248c1.794-1.636 2.276-2.454 2.276-3.447 0-1.513-1.236-2.583-3.107-2.583-1.832 0-3.09 1.096-3.085 2.809h1.751c-.004-.836.524-1.347 1.321-1.347.767 0 1.338.477 1.338 1.244 0 .695-.426 1.172-1.218 1.935l-3.107 2.877V19z"
        fill="#919BB4"
      />
    </>
  ),
  [NftRarity.Holo]: (
    <>
      <path
        d="M25 8.75L22.5 10M25 8.75L22.5 7.5M25 8.75v3.125M17.5 5L15 3.75 12.5 5M5 8.75L7.5 7.5M5 8.75L7.5 10M5 8.75v3.125M15 26.25L12.5 25m2.5 1.25L17.5 25M15 26.25v-3.125M7.5 22.5L5 21.25v-3.125M22.5 22.5l2.5-1.25v-3.125"
        stroke="#737D96"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.978 19.12c1.96 0 3.362-1.079 3.358-2.57.004-1.087-.686-1.863-1.969-2.024v-.069c.976-.174 1.65-.865 1.645-1.845.005-1.376-1.201-2.459-3.008-2.459-1.79 0-3.137 1.049-3.162 2.557h1.768c.022-.665.631-1.095 1.385-1.095.746 0 1.245.452 1.24 1.108.005.686-.58 1.15-1.419 1.15h-.814v1.356h.814c.989 0 1.607.494 1.603 1.197.004.695-.593 1.172-1.437 1.172-.813 0-1.423-.426-1.457-1.065h-1.858c.03 1.525 1.394 2.586 3.311 2.586z"
        fill="#737D96"
      />
    </>
  ),
  [NftRarity.Platinum]: (
    <>
      <path
        d="M25 8.889l-2.5-1.222-5-2.445L15 4l-2.5 1.222-5 2.445L5 8.889V21.11l2.5 1.222 5 2.445L15 26l2.5-1.222 5-2.445 2.5-1.222V8.89z"
        fill="#F1F4FA"
      />
      <path
        d="M25 8.75L22.5 10M25 8.75L22.5 7.5M25 8.75v3.125M17.5 5L15 3.75 12.5 5M5 8.75L7.5 7.5M5 8.75L7.5 10M5 8.75v3.125M15 26.25L12.5 25m2.5 1.25L17.5 25M15 26.25v-3.125M7.5 22.5L5 21.25v-3.125M22.5 22.5l2.5-1.25v-3.125"
        stroke="#4B556E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.009 17.466h4.189V19h1.764v-1.534h1.082v-1.479h-1.082v-5.714h-2.31l-3.643 5.74v1.453zm4.223-1.479h-2.365v-.068l2.297-3.635h.068v3.703z"
        fill="#4B556E"
      />
    </>
  ),
  [NftRarity.Unique]: (
    <>
      <path
        d="M25 8.889l-2.5-1.222-5-2.445L15 4l-2.5 1.222-5 2.445L5 8.889V21.11l2.5 1.222 5 2.445L15 26l2.5-1.222 5-2.445 2.5-1.222V8.89z"
        fill="#7554F9"
        fillOpacity=".15"
      />
      <path
        d="M25 8.75L22.5 10M25 8.75L22.5 7.5M25 8.75v3.125M17.5 5L15 3.75 12.5 5M5 8.75L7.5 7.5M5 8.75L7.5 10M5 8.75v3.125M15 26.25L12.5 25m2.5 1.25L17.5 25M15 26.25v-3.125M7.5 22.5L5 21.25v-3.125M22.5 22.5l2.5-1.25v-3.125"
        stroke="#7554F9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.772 10.273v5.51c0 .997-.699 1.717-1.772 1.717-1.07 0-1.773-.72-1.773-1.717v-5.51h-1.845v5.667c0 1.91 1.44 3.184 3.617 3.184 2.17 0 3.618-1.275 3.618-3.184v-5.667h-1.845z"
        fill="#7554F9"
      />
    </>
  ),
};

const NftRarityIcon: React.FC<NftRarityIconProps> = ({ rarity, ...rest }) => (
  <svg
    data-testid="nftRarityIcon"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    {rarityToSvgPaths[rarity]}
  </svg>
);

export default NftRarityIcon;
