import React from 'react';
import { Routes, Route } from 'react-router-dom';

import CollectionsRouteAdd from './CollectionsRouteAdd';
import CollectionsRouteEdit from './CollectionsRouteEdit';
import CollectionsRouteList from './CollectionsRouteList';

const CollectionsRoute: React.FC = () => (
  <Routes>
    <Route index element={<CollectionsRouteList />} />
    <Route path="add" element={<CollectionsRouteAdd />} />
    <Route path="edit/:id" element={<CollectionsRouteEdit />} />
  </Routes>
);
export default CollectionsRoute;
