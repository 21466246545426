import React, { forwardRef } from 'react';

import Text, { TextProps } from 'components/atoms/Text';

export interface HeadingProps extends TextProps {}

const Heading = forwardRef<HTMLElement, HeadingProps>(
  (
    {
      tag = 'h2',
      fontFamily = 'heading',
      fontWeight = 'heading',
      fontSize = 'lg',
      ...rest
    },
    ref,
  ) => (
    <Text
      tag={tag}
      fontFamily={fontFamily}
      fontWeight={fontWeight}
      fontSize={fontSize}
      color="gray.800"
      ref={ref}
      {...rest}
    />
  ),
);

export default Heading;
