import AppAuthContext from 'contexts/AppAuthContext';
import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

const usePrivateRoute = () => {
  const navigate = useNavigate();
  const { isTried, isAuthenticated } = useContext(AppAuthContext);

  useEffect(() => {
    if (isTried && !isAuthenticated) {
      navigate('/auth/sign-in', { replace: true });
    }
  }, [isTried, isAuthenticated]);
};

export default usePrivateRoute;
