import { BasePost, Purchase } from '@fanadise/common-types';
import {
  Box,
  Stack,
  Alert,
  Icon,
  Text,
  Button,
  Separator,
} from '@fanadise/common-ui';
import PurchaseSummary from 'components/payment/PurchaseSummary';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { PostAccess, PurchaseContentType } from '@fanadise/common-consts';

export interface PurchaseSuccessProps {
  purchase: Purchase;
}

const PurchaseSuccess: React.FC<PurchaseSuccessProps> = ({ purchase }) => {
  const { translate } = useTranslation();

  return (
    <Stack gap="4" align="center">
      <Alert
        message={translate('purchaseSuccess:message')}
        icon={<Icon icon={CheckCircleIcon} size="lg" />}
        type="success"
        size="lg"
      />

      {purchase.contentType === PurchaseContentType.Post &&
      (purchase.content as BasePost).access === PostAccess.Paid ? (
        <a href={`/?postId=${purchase.content.id}`}>
          <Button tag="span" size="lg">
            {translate('purchaseSuccess:viewPost')}
          </Button>
        </a>
      ) : (
        <>
          <Box
            mb="2"
            px="6"
            py="4"
            maxW="md"
            w="full"
            mx="auto"
            bg="gray.50"
            borderRadius="sm"
          >
            <Text fontWeight="semibold">
              {translate('purchaseSuccess:pleaseWait')}
            </Text>
          </Box>

          <a href="/">
            <Button tag="span" size="lg">
              {translate('common:backToHome')}
            </Button>
          </a>
        </>
      )}

      {purchase && (
        <>
          <Box w="full">
            <Separator spacing="4" />
          </Box>

          <PurchaseSummary purchase={purchase} />
        </>
      )}
    </Stack>
  );
};

export default PurchaseSuccess;
