import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useField, useFormikContext } from 'formik';
import log from 'loglevel';
import { usersApi } from '@fanadise/common-data-access';
import { Input, InputProps, Spinner } from '@fanadise/common-ui';

import styles from './UsernameField.module.css';

export interface UsernameFieldProps extends InputProps {
  name: string;
  currentUsername: string;
  type?: InputProps['type'];
}

const UsernameField: React.FC<UsernameFieldProps> = ({
  name,
  currentUsername,
  type,
  ...rest
}) => {
  const [{ value, onChange, ...field }, { error }] = useField({
    name,
    type,
  });
  const { setFieldValue } = useFormikContext();
  const isInvalid = Boolean(error);
  const timeoutRef = useRef<ReturnType<Window['setTimeout']>>();
  const [isChecking, setIsChecking] = useState(false);

  useEffect(
    () => () => {
      window.clearTimeout(timeoutRef.current);
    },
    [],
  );

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      window.clearTimeout(timeoutRef.current);

      const newValue = event.currentTarget.value;

      if (newValue === currentUsername) {
        setFieldValue('isUsernameUnique', true);
      } else if (newValue.length > 4) {
        timeoutRef.current = window.setTimeout(async () => {
          try {
            setIsChecking(true);
            const isUnique =
              (await (await usersApi.fetchUsers({ username: newValue })).users)
                .length === 0;
            setFieldValue('isUsernameUnique', isUnique);
          } catch (err) {
            log.error(err);
          } finally {
            setIsChecking(false);
          }
        }, 1000);
      }

      onChange(event);
    },
    [onChange, currentUsername],
  );

  return (
    <div className={styles.root}>
      <Input
        type={type}
        value={value}
        isInvalid={isInvalid}
        onChange={handleChange}
        {...field}
        {...rest}
      />

      {isChecking && (
        <div className={styles.spinner}>
          <Spinner size="sm" />
        </div>
      )}
    </div>
  );
};

export default UsernameField;
