import React from 'react';
import classNames from 'classnames';
import { CubeTransparentIcon } from '@heroicons/react/outline';
import { NftRarity } from '@fanadise/common-consts';
import { capitalize } from '@fanadise/common-utils';
import Icon from 'components/atoms/Icon';

import styles from './NftRarityBadge.module.css';

export interface NftRarityBadgeProps {
  tag?: keyof JSX.IntrinsicElements;
  rarity: NftRarity;
  size?: 'sm' | 'md';
  className?: string;
}

const NftRarityBadge: React.FC<NftRarityBadgeProps> = ({
  tag: Tag = 'span',
  rarity,
  size = 'md',
  children,
  className,
  ...rest
}) => (
  <Tag
    data-testid="nftRarityBadge"
    className={classNames(
      styles.root,
      styles[`rarity--${rarity}`],
      styles[`size--${size}`],
      className,
    )}
    {...rest}
  >
    <span className={styles.wrap}>
      <Icon icon={CubeTransparentIcon} size="sm" />
      <span>{children || capitalize(rarity)}</span>
    </span>
  </Tag>
);

export default NftRarityBadge;
