import { camelToKebabCase } from '@fanadise/common-utils';
import { THEME_PREFIX } from 'consts/themePrefix';
import { ThemeProperties } from 'types/themeProperty';

export const convertThemeProperty = (
  property: keyof ThemeProperties,
  value?: string | number,
  prefix: string = THEME_PREFIX,
) => {
  if (!value) {
    return;
  }

  const propertyAsKebab = camelToKebabCase(property);
  const formatedValue = value.toString().replace('-', '').replace('.', '-');

  return `var(--${prefix}-${propertyAsKebab}-${formatedValue})`;
};

export const convertThemeSize = (value?: ThemeProperties['size']) => {
  if (value === undefined) {
    return;
  }

  if (value === 'none') {
    return '0px';
  }

  if (value === 'full') {
    return '100%';
  }

  if (value === 'w-screen') {
    return '100vw';
  }

  if (value === 'h-screen') {
    return '100vh';
  }

  if (
    value === 'auto' ||
    value.endsWith('px') ||
    value.endsWith('rem') ||
    value.endsWith('vw') ||
    value.endsWith('vh') ||
    value.endsWith('%') ||
    value.match(/calc\(.+\)/)
  ) {
    return value;
  }

  const valueAsNumber = Number(value);
  if (Number.isNaN(valueAsNumber)) {
    return convertThemeProperty('size', value!);
  }

  return valueAsNumber < 0
    ? `calc(-1 * ${convertThemeProperty('size', value)})`
    : convertThemeProperty('size', value);
};

export const convertThemeColor = (value?: ThemeProperties['color']) => {
  if (value === 'white' || value === 'black') {
    return value;
  }
  return convertThemeProperty('color', value);
};

export const convertThemeBorderRadius = (
  value?: ThemeProperties['borderRadius'],
) => {
  if (value === 'full') {
    return '9999px';
  }

  return convertThemeProperty('borderRadius', value);
};

export const convertThemeShadow = (value?: ThemeProperties['shadow']) =>
  convertThemeProperty('shadow', value);

export const convertThemeFontFamily = (value?: ThemeProperties['fontFamily']) =>
  convertThemeProperty('fontFamily', value);

export const convertThemeFontSize = (value?: ThemeProperties['fontSize']) =>
  convertThemeProperty('fontSize', value);

export const convertThemeFontWeight = (value?: ThemeProperties['fontWeight']) =>
  convertThemeProperty('fontWeight', value);

export const convertThemeLineHeight = (value?: ThemeProperties['lineHeight']) =>
  convertThemeProperty('lineHeight', value);
