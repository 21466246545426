import React from 'react';

import Button, { ButtonProps } from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import { HeartIcon } from '@heroicons/react/solid';
import { HeartIcon as HeartOutlineIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

import styles from './HeartButton.module.css';

export interface HeartButtonProps
  extends Omit<
    ButtonProps,
    'icon' | 'variant' | 'fixedIcon' | 'type' | 'children'
  > {
  variant?: 'transparent' | 'filled';
  isHearted?: boolean;
}

const HeartButton: React.FC<HeartButtonProps> = ({
  variant = 'transparent',
  isHearted = false,
  className,
  ...rest
}) => (
  <Button
    className={classNames(
      styles.root,
      styles[`variant--${variant}`],
      {
        [styles.isHearted]: isHearted,
      },
      className,
    )}
    icon={<Icon icon={isHearted ? HeartIcon : HeartOutlineIcon} />}
    color="danger"
    variant="icon"
    {...rest}
  />
);

export default HeartButton;
