import React from 'react';
import { Toolbar, Panel } from '@fanadise/common-ui';
import AdminSettingsForm from 'components/adminSettings/AdminSettingsForm';
import AdminPasswordForm from 'components/adminSettings/AdminPasswordForm';
import * as adminsApi from 'api/adminsApi';
import { Translate } from '@fanadise/common-logic';

const AdminSettingsRoute: React.FC = () => (
  <>
    <Toolbar title={<Translate id="common:settings" />} mb="8" />

    <Panel>
      <AdminSettingsForm />
      <AdminPasswordForm
        changePassword={(values) => adminsApi.changeAdminPassword(values)}
      />
    </Panel>
  </>
);

export default AdminSettingsRoute;
