import React from 'react';
import { utils } from 'ethers';
import { shortenBalance } from '@fanadise/common-utils';

export interface WalletBalanceProps {
  balance: string;
  symbol?: string;
  shortenTo?: number;
}

const WalletBalance: React.FC<WalletBalanceProps> = ({
  balance,
  symbol,
  shortenTo = 5,
}) =>
  balance ? (
    <>
      {shortenBalance(utils.formatEther(balance), shortenTo)}
      {symbol ? ` ${symbol}` : ''}
    </>
  ) : null;

export default WalletBalance;
