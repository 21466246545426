import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  FieldArray,
  Form,
  Formik,
  FormikConsumer,
  FormikContextType,
  FormikHelpers,
} from 'formik';
import * as yup from 'yup';
import {
  SnackbarContext,
  ConnectedLoading,
  ConnectedSubmit,
  ConnectedInput,
  ConnectedFormItem,
  Separator,
  ConnectedFileInput,
  FilePreview,
  DndProvider,
  DndHTML5Backend,
  DragDropItem,
  Button,
  Icon,
  Stack,
} from '@fanadise/common-ui';
import { useNavigate } from 'react-router-dom';
import {
  NftConfig,
  NftAttribute,
  BaseAccount,
  Category,
  Collection,
} from '@fanadise/common-types';
import {
  NftAttributeDisplayType,
  NftRarity,
  NftType,
} from '@fanadise/common-consts';
import FormSectionTitle from 'components/shared/FormSectionTitle';
import FormSubmitBar from 'components/shared/FormSubmitBar';
import FormGrid from 'components/shared/FormGrid';
import { TrashIcon, PlusIcon } from '@heroicons/react/outline';
import { Translate, useTranslation } from '@fanadise/common-logic';
import ConnectedSmartSelect from 'components/shared/ConnectedSmartSelect';
import {
  accountsApi,
  categoriesApi,
  collectionsApi,
  nftConfigsApi,
} from '@fanadise/common-data-access';

import styles from './EditNftConfigForm.module.css';

interface EditNftConfigFormValues {
  accountId?: string;
  categoryId?: string;
  collectionId?: string;
  name: string;
  description: string;
  extendedDescription?: string;
  type: NftType;
  rarity: NftRarity;
  totalSupply?: number;
  youtubeUrl: string;
  animationUrl: string;
  backgroundColor: string;
  fileFile?: File;
  coverFile?: File;
  attributes: NftAttribute[];
}

export interface EditNftConfigFormProps {
  nftConfig?: NftConfig;
  onChange?: (nft: NftConfig) => void;
}

const EditNftConfigForm: React.FC<EditNftConfigFormProps> = ({
  nftConfig,
  onChange,
}) => {
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const { addSuccessAlert, addErrorAlert } = useContext(SnackbarContext)!;
  const [accounts, setAccounts] = useState<BaseAccount[]>();
  const [collections, setCollections] = useState<Collection[]>();
  const [categories, setCategories] = useState<Category[]>();

  const validationSchema = yup.object().shape({
    accountId: yup.string().required(translate('validation:required')),
    collectionId: yup.string().required(translate('validation:required')),
    categoryId: yup.string().required(translate('validation:required')),
    name: yup.string().required(translate('validation:required')),
    description: yup.string().required(translate('validation:required')),
    type: yup.string().required(translate('validation:required')),
    rarity: yup.string().required(translate('validation:required')),
    totalSupply: yup
      .number()
      .required(translate('validation:required'))
      .integer(translate('validation:invalid'))
      .min(1, translate('validation:invalid')),
    attributes: yup.array().of(
      yup.object().shape({
        traitType: yup.string().required(translate('validation:required')),
        value: yup.string().required(translate('validation:required')),
      }),
    ),
  });

  const initialValues: EditNftConfigFormValues = {
    accountId: nftConfig?.accountId,
    collectionId: nftConfig?.collectionId,
    categoryId: nftConfig?.categoryId,
    name: nftConfig?.name || '',
    description: nftConfig?.description || '',
    extendedDescription: nftConfig?.extendedDescription || '',
    type: nftConfig?.type || NftType.Content,
    rarity: nftConfig?.rarity || NftRarity.Common,
    totalSupply: nftConfig?.totalSupply,
    youtubeUrl: nftConfig?.youtubeUrl || '',
    animationUrl: nftConfig?.animationUrl || '',
    backgroundColor: nftConfig?.backgroundColor || '',
    attributes: nftConfig?.attributes || [],
  };

  const handleSubmit = useCallback(
    async (
      values: EditNftConfigFormValues,
      { setFieldValue }: FormikHelpers<EditNftConfigFormValues>,
    ) => {
      try {
        const propsToSave = {
          accountId: values.accountId!,
          collectionId: values.collectionId!,
          categoryId: values.categoryId!,
          name: values.name,
          description: values.description,
          extendedDescription: values.extendedDescription,
          type: values.type,
          rarity: values.rarity,
          totalSupply: values.totalSupply!,
          youtubeUrl: values.youtubeUrl,
          animationUrl: values.animationUrl,
          backgroundColor: values.backgroundColor,
          attributes: values.attributes.map((attr) => ({
            value: attr.value,
            traitType: attr.traitType,
            displayType: attr.displayType || null,
          })),
        };

        let savedNftConfig: NftConfig;
        let message;

        if (nftConfig) {
          savedNftConfig = await nftConfigsApi.updateNftConfig(
            nftConfig.id,
            propsToSave,
          );
          message = translate('success:saved');
        } else {
          savedNftConfig = await nftConfigsApi.createNftConfig(propsToSave);
          message = translate('success:added');
        }

        try {
          if (values.fileFile) {
            const data = new FormData();
            data.append('file', values.fileFile);
            savedNftConfig = await nftConfigsApi.uploadNftConfigFile(
              savedNftConfig.id,
              data,
            );
            setFieldValue('file', null);
          }

          if (values.coverFile) {
            const data = new FormData();
            data.append('file', values.coverFile);
            savedNftConfig = await nftConfigsApi.uploadNftConfigCover(
              savedNftConfig.id,
              data,
            );
            setFieldValue('coverFile', null);
          }
        } catch (err) {
          if (nftConfig) {
            throw err;
          }
        }

        addSuccessAlert(message);

        if (!nftConfig) {
          navigate(`/account/configs/edit/${savedNftConfig!.id}`, {
            replace: true,
          });
        } else if (onChange) {
          onChange(nftConfig);
        }
      } catch (err: any) {
        addErrorAlert(err.message || translate('error:default'));
      }
    },
    [translate, addSuccessAlert, addErrorAlert, nftConfig?.id, onChange],
  );

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const result = await accountsApi.fetchAccounts();
        setAccounts(result.accounts);
      } catch (err: any) {
        addErrorAlert(err.message || translate('error:default'));
      }
    };

    const fetchCollections = async () => {
      try {
        const result = await collectionsApi.fetchCollections();
        setCollections(result.collections);
      } catch (err: any) {
        addErrorAlert(err.message || translate('error:default'));
      }
    };

    const fetchCategories = async () => {
      try {
        const result = await categoriesApi.fetchCategories();
        setCategories(result.categories);
      } catch (err: any) {
        addErrorAlert(err.message || translate('error:default'));
      }
    };

    fetchAccounts();
    fetchCollections();
    fetchCategories();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <ConnectedLoading>
        <Form noValidate>
          <FormGrid>
            <ConnectedFormItem
              fieldId="accountId"
              fieldName="accountId"
              label={translate('nftConfigs:form:account')}
              isRequired
            >
              <ConnectedSmartSelect
                name="accountId"
                placeholder={translate('nftConfigs:form:account:placeholder')}
                options={
                  accounts
                    ? accounts.map((account) => ({
                        label: `${account.name} (${account.id})`,
                        value: account.id,
                      }))
                    : []
                }
              />
            </ConnectedFormItem>
          </FormGrid>

          <ConnectedFormItem
            fieldId="name"
            fieldName="name"
            label={translate('common:name')}
            isRequired
          >
            <ConnectedInput
              name="name"
              placeholder={translate('nftConfigs:form:name:placeholder')}
            />
          </ConnectedFormItem>

          <ConnectedFormItem
            fieldId="description"
            fieldName="description"
            label={translate('common:description')}
            isRequired
          >
            <ConnectedInput
              name="description"
              type="textarea"
              rows={3}
              placeholder={translate('common:descriptionPlaceholder')}
            />
          </ConnectedFormItem>

          <FormGrid>
            <ConnectedFormItem
              fieldId="type"
              fieldName="type"
              label={translate('nftConfigs:form:nftType')}
              isRequired
            >
              <ConnectedSmartSelect
                name="type"
                isSearchable={false}
                options={Object.values(NftType).map((type) => ({
                  value: type,
                  label: translate(`nft:type:${type}`),
                }))}
              />
            </ConnectedFormItem>

            <ConnectedFormItem
              fieldId="rarity"
              fieldName="rarity"
              label={translate('nftConfigs:form:nftRarity')}
              isRequired
            >
              <ConnectedSmartSelect
                name="rarity"
                isSearchable={false}
                options={Object.values(NftRarity).map((lvl) => ({
                  value: lvl,
                  label: translate(`nft:rarity:${lvl}`),
                }))}
              />
            </ConnectedFormItem>
          </FormGrid>

          <FormGrid>
            <ConnectedFormItem
              fieldId="collectionId"
              fieldName="collectionId"
              label={translate('nftConfigs:form:collection')}
              isRequired
            >
              <ConnectedSmartSelect
                name="collectionId"
                placeholder={translate(
                  'nftConfigs:form:collection:placeholder',
                )}
                options={
                  collections
                    ? collections.map((collection) => ({
                        label: `${collection.name}`,
                        value: collection.id,
                      }))
                    : []
                }
              />
            </ConnectedFormItem>

            <ConnectedFormItem
              fieldId="categoryId"
              fieldName="categoryId"
              label={translate('nftConfigs:form:category')}
              isRequired
            >
              <ConnectedSmartSelect
                name="categoryId"
                placeholder={translate('nftConfigs:form:category:placeholder')}
                options={
                  categories
                    ? categories.map((category) => ({
                        label: `${category.name}`,
                        value: category.id,
                      }))
                    : []
                }
              />
            </ConnectedFormItem>
          </FormGrid>

          <ConnectedFormItem
            fieldId="totalSupply"
            fieldName="totalSupply"
            label={translate('nftConfigs:form:supply')}
            isRequired
          >
            <ConnectedInput
              name="totalSupply"
              type="number"
              placeholder={translate('nftConfigs:form:supply:placeholder')}
            />
          </ConnectedFormItem>

          <ConnectedFormItem
            fieldId="extendedDescription"
            fieldName="extendedDescription"
            label={translate('nftConfigs:form:extendedDescription')}
          >
            <ConnectedInput
              name="extendedDescription"
              type="textarea"
              rows={5}
            />
          </ConnectedFormItem>

          <FormGrid>
            <ConnectedFormItem
              fieldId="youtubeUrl"
              fieldName="youtubeUrl"
              label={translate('nftConfigs:form:youtubeUrl')}
            >
              <ConnectedInput name="youtubeUrl" />
            </ConnectedFormItem>

            <ConnectedFormItem
              fieldId="animationUrl"
              fieldName="animationUrl"
              label={translate('nftConfigs:form:animationUrl')}
            >
              <ConnectedInput name="animationUrl" />
            </ConnectedFormItem>

            <ConnectedFormItem
              fieldId="backgroundColor"
              fieldName="backgroundColor"
              label={translate('nftConfigs:form:backgroundColor')}
            >
              <ConnectedInput name="backgroundColor" />
            </ConnectedFormItem>
          </FormGrid>

          <Separator />

          <FormSectionTitle>
            <Translate id="nftConfigs:form:attributes:title" />
          </FormSectionTitle>

          <DndProvider backend={DndHTML5Backend}>
            <FormikConsumer>
              {({ values }: FormikContextType<EditNftConfigFormValues>) => (
                <FieldArray
                  name="attributes"
                  render={(helpers) => (
                    <>
                      <div>
                        {values.attributes.map((_, i) => {
                          const fieldName = `attributes.${i}`;

                          return (
                            <DragDropItem
                              key={i}
                              id={i.toString()}
                              index={i}
                              type="attr"
                              onMove={helpers.swap}
                              className={styles.attributeDad}
                            >
                              <div className={styles.attribute}>
                                <ConnectedFormItem
                                  fieldName={`${fieldName}.traitType`}
                                  isRequired
                                >
                                  <ConnectedInput
                                    name={`${fieldName}.traitType`}
                                    placeholder={translate(
                                      'nftConfigs:form:attributes:traitType:placeholder',
                                    )}
                                  />
                                </ConnectedFormItem>
                                <ConnectedFormItem
                                  fieldName={`${fieldName}.value`}
                                  isRequired
                                >
                                  <ConnectedInput
                                    name={`${fieldName}.value`}
                                    placeholder={translate(
                                      'nftConfigs:form:attributes:value:placeholder',
                                    )}
                                  />
                                </ConnectedFormItem>
                                <ConnectedFormItem
                                  fieldName={`${fieldName}.displayType`}
                                >
                                  <ConnectedSmartSelect
                                    name={`${fieldName}.displayType`}
                                    options={[
                                      {
                                        value: '',
                                        label: translate(
                                          'nft:attribute:displayType:default',
                                        ),
                                      },
                                      ...Object.values(
                                        NftAttributeDisplayType,
                                      ).map((displayType) => ({
                                        value: displayType,
                                        label: translate(
                                          `nft:attribute:displayType:${displayType}`,
                                        ),
                                      })),
                                    ]}
                                  />
                                </ConnectedFormItem>
                                <Button
                                  variant="icon"
                                  color="danger"
                                  icon={<Icon icon={TrashIcon} />}
                                  onClick={() => helpers.remove(i)}
                                />
                              </div>
                            </DragDropItem>
                          );
                        })}
                      </div>
                      <Button
                        color="secondary"
                        icon={<Icon icon={PlusIcon} size="sm" />}
                        onClick={() =>
                          helpers.push({ key: '', traitType: '', value: '' })
                        }
                      >
                        <Translate id="common:add" />
                      </Button>
                    </>
                  )}
                />
              )}
            </FormikConsumer>
          </DndProvider>

          <Separator />

          <ConnectedFormItem
            fieldId="file"
            fieldName="file"
            isRequired
            label={translate('common:file')}
          >
            <Stack dir="row">
              {nftConfig?.imageUrl && (
                <FilePreview
                  url={nftConfig.imageUrl}
                  className={styles.filePreview}
                />
              )}

              <ConnectedFileInput
                name="fileFile"
                accept="image/png, image/jpeg, video/mp4"
                label={translate('common:selectFile')}
              />
            </Stack>
          </ConnectedFormItem>

          <ConnectedFormItem
            fieldId="coverFile"
            fieldName="coverFile"
            isRequired
            label={translate('common:cover')}
          >
            <Stack dir="row">
              {nftConfig?.coverUrl && (
                <FilePreview
                  url={nftConfig.coverUrl}
                  className={styles.filePreview}
                />
              )}

              <ConnectedFileInput
                name="coverFile"
                accept="image/png, image/jpeg"
                label={translate('common:selectFile')}
              />
            </Stack>
          </ConnectedFormItem>

          <FormSubmitBar>
            <ConnectedSubmit>{translate('common:save')}</ConnectedSubmit>
          </FormSubmitBar>
        </Form>
      </ConnectedLoading>
    </Formik>
  );
};

export default EditNftConfigForm;
