import React from 'react';
import classNames from 'classnames';

import styles from './Tag.module.css';

export interface TagProps {
  tag?: 'li' | 'button' | 'span';
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const Tag: React.FC<TagProps> = ({
  tag: TagComponent = 'button',
  children,
  className,
  ...rest
}) => (
  <TagComponent
    data-testid="tag"
    className={classNames(styles.root, className)}
    {...rest}
  >
    {children}
  </TagComponent>
);

export default Tag;
