import { api } from 'api';
import { SingleResultDto, MultiResultsDto, File } from '@fanadise/common-types';
import type { PaginatedRequestQuery } from 'types/paginatedRequestQuery';
import type { PaginatedResponseData } from 'types/paginatedResponseData';

export const fetchFiles = async (
  params: PaginatedRequestQuery = { page: 1, size: 30 },
) => {
  const res = await api.get<
    MultiResultsDto<'files', File> & PaginatedResponseData
  >('/files', { params });
  return res.data;
};

export const deleteFile = async (id: string) => {
  await api.delete(`/files/${id}`);
};

export const uploadFile = async (data: FormData) => {
  const res = await api.post<SingleResultDto<'file', File>>('/files', data);
  return res.data.file;
};
