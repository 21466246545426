import React, { useMemo } from 'react';
import { Currency } from '@fanadise/common-consts';
import { formatFiatPrice } from '@fanadise/common-utils';

export interface FiatPriceProps {
  tag?: keyof JSX.IntrinsicElements;
  value: number | string;
  currency: Currency;
  className?: string;
}

const FiatPrice: React.FC<FiatPriceProps> = ({
  tag: Tag = 'span',
  value,
  currency,
  ...rest
}) => {
  const formattedPrice = useMemo(
    () => formatFiatPrice(value, currency),
    [value, currency],
  );

  return (
    <Tag data-testid="fiatPrice" {...rest}>
      {formattedPrice}
    </Tag>
  );
};

export default FiatPrice;
