import {
  api,
  PaginatedRequestQuery,
  PaginatedResponseData,
} from '@fanadise/common-data-access';
import {
  SingleResultDto,
  MultiResultsDto,
  Admin,
} from '@fanadise/common-types';

export const fetchAdmin = async () => {
  const res = await api.get<SingleResultDto<'admin', Admin>>('/admins/me');
  return res.data.admin;
};

export const updateAdmin = async (data: { username: string }) => {
  const res = await api.put<SingleResultDto<'admin', Admin>>(`/me`, data);
  return res.data.admin;
};

export const changeAdminPassword = async (data: {
  oldPassword: string;
  newPassword: string;
}) => {
  await api.post(`/admins/me/change-password`, data);
};

export const fetchAdmins = async (params?: PaginatedRequestQuery) => {
  const res = await api.get<
    MultiResultsDto<'admins', Admin> & PaginatedResponseData
  >('/admins', {
    params,
  });
  return res.data;
};
