import React from 'react';
import type { Theme } from '@fanadise/common-types';
import { DEFAULT_THEME } from 'consts/defaultTheme';
import { ThemeProperties } from 'types/themeProperty';
import { ResponsiveProperty } from 'types/responsiveProperty';

type ConvertFunction<T> = (value?: T) => string | undefined;
export interface ThemeContextValue {
  theme: Theme;
  convertSize: ConvertFunction<ThemeProperties['size']>;
  convertColor: ConvertFunction<ThemeProperties['color']>;
  convertBorderRadius: ConvertFunction<ThemeProperties['borderRadius']>;
  convertShadow: ConvertFunction<ThemeProperties['shadow']>;
  convertFontFamily: ConvertFunction<ThemeProperties['fontFamily']>;
  convertFontSize: ConvertFunction<ThemeProperties['fontSize']>;
  convertFontWeight: ConvertFunction<ThemeProperties['fontWeight']>;
  convertLineHeight: ConvertFunction<ThemeProperties['lineHeight']>;
  covertResponsiveProperty: <T extends any>(
    prop: ResponsiveProperty<T> | undefined,
    convert?: ConvertFunction<T>,
  ) => any;
}

const ThemeContext = React.createContext<ThemeContextValue>({
  theme: DEFAULT_THEME,
  convertSize: () => undefined,
  convertColor: () => undefined,
  convertBorderRadius: () => undefined,
  convertShadow: () => undefined,
  convertFontFamily: () => undefined,
  convertFontSize: () => undefined,
  convertFontWeight: () => undefined,
  convertLineHeight: () => undefined,
  covertResponsiveProperty: () => undefined,
});

export default ThemeContext;
