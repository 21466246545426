import React, { useCallback, useContext } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import {
  SnackbarContext,
  ConnectedLoading,
  ConnectedSubmit,
  ConnectedInput,
  ConnectedFormItem,
} from '@fanadise/common-ui';
import FormSectionTitle from 'components/shared/FormSectionTitle';
import FormGrid from 'components/shared/FormGrid';
import FormSubmitBar from 'components/shared/FormSubmitBar';
import * as adminsApi from 'api/adminsApi';

import AppAuthContext from 'contexts/AppAuthContext';
import { Translate, useTranslation } from '@fanadise/common-logic';

interface AdminSettingsFormValues {
  username: string;
  email: string;
}

const AdminSettingsForm: React.FC = () => {
  const { translate } = useTranslation();
  const { addSuccessAlert, addErrorAlert } = useContext(SnackbarContext)!;
  const { user, updateUser } = useContext(AppAuthContext);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(translate('validation:invalid'))
      .required(translate('validation:required')),
    username: yup.string().required(translate('validation:required')),
  });

  const initialValues: AdminSettingsFormValues = {
    email: user?.email || '',
    username: user?.username || '',
  };

  const handleSubmit = useCallback(
    async (values: AdminSettingsFormValues) => {
      try {
        updateUser(await adminsApi.updateAdmin(values));
        addSuccessAlert(translate('success:saved'));
      } catch (err: any) {
        addErrorAlert(err.message || translate('error:default'));
      }
    },
    [translate, updateUser, addSuccessAlert, addErrorAlert],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <ConnectedLoading>
        <Form noValidate>
          <FormSectionTitle>
            <Translate id="profile:title" />
          </FormSectionTitle>

          <FormGrid>
            <ConnectedFormItem
              fieldId="username"
              fieldName="username"
              label={translate('common:username')}
              isRequired
            >
              <ConnectedInput name="username" />
            </ConnectedFormItem>

            <ConnectedFormItem
              fieldId="email"
              fieldName="email"
              label={translate('common:email')}
              isRequired
            >
              <ConnectedInput name="email" type="email" isDisabled />
            </ConnectedFormItem>
          </FormGrid>

          <FormSubmitBar>
            <ConnectedSubmit>{translate('common:save')}</ConnectedSubmit>
          </FormSubmitBar>
        </Form>
      </ConnectedLoading>
    </Formik>
  );
};

export default AdminSettingsForm;
