import { profileApi } from '@fanadise/common-data-access';
import React, { useContext, useEffect, useState } from 'react';
import { BasePurchaseWithUser } from '@fanadise/common-types';
import {
  SnackbarContext,
  Loading,
  EmptyStateMessage,
  Pagination,
} from '@fanadise/common-ui';
import useTranslation from 'hooks/useTranslation';
import PurchasesTable from 'components/profile/PurchasesTable';

import styles from './PurchasesHistory.module.css';

const PURCHASES_PER_PAGE = 30;

const PurchasesHistory: React.FC = () => {
  const { translate } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const { addErrorAlert } = useContext(SnackbarContext)!;
  const [currentPage, setCurrentPage] = useState(0);
  const [pagesCount, setPagesCount] = useState<number>(1);
  const [purchases, setPurchases] =
    useState<BasePurchaseWithUser[] | null>(null);

  useEffect(() => {
    const fetchAccountPurchases = async () => {
      try {
        const params = { page: currentPage + 1, size: PURCHASES_PER_PAGE };
        const result = await profileApi.fetchProfilePurchases(params);
        setPurchases(result.purchases);
        setPagesCount(Math.ceil(result.totalCount / PURCHASES_PER_PAGE));
      } catch (err: any) {
        const message = err.message || translate('error:default');
        addErrorAlert(message);
      } finally {
        setIsLoading(false);
      }
    };

    setIsLoading(true);
    fetchAccountPurchases();
  }, [currentPage]);

  return (
    <div className={styles.root}>
      <Loading isLoading={isLoading}>
        {purchases &&
          (purchases.length ? (
            <>
              <PurchasesTable purchases={purchases} />

              <Pagination
                pageCount={pagesCount}
                onPageChange={setCurrentPage}
                className={styles.pagination}
              />
            </>
          ) : (
            <EmptyStateMessage message={translate('common:noResults')} />
          ))}
      </Loading>
    </div>
  );
};

export default PurchasesHistory;
