import React from 'react';
import { Button, Icon, Toolbar, Panel } from '@fanadise/common-ui';
import { Link } from 'react-router-dom';
import { ArrowSmLeftIcon } from '@heroicons/react/solid';
import { Translate } from '@fanadise/common-logic';
import EditUserForm from 'components/users/EditUserForm';

const UsersRouteAdd: React.FC = () => (
  <>
    <Toolbar
      title={<Translate id="users:add:title" />}
      leading={
        <Link to="../">
          <Button
            tag="span"
            variant="icon"
            icon={<Icon icon={ArrowSmLeftIcon} size="lg" />}
          />
        </Link>
      }
      mb="8"
    />
    <Panel>
      <EditUserForm />
    </Panel>
  </>
);

export default UsersRouteAdd;
