import React from 'react';
import Box, { BoxProps } from 'components/atoms/Box';

export interface BorderedBoxProps extends BoxProps {}

const BorderedBox: React.FC<BorderedBoxProps> = (props) => (
  <Box
    data-testid="borderedBox"
    bg="white"
    borderRadius="md"
    p="6"
    border="1px"
    borderColor="gray.100"
    borderStyle="solid"
    {...props}
  />
);

export default BorderedBox;
