import React from 'react';
import { Routes, Route } from 'react-router-dom';

import CategoriesRouteAdd from './CategoriesRouteAdd';
import CategoriesRouteEdit from './CategoriesRouteEdit';
import CategoriesRouteList from './CategoriesRouteList';

const CategoriesRoute: React.FC = () => (
  <Routes>
    <Route index element={<CategoriesRouteList />} />
    <Route path="add" element={<CategoriesRouteAdd />} />
    <Route path="edit/:id" element={<CategoriesRouteEdit />} />
  </Routes>
);
export default CategoriesRoute;
