import React from 'react';

const UsdCurrencyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 10C0 4.47715 4.47715 0 10 0V0C15.5228 0 20 4.47715 20 10V10C20 15.5228 15.5228 20 10 20V20C4.47715 20 0 15.5228 0 10V10Z"
      fill="#1db073"
    />
    <path
      d="M12.4244 7.07684C12.6325 7.3167 12.9957 7.34242 13.2355 7.1343C13.4754 6.92618 13.5011 6.56302 13.293 6.32316L12.4244 7.07684ZM7.576 12.9232C7.36787 12.6833 7.00471 12.6576 6.76486 12.8657C6.525 13.0738 6.49927 13.437 6.7074 13.6768L7.576 12.9232ZM10.5752 4.5C10.5752 4.18244 10.3177 3.925 10.0002 3.925C9.68262 3.925 9.42518 4.18244 9.42518 4.5H10.5752ZM9.42518 15.5C9.42518 15.8176 9.68261 16.075 10.0002 16.075C10.3177 16.075 10.5752 15.8176 10.5752 15.5L9.42518 15.5ZM10.0002 9.425C9.1866 9.425 8.47605 9.20411 7.98569 8.87721C7.49131 8.54762 7.2752 8.15544 7.2752 7.8H6.1252C6.1252 8.65958 6.6478 9.36741 7.34779 9.83406C8.05179 10.3034 8.99123 10.575 10.0002 10.575V9.425ZM7.2752 7.8C7.2752 7.44456 7.49131 7.05238 7.98569 6.72279C8.47605 6.39589 9.1866 6.175 10.0002 6.175V5.025C8.99123 5.025 8.05179 5.2966 7.34779 5.76594C6.6478 6.23259 6.1252 6.94042 6.1252 7.8H7.2752ZM10.0002 6.175C11.1028 6.175 11.9909 6.57723 12.4244 7.07684L13.293 6.32316C12.5853 5.5076 11.3405 5.025 10.0002 5.025V6.175ZM10.0002 10.575C10.8138 10.575 11.5243 10.7959 12.0147 11.1228C12.5091 11.4524 12.7252 11.8446 12.7252 12.2H13.8752C13.8752 11.3404 13.3526 10.6326 12.6526 10.1659C11.9486 9.6966 11.0091 9.425 10.0002 9.425V10.575ZM9.42518 4.5V5.6H10.5752V4.5H9.42518ZM9.42521 14.4L9.42518 15.5L10.5752 15.5L10.5752 14.4L9.42521 14.4ZM10.0002 13.825C8.89757 13.825 8.0095 13.4228 7.576 12.9232L6.7074 13.6768C7.41505 14.4924 8.65994 14.975 10.0002 14.975L10.0002 13.825ZM12.7252 12.2C12.7252 12.5554 12.5091 12.9476 12.0147 13.2772C11.5243 13.6041 10.8138 13.825 10.0002 13.825V14.975C11.0092 14.975 11.9486 14.7034 12.6526 14.2341C13.3526 13.7674 13.8752 13.0596 13.8752 12.2H12.7252ZM9.42518 5.6L9.42521 14.4L10.5752 14.4L10.5752 5.6L9.42518 5.6Z"
      fill="white"
    />
  </svg>
);

export default UsdCurrencyIcon;
