/* eslint-disable arrow-body-style */
import Snackbar from 'components/molecules/Snackbar';
import { SnackbarAlertOptions } from 'components/molecules/Snackbar/types/snackbarAlertOptions';
import { SnackbarAlertType } from 'components/molecules/Snackbar/types/snackbarAlertType';
import SnackbarContext from 'contexts/SnackbarContext';
import React, { useCallback, useMemo, useState } from 'react';

const SnackbarProvider: React.FC = ({ children }) => {
  const [alerts, setAlerts] = useState<SnackbarAlertOptions[]>([]);

  const addAlert = useCallback(
    (message: string, type?: SnackbarAlertType) => {
      const newAlert: SnackbarAlertOptions = {
        id: Date.now().toString(),
        message,
        type,
      };

      setAlerts([...alerts, newAlert]);
    },
    [alerts],
  );

  const closeAlert = useCallback(
    (alertId: string) => {
      setAlerts(alerts.filter(({ id }) => id !== alertId));
    },
    [alerts],
  );

  const addErrorAlert = useCallback(
    (message: string) => addAlert(message, 'error'),
    [addAlert],
  );

  const addSuccessAlert = useCallback(
    (message: string) => addAlert(message, 'success'),
    [addAlert],
  );

  const contextValue = useMemo(
    () => ({
      alerts,
      addAlert,
      addErrorAlert,
      addSuccessAlert,
      closeAlert,
    }),
    [alerts],
  );

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}

      <Snackbar alerts={alerts} onAlertClose={closeAlert} />
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
