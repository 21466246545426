import React from 'react';
import Box, { BoxProps } from '../Box';

export interface StackProps extends Omit<BoxProps, 'display'> {}

const Stack: React.FC<StackProps> = ({
  dir = 'column',
  gap = '4',
  ...rest
}) => <Box display="flex" dir={dir} gap={gap} {...rest} />;

export default Stack;
