import React, { ReactChild } from 'react';
import classNames from 'classnames';

import Badge, { BadgeProps } from 'components/atoms/Badge';
import styles from './PositionedBadge.module.css';

export interface PositionedBadgeProps extends BadgeProps {
  position?:
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'centerLeft'
    | 'centerRight';
  badge?: ReactChild;
  className?: string;
}

const PositionedBadge: React.FC<PositionedBadgeProps> = ({
  position = 'topRight',
  badge,
  children,
  className,
  ...rest
}) => (
  <div
    data-testid="positionedBadge"
    className={classNames(
      styles.root,
      styles[`position--${position}`],
      className,
    )}
  >
    {badge && (
      <Badge className={styles.badge} {...rest}>
        {badge}
      </Badge>
    )}

    {children}
  </div>
);

export default PositionedBadge;
