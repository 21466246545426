import React, { useCallback, useContext, useState } from 'react';
import classNames from 'classnames';
import log from 'loglevel';
import {
  Loading,
  Button,
  SnackbarContext,
  Stack,
  CryptoPrice,
  FanCurrencyIcon,
  Icon,
  MaticCurrencyIcon,
} from '@fanadise/common-ui';
import { CryptoCurrency } from '@fanadise/common-consts';
import { WalletContextValue } from 'contexts/WalletContext';
import { AuthContextValue } from 'contexts/AuthContext';
import { User } from '@fanadise/common-types';
import useTranslation from 'hooks/useTranslation';
import useCurrency from 'hooks/useCurrency';
import useCryptoPrices from 'hooks/useCryptoPrices';
import { isEnvFlagOn } from '@fanadise/common-utils';

import styles from './CryptoPayment.module.css';

export interface CryptoPaymentProps {
  authContext: React.Context<AuthContextValue<User>>;
  walletContext: React.Context<WalletContextValue>;
  price: string;
  onSuccess: (purchaseId: string) => void;
  initializePurchasePromise: (data: {
    cryptoCurrency: CryptoCurrency;
  }) => Promise<{
    id: string;
    redirectUrl: string;
    txns: {
      to: string;
      data: string;
      value: {
        type: string;
        hex: string;
      };
      gasLimit: number;
    }[];
  }>;
  notConnectedFallback: JSX.Element;
  className?: string;
}

const CryptoPayment: React.FC<CryptoPaymentProps> = ({
  authContext,
  walletContext,
  price,
  onSuccess,
  initializePurchasePromise,
  notConnectedFallback,
  className,
}) => {
  const { user } = useContext(authContext);
  const { isConnected, address, library } = useContext(walletContext);
  const { translate } = useTranslation();
  const { addErrorAlert } = useContext(SnackbarContext)!;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fiatCurrency = useCurrency();
  const cryptoPrices = useCryptoPrices();
  const isCryptoPaymentOn = isEnvFlagOn(process.env.NEXT_PUBLIC_CRYPTO_PAYMENT);

  const handleSubmit = useCallback(
    async (cryptoCurrency: CryptoCurrency) => {
      if (isSubmitting) {
        return;
      }

      setIsSubmitting(true);

      try {
        const { id, txns, redirectUrl } = await initializePurchasePromise({
          cryptoCurrency,
        });

        if (txns?.length) {
          await Promise.all(
            txns.map((txn) =>
              library.getSigner().sendTransaction({
                from: user!.wallet!.address,
                to: txn.to,
                data: txn.data,
                gasLimit: txn.gasLimit,
                value: txn.value.hex,
              }),
            ),
          );

          if (redirectUrl) {
            window.location.href = redirectUrl;
          } else {
            onSuccess(id);
          }
        } else {
          throw new Error();
        }
      } catch (err: any) {
        log.error(err);
        const message = err.message || translate('error:default');
        addErrorAlert(message);
        setIsSubmitting(false);
      }
    },
    [
      initializePurchasePromise,
      onSuccess,
      addErrorAlert,
      translate,
      isSubmitting,
    ],
  );

  return (
    <div className={classNames(styles.root, className)}>
      <Loading isLoading={isSubmitting} minH={0}>
        {isConnected && user?.wallet?.address === address ? (
          <Stack gap="3">
            <Button
              size="lg"
              variant="outlined"
              iconPlacement="fixedLeft"
              icon={<Icon icon={FanCurrencyIcon} size="lg" />}
              onClick={() => handleSubmit(CryptoCurrency.Fan)}
              isDisabled={!isCryptoPaymentOn}
              className={styles.submitButton}
            >
              {translate('common:payWithFan')}{' '}
              <CryptoPrice
                fiatValue={price}
                currency={CryptoCurrency.Fan}
                rate={cryptoPrices[CryptoCurrency.Fan][fiatCurrency]}
                isApproximate
                hasAbbreviation={false}
              />
            </Button>

            <Button
              size="lg"
              variant="outlined"
              iconPlacement="fixedLeft"
              icon={<Icon icon={MaticCurrencyIcon} size="lg" />}
              onClick={() => handleSubmit(CryptoCurrency.Matic)}
              isDisabled={!isCryptoPaymentOn}
              className={styles.submitButton}
            >
              {translate('common:payWithMatic')}{' '}
              <CryptoPrice
                fiatValue={price}
                currency={CryptoCurrency.Matic}
                rate={cryptoPrices[CryptoCurrency.Matic][fiatCurrency]}
                isApproximate
                hasAbbreviation={false}
              />
            </Button>
          </Stack>
        ) : (
          notConnectedFallback
        )}
      </Loading>
    </div>
  );
};

export default CryptoPayment;
