import { useState, useEffect } from 'react';

const getWindowWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const useCurrentWindowWidth = () => {
  // @ts-ignore
  if (!process.browser) {
    return 0;
  }

  const [width, setWidth] = useState<number>(getWindowWidth());

  useEffect(() => {
    let timeoutId: number;
    const resizeListener = () => {
      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => setWidth(getWindowWidth()), 150);
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return width;
};

export default useCurrentWindowWidth;
