import React from 'react';
import type { AbstractConnector } from '@web3-react/abstract-connector';

export type WalletContextValue = {
  isTried: boolean;
  isEthereumSupported?: boolean;
  isConnected: boolean;
  isSupportedChain: boolean;
  isMatchingUserWalletAddress: boolean;
  isReady: boolean;
  address?: string | null;
  chainId?: number;
  library: any;
  connector?: AbstractConnector;
  connect: () => void;
  disconnect: () => void;
  error?: Error;
  setError: (error: Error) => void;
};

export const createWalletContext = () =>
  React.createContext<WalletContextValue>(
    // @ts-ignore
    null,
  );
