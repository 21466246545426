import React from 'react';
import type { Locale } from '@fanadise/common-consts';

export type LocaleContextValue = Locale;

const LocaleContext = React.createContext<LocaleContextValue>(
  // @ts-ignore
  null,
);

export default LocaleContext;
