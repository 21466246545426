import React from 'react';
import CryptoPricesContext, {
  CryptoPricesContextValue,
} from 'contexts/CryptoPricesContext';

export interface CryptoPricesProviderProps {
  prices: CryptoPricesContextValue;
}

const CryptoPricesProvider: React.FC<CryptoPricesProviderProps> = ({
  prices,
  children,
}) => (
  <CryptoPricesContext.Provider value={prices}>
    {children}
  </CryptoPricesContext.Provider>
);

export default CryptoPricesProvider;
