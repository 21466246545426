import React, { Context, useContext, useMemo } from 'react';
import WalletBalance from 'components/wallet/WalletBalance';
import useWalletAssets from 'hooks/useWalletAssets';
import {
  ClipboardCopyIcon,
  ExternalLinkIcon,
  UploadIcon,
} from '@heroicons/react/outline';
import {
  Button,
  Loading,
  BnbCurrencyIcon,
  FanCurrencyIcon,
  Icon,
  Stack,
  Box,
  Text,
  SnackbarContext,
  TinyHeading,
  MaticCurrencyIcon,
} from '@fanadise/common-ui';
import log from 'loglevel';
import copy from 'copy-to-clipboard';
import useTranslation from 'hooks/useTranslation';
import useCurrency from 'hooks/useCurrency';
import useCryptoPrices from 'hooks/useCryptoPrices';
import useEvmNetwork from 'hooks/useEvmNetwork';
import Translate from 'components/shared/Translate';
import { WalletContextValue } from 'contexts/WalletContext';
import { CryptoCurrency } from '@fanadise/common-consts';
import WalletDetailsBalance from './WalletDetailsBalance';

import styles from './WalletDetails.module.css';

const CRYPTO_CURRENCY_ICONS = {
  bnb: BnbCurrencyIcon,
  fan: FanCurrencyIcon,
  matic: MaticCurrencyIcon,
};

export interface WalletDetailsProps {
  className?: string;
  walletContext: Context<WalletContextValue>;
}

const WalletDetails: React.FC<WalletDetailsProps> = ({
  className,
  walletContext,
}) => {
  const { translate } = useTranslation();
  const { addSuccessAlert } = useContext(SnackbarContext)!;
  const { address, isReady, connector } = useContext(walletContext);
  const [assets, hasAssets] = useWalletAssets(walletContext);
  const fiatCurrency = useCurrency();
  const cryptoPrices = useCryptoPrices();
  const { token, network } = useEvmNetwork();

  const externalUrl = useMemo(
    () => (address ? `${network.explorerUrls[0]}/address/${address}` : ''),
    [address, network],
  );

  const handleCopyAddressClick = () => {
    copy(address!);
    addSuccessAlert(translate('common:copied'));
  };

  const handleWatchFanToken = async () => {
    const provider = await connector!.getProvider();

    try {
      await provider.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: token.address,
            symbol: token.symbol.toUpperCase(),
            decimals: token.decimals,
            image: token.image,
          },
        },
      });
    } catch (err) {
      log.error(err);
    }
  };

  return isReady ? (
    <Stack gap="4" className={className}>
      <Box
        borderRadius="sm"
        p="4"
        border="1px"
        borderStyle="solid"
        borderColor="gray.200"
      >
        <Stack gap="2">
          <TinyHeading>
            <Translate id="walletDetails:address" />
          </TinyHeading>

          <Box
            display="flex"
            align="center"
            justify="space-between"
            overflow="hidden"
          >
            <Text
              fontSize="md"
              color="gray.700"
              isTruncated
              className={styles.address}
            >
              {address}
            </Text>

            <Button
              variant="icon"
              icon={<Icon icon={ClipboardCopyIcon} />}
              onClick={handleCopyAddressClick}
              className={styles.copyButton}
            />
          </Box>
        </Stack>
      </Box>

      <Loading isLoading={!hasAssets} minH={16}>
        {hasAssets && (
          <Stack gap="4">
            <WalletDetailsBalance assets={assets} />

            <Box
              borderRadius="sm"
              p="4"
              border="1px"
              borderStyle="solid"
              borderColor="gray.200"
            >
              <Stack gap="3">
                <TinyHeading>
                  <Translate id="common:tokens" />
                </TinyHeading>

                <Stack>
                  {assets.map(({ balance, symbol }) => {
                    const CurrencyIcon =
                      CRYPTO_CURRENCY_ICONS[
                        symbol.toLowerCase() as keyof typeof CRYPTO_CURRENCY_ICONS
                      ];

                    return (
                      <Box
                        key={symbol}
                        display="flex"
                        align="center"
                        justify="space-between"
                      >
                        <Stack dir="row" gap="2">
                          {CurrencyIcon && (
                            <CurrencyIcon className={styles.currencyIcon} />
                          )}

                          <Box>
                            <Text
                              fontWeight="semibold"
                              fontSize="sm"
                              color="gray.800"
                            >
                              {symbol.toUpperCase()}
                            </Text>
                            <Text fontWeight="normal" fontSize="sm">
                              {`1 = $${
                                cryptoPrices[
                                  symbol.toLowerCase() as CryptoCurrency
                                ][fiatCurrency]
                              }`}
                            </Text>
                          </Box>
                        </Stack>

                        <Text
                          fontWeight="semibold"
                          fontSize="2xl"
                          color="gray.800"
                        >
                          <WalletBalance balance={balance} />
                        </Text>
                      </Box>
                    );
                  })}
                </Stack>
              </Stack>
            </Box>
          </Stack>
        )}
      </Loading>

      <Stack gap="3">
        <a target="_blank" rel="noreferrer" href={externalUrl}>
          <Button
            size="lg"
            w="full"
            variant="outlined"
            color="primary"
            icon={<Icon icon={ExternalLinkIcon} />}
          >
            <Translate id="walletDetails:explorerLinkLabel" />
          </Button>
        </a>

        <Button
          size="lg"
          w="full"
          variant="filled"
          color="secondary"
          icon={<Icon icon={UploadIcon} />}
          onClick={handleWatchFanToken}
          className={styles.importTokenButton}
        >
          <Translate id="walletDetails:importFanToken" />
        </Button>
      </Stack>
    </Stack>
  ) : null;
};

export default WalletDetails;
