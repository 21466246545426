import React from 'react';
import { CopyableText, VCard } from '@fanadise/common-ui';
import { Account } from '@fanadise/common-types';
import ResourcesList from 'components/shared/ResourcesList';
import { useTranslation } from '@fanadise/common-logic';
import { accountsApi } from '@fanadise/common-data-access';

const AccountsList: React.FC = () => {
  const { translate } = useTranslation();

  return (
    <ResourcesList<Account, 'accounts'>
      dataKey="accounts"
      fetchData={accountsApi.fetchAccounts}
      sortedProps={['createdAt']}
      columns={[
        {
          prop: 'id',
          label: translate('common:id'),
          renderCell: ({ id }) => (
            <CopyableText
              maxW="40"
              text={id}
              tooltipText={translate('common:copy')}
              copiedMessage={translate('common:copied')}
            />
          ),
        },
        {
          prop: 'ownerId',
          label: translate('common:owner'),
          renderCell: ({ owner }) => <VCard user={owner} />,
        },

        {
          prop: 'domain',
          label: translate('common:domain'),
          renderCell: ({ url, domain }) =>
            domain ? (
              <a href={url} target="_blank" rel="noreferrer">
                {domain}
              </a>
            ) : (
              '-'
            ),
        },
        {
          prop: 'createdAt',
          label: translate('common:createdAt'),
          renderCell: ({ createdAt }) => (
            <time dateTime={new Date(createdAt).toISOString()}>
              {new Date(createdAt).toLocaleDateString()}
            </time>
          ),
        },
      ]}
    />
  );
};

export default AccountsList;
