import React from 'react';

const MetaMaskIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m21.148 2.674-7.496 5.567 1.386-3.284 6.11-2.283Z"
      fill="#E2761B"
      stroke="#E2761B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m3.845 2.674 7.435 5.62L9.96 4.957 3.845 2.674Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m18.451 15.578-1.996 3.059 4.271 1.175 1.228-4.166-3.503-.068Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m3.054 15.646 1.22 4.166 4.271-1.175-1.996-3.059-3.495.068Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m8.304 10.41-1.19 1.8 4.241.19-.15-4.558-2.9 2.569Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.688 10.41 13.75 7.79l-.098 4.61 4.234-.19-1.198-1.8Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m8.545 18.637 2.546-1.243-2.2-1.718-.346 2.96Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m13.901 17.394 2.554 1.243-.354-2.96-2.2 1.717Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.454 18.637 13.9 17.394l.204 1.664-.023.7 2.373-1.121Z"
      fill="#D7C1B3"
      stroke="#D7C1B3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m8.545 18.637 2.373 1.122-.015-.7.188-1.665-2.546 1.243Z"
      fill="#D7C1B3"
      stroke="#D7C1B3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m10.955 14.576-2.124-.625 1.5-.686.624 1.311Z"
      fill="#233447"
      stroke="#233447"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m14.037 14.576.625-1.31 1.507.685-2.132.625Z"
      fill="#233447"
      stroke="#233447"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m8.545 18.637.362-3.059-2.358.068 1.996 2.99Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m16.093 15.578.361 3.059 1.997-2.991-2.358-.068Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m17.886 12.21-4.234.19.392 2.176.625-1.31 1.507.685 1.71-1.74Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m8.832 13.951 1.506-.686.618 1.311.4-2.177-4.242-.188 1.718 1.74Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m7.114 12.21 1.778 3.466-.06-1.725-1.718-1.74Z"
      fill="#E4751F"
      stroke="#E4751F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m16.177 13.951-.075 1.725 1.785-3.465-1.71 1.74Z"
      fill="#E4751F"
      stroke="#E4751F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m11.355 12.4-.399 2.176.497 2.569.113-3.382-.21-1.364Z"
      fill="#E4751F"
      stroke="#E4751F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m13.653 12.4-.204 1.355.09 3.39.505-2.569-.391-2.177Z"
      fill="#E4751F"
      stroke="#E4751F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m14.044 14.576-.505 2.569.362.249 2.2-1.718.075-1.725-2.132.625Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m8.831 13.951.06 1.725 2.2 1.718.362-.249-.498-2.569-2.124-.625Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m14.082 19.759.022-.7-.188-.166h-2.84l-.173.165.015.7-2.373-1.121.829.677 1.68 1.168h2.884l1.688-1.168.829-.677-2.373 1.122Z"
      fill="#C0AD9E"
      stroke="#C0AD9E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m13.901 17.394-.361-.249h-2.087l-.361.249-.189 1.664.174-.165h2.84l.188.165-.204-1.664Z"
      fill="#161616"
      stroke="#161616"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m21.465 8.603.64-3.074-.957-2.855-7.247 5.379 2.788 2.358 3.94 1.152.873-1.017-.377-.271.603-.55-.467-.362.603-.459-.4-.301Z"
      fill="#763D16"
      stroke="#763D16"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m2.896 5.53.64 3.073-.407.301.603.46-.46.361.603.55-.377.271.866 1.017 3.94-1.152 2.787-2.358-7.246-5.379-.95 2.855Z"
      fill="#763D16"
      stroke="#763D16"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m20.628 11.563-3.94-1.152 1.198 1.8-1.785 3.465 2.35-.03h3.503l-1.326-4.083Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m8.304 10.41-3.94 1.153-1.31 4.083h3.495l2.343.03-1.778-3.465 1.19-1.8Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m13.652 12.4.249-4.347 1.145-3.096H9.96l1.13 3.096.264 4.346.09 1.371.008 3.375h2.086l.015-3.375.098-1.37Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MetaMaskIcon;
