import React, { useCallback, useContext, useState } from 'react';
import {
  CopyInput,
  Icon,
  MenuItem,
  SnackbarContext,
} from '@fanadise/common-ui';
import { CommonFile } from '@fanadise/common-types';
import { commonFilesApi } from '@fanadise/common-data-access';
import { TrashIcon } from '@heroicons/react/outline';
import { useTranslation } from '@fanadise/common-logic';
import UploadFileForm from 'components/files/UploadFileForm';
import ResourcesList from 'components/shared/ResourcesList';

const FilesList: React.FC = () => {
  const { translate } = useTranslation();
  const { addErrorAlert } = useContext(SnackbarContext)!;
  const [fetchDate, setFetchDate] = useState<Date>();

  const handleDelete = useCallback(async (file: CommonFile) => {
    try {
      await commonFilesApi.deleteCommonFile(file.id);
      setFetchDate(new Date());
    } catch (err: any) {
      addErrorAlert(err.message || translate('error:default'));
    }
  }, []);

  return (
    <>
      <UploadFileForm onSuccess={() => setFetchDate(new Date())} />

      <ResourcesList<CommonFile, 'files'>
        dataKey="files"
        fetchData={commonFilesApi.fetchCommonFiles}
        fetchDate={fetchDate}
        hasSearch={false}
        sortedProps={['createdAt']}
        columns={[
          {
            prop: 'name',
            label: translate('files:list:name'),
            renderCell: ({ name: fileName }) => fileName,
          },
          {
            prop: 'fileUrl',
            label: translate('files:list:url'),
            renderCell: ({ fileUrl }) => <CopyInput value={fileUrl} />,
          },
          {
            prop: 'createdAt',
            label: translate('purchases:createdAt'),
            renderCell: ({ createdAt }) => (
              <time dateTime={new Date(createdAt).toISOString()}>
                {new Date(createdAt).toLocaleDateString()}
              </time>
            ),
          },
        ]}
        renderMenu={(item) => (
          <MenuItem
            tag="span"
            icon={<Icon icon={TrashIcon} size="sm" />}
            onClick={() => handleDelete(item)}
          >
            {translate('common:delete')}
          </MenuItem>
        )}
      />
    </>
  );
};

export default FilesList;
