import React from 'react';
import Box, { BoxProps } from '../Box';

export interface CenterProps
  extends Omit<BoxProps, 'display' | 'align' | 'justify'> {}

const Center: React.FC<CenterProps> = (props) => (
  <Box display="flex" align="center" justify="center" {...props} />
);

export default Center;
