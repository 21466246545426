import React from 'react';
import { Button, Icon, Panel, Toolbar } from '@fanadise/common-ui';
import { Link } from 'react-router-dom';
import { ArrowSmLeftIcon } from '@heroicons/react/solid';
import EditNftConfigForm from 'components/nftConfigs/EditNftConfigForm';
import { Translate } from '@fanadise/common-logic';

const NftConfigsRouteAdd: React.FC = () => (
  <>
    <Toolbar
      title={<Translate id="nftConfigs:add:title" />}
      leading={
        <Link to="/account/configs">
          <Button
            tag="span"
            variant="icon"
            icon={<Icon icon={ArrowSmLeftIcon} size="lg" />}
          />
        </Link>
      }
      mb="8"
    />
    <Panel>
      <EditNftConfigForm />
    </Panel>
  </>
);

export default NftConfigsRouteAdd;
