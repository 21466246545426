import Button from 'components/atoms/Button';
import Dialog from 'components/templates/Dialog';
import React from 'react';

import styles from './ConfirmDialog.module.css';

export interface ConfirmDialogProps {
  title: string;
  text?: string;
  onCancel: () => void;
  cancelLabel: string;
  onConfirm: () => void;
  confirmLabel: string;
  isLoading?: boolean;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  title,
  text,
  onCancel,
  cancelLabel,
  onConfirm,
  confirmLabel,
  isLoading,
}) => (
  <Dialog
    title={title}
    size="sm"
    isOpen
    onRequestClose={onCancel}
    ariaHideApp={false}
  >
    {text && <p className={styles.message}>{text}</p>}

    <div className={styles.actions}>
      <Button type="button" variant="text" onClick={() => onCancel()}>
        {cancelLabel}
      </Button>

      <Button type="button" onClick={() => onConfirm()} isLoading={isLoading}>
        {confirmLabel}
      </Button>
    </div>
  </Dialog>
);

export default ConfirmDialog;
