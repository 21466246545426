import React from 'react';
import { CryptoCurrency, Currency } from '@fanadise/common-consts';

export type CryptoPricesContextValue = {
  [cryptoCurrency in CryptoCurrency]: {
    [fiatCurrency in Currency]: number;
  };
};

const CryptoPricesContext = React.createContext<CryptoPricesContextValue>(
  // @ts-ignore
  null,
);

export default CryptoPricesContext;
