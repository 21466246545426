import React from 'react';
import { CopyableText, Panel, Text } from '@fanadise/common-ui';
import { Collection } from '@fanadise/common-types';
import ResourcesList from 'components/shared/ResourcesList';
import { useTranslation } from '@fanadise/common-logic';
import { collectionsApi } from '@fanadise/common-data-access';

const CollectionsList: React.FC = () => {
  const { translate } = useTranslation();

  return (
    <Panel>
      <ResourcesList<Collection, 'collections'>
        dataKey="collections"
        fetchData={collectionsApi.fetchCollections}
        sortedProps={['createdAt']}
        columns={[
          {
            prop: 'id',
            label: translate('common:id'),
            renderCell: ({ id }) => (
              <CopyableText
                maxW="30"
                text={id}
                tooltipText={translate('common:copy')}
                copiedMessage={translate('common:copied')}
              />
            ),
          },
          {
            prop: 'name',
            label: translate('collections:list:name'),
            renderCell: ({ name }) => (
              <Text fontWeight="semibold" color="gray.800">
                {name}
              </Text>
            ),
          },
          {
            prop: 'address',
            label: translate('collections:list:address'),
            renderCell: ({ contractAddress }) =>
              contractAddress ? (
                <CopyableText
                  text={contractAddress}
                  tooltipText={translate('common:copy')}
                  copiedMessage={translate('common:copied')}
                >
                  {contractAddress}
                </CopyableText>
              ) : (
                '--'
              ),
          },
          {
            prop: 'createdAt',
            label: translate('common:createdAt'),
            renderCell: ({ createdAt }) => (
              <time dateTime={new Date(createdAt).toISOString()}>
                {new Date(createdAt).toLocaleDateString()}
              </time>
            ),
          },
        ]}
      />
    </Panel>
  );
};

export default CollectionsList;
