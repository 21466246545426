import React, { forwardRef } from 'react';
import classNames from 'classnames';

import { IconProps } from 'components/atoms/Icon';
import styles from './MenuItem.module.css';

export interface MenuItemProps {
  tag?: 'button' | 'a' | 'span';
  icon?: React.ReactElement<IconProps>;
  isDisabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  className?: string;
}

const MenuItem: React.FC<MenuItemProps> = forwardRef<
  HTMLElement,
  MenuItemProps
>(
  (
    {
      icon,
      tag: Tag = 'button',
      onClick,
      isDisabled = false,
      className,
      children,
    },
    ref,
  ) => (
    <Tag
      // @ts-ignore
      ref={ref}
      className={classNames(
        styles.root,
        {
          [styles.isDisabled]: isDisabled,
        },
        className,
      )}
      onClick={onClick}
    >
      {icon &&
        React.cloneElement(icon, {
          className: classNames(styles.icon, icon.props.className),
        })}
      {children}
    </Tag>
  ),
);

export default MenuItem;
