import React, { forwardRef } from 'react';
import classNames from 'classnames';
import Box, { BoxProps } from 'components/atoms/Box';

import styles from './Ratio.module.css';

export interface RatioProps extends BoxProps {}

const Ratio = forwardRef<HTMLElement, BoxProps>(
  ({ ratio = 1, className, ...rest }, ref) => (
    <Box
      className={classNames(styles.root, className)}
      ratio={ratio}
      ref={ref}
      {...rest}
    />
  ),
);

export default Ratio;
