import React, { MouseEventHandler, useCallback, useMemo } from 'react';
import Select, {
  StylesConfig,
  GroupBase,
  Props as SelectProps,
  MultiValueProps,
  components,
  Props,
  MultiValueGenericProps,
  MultiValue,
} from 'react-select';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortEndHandler,
  SortableHandle,
} from 'react-sortable-hoc';

const SortableMultiValue = SortableElement((props: MultiValueProps) => {
  const onMouseDown: MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = SortableHandle(
  (props: MultiValueGenericProps) => <components.MultiValueLabel {...props} />,
);

const SortableSelect = SortableContainer(Select) as React.ComponentClass<
  Props<any, true> & SortableContainerProps
>;

export const createSmartSelectStyles = <
  Option extends unknown,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  isInvalid: boolean = false,
): StylesConfig<Option, IsMulti, Group> => ({
  option: (provided, state) => ({
    ...provided,
    color: 'var(--theme-color-gray-800)',
    backgroundColor: state.isFocused ? 'var(--theme-color-gray-100)' : 'white',
    ':hover': {
      backgroundColor: 'var(--theme-color-gray-100)',
    },
  }),
  menu: () => ({
    color: 'var(--theme-color-gray-800)',
    boxShadow: 'var(--theme-shadow-lg)',
    borderRadius: 'var(--theme-border-radius-sm)',
    backgroundColor: 'white',
    overflow: 'hidden',
    marginTop: 'var(--theme-size-2)',
    position: 'absolute',
    width: '100%',
    zIndex: '10',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--theme-color-gray-400)',
  }),
  input: (provided, state) => ({
    ...provided,
    color: state.isDisabled
      ? 'var(--theme-color-gray)'
      : 'var(--theme-color-gray-800)',
  }),
  dropdownIndicator: (provided, state) => ({
    color: state.isDisabled
      ? 'var(--theme-color-gray-200)'
      : 'var(--theme-color-gray)',
    width: 'var(--theme-size-10)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ':hover': {
      color: state.isDisabled
        ? 'var(--theme-color-gray-200)'
        : 'var(--theme-color-gray-800)',
    },
  }),
  clearIndicator: () => ({
    color: 'var(--theme-color-gray)',
    width: 'var(--theme-size-10)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ':hover': {
      color: 'var(--theme-color-gray-800)',
    },
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    color: 'var(--theme-color-gray)',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: 'var(--theme-color-gray-200)',
  }),
  container: (provided) => ({
    ...provided,
    display: 'block',
    width: '100%',
  }),
  control: (provided, state) => {
    let borderColor = 'var(--theme-color-gray-200)';
    let borderStyle = 'solid';
    let backgroundColor = 'white';

    if (state.isFocused) {
      borderColor = 'var(--theme-color-primary)';
    }
    if (isInvalid) {
      borderColor = 'var(--theme-color-error)';
    }
    if (state.isDisabled) {
      borderStyle = 'dashed';
      backgroundColor = 'var(--theme-color-gray-50)';
    }

    return {
      display: 'flex',
      width: '100%',
      minHeight: 'var(--theme-size-10)',
      borderRadius: 'var(--theme-border-radius-sm)',
      borderWidth: '1px',
      borderColor,
      borderStyle,
      backgroundColor,
    };
  },
  valueContainer: (provided) => ({
    ...provided,
    padding: 'var(--theme-size-1) var(--theme-size-2)',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled
      ? 'var(--theme-color-gray)'
      : 'var(--theme-color-gray-800)',
  }),
  multiValue: (provided) => ({
    ...provided,
    color: 'var(--theme-color-gray-800)',
    borderRadius: 'var(--theme-border-radius-full)',
    backgroundColor: 'var(--theme-color-gray-200)',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: 'var(--theme-color-gray)',
    borderRadius: 'var(--theme-border-radius-full)',
    backgroundColor: 'var(--theme-color-gray-200)',
    ':hover': {
      backgroundColor: 'var(--theme-color-gray-200)',
      color: 'var(--theme-color-gray-800)',
    },
  }),
});

export interface SmartSelectProps<
  Option extends unknown,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> extends SelectProps<Option, IsMulti, Group> {
  isInvalid: boolean;
  isSortable?: boolean;
}

const SmartSelect = <
  Option extends unknown,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  isInvalid,
  isSortable,
  value,
  onChange,
  ...rest
}: SmartSelectProps<Option, IsMulti, Group>) => {
  const styles = useMemo<StylesConfig<Option, IsMulti, Group>>(
    () => createSmartSelectStyles<Option, IsMulti, Group>(isInvalid),
    [isInvalid],
  );

  const onSortEnd = useCallback<SortEndHandler>(
    ({ oldIndex, newIndex }) => {
      const newValue = (value as MultiValue<Option>).slice();
      newValue.splice(newIndex, 0, newValue.splice(oldIndex, 1)[0]);
      // @ts-ignore
      onChange(newValue);
    },
    [value, onChange],
  );

  return isSortable ? (
    <SortableSelect
      useDragHandle
      axis="xy"
      onSortEnd={onSortEnd}
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      components={{
        // @ts-ignore
        MultiValue: SortableMultiValue,
        MultiValueLabel: SortableMultiValueLabel,
      }}
      // @ts-ignore
      styles={styles}
      value={value}
      // @ts-ignore
      onChange={onChange}
      {...rest}
    />
  ) : (
    <Select styles={styles} value={value} onChange={onChange} {...rest} />
  );
};

export default SmartSelect;
