import React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import {
  Anchor,
  Box,
  Button,
  ConnectedFormItem,
  ConnectedInput,
  ConnectedLoading,
  ConnectedMessages,
  Icon,
  IntroLink,
} from '@fanadise/common-ui';
import { User } from '@fanadise/common-types';
import useTranslation from 'hooks/useTranslation';
import { authApi } from '@fanadise/common-data-access';
import Link from 'components/shared/Link';
import { MailIcon, LockClosedIcon } from '@heroicons/react/outline';

import styles from './SignInForm.module.css';

export interface SignInFormValues {
  email: string;
  password: string;
}

export interface SignInFormProps {
  defaultEmail?: string;
  onSuccess: (user: User) => void;
  forgotPasswordHref: string;
  signUpHref: string;
}

const SignInForm: React.FC<SignInFormProps> = ({
  defaultEmail,
  onSuccess,
  forgotPasswordHref,
  signUpHref,
}) => {
  const { translate } = useTranslation();

  const initialValues = {
    email: defaultEmail || '',
    password: '',
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(translate('validation:required'))
      .email(translate('validation:invalid')),
    password: yup.string().required(translate('validation:required')),
  });

  return (
    <Formik<SignInFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        try {
          onSuccess(await authApi.signInWithEmailAndPassword(values));
        } catch (err: any) {
          const message = err.message || translate('error:default');

          setStatus({ errorMessages: [message] });
          setSubmitting(false);
        }
      }}
    >
      <ConnectedLoading>
        <Form noValidate>
          <ConnectedMessages className={styles.formMessages} />

          <ConnectedFormItem
            fieldId="email"
            fieldName="email"
            label={translate('common:email')}
            isRequired
          >
            <ConnectedInput
              size="lg"
              name="email"
              type="email"
              icon={<Icon icon={MailIcon} />}
              placeholder={translate('auth:signIn:emailPlaceholder')}
              isDisabled={!!defaultEmail}
            />
          </ConnectedFormItem>

          <ConnectedFormItem
            fieldId="password"
            fieldName="password"
            label={translate('common:password')}
            isRequired
          >
            <ConnectedInput
              size="lg"
              name="password"
              type="password"
              icon={<Icon icon={LockClosedIcon} />}
              placeholder={translate('auth:signIn:passwordPlaceholder')}
            />
          </ConnectedFormItem>

          <Link href={forgotPasswordHref} passHref>
            <Anchor className={styles.forgotPassword}>
              {translate('auth:signIn:forgotPassword')}
            </Anchor>
          </Link>

          <Box pt="2">
            <Button type="submit" size="lg" w="full">
              {translate('auth:signIn:submit')}
            </Button>
          </Box>

          {!defaultEmail && (
            <Link href={signUpHref} passHref>
              <IntroLink
                introText={translate('auth:signIn:noAccount:prefix')}
                linkText={translate('auth:signIn:noAccount:suffix')}
              />
            </Link>
          )}
        </Form>
      </ConnectedLoading>
    </Formik>
  );
};

export default SignInForm;
