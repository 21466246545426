import React from 'react';
import { Routes, Route } from 'react-router-dom';

import NftConfigsRouteAdd from './NftConfigsRouteAdd';
import NftConfigsRouteEdit from './NftConfigsRouteEdit';
import NfConfigRouteList from './NftConfigsRouteList';

const NftConfigsRoute: React.FC = () => (
  <Routes>
    <Route index element={<NfConfigRouteList />} />
    <Route path="add" element={<NftConfigsRouteAdd />} />
    <Route path="edit/:id" element={<NftConfigsRouteEdit />} />
  </Routes>
);

export default NftConfigsRoute;
