import ActiveRoute from 'components/shared/ActiveRoute';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

type Props = LinkProps & {
  to: string;
  children: (isActive: boolean) => React.ReactNode;
};

const ActiveLink: React.FC<Props> = ({ to, children, ...rest }) => (
  <Link to={to} {...rest}>
    <ActiveRoute path={to}>{children}</ActiveRoute>
  </Link>
);

export default ActiveLink;
