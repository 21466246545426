import { Formik } from 'formik';
import React, { useCallback, useContext } from 'react';
import { BaseNft } from '@fanadise/common-types';
import * as yup from 'yup';
import {
  ConnectedFormItem,
  SnackbarContext,
  ConnectedFormDialog,
} from '@fanadise/common-ui';
import { useTranslation } from '@fanadise/common-logic';
import { nftsApi, usersApi } from '@fanadise/common-data-access';
import ConnectedAsyncSmartSelect from 'components/shared/ConnectedAsyncSmartSelect';

interface AssignUserFormValues {
  userId: string;
}

interface AssignUserModalProps {
  nft: BaseNft;
  onClose: () => void;
  onSuccess: (nft: BaseNft) => void;
}

const AssignUserModal: React.FC<AssignUserModalProps> = ({
  nft,
  onClose,
  onSuccess,
}) => {
  const { addSuccessAlert, addErrorAlert } = useContext(SnackbarContext)!;
  const { translate } = useTranslation();

  const initialValues: AssignUserFormValues = {
    userId: '',
  };

  const validationSchema = yup.object().shape({
    userId: yup.string().required(translate('validation:required')),
  });

  const handleSubmit = useCallback(
    async (values: AssignUserFormValues) => {
      try {
        const savedNft = await nftsApi.assignNftToUser(nft.id, values);
        addSuccessAlert(translate('success:saved'));
        onSuccess(savedNft);
      } catch (err: any) {
        addErrorAlert(err.message || translate('error:default'));
      }
    },
    [nft],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <ConnectedFormDialog
        isOpen
        submitLabel={translate('common:save')}
        cancelLabel={translate('common:cancel')}
        onRequestClose={onClose}
        title={translate('nfts:assignUser:title')}
      >
        <ConnectedFormItem
          fieldId="userId"
          fieldName="userId"
          label={translate('common:user')}
          isRequired
        >
          <ConnectedAsyncSmartSelect
            name="userId"
            placeholder={translate('nfts:assignUser:user:placeholder')}
            cacheOptions
            loadOptions={async (value, callback) => {
              const result = await usersApi.fetchUsers({
                search: value,
              });

              callback(
                result.users
                  ? result.users.map((user) => ({
                      value: user.id,
                      label: `@${user.username}`,
                    }))
                  : [],
              );
            }}
          />
        </ConnectedFormItem>
      </ConnectedFormDialog>
    </Formik>
  );
};

export default AssignUserModal;
