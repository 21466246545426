import React from 'react';
import { Button, Icon, Panel, Toolbar } from '@fanadise/common-ui';
import { Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/solid';
import AccountsList from 'components/accounts/AccountsList';
import { Translate } from '@fanadise/common-logic';

const AccountsRouteList: React.FC = () => (
  <>
    <Toolbar
      title={<Translate id="accounts:list:title" />}
      actions={
        <Link to="add">
          <Button tag="span" icon={<Icon icon={PlusIcon} />}>
            <Translate id="accounts:add:title" />
          </Button>
        </Link>
      }
      mb="8"
    />
    <Panel>
      <AccountsList />
    </Panel>
  </>
);

export default AccountsRouteList;
