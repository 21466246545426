import React, { useCallback, useContext, useState } from 'react';
import {
  Panel,
  FiatPrice,
  CopyableText,
  Text,
  Badge,
  SnackbarContext,
  Tooltip,
  Icon,
  MenuItem,
  ConfirmDialogContext,
  Box,
} from '@fanadise/common-ui';
import { BaseNftListing } from '@fanadise/common-types';
import ResourcesList from 'components/shared/ResourcesList';
import { useTranslation } from '@fanadise/common-logic';
import { nftListingsApi } from '@fanadise/common-data-access';
import { capitalize, shortenAddress } from '@fanadise/common-utils';
import copy from 'copy-to-clipboard';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { NftListingStatus } from '@fanadise/common-consts';
import EditNftListingModal from 'components/nftListings/EditNftListingModal';

const NftListingsList: React.FC = () => {
  const { translate } = useTranslation();
  const [selectedNftListing, setSelectedNftListing] =
    useState<BaseNftListing | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { addSuccessAlert, addErrorAlert } = useContext(SnackbarContext)!;
  const { activateConfirmDialog } = useContext(ConfirmDialogContext);

  const handleCopyToClipboard = useCallback((value: string) => {
    copy(value);
    addSuccessAlert(translate('common:copied'));
  }, []);

  const handleEdit = useCallback((nftListing: BaseNftListing) => {
    setSelectedNftListing(nftListing);
    setIsEditModalOpen(true);
  }, []);

  const handleDeleteListing = useCallback(
    async (nftListing: BaseNftListing) => {
      const isConfirmed = await activateConfirmDialog();
      if (!isConfirmed) {
        return;
      }

      try {
        await nftListingsApi.deleteNftListing(nftListing.id);
        addSuccessAlert(translate('success:removed'));
        window.location.reload();
      } catch (err: any) {
        addErrorAlert(err.message || translate('error:default'));
      }
    },
    [],
  );

  return (
    <>
      <Panel>
        <ResourcesList<BaseNftListing, 'nftListings'>
          dataKey="nftListings"
          fetchData={nftListingsApi.fetchNftListings}
          sortedProps={['createdAt']}
          columns={[
            {
              prop: 'id',
              label: translate('nftListings:list:id'),
              renderCell: ({ id }) => (
                <CopyableText
                  maxW="16"
                  text={id}
                  tooltipText={translate('common:copy')}
                  copiedMessage={translate('common:copied')}
                />
              ),
            },
            {
              prop: 'config.name',
              label: translate('nftListings:list:name'),
              renderCell: ({ nft }) => (
                <Box maxW="60">
                  <Text color="gray.800" fontWeight="semibold" isTruncated>
                    {nft.config.name}
                  </Text>
                </Box>
              ),
            },
            {
              prop: 'config.accountId',
              label: translate('nftListings:list:accountId'),
              renderCell: ({ nft }) => (
                <CopyableText
                  maxW="16"
                  text={nft.config.accountId}
                  tooltipText={translate('common:copy')}
                  copiedMessage={translate('common:copied')}
                />
              ),
            },
            {
              prop: 'configId',
              label: translate('nftListings:list:configId'),
              renderCell: ({ nft }) => (
                <CopyableText
                  maxW="16"
                  text={nft.configId}
                  tooltipText={translate('common:copy')}
                  copiedMessage={translate('common:copied')}
                />
              ),
            },
            {
              prop: 'tokenId',
              label: translate('nftListings:list:tokenId'),
              renderCell: ({ nft }) => (
                <CopyableText
                  maxW="16"
                  text={nft.tokenId.toString()}
                  tooltipText={translate('common:copy')}
                  copiedMessage={translate('common:copied')}
                />
              ),
            },
            {
              prop: 'address',
              label: translate('nfts:list:address'),
              renderCell: ({ nft }) =>
                nft.address ? (
                  <Tooltip text={translate('common:copy')}>
                    <span>
                      <Badge
                        variant="secondary"
                        onClick={() => handleCopyToClipboard(nft.address!)}
                      >
                        {shortenAddress(nft.address!, 3)}
                      </Badge>
                    </span>
                  </Tooltip>
                ) : (
                  '--'
                ),
            },
            {
              prop: 'status',
              label: translate('common:status'),
              renderCell: ({ status }) => (
                <Badge
                  variant={
                    status === NftListingStatus.Purchased
                      ? 'primary'
                      : 'secondary'
                  }
                >
                  {status ? capitalize(status) : '--'}
                </Badge>
              ),
            },
            {
              prop: 'active',
              label: translate('common:activity'),
              renderCell: ({ active }) => (
                <Badge variant={active ? 'success' : 'secondary'}>
                  {translate(active ? 'common:active' : 'common:unactive')}
                </Badge>
              ),
            },
            {
              prop: 'price',
              label: translate('nftListings:list:price'),
              renderCell: ({ price, currency }) => (
                <Text fontWeight="semibold">
                  <FiatPrice value={price} currency={currency} />
                </Text>
              ),
            },
            {
              prop: 'createdAt',
              label: translate('common:createdAt'),
              renderCell: ({ createdAt }) => (
                <time dateTime={new Date(createdAt).toISOString()}>
                  {new Date(createdAt).toLocaleDateString()}
                </time>
              ),
            },
          ]}
          renderMenu={(item) => (
            <>
              <MenuItem
                tag="span"
                icon={<Icon icon={PencilIcon} size="sm" />}
                onClick={() => handleEdit(item)}
              >
                {translate('common:edit')}
              </MenuItem>

              <MenuItem
                tag="span"
                icon={<Icon icon={TrashIcon} size="sm" />}
                onClick={() => handleDeleteListing(item)}
              >
                {translate('common:delete')}
              </MenuItem>
            </>
          )}
        />
      </Panel>

      {isEditModalOpen && selectedNftListing && (
        <EditNftListingModal
          nftListing={selectedNftListing}
          onSuccess={() => {
            setIsEditModalOpen(false);
            setSelectedNftListing(null);
            window.location.reload();
          }}
          onClose={() => {
            setIsEditModalOpen(false);
            setSelectedNftListing(null);
          }}
        />
      )}
    </>
  );
};

export default NftListingsList;
