import React from 'react';
import classNames from 'classnames';
import Image, { ImageProps } from 'components/atoms/Image';

import styles from './ImageWrapper.module.css';

export interface ImageWrapperProps {
  src: string;
  alt?: string;
  ratio?: number | string;
  fit?: ImageProps['fit'];
  onLoad?: () => void;
  className?: string;
}

const ImageWrapper: React.FC<ImageWrapperProps> = ({
  src,
  alt,
  fit = 'contain',
  onLoad,
  className,
  ...rest
}) => (
  <div className={classNames(styles.root, className)} {...rest}>
    <span
      className={styles.background}
      style={{
        backgroundImage: `url(${src})`,
      }}
    />

    <Image
      src={src}
      alt={alt}
      w="full"
      h="full"
      fit={fit}
      align="50% 50%"
      onLoad={onLoad}
      className={styles.image}
    />
  </div>
);

export default ImageWrapper;
