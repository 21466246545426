import ConfirmDialog from 'components/templates/ConfirmDialog';
import ConfirmDialogContext, {
  ConfirmContent,
  ConfirmDialogContextValue,
} from 'contexts/ConfirmDialogContext';
import React, { useState } from 'react';

interface ConfirmDialogProviderProps {
  defaultTitle: string;
  defaultCancelLabel: string;
  defaultConfirmLabel: string;
}

interface ConfirmDialogState {
  content?: ConfirmContent;
  callback: (isConfirmed: boolean) => void;
}

const ConfirmDialogProvider: React.FC<ConfirmDialogProviderProps> = ({
  defaultTitle,
  defaultConfirmLabel,
  defaultCancelLabel,
  children,
}) => {
  const [state, setState] = useState<ConfirmDialogState | null>(null);

  const handleCancel = () => {
    state?.callback(false);
    setState(null);
  };

  const handleConfirm = () => {
    state?.callback(true);
    setState(null);
  };

  const activate = (content?: ConfirmContent) => {
    if (state) {
      handleCancel();
    }

    return new Promise<boolean>((resolve) => {
      setState({ content, callback: resolve });
    });
  };

  const value: ConfirmDialogContextValue = {
    isConfirmDialogActive: !!state,
    activateConfirmDialog: activate,
  };

  return (
    <ConfirmDialogContext.Provider value={value}>
      {children}
      {state && (
        <ConfirmDialog
          title={state.content?.title || defaultTitle}
          text={state.content?.text}
          onConfirm={handleConfirm}
          confirmLabel={defaultConfirmLabel}
          onCancel={handleCancel}
          cancelLabel={defaultCancelLabel}
        />
      )}
    </ConfirmDialogContext.Provider>
  );
};

export default ConfirmDialogProvider;
