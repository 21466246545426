import React from 'react';
import { Button, Icon, Toolbar, Panel } from '@fanadise/common-ui';
import { Link } from 'react-router-dom';
import { ArrowSmLeftIcon } from '@heroicons/react/solid';
import EditAccountForm from 'components/accounts/EditAccountForm';
import { Translate } from '@fanadise/common-logic';

const AccountsRouteAdd: React.FC = () => (
  <>
    <Toolbar
      title={<Translate id="accounts:add:title" />}
      leading={
        <Link to="../">
          <Button
            tag="span"
            variant="icon"
            icon={<Icon icon={ArrowSmLeftIcon} size="lg" />}
          />
        </Link>
      }
      mb="8"
    />
    <Panel>
      <EditAccountForm />
    </Panel>
  </>
);

export default AccountsRouteAdd;
