import React, { useCallback, useContext, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import {
  SnackbarContext,
  ConnectedLoading,
  ConnectedSubmit,
  ConnectedInput,
  ConnectedFormItem,
  ConnectedFileInput,
  FilePreview,
  Separator,
  Button,
  Stack,
} from '@fanadise/common-ui';
import { useNavigate } from 'react-router-dom';
import { Category } from '@fanadise/common-types';
import FormSubmitBar from 'components/shared/FormSubmitBar';
import { useTranslation } from '@fanadise/common-logic';
import { categoriesApi } from '@fanadise/common-data-access';
import FormGrid from 'components/shared/FormGrid';

interface EditCategoryFormValues {
  name: string;
  iconFile?: File;
}

export interface EditCategoryFormProps {
  category?: Category;
  onChange?: (category: Category) => void;
}

const EditCategoryForm: React.FC<EditCategoryFormProps> = ({
  category,
  onChange,
}) => {
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const { addSuccessAlert, addErrorAlert } = useContext(SnackbarContext)!;
  const [isDeleting, setIsDeleting] = useState(false);

  const validationSchema = yup.object().shape({
    name: yup.string().required(translate('validation:required')),
  });

  const initialValues: EditCategoryFormValues = {
    name: category?.name || '',
  };

  const handleSubmit = useCallback(
    async (
      values: EditCategoryFormValues,
      { setFieldValue }: FormikHelpers<EditCategoryFormValues>,
    ) => {
      try {
        const propsToSave = {
          name: values.name,
        };

        let savedCategory: Category;
        let message;

        if (category) {
          savedCategory = await categoriesApi.updateCategory(
            category.id,
            propsToSave,
          );
          message = translate('success:saved');
        } else {
          savedCategory = await categoriesApi.createCategory(propsToSave);
          message = translate('success:added');
        }

        if (values.iconFile) {
          try {
            const data = new FormData();
            data.append('file', values.iconFile);
            savedCategory = await categoriesApi.uploadCategoryIcon(
              savedCategory.id,
              data,
            );
            setFieldValue('iconFile', null);
          } catch (err) {
            if (category) {
              throw err;
            }
          }
        }

        addSuccessAlert(message);

        if (!category) {
          navigate(`/account/categories/edit/${savedCategory!.id}`, {
            replace: true,
          });
        } else if (onChange) {
          onChange(savedCategory);
        }
      } catch (err: any) {
        addErrorAlert(err.message || translate('error:default'));
      }
    },
    [translate, addSuccessAlert, addErrorAlert, category?.id, onChange],
  );

  const handleDelete = useCallback(async () => {
    setIsDeleting(true);
    try {
      await categoriesApi.deleteCategory(category!.id);
      addSuccessAlert(translate('success:removed'));
      navigate('../');
    } catch (err: any) {
      addErrorAlert(err.message || translate('error:default'));
      setIsDeleting(false);
    }
  }, [category?.id, navigate, addSuccessAlert, addErrorAlert]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <ConnectedLoading>
        <Form noValidate>
          <FormGrid>
            <ConnectedFormItem
              fieldId="name"
              fieldName="name"
              label={translate('common:name')}
              isRequired
            >
              <ConnectedInput name="name" />
            </ConnectedFormItem>
          </FormGrid>

          <ConnectedFormItem
            fieldId="iconFile"
            fieldName="iconFile"
            isRequired
            label={translate('common:icon')}
          >
            <Stack dir="row" gap="4" align="center">
              {category?.iconUrl && <FilePreview url={category.iconUrl} />}

              <ConnectedFileInput
                name="iconFile"
                accept="image/png, image/jpeg"
                label={translate('common:selectFile')}
              />
            </Stack>
          </ConnectedFormItem>

          <FormSubmitBar>
            <ConnectedSubmit isDisabled={isDeleting}>
              {translate('common:save')}
            </ConnectedSubmit>
          </FormSubmitBar>
        </Form>

        {category && (
          <>
            <Separator spacing="6" />
            <Button
              color="danger"
              onClick={handleDelete}
              isLoading={isDeleting}
            >
              {translate('common:delete')}
            </Button>
          </>
        )}
      </ConnectedLoading>
    </Formik>
  );
};

export default EditCategoryForm;
