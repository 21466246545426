import React from 'react';
import { BaseAccountWithOwner } from '@fanadise/common-types';
import Ratio from 'components/atoms/Ratio';
import Avatar from 'components/atoms/Avatar';
import ImageWrapper from 'components/atoms/ImageWrapper';

import { DEFAULT_COVER_URL } from '@fanadise/common-consts';
import styles from './AccountCard.module.css';

export interface AccountCardProps {
  account: Pick<BaseAccountWithOwner, 'id' | 'name' | 'coverUrl' | 'owner'>;
  ratio?: number;
  onClick?: () => void;
}

const AccountCard: React.FC<AccountCardProps> = ({
  ratio = 0.75,
  account,
  onClick,
}) => (
  <Ratio ratio={ratio} className={styles.root}>
    <div role="link" tabIndex={0} onClick={onClick}>
      <ImageWrapper
        src={account.coverUrl || DEFAULT_COVER_URL}
        alt={account.name}
        fit="cover"
      />

      <div className={styles.owner}>
        <Avatar url={account.owner.avatarUrl} size="md" />
      </div>

      <div className={styles.footer}>
        <p className={styles.name}>{account.name}</p>
        <p className={styles.username}>@{account.owner.username}</p>
      </div>
    </div>
  </Ratio>
);

export default AccountCard;
