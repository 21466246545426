import React, { ReactNode, useRef } from 'react';
import classNames from 'classnames';
import Modal, { Props as ModalProps } from 'react-modal';

import CloseButton from 'components/atoms/CloseButton';
import styles from './Dialog.module.css';

export interface DialogProps
  extends Omit<
    ModalProps,
    | 'overlayClassName'
    | 'bodyOpenClassName'
    | 'bodyOpenClassName'
    | 'shouldCloseOnOverlayClick'
    | 'contentLabel'
  > {
  title?: string;
  description?: React.ReactNode | string;
  isClosable?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  variant?: 'default' | 'centered';
  footer?: ReactNode;
}

const Dialog: React.FC<DialogProps> = ({
  title,
  description,
  isClosable = true,
  isOpen,
  size = 'md',
  variant = 'default',
  footer,
  closeTimeoutMS = 300,
  children,
  className,
  ...rest
}) => {
  const prevContent = useRef<JSX.Element | null>(null);

  const content = !isOpen ? (
    prevContent.current || null
  ) : (
    <>
      {title && (
        <div className={styles.header}>
          <h4 className={styles.title}>{title}</h4>
          {description && <p className={styles.description}>{description}</p>}
        </div>
      )}

      {isClosable && (
        <CloseButton
          className={styles.closeButton}
          onClick={rest.onRequestClose}
        />
      )}

      {children && <div className={styles.body}>{children}</div>}

      {footer && <div className={styles.footer}>{footer}</div>}
    </>
  );
  prevContent.current = content;

  return (
    <Modal
      className={classNames(
        styles.root,
        styles[`size--${size}`],
        styles[`variant--${variant}`],
        !isOpen && styles.closed,
        !children && styles.empty,
        className,
      )}
      overlayClassName={classNames(
        styles.overlay,
        styles[`variant--${variant}`],
        !isOpen && styles['overlay--closed'],
      )}
      shouldCloseOnOverlayClick={isClosable}
      contentLabel={title}
      closeTimeoutMS={closeTimeoutMS}
      isOpen={isOpen}
      htmlOpenClassName="no-scroll"
      {...rest}
    >
      {content}
    </Modal>
  );
};

export default Dialog;
