import { Avatar, Box, Button, Icon, Stack } from '@fanadise/common-ui';
import { ArrowSmRightIcon } from '@heroicons/react/solid';
import React from 'react';
import { BaseAccount } from '@fanadise/common-types';
import Translate from 'components/shared/Translate';
import { DEFAULT_ICON_URL } from '@fanadise/common-consts';

export interface AccountsListProps {
  accounts: BaseAccount[];
}

const AccountsList: React.FC<AccountsListProps> = ({ accounts }) => {
  const hasAccounts = Boolean(accounts?.length);

  return (
    <Stack dir="row" gap="2" align="center" wrap="wrap">
      {hasAccounts &&
        accounts.map((account) => (
          <a
            href={account.url}
            key={account.id}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Avatar
              url={account.iconUrl || DEFAULT_ICON_URL}
              size="md"
              alt={account.name}
            />
          </a>
        ))}

      <Box pl="2">
        <a
          href="https://fanadise.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            tag="span"
            color="secondary"
            size="sm"
            icon={<Icon icon={ArrowSmRightIcon} size="sm" />}
          >
            <Translate id="common:explore" />
          </Button>
        </a>
      </Box>
    </Stack>
  );
};

export default AccountsList;
