import React, { useCallback, useContext, useState } from 'react';
import { BaseNft } from '@fanadise/common-types';
import { SnackbarContext, ConfirmDialog } from '@fanadise/common-ui';
import { useTranslation } from '@fanadise/common-logic';
import { nftsApi } from '@fanadise/common-data-access';

interface UnassignUserModalProps {
  nft: BaseNft;
  onClose: () => void;
  onSuccess: (nft: BaseNft) => void;
}

const UnassignUserModal: React.FC<UnassignUserModalProps> = ({
  nft,
  onClose,
  onSuccess,
}) => {
  const { addSuccessAlert, addErrorAlert } = useContext(SnackbarContext)!;
  const { translate } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = useCallback(async () => {
    setIsLoading(true);
    try {
      const savedNft = await nftsApi.unassignNftFromUser(nft.id);
      addSuccessAlert(translate('success:saved'));
      onSuccess(savedNft);
    } catch (err: any) {
      setIsLoading(false);
      addErrorAlert(err.message || translate('error:default'));
    }
  }, [nft]);

  return (
    <ConfirmDialog
      title={translate('common:confirm:title')}
      cancelLabel={translate('common:cancel')}
      confirmLabel={translate('common:confirm')}
      onCancel={onClose}
      onConfirm={handleConfirm}
      isLoading={isLoading}
    />
  );
};

export default UnassignUserModal;
