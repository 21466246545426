import { api } from 'api';
import {
  Category,
  MultiResultsDto,
  SingleResultDto,
} from '@fanadise/common-types';
import type { PaginatedRequestQuery } from 'types/paginatedRequestQuery';
import type { PaginatedResponseData } from 'types/paginatedResponseData';

export const fetchCategory = async (id: string) => {
  const res = await api.get<SingleResultDto<'category', Category>>(
    `/nft-categories/${id}`,
  );
  return res.data.category;
};

export const fetchCategories = async (
  params: { search?: string } & PaginatedRequestQuery = {
    page: 1,
    size: 50,
  },
) => {
  const res = await api.get<
    MultiResultsDto<'categories', Category> & PaginatedResponseData
  >('/nft-categories', {
    params,
  });
  return res.data;
};

export const updateCategory = async (id: string, data: { name: string }) => {
  const res = await api.put<SingleResultDto<'category', Category>>(
    `/nft-categories/${id}`,
    data,
  );
  return res.data.category;
};

export const deleteCategory = async (id: string) => {
  await api.delete(`/nft-categories/${id}`);
};

export const createCategory = async (data: { name: string }) => {
  const res = await api.post<SingleResultDto<'category', Category>>(
    `/nft-categories`,
    data,
  );
  return res.data.category;
};

export const uploadCategoryIcon = async (id: string, data: FormData) => {
  const res = await api.post<SingleResultDto<'category', Category>>(
    `/nft-categories/${id}/upload-icon`,
    data,
  );
  return res.data.category;
};
