import classNames from 'classnames';
import Input from 'components/atoms/Input';
import React, { ReactNode } from 'react';
import { Range, getTrackBackground } from 'react-range';
import { Direction, IProps } from 'react-range/lib/types';

import styles from './RangeSlider.module.css';

export interface RangeSliderProps
  extends Omit<
    IProps,
    | 'renderTrack'
    | 'renderThumb'
    | 'direction'
    | 'allowOverlap'
    | 'draggableTrack'
    | 'disabled'
    | 'rtl'
  > {
  minLabel?: ReactNode;
  maxLabel?: ReactNode;
  className?: string;
  direction?: Direction;
  isDisabled?: boolean;
  hasInput?: boolean;
}

const RangeSlider: React.FC<RangeSliderProps> = ({
  minLabel,
  maxLabel,
  values,
  onChange,
  min,
  max,
  className,
  isDisabled,
  hasInput,
  ...rest
}) => (
  <div
    data-testid="rangeSlider"
    className={classNames(
      styles.root,
      {
        [styles.hasLabels]: minLabel || maxLabel,
        [styles.isDisabled]: isDisabled,
      },
      className,
    )}
  >
    <div className={styles.range}>
      {(minLabel || maxLabel) && (
        <div className={styles.labels}>
          {minLabel && <span>{minLabel}</span>}
          {maxLabel && <span>{maxLabel}</span>}
        </div>
      )}
      <Range
        disabled={isDisabled}
        min={min}
        max={max}
        values={values}
        onChange={onChange}
        {...rest}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            className={styles.track}
            style={{
              background: getTrackBackground({
                values,
                min,
                max,
                colors: [
                  'var(--theme-color-primary)',
                  'var(--theme-color-gray-300)',
                ],
              }),
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => <div {...props} className={styles.thumb} />}
      />
    </div>

    {hasInput && (
      <Input
        type="number"
        size="xs"
        min={min}
        max={max}
        value={values[0]}
        onChange={(e) =>
          onChange([
            Math.min(Math.max(parseFloat(e.target.value) || 0, min), max),
          ])
        }
        isDisabled={isDisabled}
        className={styles.input}
      />
    )}
  </div>
);

export default RangeSlider;
