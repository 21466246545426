import React from 'react';
import classNames from 'classnames';
import Pop, { PopProps } from '../Pop';

import styles from './Menu.module.css';

export interface MenuProps extends PopProps {
  minW?: number;
}

const Menu: React.FC<MenuProps> = ({
  minW = 6,
  className,
  children,
  ...rest
}) => (
  <Pop {...rest} className={classNames(styles.root, className)}>
    <div
      style={{
        minWidth: `${minW}rem`,
      }}
    >
      {children}
    </div>
  </Pop>
);

export default Menu;
