import { AuthContextValue } from 'contexts/AuthContext';
import React, {
  Context,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import log from 'loglevel';

interface AuthProviderProps<User = {}> {
  tokenKey: string;
  context: Context<AuthContextValue<User>>;
  fetchUserPromise: () => Promise<User>;
  onAuth?: (newUser: User) => void;
  onSignOut?: () => void;
}

const AuthProvider = <User extends object>({
  tokenKey,
  context: AuthContext,
  fetchUserPromise,
  onAuth,
  onSignOut,
  children,
}: PropsWithChildren<AuthProviderProps<User>>) => {
  const [user, setCurrentUser] = useState<User>();
  const [isTried, setIsTried] = useState(false);

  const setUser = (newUser: User) => {
    onAuth?.(newUser);
    setCurrentUser(newUser);
  };

  const updateUser = useCallback(
    (updatedUser: Partial<User>) => {
      if (user) {
        setUser({
          ...user,
          ...updatedUser,
        });
      }
    },
    [user],
  );

  const fetchUser = useCallback(async () => {
    try {
      setCurrentUser(await fetchUserPromise());
    } catch (err) {
      log.error(err);
    } finally {
      setIsTried(true);
    }
  }, [fetchUserPromise]);

  const signOut = useCallback(() => {
    onSignOut?.();
    localStorage.removeItem(tokenKey);
    setCurrentUser(undefined);
  }, [tokenKey, onSignOut]);

  useEffect(() => {
    const token = localStorage.getItem(tokenKey);
    if (token) {
      fetchUser();
    } else {
      setIsTried(true);
    }
  }, [tokenKey]);

  const contextValue: AuthContextValue<User> = useMemo(
    () => ({
      user,
      isTried,
      isAuthenticated: Boolean(user),
      setUser,
      updateUser,
      signOut,
    }),
    [user, isTried],
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
