import React from 'react';
import { useLocation } from 'react-router-dom';
import { StepScaffold, FanadiseLogo } from '@fanadise/common-ui';
import { camelize } from '@fanadise/common-utils';
import { Translate } from '@fanadise/common-logic';

const AuthLayout: React.FC = ({ children }) => {
  const { pathname } = useLocation();
  const translationAuthKey = camelize(pathname.split('/').pop()!);

  return (
    <StepScaffold
      header={<FanadiseLogo size="2xl" color="primary" />}
      title={<Translate id={`auth:${translationAuthKey}:title`} />}
      description={<Translate id={`auth:${translationAuthKey}:description`} />}
    >
      {children}
    </StepScaffold>
  );
};

export default AuthLayout;
