import React from 'react';
import { Toolbar, Panel } from '@fanadise/common-ui';
import FilesList from 'components/files/FilesList';
import { Translate } from '@fanadise/common-logic';

const FilesRouteList: React.FC = () => (
  <>
    <Toolbar title={<Translate id="files:list:title" />} mb="8" />

    <Panel>
      <FilesList />
    </Panel>
  </>
);

export default FilesRouteList;
