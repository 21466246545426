import React, { HTMLAttributes } from 'react';

export interface CopyrightsProps extends HTMLAttributes<HTMLElement> {}

const Copyrights: React.FC<CopyrightsProps> = ({ children, ...rest }) => {
  const year = new Date().getFullYear().toString();

  return (
    <span data-testid="copyrights" {...rest}>
      &#169; {year} {children}
    </span>
  );
};

export default Copyrights;
