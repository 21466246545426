import React from 'react';
import { Toolbar } from '@fanadise/common-ui';
import { Translate } from '@fanadise/common-logic';
import NftListingsList from 'components/nftListings/NftListingsList';

const NftListingsRouteList: React.FC = () => (
  <>
    <Toolbar mb="8" title={<Translate id="nftListings:list:title" />} />

    <NftListingsList />
  </>
);

export default NftListingsRouteList;
