import React from 'react';
import CurrencyContext from 'contexts/CurrencyContext';
import type { Currency } from '@fanadise/common-consts';

export interface CurrencyProviderProps {
  currency: Currency;
}

const CurrencyProvider: React.FC<CurrencyProviderProps> = ({
  currency,
  children,
}) => (
  <CurrencyContext.Provider value={currency}>
    {children}
  </CurrencyContext.Provider>
);

export default CurrencyProvider;
