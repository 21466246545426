import React from 'react';
import { useField } from 'formik';
import Select, { SelectProps } from 'components/atoms/Select';

export interface ConnectedSelectProps extends SelectProps {
  name: string;
}

const ConnectedSelect: React.FC<ConnectedSelectProps> = ({ name, ...rest }) => {
  const [field, { error, touched }] = useField({ name });
  const isInvalid = touched && Boolean(error);

  return <Select isInvalid={isInvalid} {...field} {...rest} />;
};

export default ConnectedSelect;
