import {
  Icon,
  ConnectedLoading,
  ConnectedInput,
  ConnectedFormItem,
  ConnectedForm,
  ConnectedSubmit,
  Button,
  Box,
} from '@fanadise/common-ui';
import { Formik } from 'formik';
import { ArrowSmLeftIcon } from '@heroicons/react/outline';
import React from 'react';
import * as yup from 'yup';
import useTranslation from 'hooks/useTranslation';
import { authApi } from '@fanadise/common-data-access';
import Link from 'components/shared/Link';

export interface ForgotPasswordFormValues {
  email: string;
}

export interface ForgotPasswordFormProps {
  signInHref: string;
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  signInHref,
}) => {
  const { translate } = useTranslation();

  const initialValues: ForgotPasswordFormValues = {
    email: '',
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(translate('validation:required'))
      .email(translate('validation:invalid')),
  });

  return (
    <Formik<ForgotPasswordFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, resetForm, setStatus }) => {
        try {
          await authApi.resetPassword(values);
          resetForm();
          setStatus({
            successMessages: [translate('auth:forgotPassword:successMessage')],
          });
        } catch (err: any) {
          const message = err.message || translate('error:default');

          setStatus({ errorMessages: [message] });
          setSubmitting(false);
        }
      }}
    >
      <ConnectedLoading>
        <ConnectedForm>
          <ConnectedFormItem
            fieldId="email"
            fieldName="email"
            label={translate('common:email')}
            isRequired
          >
            <ConnectedInput size="lg" name="email" type="email" />
          </ConnectedFormItem>

          <ConnectedSubmit size="lg">
            {translate('auth:forgotPassword:submit')}
          </ConnectedSubmit>

          <Box ml="-4" mt="4">
            <Link href={signInHref} passHref>
              <Button
                variant="text"
                tag="a"
                icon={<Icon icon={ArrowSmLeftIcon} />}
              >
                {translate('auth:backToSignIn')}
              </Button>
            </Link>
          </Box>
        </ConnectedForm>
      </ConnectedLoading>
    </Formik>
  );
};

export default ForgotPasswordForm;
