import React from 'react';

export interface TranslationsContextValue {
  translate: (id: string, values?: { [key: string]: any }) => string;
  data?: object;
}

const TranslationsContext = React.createContext<TranslationsContextValue>(
  {} as TranslationsContextValue,
);

export default TranslationsContext;
