import React from 'react';

import { Heading, Box } from '@fanadise/common-ui';

interface FormSectionTitleProps {
  className?: string;
}

const FormSectionTitle: React.FC<FormSectionTitleProps> = ({
  children,
  className,
}) => (
  <Box mb="6" className={className}>
    <Heading fontSize="lg">{children}</Heading>
  </Box>
);

export default FormSectionTitle;
