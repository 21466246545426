import React, { useCallback, useContext } from 'react';
import { Formik, Form, FormikHelpers, FormikConsumer } from 'formik';
import * as yup from 'yup';
import { NftStatus } from '@fanadise/common-consts';
import { nftsApi } from '@fanadise/common-data-access';
import { Translate, useTranslation } from '@fanadise/common-logic';
import {
  ConnectedFormItem,
  ConnectedLoading,
  ConnectedSubmit,
  SnackbarContext,
  Stack,
} from '@fanadise/common-ui';
import ConnectedAsyncSmartSelect from 'components/shared/ConnectedAsyncSmartSelect';

interface AssignUserFormValues {
  nftId: string;
}

interface AssignUserFormProps {
  userId: string;
}

const AssignUserForm: React.FC<AssignUserFormProps> = ({ userId }) => {
  const { translate } = useTranslation();
  const { addSuccessAlert, addErrorAlert } = useContext(SnackbarContext)!;

  const validationSchema = yup.object().shape({
    nftId: yup.string().required(translate('validation:required')),
  });

  const initialValues: AssignUserFormValues = {
    nftId: '',
  };

  const handleSubmit = useCallback(
    async (
      values: AssignUserFormValues,
      { resetForm }: FormikHelpers<AssignUserFormValues>,
    ) => {
      try {
        await nftsApi.assignNftToUser(values.nftId, { userId });
        addSuccessAlert(translate('success:saved'));
        resetForm();
      } catch (err: any) {
        addErrorAlert(err.message || translate('error:default'));
      }
    },
    [userId],
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <Form>
        <ConnectedLoading>
          <Stack dir="row" maxW="sm" h="10">
            <ConnectedFormItem fieldId="nftId" fieldName="nftId">
              <ConnectedAsyncSmartSelect
                name="nftId"
                placeholder={translate('users:assign:placeholder')}
                cacheOptions
                loadOptions={async (value, callback) => {
                  const result = await nftsApi.fetchNfts({
                    search: value,
                    status: NftStatus.Minted,
                  });

                  callback(
                    result.nfts
                      ? result.nfts.map((nft) => ({
                          value: nft.id,
                          label: `#${nft.tokenId}, ${nft.config.name}`,
                        }))
                      : [],
                  );
                }}
              />
            </ConnectedFormItem>
            <FormikConsumer>
              {({ values }) => (
                <ConnectedSubmit isDisabled={!values.nftId} color="secondary">
                  <Translate id="common:assign" />
                </ConnectedSubmit>
              )}
            </FormikConsumer>
          </Stack>
        </ConnectedLoading>
      </Form>
    </Formik>
  );
};

export default AssignUserForm;
