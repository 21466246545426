import ToggleSwitch, { ToggleSwitchProps } from 'components/atoms/ToggleSwitch';
import { useField } from 'formik';
import React from 'react';

export interface ConnectedToggleSwitchProps extends ToggleSwitchProps {
  name: string;
  value?: string;
}

const ConnectedToggleSwitch: React.FC<ConnectedToggleSwitchProps> = ({
  name,
  value,
  ...rest
}) => {
  const [field] = useField({ name, value, type: 'checkbox' });

  return <ToggleSwitch isChecked={field.checked} {...field} {...rest} />;
};

export default ConnectedToggleSwitch;
