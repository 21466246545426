import { api } from 'api';
import {
  Collection,
  MultiResultsDto,
  SingleResultDto,
} from '@fanadise/common-types';
import type { PaginatedRequestQuery } from 'types/paginatedRequestQuery';
import type { PaginatedResponseData } from 'types/paginatedResponseData';

export const fetchCollection = async (id: string) => {
  const res = await api.get<SingleResultDto<'collection', Collection>>(
    `/nft-collections/${id}`,
  );
  return res.data.collection;
};

export const fetchCollections = async (
  params: { search?: string } & PaginatedRequestQuery = {
    page: 1,
    size: 50,
  },
) => {
  const res = await api.get<
    MultiResultsDto<'collections', Collection> & PaginatedResponseData
  >('/nft-collections', {
    params,
  });
  return res.data;
};

export const uploadCollectionIcon = async (id: string, data: FormData) => {
  const res = await api.post<SingleResultDto<'collection', Collection>>(
    `/nft-collections/${id}/upload-icon`,
    data,
  );
  return res.data.collection;
};

export const uploadCollectionBackground = async (
  id: string,
  data: FormData,
) => {
  const res = await api.post<SingleResultDto<'collection', Collection>>(
    `/nft-collections/${id}/upload-background`,
    data,
  );
  return res.data.collection;
};

export const createCollection = async (data: { name: string }) => {
  const res = await api.post<SingleResultDto<'collection', Collection>>(
    `/nft-collections`,
    data,
  );
  return res.data.collection;
};
