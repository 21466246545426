import React, {
  Context,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { SnackbarContext } from '@fanadise/common-ui';
import log from 'loglevel';
import { profileApi } from '@fanadise/common-data-access';
import useTranslation from 'hooks/useTranslation';
import { EmailVerificationContextValue } from 'contexts/EmailVerificationContext';
import { AuthContextValue } from 'contexts/AuthContext';
import { User } from '@fanadise/common-types';

interface EmailVerificationProviderProps {
  context: Context<EmailVerificationContextValue>;
  authContext: Context<AuthContextValue<User>>;
  token: string;
  onRemoveToken: () => void;
}

const EmailVerificationProvider: React.FC<EmailVerificationProviderProps> = ({
  context: EmailVerificationContext,
  authContext: AuthContext,
  token,
  onRemoveToken,
  children,
}) => {
  const { isAuthenticated, setUser } = useContext(AuthContext);
  const { translate } = useTranslation();
  const { addSuccessAlert, addErrorAlert } = useContext(SnackbarContext)!;

  useEffect(() => {
    if (!token || !isAuthenticated) {
      return;
    }

    onRemoveToken();

    const confirmEmail = async () => {
      try {
        const updatedUser = await profileApi.confirmProfileEmail(
          token as string,
        );
        setUser(updatedUser);

        addSuccessAlert(translate('email:alert:confirmed'));
      } catch (err: any) {
        log.error(err);
        addErrorAlert(err.message || translate('error:default'));
      }
    };

    confirmEmail();
  }, [token, isAuthenticated]);

  const requestEmailVerification = useCallback(async () => {
    try {
      await profileApi.verifyProfileEmail();
      addSuccessAlert(translate('email:alert:verifyLinkSent'));
    } catch (err: any) {
      log.error(err);
      addErrorAlert(err.message || translate('error:default'));
    }
  }, []);

  const contextValue: EmailVerificationContextValue = useMemo(
    () => ({
      requestEmailVerification,
    }),
    [requestEmailVerification],
  );

  return (
    <EmailVerificationContext.Provider value={contextValue}>
      {children}
    </EmailVerificationContext.Provider>
  );
};

export default EmailVerificationProvider;
