import React, { forwardRef } from 'react';
import classNames from 'classnames';

import styles from './IntroLink.module.css';

export interface IntroLinkProps
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  introText: string | JSX.Element;
  linkText: string | JSX.Element;
  isBroken?: boolean;
}

const IntroLink = forwardRef<HTMLAnchorElement, IntroLinkProps>(
  ({ introText, linkText, isBroken, className, ...rest }, ref) => (
    <a className={classNames(styles.root, className)} ref={ref} {...rest}>
      {introText} {isBroken ? <br /> : ' '}
      <span className={styles.link}>{linkText}</span>
    </a>
  ),
);

export default IntroLink;
