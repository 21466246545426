import classNames from 'classnames';
import React from 'react';

import styles from './Icon.module.css';

export interface IconProps {
  icon: React.ComponentType<React.ComponentProps<'svg'>>;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
  className?: string;
}

const Icon = ({ icon: IconElement, size = 'md', className }: IconProps) => (
  <i
    data-testid="icon"
    className={classNames(styles.root, styles[`size--${size}`], className)}
  >
    <IconElement />
  </i>
);

export default Icon;
