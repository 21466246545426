import React from 'react';
import { authApi } from '@fanadise/common-data-access';
import {
  ConnectedLoading,
  ConnectedInput,
  ConnectedFormItem,
  ConnectedForm,
  ConnectedSubmit,
  Icon,
  Button,
  Box,
} from '@fanadise/common-ui';
import { ArrowSmLeftIcon } from '@heroicons/react/outline';
import Link from 'components/shared/Link';
import { Formik, FormikConsumer, FormikHelpers } from 'formik';
import * as yup from 'yup';
import useTranslation from 'hooks/useTranslation';

export interface ChangePasswordFormValues {
  password: string;
}

export interface ChangePasswordFormProps {
  token: string;
  signInHref: string;
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  token,
  signInHref,
}) => {
  const { translate } = useTranslation();

  const initialValues: ChangePasswordFormValues = {
    password: '',
  };

  const validationSchema = yup.object().shape({
    password: yup.string().required(translate('validation:required')),
  });

  const handleSubmit = async (
    { password }: ChangePasswordFormValues,
    {
      setSubmitting,
      setStatus,
      resetForm,
    }: FormikHelpers<ChangePasswordFormValues>,
  ) => {
    try {
      await authApi.changePassword({ token, password });
      resetForm();
      setStatus({
        successMessages: [translate('auth:changePassword:successMessage')],
      });
    } catch (err: any) {
      const message = err.message || translate('error:default');

      setSubmitting(false);
      setStatus({ errorMessages: [message] });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <ConnectedLoading>
        <ConnectedForm>
          <FormikConsumer>
            {({ status }) =>
              status?.successMessages ? (
                <>
                  <Link href={signInHref} passHref>
                    <a>
                      <Button
                        size="lg"
                        w="full"
                        tag="span"
                        icon={<Icon icon={ArrowSmLeftIcon} />}
                      >
                        {translate('auth:backToSignIn')}
                      </Button>
                    </a>
                  </Link>
                </>
              ) : (
                <>
                  <ConnectedFormItem
                    fieldId="password"
                    fieldName="password"
                    label={translate('common:password')}
                    isRequired
                  >
                    <ConnectedInput size="lg" name="password" type="password" />
                  </ConnectedFormItem>

                  <ConnectedSubmit size="lg">
                    {translate('auth:changePassword:submit')}
                  </ConnectedSubmit>

                  <Box ml="-4" mt="4">
                    <Link href={signInHref} passHref>
                      <Button
                        variant="text"
                        tag="a"
                        icon={<Icon icon={ArrowSmLeftIcon} />}
                      >
                        {translate('auth:backToSignIn')}
                      </Button>
                    </Link>
                  </Box>
                </>
              )
            }
          </FormikConsumer>
        </ConnectedForm>
      </ConnectedLoading>
    </Formik>
  );
};

export default ChangePasswordForm;
