import classNames from 'classnames';
import React from 'react';

import styles from './Spinner.module.css';

export interface SpinnerProps {
  color?: 'primary' | 'success' | 'white';
  size?: 'sm' | 'md' | 'lg';
  className?: string;
}

const Spinner: React.FC<SpinnerProps> = ({
  color = 'primary',
  size = 'md',
  className,
}) => (
  <svg
    data-testid="spinner"
    className={classNames(
      styles.root,
      styles[`color--${color}`],
      styles[`size--${size}`],
      className,
    )}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      cx="12"
      cy="12"
      r="10"
      fill="none"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      stroke="currentColor"
      d="M2,12C2,6.5,6.5,2,12,2"
      fill="none"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

export default Spinner;
