import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import SignInForm from 'components/auth/SignInForm';
import AuthLayout from 'components/auth/AuthLayout';

// import ResetPasswordForm from 'components/auth/ResetPasswordForm';
// import ChangePasswordForm from 'components/auth/ChangePasswordForm';

const AuthPage: React.FC = () => (
  <AuthLayout>
    <Routes>
      {/* <Route path="reset-password" element={<ResetPasswordForm />} />
      <Route path="change-password" element={<ChangePasswordForm />} /> */}
      <Route path="sign-in" element={<SignInForm />} />
      <Route path="*" element={<Navigate to="sign-in" />} />
    </Routes>
  </AuthLayout>
);

export default AuthPage;
