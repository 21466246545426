import React from 'react';
import { useField } from 'formik';
import FormItem, { FormItemProps } from 'components/molecules/FormItem';

export interface ConnectedFormItemProps extends Omit<FormItemProps, 'error'> {
  fieldName: string;
}

const ConnectedFormItem: React.FC<ConnectedFormItemProps> = ({
  fieldName,
  ...rest
}) => {
  const [, { error, touched }] = useField(fieldName);

  return <FormItem error={touched ? error : undefined} {...rest} />;
};

export default ConnectedFormItem;
