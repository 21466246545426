import React, { useContext } from 'react';
import AppAuthContext from 'contexts/AppAuthContext';
import { Navigate } from 'react-router-dom';

const HomePage: React.FC = () => {
  const { isAuthenticated, isTried } = useContext(AppAuthContext);

  return isTried ? (
    <Navigate to={isAuthenticated ? '/account' : '/auth'} />
  ) : null;
};

export default HomePage;
