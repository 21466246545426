import React from 'react';
import classNames from 'classnames';

import Heading from 'components/atoms/Heading';
import Stack from 'components/atoms/Stack';
import Text from 'components/atoms/Text';
import styles from './ProcessingMessage.module.css';

export interface ProcessingMessageProps {
  icon: JSX.Element;
  title: string;
  description: string;
  className?: string;
}

const ProcessingMessage: React.FC<ProcessingMessageProps> = ({
  title,
  description,
  icon: Icon,
  className,
}) => (
  <Stack
    dir="column"
    gap="4"
    align="center"
    className={classNames(styles.root, className)}
  >
    {React.cloneElement(Icon, { size: '2xl', className: styles.icon })}

    <Heading tag="p" fontSize="2xl">
      {title}
    </Heading>

    <Text>{description}</Text>
  </Stack>
);

export default ProcessingMessage;
