import { isSortParam } from 'utils/isSortParam';
import { encodeSortQuery } from 'utils/encodeSortQuery';

export const serializeParams = (params: any) => {
  const searchParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    const param = params[key];

    if (param) {
      if (Array.isArray(param)) {
        searchParams.append(key, param.join(','));
      } else if (isSortParam(param)) {
        searchParams.append(key, encodeSortQuery(param));
      } else {
        searchParams.append(key, param);
      }
    }
  });

  return searchParams.toString();
};
