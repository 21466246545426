import React from 'react';

const FanCurrencyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
      fill="#7554F9"
    />
    <path
      d="M14.4856 7.94551C14.3552 7.84697 14.2144 7.76313 14.0656 7.69551C14.1782 7.3744 14.2324 7.03572 14.2256 6.69551C14.2256 5.88551 13.7356 4.84549 11.6856 4.45549C10.7433 4.27246 9.77116 4.31475 8.84836 4.5789C7.92556 4.84306 7.07826 5.32161 6.37558 5.97551C5.77953 6.56838 5.32927 7.29155 5.0603 8.08806C4.79133 8.88457 4.71099 9.73266 4.82559 10.5655C5.07559 13.0355 7.62559 15.6555 11.8256 15.6555H12.4056H13.1056L12.8356 14.9855C12.1256 13.1955 13.1056 12.2055 14.0856 11.2255C14.6956 10.6155 15.2256 10.0855 15.2256 9.3755C15.2209 9.09265 15.149 8.81498 15.0156 8.56551C14.8987 8.31441 14.7154 8.10003 14.4856 7.94551ZM11.7556 14.6555C9.05558 14.5755 6.12557 13.1255 5.86557 10.4955C5.76785 9.81933 5.83006 9.12967 6.04715 8.48189C6.26423 7.83411 6.63015 7.24627 7.11557 6.76552C8.03314 5.91649 9.23549 5.44196 10.4856 5.4355H10.5856C10.9207 5.43043 11.2555 5.45723 11.5856 5.51552C12.7156 5.72552 13.2956 6.14552 13.3056 6.76552C13.3049 6.97372 13.2676 7.18017 13.1956 7.3755C12.0144 7.12839 10.7899 7.18343 9.63559 7.53551C9.095 7.67936 8.63174 8.02825 8.34421 8.5081C8.05667 8.98795 7.96749 9.56098 8.09558 10.1055C8.16664 10.3363 8.28368 10.5503 8.43963 10.7347C8.59559 10.9191 8.78723 11.07 9.00305 11.1784C9.21886 11.2868 9.45437 11.3504 9.6954 11.3653C9.93644 11.3803 10.178 11.3464 10.4056 11.2655C11.7633 10.7568 12.9183 9.81944 13.6956 8.59551L13.9956 8.7755C14.0937 8.8318 14.1735 8.91513 14.2256 9.01552C14.2828 9.11681 14.317 9.22948 14.3256 9.34551C14.3256 9.63551 13.8756 10.0855 13.4656 10.4955C12.4656 11.4055 11.2256 12.6455 11.7556 14.6555ZM10.1356 10.2655C9.44559 10.4355 9.13558 10.1255 9.00558 9.81551C8.87558 9.50551 9.1256 8.81552 9.9256 8.51552C10.4373 8.35701 10.9699 8.27609 11.5056 8.2755H11.7556C12.0298 8.28057 12.3036 8.3006 12.5756 8.3355C11.9482 9.20464 11.0837 9.87471 10.0856 10.2655H10.1356Z"
      fill="white"
    />
  </svg>
);

export default FanCurrencyIcon;
