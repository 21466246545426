import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import Box, { BoxProps } from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import styles from './Separator.module.css';

export interface SeparatorProps extends HTMLAttributes<HTMLElement> {
  spacing?: BoxProps['py'];
  label?: string;
}

const Separator: React.FC<SeparatorProps> = ({
  spacing = '8',
  label,
  className,
}) => (
  <Box
    py={spacing}
    data-testid="separator"
    className={classNames(className, styles.root)}
  >
    {label && (
      <Text fontSize="sm" color="gray.400" className={styles.label}>
        {label}
      </Text>
    )}
  </Box>
);

export default Separator;
