import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  Icon,
  Panel,
  SnackbarContext,
  Toolbar,
} from '@fanadise/common-ui';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { ArrowSmLeftIcon } from '@heroicons/react/solid';
import EditCategoryForm from 'components/categories/EditCategoryForm';
import { Category } from '@fanadise/common-types';
import { Translate, useTranslation } from '@fanadise/common-logic';
import { categoriesApi } from '@fanadise/common-data-access';

const CategoriesRouteEdit: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const { translate } = useTranslation();
  const { addErrorAlert } = useContext(SnackbarContext)!;
  const [category, setCategory] = useState<Category>();

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        setCategory(await categoriesApi.fetchCategory(id));
      } catch (err: any) {
        addErrorAlert(err.message || translate('error:default'));
        navigate('/account/categories', { replace: true });
      }
    };

    fetchCategory();
  }, [id]);

  return (
    <>
      <Toolbar
        title={<Translate id="categories:edit:title" />}
        leading={
          <Link to="/account/categories">
            <Button
              tag="span"
              variant="icon"
              icon={<Icon icon={ArrowSmLeftIcon} size="lg" />}
            />
          </Link>
        }
        mb="8"
      />

      {category && (
        <Panel>
          <EditCategoryForm category={category} onChange={setCategory} />
        </Panel>
      )}
    </>
  );
};

export default CategoriesRouteEdit;
