import React, { Context, useCallback, useContext } from 'react';
import { profileApi } from '@fanadise/common-data-access';
import {
  Avatar,
  SnackbarContext,
  ConnectedLoading,
  ConnectedSubmit,
  ConnectedInput,
  ConnectedFormItem,
  ConnectedFileInput,
  Stack,
  Box,
  Text,
  ImageWrapper,
  Ratio,
} from '@fanadise/common-ui';
import { Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { AuthContextValue } from 'contexts/AuthContext';
import { EmailVerificationContextValue } from 'contexts/EmailVerificationContext';
import { User } from '@fanadise/common-types';
import useTranslation from 'hooks/useTranslation';
import UsernameField from 'components/profile/UsernameField';
import VerifyEmail from 'components/profile/VerifyEmail';
import { DEFAULT_BACKGROUND_URL } from '@fanadise/common-consts';

export interface ProfileSettingsFormProps<T extends User = User> {
  authContext: Context<AuthContextValue<T>>;
  emailVerificationContext: Context<EmailVerificationContextValue>;
  onSaved?: (user: T) => void;
}

interface ProfileSettingsFormValues {
  username: string;
  email: string;
  bio?: string;
  isUsernameUnique: boolean;
}

const ProfileSettingsForm: React.FC<ProfileSettingsFormProps> = ({
  authContext,
  emailVerificationContext,
  onSaved,
}) => {
  const { addSuccessAlert, addErrorAlert } = useContext(SnackbarContext)!;
  const { user, updateUser } = useContext(authContext);
  const { translate } = useTranslation();

  const initialValues: ProfileSettingsFormValues = {
    username: user!.username,
    email: user!.email,
    bio: user!.bio,
    isUsernameUnique: true,
  };

  const validationSchema = yup.object().shape({
    username: yup
      .string()
      .required(translate('validation:required'))
      .matches(/^[a-z0-9-_]*$/i, translate('validation:invalid'))
      .matches(/^(?!.*(__|--))/, translate('validation:invalid'))
      .test(
        'is-busy',
        translate('validation:usernameTaken'),
        (_, ctx) => ctx.parent.isUsernameUnique,
      )
      .max(64, translate('validation:invalid')),
    bio: yup.string().max(256, translate('validation:invalid')),
  });

  const handleSubmit = useCallback(
    async (values, { setSubmitting, setFieldValue }: FormikHelpers<any>) => {
      try {
        let updatedUser = await profileApi.updateProfile({
          username: values.username,
          bio: values.bio,
        });

        if (values.avatarFile) {
          const data = new FormData();
          data.append('avatar', values.avatarFile);
          updatedUser = await profileApi.uploadProfileAvatar(data);
        }

        if (values.backgroundFile) {
          const data = new FormData();
          data.append('file', values.backgroundFile);
          updatedUser = await profileApi.uploadProfileBackground(data);
        }

        updateUser(updatedUser);
        addSuccessAlert(translate('success:saved'));

        setFieldValue('avatarFile', null);
        setFieldValue('backgroundFile', null);

        onSaved?.(updatedUser);
      } catch (err: any) {
        if (err.statusCode === 409) {
          setFieldValue('isUsernameUnique', false);
        } else {
          addErrorAlert(err.message || translate('error:default'));
        }

        setSubmitting(false);
      }
    },
    [translate, updateUser, addSuccessAlert, addErrorAlert],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <ConnectedLoading>
          <Form noValidate>
            <ConnectedFormItem fieldId="avatarFile" fieldName="avatarFile">
              <Stack gap="1" mb="2">
                <Text>{translate('common:avatar')}</Text>
                <Text fontSize="sm">
                  {translate('profileSettings:avatarHint')}
                </Text>
              </Stack>

              <Stack dir="row" gap="4" align="center">
                <Avatar size="xl" url={user!.avatarUrl} />
                <div>
                  <ConnectedFileInput
                    name="avatarFile"
                    size="sm"
                    accept="image/png, image/jpeg, image/gif"
                    label={translate('common:selectFile')}
                  />
                </div>
              </Stack>
            </ConnectedFormItem>

            <ConnectedFormItem
              fieldId="backgroundFile"
              fieldName="backgroundFile"
            >
              <Stack gap="1" mb="2">
                <Text>{translate('common:background')}</Text>
                <Text fontSize="sm">
                  {translate('profileSettings:backgroundHint')}
                </Text>
              </Stack>

              <Stack dir="row" gap="4" align="center">
                <Box w="full" maxW="64" borderRadius="md" overflow="hidden">
                  <Ratio ratio={4}>
                    <ImageWrapper
                      src={user?.backgroundUrl || DEFAULT_BACKGROUND_URL}
                      fit="cover"
                    />
                  </Ratio>
                </Box>

                <div>
                  <ConnectedFileInput
                    name="backgroundFile"
                    size="sm"
                    accept="image/png, image/jpeg, image/gif"
                    label={translate('common:selectFile')}
                  />
                </div>
              </Stack>
            </ConnectedFormItem>

            <ConnectedFormItem
              fieldId="username"
              fieldName="username"
              label={translate('common:username')}
              isRequired
            >
              <UsernameField name="username" currentUsername={user!.username} />
            </ConnectedFormItem>

            <ConnectedFormItem
              fieldId="bio"
              fieldName="bio"
              label={`${translate('common:bio')} (${translate(
                'common:optional',
              )})`}
              hint={`${values?.bio?.length || 0} / 256`}
            >
              <ConnectedInput type="textarea" name="bio" rows={3} />
            </ConnectedFormItem>

            <ConnectedFormItem
              fieldId="email"
              fieldName="email"
              label={translate('common:email')}
              isRequired
            >
              <ConnectedInput name="email" isDisabled />
            </ConnectedFormItem>

            {!user!.emailVerifiedAt && (
              <Box mt="-4" mb="4">
                <VerifyEmail
                  emailVerificationContext={emailVerificationContext}
                />
              </Box>
            )}

            <Box textAlign="right">
              <ConnectedSubmit>{translate('common:save')}</ConnectedSubmit>
            </Box>
          </Form>
        </ConnectedLoading>
      )}
    </Formik>
  );
};

export default ProfileSettingsForm;
