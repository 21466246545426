import React from 'react';
import { Form } from 'formik';
import Button from 'components/atoms/Button';
import Dialog, { DialogProps } from 'components/templates/Dialog';
import ConnectedSubmit from 'components/force/ConnectedSubmit';
import Stack from 'components/atoms/Stack';
import Separator from 'components/atoms/Separator';

export interface ConnectedFormDialogProps extends DialogProps {
  title?: string;
  submitLabel?: string;
  cancelLabel?: string;
  onRequestClose: () => void;
}

const ConnectedFormDialog: React.FC<ConnectedFormDialogProps> = ({
  children,
  submitLabel,
  cancelLabel,
  onRequestClose,
  ...rest
}) => (
  <Dialog size="sm" onRequestClose={onRequestClose} {...rest}>
    <Form noValidate>
      {children}

      <Separator spacing="none" />

      <Stack mt="6" dir="row" align="center" justify="flex-end">
        <Button type="button" variant="text" onClick={onRequestClose}>
          {cancelLabel}
        </Button>

        <ConnectedSubmit>{submitLabel}</ConnectedSubmit>
      </Stack>
    </Form>
  </Dialog>
);

export default ConnectedFormDialog;
