import React from 'react';

import Button, { ButtonProps } from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import { ThumbUpIcon } from '@heroicons/react/solid';
import { ThumbUpIcon as ThumbUpOutlineIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { abbreviateNumber } from '@fanadise/common-utils';

import styles from './LikeButton.module.css';

export interface LikeButtonProps
  extends Omit<
    ButtonProps,
    'icon' | 'variant' | 'fixedIcon' | 'type' | 'children'
  > {
  variant?: 'transparent' | 'filled';
  likesCount: number;
  isLiked?: boolean;
}

const LikeButton: React.FC<LikeButtonProps> = ({
  likesCount,
  variant = 'transparent',
  isLiked = false,
  className,
  ...rest
}) => (
  <Button
    className={classNames(
      styles.root,
      styles[`variant--${variant}`],
      {
        [styles.isLiked]: isLiked,
      },
      className,
    )}
    color="primary"
    variant="text"
    {...rest}
  >
    <span className={styles.likesCount}>
      {abbreviateNumber(likesCount || 0)}
    </span>
    <Icon icon={isLiked ? ThumbUpIcon : ThumbUpOutlineIcon} />
  </Button>
);

export default LikeButton;
