/* eslint-disable no-bitwise */

export const generateObjectId = () => {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);

  return (
    timestamp +
    new Array(16)
      .fill(null)
      .map(() => ((Math.random() * 16) | 0).toString(16))
      .join('')
      .toLowerCase()
  );
};
