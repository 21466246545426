import React from 'react';
import { BasePurchaseWithUser } from '@fanadise/common-types';
import { purchasesApi } from '@fanadise/common-data-access';
import { Translate, useTranslation } from '@fanadise/common-logic';
import ResourcesList from 'components/shared/ResourcesList';
import {
  MenuItem,
  Icon,
  CopyableText,
  Box,
  Text,
  Badge,
  Anchor,
  Pop,
  FiatPrice,
} from '@fanadise/common-ui';
import { ArrowSmRightIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

const PurchasesList: React.FC = () => {
  const { translate } = useTranslation();

  return (
    <ResourcesList<BasePurchaseWithUser, 'purchases'>
      dataKey="purchases"
      fetchData={purchasesApi.fetchPurchases}
      sortedProps={['createdAt']}
      columns={[
        {
          prop: 'id',
          label: translate('common:id'),
          renderCell: ({ id }) => (
            <CopyableText
              maxW="16"
              text={id}
              tooltipText={translate('common:copy')}
              copiedMessage={translate('common:copied')}
            />
          ),
        },
        {
          prop: 'description',
          label: translate('purchases:description'),
          renderCell: ({ description }) => (
            <Box maxW="60">
              <Text color="gray.800" fontWeight="semibold" isTruncated>
                {description}
              </Text>
            </Box>
          ),
        },
        {
          prop: 'contentType',
          label: translate('purchases:content'),
          renderCell: ({ contentType }) => (
            <Badge variant="secondary">
              <Translate id={`common:purchaseContentType:${contentType}`} />
            </Badge>
          ),
        },
        {
          prop: 'accountId',
          label: translate('purchases:account'),
          renderCell: ({ accountId }) => (
            <CopyableText
              maxW="20"
              text={accountId}
              tooltipText={translate('common:copy')}
              copiedMessage={translate('common:copied')}
            />
          ),
        },
        {
          prop: 'userId',
          label: translate('purchases:username'),
          renderCell: ({ userId, user }) => (
            <Pop trigger={<span>@{user?.username}</span>}>
              <Box p="2">
                #{userId}
                <br />
                <a href={`mailto:${user.email}`}>
                  <Anchor>{user.email}</Anchor>
                </a>
              </Box>
            </Pop>
          ),
        },
        {
          prop: 'paid',
          label: translate('common:status'),
          renderCell: ({ paid }) =>
            paid ? (
              <Badge variant="primary">
                <Translate id="common:purchaseStatus:paid" />
              </Badge>
            ) : (
              <Badge variant="secondary">
                <Translate id="common:purchaseStatus:unpaid" />
              </Badge>
            ),
        },
        {
          prop: 'price',
          label: translate('purchases:price'),
          renderCell: ({ price, currency }) => (
            <Text fontWeight="semibold" color="gray.800">
              <FiatPrice value={price} currency={currency} />
            </Text>
          ),
        },
        {
          prop: 'createdAt',
          label: translate('purchases:createdAt'),
          renderCell: ({ createdAt }) => (
            <time dateTime={new Date(createdAt).toISOString()}>
              {new Date(createdAt).toLocaleDateString()}
            </time>
          ),
        },
      ]}
      renderMenu={(item) => (
        <>
          <Link to={`/account/purchases/${item.id}`}>
            <MenuItem
              tag="span"
              icon={<Icon icon={ArrowSmRightIcon} size="sm" />}
            >
              {translate('common:view')}
            </MenuItem>
          </Link>
        </>
      )}
    />
  );
};

export default PurchasesList;
