import { Locale } from '@fanadise/common-consts';
import { DEFAULT_TRANSLATIONS } from 'consts/defaultTranslations';

export const mergeTranslations = (
  translations: Record<string, string>,
  locale: Locale,
): Required<Record<string, string>> => ({
  ...DEFAULT_TRANSLATIONS[locale],
  ...translations,
});
