import React, { MouseEventHandler } from 'react';
import classNames from 'classnames';
import CloseButton from 'components/atoms/CloseButton';

import styles from './Chip.module.css';

export interface ChipProps {
  tag?: 'button' | 'span';
  size?: 'md';
  variant?: 'primary';
  imageUrl?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  isClosable?: boolean;
  onClose?: MouseEventHandler;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const Chip: React.FC<ChipProps> = ({
  tag: Tag = 'span',
  size = 'md',
  variant = 'primary',
  imageUrl,
  isActive = false,
  isDisabled = false,
  isClosable,
  onClose,
  children,
  className,
  ...rest
}) => (
  <Tag
    data-testid="chip"
    className={classNames(
      styles.root,
      styles[`variant--${variant}`],
      styles[`size--${size}`],
      className,
      {
        [styles.isActive]: isActive,
        [styles.isDisabled]: isDisabled,
      },
    )}
    {...rest}
  >
    {imageUrl && (
      <div
        style={{ backgroundImage: `url(${imageUrl})` }}
        className={styles.image}
      />
    )}
    {children}
    {isClosable && (
      <CloseButton
        onClick={onClose}
        className={styles.closeButton}
        size={size}
      />
    )}
  </Tag>
);

export default Chip;
