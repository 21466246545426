import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import styles from './Resizable.module.css';

export interface ResizableProps {
  speed?: number;
  className?: string;
}

const Resizable: React.FC<ResizableProps> = ({
  speed = 600,
  className,
  children,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number | undefined>();
  const duration = height ? height / speed : undefined;

  useEffect(() => {
    setHeight(ref.current!.clientHeight);
  }, [children]);

  return (
    <div
      className={classNames(styles.root, className)}
      style={{
        transitionDuration: duration ? `${duration}s` : undefined,
        height,
      }}
    >
      <div ref={ref}>{children}</div>
    </div>
  );
};

export default Resizable;
