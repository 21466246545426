import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import { ClipboardCopyIcon } from '@heroicons/react/outline';
import React, { useCallback } from 'react';

import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Input, { InputProps } from 'components/atoms/Input';
import styles from './CopyInput.module.css';

export interface CopyInputProps
  extends Omit<
    InputProps,
    'type' | 'step' | 'min' | 'max' | 'rows' | 'autoComplete'
  > {
  onCopied?: () => void;
}

const CopyInput: React.FC<CopyInputProps> = ({
  value,
  className,
  onCopied,
  ...rest
}) => {
  const handleCopyClick = useCallback(() => {
    copy(value as string);
    onCopied?.();
  }, []);

  return (
    <div data-testid="copyInput" className={classNames(styles.root, className)}>
      <Input value={value} isReadOnly className={styles.input} {...rest} />

      <Button
        variant="icon"
        icon={<Icon icon={ClipboardCopyIcon} />}
        onClick={handleCopyClick}
        className={styles.button}
      />
    </div>
  );
};

export default CopyInput;
