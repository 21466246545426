import { Context, useContext, useEffect, useState } from 'react';
import erc20Abi from 'abis/erc20.abi.json';
import { WalletAsset } from '@fanadise/common-types';
import { Contract, ContractInterface } from 'ethers';
import useEvmNetwork from 'hooks/useEvmNetwork';
import { WalletContextValue } from 'contexts/WalletContext';

const useWalletAssets = (
  context: Context<WalletContextValue>,
): [WalletAsset[], boolean] => {
  const { isReady, address, library } = useContext(context);
  const [assets, setAssets] = useState<WalletAsset[]>();
  const { network, token } = useEvmNetwork();

  useEffect(() => {
    if (!isReady) {
      return;
    }

    const getAssets = async () => {
      const nativeCurrencyBalance = await library.getBalance(address!);
      const nativeCurrencySymbol = network.nativeCurrency.symbol;

      const fanadiseContract = new Contract(
        token.address,
        erc20Abi as ContractInterface,
        library,
      );

      const fanadiseBalance = await fanadiseContract.balanceOf(address);
      const fanadiseSymbol = token.symbol;

      setAssets([
        {
          balance: nativeCurrencyBalance,
          symbol: nativeCurrencySymbol,
        },
        {
          balance: fanadiseBalance,
          symbol: fanadiseSymbol,
        },
      ]);
    };

    getAssets();
  }, [isReady, token, network.nativeCurrency.symbol]);

  return [assets!, !!assets];
};

export default useWalletAssets;
