import { SnackbarAlertOptions } from 'components/molecules/Snackbar/types/snackbarAlertOptions';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import styles from './Snackbar.module.css';
import SnackbarAlert from './SnackbarAlert';

export interface SnackbarProps {
  alerts: SnackbarAlertOptions[];
  onAlertClose: (id: SnackbarAlertOptions['id']) => void;
  autoCloseDuration?: number;
}

const Snackbar: React.FC<SnackbarProps> = ({
  alerts,
  onAlertClose,
  autoCloseDuration = 4000,
}) => {
  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const element = document.createElement('div');
    element.className = 'SnackbarPortal';
    document.body.appendChild(element);

    setPortalElement(element);
  }, []);

  if (!portalElement) {
    return null;
  }

  return ReactDOM.createPortal(
    <ul data-testid="snackbar" className={styles.root}>
      {alerts.map((options) => (
        <SnackbarAlert
          key={options.id}
          options={options}
          onClose={onAlertClose}
          autoCloseDuration={autoCloseDuration}
        />
      ))}
    </ul>,
    portalElement,
  );
};

export default Snackbar;
