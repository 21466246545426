import React from 'react';
import classNames from 'classnames';
import SidebarTab from './SidebarTab';

import styles from './Sidebar.module.css';

export interface SidebarProps {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
}

const Sidebar: React.FC<SidebarProps> = ({
  header,
  footer,
  children,
  className,
}) => (
  <div data-testid="sidebar" className={classNames(styles.root, className)}>
    <div className={styles.header}>{header}</div>

    <div>{children}</div>

    <div className={styles.footer}>{footer}</div>
  </div>
);

export default Sidebar;
export { SidebarTab };
