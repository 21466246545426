import React from 'react';

const BnbCurrencyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 10C0 4.47715 4.47715 0 10 0V0C15.5228 0 20 4.47715 20 10V10C20 15.5228 15.5228 20 10 20V20C4.47715 20 0 15.5228 0 10V10Z"
      fill="#FAB224"
    />
    <path
      d="M7.66949 9.0425L9.99998 6.712L12.3316 9.0435L13.6876 7.6875L9.99998 4L6.31359 7.6864L7.66954 9.0424L7.66949 9.0425ZM4 10L5.35604 8.64376L6.71199 9.99971L5.35595 11.3558L4 10ZM7.66949 10.9576L9.99998 13.288L12.3315 10.9565L13.6882 12.3118L13.6876 12.3125L9.99998 16L6.31359 12.3136L6.31167 12.3117L7.66963 10.9575L7.66949 10.9576ZM13.288 10.0006L14.644 8.64453L16 10.0005L14.644 11.3565L13.288 10.0006Z"
      fill="white"
    />
    <path
      d="M11.3755 9.99904H11.3761L10.0002 8.62305L8.98325 9.63976L8.86641 9.75664L8.62546 9.99765L8.62354 9.99952L8.62546 10.0015L10.0002 11.3765L11.3762 10.0005L11.3769 9.99972L11.3756 9.99904"
      fill="white"
    />
  </svg>
);

export default BnbCurrencyIcon;
