import React, { MouseEventHandler } from 'react';
import classNames from 'classnames';
import { IconProps } from 'components/atoms/Icon';

import styles from './Badge.module.css';

export interface BadgeProps {
  tag?: keyof JSX.IntrinsicElements;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'frosted';
  icon?: React.ReactElement<IconProps>;
  onClick?: MouseEventHandler;
  className?: string;
}

const Badge: React.FC<BadgeProps> = ({
  tag: Tag = 'span',
  size = 'md',
  variant = 'primary',
  icon,
  children,
  className,
  ...rest
}) => (
  <Tag
    data-testid="badge"
    className={classNames(
      styles.root,
      styles[`variant--${variant}`],
      styles[`size--${size}`],
      className,
    )}
    {...rest}
  >
    <span className={styles.wrap}>
      {icon &&
        React.cloneElement(icon, {
          className: classNames(styles.icon, icon.props.className),
        })}
      {children && <span className={styles.label}>{children}</span>}
    </span>
  </Tag>
);

export default Badge;
