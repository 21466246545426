import classNames from 'classnames';
import { SearchIcon } from '@heroicons/react/outline';
import React, { HTMLAttributes } from 'react';

import styles from './EmptyStateMessage.module.css';

export interface EmptyStateMessageProps extends HTMLAttributes<HTMLDivElement> {
  message: string | React.ReactNode;
}

const EmptyStateMessage: React.FC<EmptyStateMessageProps> = ({
  message,
  className,
  ...rest
}) => (
  <div
    data-testid="emptyStateMessage"
    className={classNames(styles.root, className)}
    {...rest}
  >
    <SearchIcon />
    <p>{message}</p>
  </div>
);

export default EmptyStateMessage;
