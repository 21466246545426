import React from 'react';

export interface AuthContextValue<User> {
  user?: User;
  isTried: boolean;
  isAuthenticated: boolean;
  setUser: (user: User) => void;
  updateUser: (data: Partial<User>) => void;
  signOut: () => void;
}

export const createAuthContext = <User extends object>() =>
  React.createContext<AuthContextValue<User>>(
    // @ts-ignore
    null,
  );
