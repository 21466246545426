import { Formik } from 'formik';
import React, { useContext } from 'react';
import { BaseNft, BaseNftListing } from '@fanadise/common-types';
import * as yup from 'yup';
import {
  Text,
  SnackbarContext,
  ConnectedFormDialog,
  ConnectedFiatPriceInput,
  ConnectedFormItem,
  Box,
  ConnectedToggleSwitch,
} from '@fanadise/common-ui';
import { useTranslation } from '@fanadise/common-logic';
import { Currency } from '@fanadise/common-consts';
import ConnectedSmartSelect from 'components/shared/ConnectedSmartSelect';
import { nftListingsApi } from '@fanadise/common-data-access';
import NftConfigTile from 'components/shared/NftConfigTile';

interface ListNftFormValues {
  nftId: string;
  price: number;
  currency: Currency;
  active: boolean;
}

interface ListNftModalProps {
  nft: BaseNft;
  onClose: () => void;
  onSuccess: (nftListing: BaseNftListing) => void;
}

const ListNftModal: React.FC<ListNftModalProps> = ({
  nft,
  onClose,
  onSuccess,
}) => {
  const { addSuccessAlert, addErrorAlert } = useContext(SnackbarContext)!;
  const { translate } = useTranslation();

  const initialValues: ListNftFormValues = {
    nftId: nft.id,
    price: 0,
    currency: Currency.Usd,
    active: true,
  };

  const validationSchema = yup.object().shape({
    nftId: yup.string().required(translate('validation:required')),
    price: yup
      .number()
      .transform((value) => parseFloat(value) || 0)
      .min(0, translate('validation:invalid')),
    currency: yup.string().required(translate('validation:required')),
  });

  const handleSubmit = async (values: ListNftFormValues) => {
    try {
      const savedNft = await nftListingsApi.createNftListing(values);
      addSuccessAlert(translate('success:saved'));

      onSuccess(savedNft);
    } catch (err: any) {
      addErrorAlert(err.message || translate('error:default'));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <ConnectedFormDialog
        isOpen
        submitLabel={translate('common:save')}
        cancelLabel={translate('common:cancel')}
        onRequestClose={onClose}
        title={translate('nfts:createListing:title')}
      >
        <Box mb="8">
          <NftConfigTile nftConfig={nft.config}>
            <Text fontSize="xs">#{nft.tokenId}</Text>
          </NftConfigTile>
        </Box>

        <ConnectedFormItem
          fieldId="price"
          fieldName="price"
          label={translate('common:price')}
          isRequired
        >
          <ConnectedFiatPriceInput name="price" />
        </ConnectedFormItem>

        <ConnectedFormItem
          fieldId="currency"
          fieldName="currency"
          label={translate('common:currency')}
          isRequired
        >
          <ConnectedSmartSelect
            name="currency"
            options={Object.values(Currency).map((currency) => ({
              value: currency,
              label: currency.toUpperCase(),
            }))}
            isDisabled
          />
        </ConnectedFormItem>

        <ConnectedFormItem
          fieldId="active"
          fieldName="active"
          label={translate('common:active')}
        >
          <ConnectedToggleSwitch name="active" />
        </ConnectedFormItem>
      </ConnectedFormDialog>
    </Formik>
  );
};

export default ListNftModal;
