import { SnackbarAlertOptions } from 'components/molecules/Snackbar/types/snackbarAlertOptions';
import { SnackbarAlertType } from 'components/molecules/Snackbar/types/snackbarAlertType';
import React from 'react';

type SnackbarContextValue = {
  alerts: SnackbarAlertOptions[];
  addAlert: (message: string, type?: SnackbarAlertType) => void;
  addErrorAlert: (message: string) => void;
  addSuccessAlert: (message: string) => void;
  closeAlert: (id: string) => void;
};

const SnackbarContext = React.createContext<SnackbarContextValue | null>(null);

export default SnackbarContext;
