import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { IconProps } from 'components/atoms/Icon';

import styles from './Stat.module.css';

export interface StatProps {
  icon?: React.ReactElement<IconProps>;
  label: string | number | JSX.Element;
  className?: string;
}

const Stat: React.FC<StatProps> = forwardRef<HTMLDivElement, StatProps>(
  ({ icon, label, className }, ref) => (
    <div
      data-testid="stat"
      ref={ref}
      className={classNames(styles.root, className)}
    >
      {icon} <span>{label}</span>
    </div>
  ),
);

export default Stat;
