import React, { useCallback, useContext } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import {
  SnackbarContext,
  ConnectedLoading,
  ConnectedSubmit,
  ConnectedInput,
  ConnectedFormItem,
  Box,
  ConnectedFileInput,
  ImageWrapper,
  Ratio,
  Stack,
  FilePreview,
} from '@fanadise/common-ui';
import { useNavigate } from 'react-router-dom';
import { Collection } from '@fanadise/common-types';
import FormSubmitBar from 'components/shared/FormSubmitBar';
import { useTranslation } from '@fanadise/common-logic';
import { collectionsApi } from '@fanadise/common-data-access';
import FormGrid from 'components/shared/FormGrid';

interface EditCollectionFormValues {
  name: string;
  symbol: string;
  iconFile?: File;
  backgroundFile?: File;
}

export interface EditCollectionFormProps {
  collection?: Collection;
  onChange?: (collection: Collection) => void;
}

const EditCollectionForm: React.FC<EditCollectionFormProps> = ({
  collection,
  onChange,
}) => {
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const { addSuccessAlert, addErrorAlert } = useContext(SnackbarContext)!;

  const validationSchema = yup.object().shape({
    name: yup.string().required(translate('validation:required')),
    symbol: yup
      .string()
      .required(translate('validation:required'))
      .matches(/^[A-Z]{3,6}$/, translate('validation:invalid')),
  });

  const initialValues: EditCollectionFormValues = {
    name: collection?.name || '',
    symbol: collection?.symbol || '',
  };

  const handleSubmit = useCallback(
    async (
      values: EditCollectionFormValues,
      { setFieldValue }: FormikHelpers<EditCollectionFormValues>,
    ) => {
      try {
        const propsToSave = {
          name: values.name,
          symbol: values.symbol,
        };

        let savedCollection: Collection | undefined;
        let message;

        if (collection) {
          message = translate('success:saved');
        } else {
          savedCollection = await collectionsApi.createCollection(propsToSave);
          message = translate('success:added');
        }

        const collectionId = savedCollection?.id || collection?.id;

        try {
          if (values.iconFile) {
            const data = new FormData();
            data.append('file', values.iconFile);
            savedCollection = await collectionsApi.uploadCollectionIcon(
              collectionId!,
              data,
            );
            setFieldValue('iconFile', null);
          }

          if (values.backgroundFile) {
            const data = new FormData();
            data.append('file', values.backgroundFile);
            savedCollection = await collectionsApi.uploadCollectionBackground(
              collectionId!,
              data,
            );
            setFieldValue('backgroundFile', null);
          }
        } catch (err) {
          if (collection) {
            throw err;
          }
        }

        addSuccessAlert(message);

        if (!collection) {
          navigate(`/account/collections/edit/${savedCollection!.id}`, {
            replace: true,
          });
        } else if (onChange && savedCollection) {
          onChange(savedCollection);
        }
      } catch (err: any) {
        addErrorAlert(err.message || translate('error:default'));
      }
    },
    [translate, addSuccessAlert, addErrorAlert, collection?.id, onChange],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <ConnectedLoading>
        <Form noValidate>
          <FormGrid>
            <ConnectedFormItem
              fieldId="name"
              fieldName="name"
              label={translate('common:name')}
              isRequired
            >
              <ConnectedInput name="name" isDisabled={!!collection} />
            </ConnectedFormItem>

            <ConnectedFormItem
              fieldId="symbol"
              fieldName="symbol"
              label={translate('common:symbol')}
              isRequired
            >
              <ConnectedInput name="symbol" isDisabled={!!collection} />
            </ConnectedFormItem>
          </FormGrid>

          <ConnectedFormItem
            fieldId="iconFile"
            fieldName="iconFile"
            label={translate('common:icon')}
          >
            <Stack dir="row" gap="4" align="center">
              {collection?.iconUrl && <FilePreview url={collection.iconUrl} />}

              <ConnectedFileInput
                name="iconFile"
                size="sm"
                accept="image/png, image/jpeg, image/gif"
                label={translate('common:selectFile')}
              />
            </Stack>
          </ConnectedFormItem>

          <ConnectedFormItem
            fieldId="backgroundFile"
            fieldName="backgroundFile"
            label={translate('common:background')}
          >
            <Stack dir="row" gap="4" align="center">
              {collection?.backgroundUrl && (
                <Box w="full" maxW="64" borderRadius="md" overflow="hidden">
                  <Ratio ratio={4}>
                    <ImageWrapper src={collection.backgroundUrl} fit="cover" />
                  </Ratio>
                </Box>
              )}

              <ConnectedFileInput
                name="backgroundFile"
                size="sm"
                accept="image/png, image/jpeg, image/gif"
                label={translate('common:selectFile')}
              />
            </Stack>
          </ConnectedFormItem>

          <FormSubmitBar>
            <ConnectedSubmit>{translate('common:save')}</ConnectedSubmit>
          </FormSubmitBar>
        </Form>
      </ConnectedLoading>
    </Formik>
  );
};

export default EditCollectionForm;
