import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  Icon,
  SnackbarContext,
  Toolbar,
  Panel,
  Box,
} from '@fanadise/common-ui';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { ArrowSmLeftIcon } from '@heroicons/react/solid';
import EditUserForm from 'components/users/EditUserForm';
import { User } from '@fanadise/common-types';
import { Translate, useTranslation } from '@fanadise/common-logic';
import { usersApi } from '@fanadise/common-data-access';
import UserNftsList from 'components/users/UserNftsList';
import FormSectionTitle from 'components/shared/FormSectionTitle';
import AssignUserForm from 'components/users/AssignUserForm';

const UsersRouteEdit: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const { translate } = useTranslation();
  const { addErrorAlert } = useContext(SnackbarContext)!;
  const [user, setUser] = useState<User>();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setUser(await usersApi.fetchUser(id));
      } catch (err: any) {
        addErrorAlert(err.message || translate('error:default'));
        navigate('../');
      }
    };

    fetchUser();
  }, [id]);

  return (
    <>
      <Toolbar
        title={<Translate id="users:edit:title" />}
        leading={
          <Link to="../">
            <Button
              tag="span"
              variant="icon"
              icon={<Icon icon={ArrowSmLeftIcon} size="lg" />}
            />
          </Link>
        }
        mb="8"
      />

      {user && (
        <>
          <Panel mb="8">
            <FormSectionTitle>
              <Translate id="users:profile:title" />
            </FormSectionTitle>
            <EditUserForm user={user} onChange={setUser} />
          </Panel>

          <Panel>
            <FormSectionTitle>
              <Translate id="users:assign:title" />
            </FormSectionTitle>

            <Box mb="8">
              <AssignUserForm userId={user.id} />
            </Box>

            <UserNftsList userId={user.id} />
          </Panel>
        </>
      )}
    </>
  );
};

export default UsersRouteEdit;
