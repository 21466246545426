import React from 'react';
import LocaleContext from 'contexts/LocaleContext';
import type { Locale } from '@fanadise/common-consts';

export interface LocaleProviderProps {
  locale: Locale;
}

const LocaleProvider: React.FC<LocaleProviderProps> = ({
  locale,
  children,
}) => (
  <LocaleContext.Provider value={locale}>{children}</LocaleContext.Provider>
);

export default LocaleProvider;
