import React from 'react';
import classNames from 'classnames';

import { IconProps } from 'components/atoms/Icon';

import styles from './SidebarTab.module.css';

export interface SidebarTabProps {
  tag?: 'button' | 'span';
  variant?: 'main' | 'sub';
  icon?: React.ReactElement<IconProps>;
  hint?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  className?: string;
}

const SidebarTab: React.FC<SidebarTabProps> = ({
  icon,
  tag: Tag = 'button',
  variant = 'main',
  hint,
  isActive,
  isDisabled,
  onClick,
  className,
  children,
}) => (
  <Tag
    className={classNames(
      styles.root,
      styles[`variant--${variant}`],
      className,
      {
        [styles.isActive]: isActive,
        [styles.isDisabled]: isDisabled,
      },
    )}
    onClick={onClick}
  >
    {icon &&
      React.cloneElement(icon, {
        className: classNames(styles.icon, icon.props.className),
      })}
    <div className={styles.wrap}>
      <span className={styles.label}>{children}</span>
      {hint && <span className={styles.hint}>{hint}</span>}
    </div>
  </Tag>
);

export default SidebarTab;
