import { useContext, useLayoutEffect, useState } from 'react';
import { SizeGrade } from '@fanadise/common-types';
import { createMediaQueryString } from 'utils/createMediaQueryString';
import ThemeContext from '../contexts/ThemeContext';

type Listener = { mediaQuery: MediaQueryList; handleChange: () => void };
type Breakpoints<T> = Partial<Record<SizeGrade, T>>;

export type UseBreakpointHook<T extends any> = (
  breakpoints: Breakpoints<T>,
  defaultValue: T,
) => T;

const useBreakpoint: UseBreakpointHook<any> = <T extends any>(
  breakpoints: Breakpoints<T>,
  defaultValue: T,
) => {
  const { theme } = useContext(ThemeContext);

  const [currentValue, setCurrentValue] = useState<T>(defaultValue);

  const formatedBreakpoints: [string, T][] = Object.entries(breakpoints)
    .map(([key, value]): [string, T] => {
      const minWidth = theme.size[key as SizeGrade] || key;
      return [minWidth!, value!];
    })
    .sort((a, b) =>
      Number.parseInt(a[0], 10) > Number.parseInt(b[0], 10) ? 1 : -1,
    )
    .map(([minWidth, value], index, arr) => {
      const next = arr[index + 1];
      return [createMediaQueryString(minWidth, next?.[0]), value];
    });

  useLayoutEffect(() => {
    const listeners = new Set<Listener>();

    formatedBreakpoints.forEach(([query, value]) => {
      const mediaQuery = window.matchMedia(query);

      if (mediaQuery.matches && currentValue !== value) {
        setCurrentValue(value);
      }

      const handleChange = () => {
        setCurrentValue(value);
      };

      mediaQuery.addListener(handleChange);
      listeners.add({ mediaQuery, handleChange });
      return () => {
        mediaQuery.removeListener(handleChange);
      };
    });

    return () => {
      listeners.forEach(({ mediaQuery, handleChange }) => {
        mediaQuery.removeListener(handleChange);
      });
      listeners.clear();
    };
  }, [breakpoints, currentValue, setCurrentValue]);

  return currentValue;
};

export default useBreakpoint;
