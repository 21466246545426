export const FAN_BSC_MAINNET = {
  id: 'fanadise',
  symbol: 'fan',
  address: '0xb6d48fcef36e19681ee29896b19c1b6cbd1eab1b',
  decimals: 18,
  image: 'https://files.fanadise.com/FAN.png',
};

export const FAN_BSC_TESTNET = {
  id: 'fanadise',
  symbol: 'fan',
  address: '0x938f80D79844e978bcC6D15f9034E7c04Ecb7Ff8',
  decimals: 18,
  image: 'https://files.fanadise.com/FAN.png',
};

export const FAN_POLYGON_MAINNET = {
  id: 'fanadise',
  symbol: 'fan',
  address: '0xb6d48fcef36e19681ee29896b19c1b6cbd1eab1b',
  decimals: 18,
  image: 'https://files.fanadise.com/FAN.png',
};

export const FAN_POLYGON_TESTNET = {
  id: 'fanadise',
  symbol: 'fan',
  address: '0x938f80D79844e978bcC6D15f9034E7c04Ecb7Ff8',
  decimals: 18,
  image: 'https://files.fanadise.com/FAN.png',
};
