import React, { useContext } from 'react';
import * as yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import {
  ConnectedLoading,
  ConnectedFormItem,
  ConnectedInput,
  ConnectedSubmit,
  ConnectedForm,
} from '@fanadise/common-ui';
import AppAuthContext from 'contexts/AppAuthContext';
import * as authApi from 'api/authApi';
import * as adminsApi from 'api/adminsApi';
import { useTranslation } from '@fanadise/common-logic';

// import styles from './SignInForm.module.css';

interface SignInFormValues {
  email: string;
  password: string;
}

const SignInForm: React.FC = () => {
  const { setUser } = useContext(AppAuthContext);
  const { translate } = useTranslation();

  const initialValues: SignInFormValues = {
    email: '',
    password: '',
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(translate('validation:required'))
      .email(translate('validation:invalid')),
    password: yup.string().required(translate('validation:required')),
  });

  const handleSubmit = async (
    { email, password }: SignInFormValues,
    { setStatus }: FormikHelpers<SignInFormValues>,
  ) => {
    try {
      await authApi.signIn({ email, password });
      setUser(await adminsApi.fetchAdmin());
    } catch (err: any) {
      const message = err.message || translate('error:default');
      setStatus({ errorMessages: [message] });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <ConnectedLoading>
        <ConnectedForm>
          <ConnectedFormItem
            fieldId="email"
            fieldName="email"
            label={translate('common:email')}
            isRequired
          >
            <ConnectedInput
              size="lg"
              name="email"
              type="email"
              isAutofocusable
              placeholder={translate('auth:signIn:emailPlaceholder')}
            />
          </ConnectedFormItem>

          <ConnectedFormItem
            fieldId="password"
            fieldName="password"
            label={translate('common:password')}
            isRequired
          >
            <ConnectedInput
              size="lg"
              name="password"
              type="password"
              placeholder={translate('auth:signIn:passwordPlaceholder')}
            />
          </ConnectedFormItem>

          {/* <div className={styles.forgotPassword}>
            <Link to="/auth/reset-password">
              <Translate id="auth:signIn:forgotPassword" />
            </Link>
          </div> */}

          <ConnectedSubmit size="lg">
            {translate('auth:signIn:submit')}
          </ConnectedSubmit>
        </ConnectedForm>
      </ConnectedLoading>
    </Formik>
  );
};

export default SignInForm;
