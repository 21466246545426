import classNames from 'classnames';
import React from 'react';

import styles from './Label.module.css';

export interface LabelProps {
  text: string;
  isRequired?: boolean;
  htmlFor?: string;
  className?: string;
}

const Label: React.FC<LabelProps> = ({
  text,
  isRequired,
  htmlFor,
  className,
}) => (
  <label
    data-testid="label"
    htmlFor={htmlFor}
    className={classNames(styles.root, className)}
  >
    {isRequired && <i className={styles.isRequired}>* </i>}
    {text}
  </label>
);

export default Label;
