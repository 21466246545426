import React from 'react';
import Loading from 'components/molecules/Loading';
import { useFormikContext } from 'formik';

export interface ConnectedLoadingProps {
  isLoading?: boolean;
  className?: string;
}

const ConnectedLoading: React.FC<ConnectedLoadingProps> = ({
  isLoading,
  children,
  className,
}) => {
  const { isSubmitting } = useFormikContext();

  return (
    <Loading
      isLoading={isLoading || isSubmitting}
      minH={0}
      className={className}
    >
      {children}
    </Loading>
  );
};

export default ConnectedLoading;
