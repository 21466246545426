/* eslint-disable no-restricted-syntax */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import log from 'loglevel';
import {
  Button,
  FiatPrice,
  Loading,
  SnackbarContext,
  ThemeContext,
} from '@fanadise/common-ui';
import classNames from 'classnames';
import useTranslation from 'hooks/useTranslation';
import {
  isExternalScriptAlreadyLoaded,
  loadExternalScript,
} from 'utils/extarnalScriptsUtils';
import { Currency } from '@fanadise/common-consts';
import useLocale from 'hooks/useLocale';
import { isEnvFlagOn } from '@fanadise/common-utils';

import styles from './PayUPaymentMethodForm.module.css';

const PAYU_SCRIPT_ID = 'payu-script';
const PAYU_PRODUCTION_SCRIPT_SRC = 'https://secure.payu.com/javascript/sdk';
const PAYU_SANDBOX_SCRIPT_SRC = 'https://secure.snd.payu.com/javascript/sdk';
const PAYU_CARD_CONTAINER_ID = 'payu-card';

export interface PayUPaymentMethodFormProps {
  payuPosId: string;
  price: string;
  currency: Currency;
  onSuccess: (purchaseId: string) => void;
  makePurchasePromise: (data: {
    token: string;
    mask: string;
  }) => Promise<{ id: string; redirectUrl?: string }>;
  className?: string;
}

const PayUPaymentMethodForm: React.FC<PayUPaymentMethodFormProps> = ({
  payuPosId,
  price,
  currency,
  onSuccess,
  makePurchasePromise,
  className,
}) => {
  const { translate } = useTranslation();
  const locale = useLocale();
  const { addErrorAlert } = useContext(SnackbarContext)!;
  const { theme } = useContext(ThemeContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [payu, setPayu] = useState<payu.PayU | null>(null);

  const isFiatPaymentOn = isEnvFlagOn(process.env.NEXT_PUBLIC_FIAT_PAYMENT);

  const isLoading = !payu;

  useEffect(() => {
    const loadSdk = async () => {
      try {
        const isSandbox = isEnvFlagOn(process.env.NEXT_PUBLIC_PAYU_SANDBOX);
        const scriptSrc = isSandbox
          ? PAYU_SANDBOX_SCRIPT_SRC
          : PAYU_PRODUCTION_SCRIPT_SRC;

        if (!isExternalScriptAlreadyLoaded(PAYU_SCRIPT_ID)) {
          await loadExternalScript(PAYU_SCRIPT_ID, scriptSrc);
        }

        const payuEntry = PayU(payuPosId, {
          dev: isSandbox,
        });
        const secureForms = payuEntry.secureForms();

        const card = secureForms.add('card', {
          lang: locale,
          style: {
            basic: {
              fontSize: '14px',
              fontColor: theme.color.gray['800'],
              fontFamily: theme.fontFamily.base,
            },
            invalid: {
              fontColor: theme.color.error['500'],
            },
            placeholder: {
              fontColor: theme.color.gray['400'],
            },
          },
        });

        const container = document.getElementById(PAYU_CARD_CONTAINER_ID);
        if (container) {
          container.innerHTML = '';
        }

        card.render(`#${PAYU_CARD_CONTAINER_ID}`);
        card.on('ready', () => {
          setPayu(payuEntry);
        });
      } catch (err) {
        log.error(err);
        addErrorAlert(translate('error:default'));
      }
    };

    loadSdk();
  }, [payuPosId]);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (isLoading || isSubmitting) {
        return;
      }

      setIsSubmitting(true);

      try {
        const result = await payu.tokenize('SINGLE');

        if (result.status === 'SUCCESS') {
          const { id, redirectUrl } = await makePurchasePromise(result.body);

          if (redirectUrl) {
            window.location.href = redirectUrl;
          } else {
            onSuccess(id);
          }
        } else if (result.status === 'ERROR') {
          for (const { message } of result.error.messages) {
            addErrorAlert(message);
          }
          setIsSubmitting(false);
        }
      } catch (err) {
        log.error(err);
        addErrorAlert(translate('error:default'));
        setIsSubmitting(false);
      }
    },
    [payu, makePurchasePromise, onSuccess, addErrorAlert, translate],
  );

  return (
    <Loading isLoading={isSubmitting} minH={0}>
      <form
        onSubmit={handleSubmit}
        noValidate
        className={classNames(styles.root, className)}
      >
        <Loading isLoading={isLoading} minH={0}>
          <div id={PAYU_CARD_CONTAINER_ID} className={styles.cardElement} />
        </Loading>

        <Button
          type="submit"
          size="lg"
          className={styles.submitButton}
          isDisabled={!isFiatPaymentOn || isLoading || isSubmitting}
          isLoading={isSubmitting}
        >
          {translate('common:payWithCard')}{' '}
          <FiatPrice value={price} currency={currency} />
        </Button>
      </form>
    </Loading>
  );
};

export default PayUPaymentMethodForm;
