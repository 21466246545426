import { BaseNftConfig } from '@fanadise/common-types';
import { Stack, Box, FilePreview, Text } from '@fanadise/common-ui';
import React from 'react';

type Props = {
  nftConfig: BaseNftConfig;
};

const NftConfigTile: React.FC<Props> = ({ nftConfig, children }) => (
  <Stack dir="row" align="center" gap="2">
    {nftConfig.coverUrl && (
      <Box>
        <FilePreview url={nftConfig.coverUrl} size="sm" />
      </Box>
    )}
    <Box maxW="40">
      <Text fontWeight="semibold" color="gray.800" isTruncated>
        {nftConfig.name}
      </Text>
      {children}
    </Box>
  </Stack>
);

export default NftConfigTile;
