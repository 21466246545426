import React from 'react';
import classNames from 'classnames';
import Ratio from 'components/atoms/Ratio';
import Spinner from 'components/atoms/Spinner';

import styles from './MediaLightboxPanel.module.css';

export interface MediaLightboxPanelProps {
  isLoaded?: boolean;
  details?: React.ReactNode;
}

const MediaLightboxPanel: React.FC<MediaLightboxPanelProps> = ({
  isLoaded,
  details,
  children,
}) => (
  <div className={classNames(styles.root, isLoaded && styles.isLoaded)}>
    <div className={styles.media}>
      <Ratio>{children}</Ratio>
    </div>

    <div className={styles.details}>{details}</div>

    {!isLoaded && (
      <div className={styles.indicator}>
        <Spinner />
      </div>
    )}
  </div>
);

export default MediaLightboxPanel;
