import Modal from 'react-modal';
import TimeAgo from 'javascript-time-ago';
import timeAgoEnLocale from 'javascript-time-ago/locale/en.json';
import log from 'loglevel';
import env from 'config/env';
import { api } from '@fanadise/common-data-access';
import * as authApi from 'api/authApi';
import { storage } from 'lib/storage';
import { applyJwtAuthInterceptors } from '@fanadise/common-logic';

const boostrap = () => {
  Modal.setAppElement('#root');
  TimeAgo.addDefaultLocale(timeAgoEnLocale);

  if (env.isProd) {
    log.setDefaultLevel(log.levels.WARN);
  }

  api.initialize({
    baseUrl: process.env.REACT_APP_API_PREFIX,
    isBrowser: true,
  });

  applyJwtAuthInterceptors(
    api.axiosInstance,
    storage,
    (path) => !path.startsWith('/admins/auth'),
    async (refreshToken) => {
      const { token } = await authApi.refreshToken({ refreshToken });
      return token;
    },
  );
};

boostrap();
