import React, { HTMLAttributes, useEffect, useRef, useState } from 'react';
import Text from 'components/atoms/Text';
import Stack, { StackProps } from 'components/atoms/Stack';

export interface ShowMoreContentProps extends HTMLAttributes<HTMLDivElement> {
  noOfLines: number;
  showMoreLabel: string | React.ReactNode;
  gap?: StackProps['gap'];
  align?: StackProps['align'];
  className?: string;
}

const ShowMoreContent: React.FC<ShowMoreContentProps> = ({
  gap = '3',
  align = 'start',
  noOfLines,
  showMoreLabel,
  children,
  className,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const contentRef = useRef<HTMLElement | null>(null);
  const showMore = isOverflow && !isExpanded;

  useEffect(() => {
    const text = contentRef.current;
    if (text) {
      setIsOverflow(text.offsetHeight < text.scrollHeight);
    }
  });

  return (
    <Stack gap={gap} align={align} className={className}>
      <Text ref={contentRef} noOfLines={!isExpanded ? noOfLines : undefined}>
        {children}
      </Text>

      {showMore && (
        <button type="button" onClick={() => setIsExpanded(true)}>
          <Text tag="span" fontWeight="semibold" color="primary">
            {showMoreLabel}
          </Text>
        </button>
      )}
    </Stack>
  );
};

export default ShowMoreContent;
