import { api } from 'api';
import { AxiosRequestConfig } from 'axios';

export const makePayment = async (data: any, config?: AxiosRequestConfig) => {
  const res = await api.post<any>('/providers/adyen/payment', data, config);
  return res.data;
};

export const sendDetails = async (data: any, config?: AxiosRequestConfig) => {
  const res = await api.post<any>('/providers/adyen/details', data, config);
  return res.data;
};

export const fetchPaymentToken = async () => {
  const res = await api.get<{ paymentToken: string }>(
    '/providers/adyen/payment-token',
  );
  return res.data;
};

export const sendRedirectResult = async (
  data: { redirectResult: string },
  config?: AxiosRequestConfig,
) => {
  const res = await api.post('/providers/adyen/redirect-result', data, config);
  return res.data;
};

export default {
  makePayment,
  sendDetails,
  fetchPaymentToken,
  sendRedirectResult,
};
