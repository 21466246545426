/* eslint-disable no-restricted-syntax */
import React, { forwardRef } from 'react';
import Styled, { StyledProps } from 'components/atoms/Styled';

export interface BoxProps
  extends Omit<
    StyledProps,
    'color' | 'fontFamily' | 'fontSize' | 'fontWeight'
  > {}

const Box = forwardRef<HTMLElement, BoxProps>((props, ref) => (
  <Styled
    // @ts-ignore
    ref={ref}
    minW="none"
    {...props}
  />
));

export default Box;
