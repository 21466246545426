import React from 'react';
import { Routes, Route } from 'react-router-dom';

import NftListingsRouteList from './NftListingsRouteList';

const NftListingsRoute: React.FC = () => (
  <Routes>
    <Route index element={<NftListingsRouteList />} />
  </Routes>
);

export default NftListingsRoute;
