import { Theme } from '@fanadise/common-types';
import classNames from 'classnames';
import Box, { BoxProps } from 'components/atoms/Box';
import React from 'react';

import styles from './Contain.module.css';

export interface ContainProps extends BoxProps {
  size?: keyof Theme['size'];
}

const Contain: React.FC<ContainProps> = ({
  size = 'xl',
  className,
  ...rest
}) => (
  <Box
    data-testid="contain"
    ml="auto"
    mr="auto"
    w="full"
    maxW={size}
    px="4"
    className={classNames(styles.root, className)}
    {...rest}
  />
);

export default Contain;
