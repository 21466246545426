import { api } from 'api';
import {
  SingleResultDto,
  MultiResultsDto,
  CommonFile,
} from '@fanadise/common-types';
import type { PaginatedRequestQuery } from 'types/paginatedRequestQuery';
import type { PaginatedResponseData } from 'types/paginatedResponseData';

export const fetchCommonFiles = async (
  params: PaginatedRequestQuery & { sort?: string } = { page: 1, size: 30 },
) => {
  const res = await api.get<
    MultiResultsDto<'files', CommonFile> & PaginatedResponseData
  >('/common-files', { params });
  return res.data;
};

export const deleteCommonFile = async (id: string) => {
  await api.delete(`/common-files/${id}`);
};

export const uploadCommonFile = async (data: FormData) => {
  const res = await api.post<SingleResultDto<'file', CommonFile>>(
    '/common-files',
    data,
  );
  return res.data.file;
};
