import React from 'react';
import classNames from 'classnames';

import styles from './TabsNav.module.css';

export interface TabsNavProps {
  tag?: 'ul' | 'div';
  isStretched?: boolean;
  className?: string;
}

const TabsNav: React.FC<TabsNavProps> = ({
  tag: Tag = 'div',
  isStretched,
  className,
  children,
  ...rest
}) => (
  <Tag
    data-testid="tabsNav"
    className={classNames(
      styles.root,
      isStretched && styles.isStretched,
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
);

export default TabsNav;
