import { api } from 'api';
import { MultiResultsDto, Nft, SingleResultDto } from '@fanadise/common-types';
import type { PaginatedRequestQuery } from 'types/paginatedRequestQuery';
import type { PaginatedResponseData } from 'types/paginatedResponseData';
import type { NftStatus } from '@fanadise/common-consts';

export const fetchNfts = async (
  params: {
    search?: string;
    configId?: string;
    status?: NftStatus;
  } & PaginatedRequestQuery = {
    page: 1,
    size: 50,
  },
) => {
  const res = await api.get<
    MultiResultsDto<'nfts', Nft> & PaginatedResponseData
  >('/nfts', {
    params,
  });
  return res.data;
};

export const fetchNft = async (nftId: string) => {
  const res = await api.get<SingleResultDto<'nft', Nft>>(`/nfts/${nftId}`);
  return res.data.nft;
};

export const assignNftToUser = async (
  nftId: string,
  data: {
    userId: string;
  },
) => {
  const res = await api.put<SingleResultDto<'nft', Nft>>(
    `/nfts/${nftId}/assignment`,
    data,
  );
  return res.data.nft;
};

export const unassignNftFromUser = async (nftId: string) => {
  const res = await api.delete<SingleResultDto<'nft', Nft>>(
    `/nfts/${nftId}/assignment`,
  );
  return res.data.nft;
};
