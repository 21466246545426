import React from 'react';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { BaseUserWithEmail, User } from '@fanadise/common-types';
import { usersApi, authApi } from '@fanadise/common-data-access';
import {
  Button,
  IntroLink,
  ConnectedLoading,
  ConnectedMessages,
  ConnectedFormItem,
  ConnectedInput,
  Box,
  ConnectedPasswordStrengthBar,
  Icon,
} from '@fanadise/common-ui';
import Link from 'components/shared/Link';
import useTranslation from 'hooks/useTranslation';
import { LockClosedIcon, MailIcon } from '@heroicons/react/outline';

import styles from './SignUpForm.module.css';

export interface SignUpFormValues {
  email: string;
  password: string;
  score?: number;
}

export interface SignUpFormProps {
  onSuccess: (user: User) => void;
  onExistingUserFound: (user: BaseUserWithEmail) => void;
  signInHref: string;
}

const SignUpForm: React.FC<SignUpFormProps> = ({
  onSuccess,
  onExistingUserFound,
}) => {
  const { translate } = useTranslation();

  const initialValues = {
    email: '',
    password: '',
    score: 0,
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(translate('validation:required'))
      .email(translate('validation:invalid')),
    score: yup.number().moreThan(0),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async ({ email, password }, { setSubmitting, setStatus }) => {
        try {
          const [existingUser] = (await usersApi.fetchUsers({ email })).users;
          if (existingUser) {
            onExistingUserFound({
              ...existingUser,
              email,
            });
            return;
          }

          const user = await authApi.signUpWithEmailAndPassword({
            email,
            password,
          });

          onSuccess(user);
        } catch (err: any) {
          const message = err.message || translate('error:default');

          setStatus({ errorMessages: [message] });
          setSubmitting(false);
        }
      }}
    >
      <ConnectedLoading>
        <Form noValidate>
          <ConnectedMessages className={styles.formMessages} />

          <ConnectedFormItem
            fieldId="email"
            fieldName="email"
            label={translate('common:email')}
            isRequired
          >
            <ConnectedInput
              size="lg"
              name="email"
              type="email"
              icon={<Icon icon={MailIcon} />}
              placeholder={translate('auth:signUp:emailPlaceholder')}
            />
          </ConnectedFormItem>

          <ConnectedFormItem
            fieldId="password"
            fieldName="password"
            label={translate('common:password')}
            isRequired
          >
            <ConnectedInput
              size="lg"
              name="password"
              type="password"
              autoComplete="new-password"
              icon={<Icon icon={LockClosedIcon} />}
              placeholder={translate('auth:signUp:passwordPlaceholder')}
            />
          </ConnectedFormItem>

          <Box mt="-3.5" mb="4">
            <ConnectedPasswordStrengthBar
              renderLabel={(score: number, isTooShort: boolean | undefined) =>
                translate(
                  isTooShort
                    ? 'validation:password:tooShort'
                    : [
                        'validation:password:weak',
                        'validation:password:good',
                        'validation:password:strong',
                      ][score],
                )
              }
            />
          </Box>

          <Box pt="2">
            <Button type="submit" size="lg" w="full">
              {translate('auth:signUp:submit')}
            </Button>
          </Box>

          <Link href="#sign-in" passHref>
            <IntroLink
              introText={translate('auth:signUp:hasAccount:prefix')}
              linkText={translate('auth:signUp:hasAccount:suffix')}
            />
          </Link>
        </Form>
      </ConnectedLoading>
    </Formik>
  );
};

export default SignUpForm;
