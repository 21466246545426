import React from 'react';
import classNames from 'classnames';
import { Category } from '@fanadise/common-types';
import Avatar from 'components/atoms/Avatar';
import { DEFAULT_ICON_URL } from '@fanadise/common-consts';

import styles from './CategoryTile.module.css';

export interface CategoryTileProps {
  category: Category;
  size?: 'md' | 'lg';
  className?: string;
}

const CategoryTile: React.FC<CategoryTileProps> = ({
  category,
  children,
  size = 'md',
  className,
  ...rest
}) => (
  <div
    data-testid="category-tile"
    className={classNames(styles.root, styles[`size--${size}`], className)}
    {...rest}
  >
    <Avatar
      size={size === 'md' ? 'sm' : 'md'}
      url={category.iconUrl || DEFAULT_ICON_URL}
    />

    <div className={styles.wrap}>
      <p className={styles.name}>{category.name}</p>
      {children && <div className={styles.inner}>{children}</div>}
    </div>
  </div>
);

export default CategoryTile;
