import { api } from 'api';
import {
  Account,
  SingleResultDto,
  MultiResultsDto,
} from '@fanadise/common-types';
import { Locale, Currency } from '@fanadise/common-consts';
import type { PaginatedRequestQuery } from 'types/paginatedRequestQuery';
import type { PaginatedResponseData } from 'types/paginatedResponseData';

export const fetchAccounts = async (
  params?: {
    search?: string;
  } & PaginatedRequestQuery,
) => {
  const res = await api.get<
    MultiResultsDto<'accounts', Account> & PaginatedResponseData
  >('/accounts', {
    params,
  });
  return res.data;
};

export const fetchAccount = async (id: string) => {
  const res = await api.get<SingleResultDto<'account', Account>>(
    `/accounts/${id}`,
  );
  return res.data.account;
};

export const cloneAccount = async (data: {
  accountId: string;
  sourceAccountId: string;
  name: string;
  email: string;
  locale: Locale;
  currency: Currency;
  ownerId: string;
}) => {
  const res = await api.post<SingleResultDto<'account', Account>>(
    '/accounts',
    data,
  );
  return res.data.account;
};

export const updateAccount = async (
  id: string,
  data: {
    name: string;
    email: string;
    locale: Locale;
    currency: Currency;
    ownerId: string;
  },
) => {
  const res = await api.put<SingleResultDto<'account', Account>>(
    `/accounts/${id}`,
    data,
  );
  return res.data.account;
};

export const uploadAccountLogo = async (id: string, data: FormData) => {
  const res = await api.post<SingleResultDto<'account', Account>>(
    `/accounts/${id}/upload-logo`,
    data,
  );
  return res.data.account;
};

export const uploadAccountIcon = async (id: string, data: FormData) => {
  const res = await api.post<SingleResultDto<'account', Account>>(
    `/accounts/${id}/upload-icon`,
    data,
  );
  return res.data.account;
};

export const uploadAccountCover = async (id: string, data: FormData) => {
  const res = await api.post<SingleResultDto<'account', Account>>(
    `/accounts/${id}/upload-cover`,
    data,
  );
  return res.data.account;
};

export const setDomain = async (
  id: string,
  data: {
    host: string;
    wwwPrefix: boolean;
  },
) => {
  const res = await api.post<SingleResultDto<'account', Account>>(
    `/accounts/${id}/set-domain`,
    data,
  );
  return res.data.account;
};

export const unsetDomain = async (id: string) => {
  const res = await api.post<SingleResultDto<'account', Account>>(
    `/accounts/${id}/unset-domain`,
  );
  return res.data.account;
};
