import { api } from 'api';
import type { SingleResultDto, User } from '@fanadise/common-types';

export const signInWithEmailAndPassword = async (data: {
  email: string;
  password: string;
}) => {
  const res = await api.post<SingleResultDto<'user', User>>(
    '/auth/sign-in',
    data,
  );
  return res.data.user;
};

export const signUpWithEmailAndPassword = async (data: {
  email: string;
  password: string;
}) => {
  const res = await api.post<SingleResultDto<'user', User>>(
    '/auth/sign-up',
    data,
  );
  return res.data.user;
};

export const signInWithWallet = async (data: {
  address: string;
  signature: string;
}) => {
  const res = await api.post<SingleResultDto<'user', User>>(
    '/auth/wallet/sign-in',
    data,
  );
  return res.data.user;
};

export const signUpWithWallet = async (data: { address: string }) => {
  const res = await api.post<SingleResultDto<'user', User>>(
    '/auth/wallet/sign-up',
    data,
  );
  return res.data.user;
};

export const resetPassword = async (data: { email: string }) => {
  await api.post('/auth/reset-password', data);
};

export const changePassword = async (data: {
  token: string;
  password: string;
}) => {
  await api.post('/auth/change-password', data);
};

export const checkUserExists = async (email: string) => {
  const res = await api.post<{
    username: string;
    exists: boolean;
  }>('/auth/exists', { email });

  return res.data;
};
