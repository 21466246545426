import {
  CryptoCurrency,
  CRYPTO_CURRENCY_SYMBOLS,
} from '@fanadise/common-consts';
import { formatPrice } from 'conversions/formatPrice';

export const formatCryptoPrice = (
  value: number | string,
  rate: number,
  cryptoCurrency: CryptoCurrency,
  hasAbbreviation: boolean = true,
) => {
  const fixed = formatPrice(value, rate, 4);
  if (!hasAbbreviation) {
    return fixed;
  }

  return `${fixed} ${CRYPTO_CURRENCY_SYMBOLS[cryptoCurrency]}`;
};
