import React from 'react';
import { Routes, Route } from 'react-router-dom';

import NfsRouteList from './NftsRouteList';

const NftsRoute: React.FC = () => (
  <Routes>
    <Route index element={<NfsRouteList />} />
  </Routes>
);

export default NftsRoute;
