import { api } from 'api';
import { DashboardSettings } from '@fanadise/common-types';

export const fetchDashboardSettings = async () => {
  const res = await api.get<DashboardSettings>('/dashboard/settings');
  return res.data;
};

export const updateDashboardSettings = async (data: {
  featuredUserIds: string[];
  featuredAccountIds: string[];
  featuredNftListingIds: string[];
}) => {
  const res = await api.put<DashboardSettings>('/dashboard/settings', data);
  return res.data;
};
