import React, { forwardRef } from 'react';
import Banner, { BannerProps } from 'components/atoms/Banner';
import { DEFAULT_BACKGROUND_URL } from '@fanadise/common-consts';

export interface BackgroundProps extends Omit<BannerProps, 'url'> {
  url?: string;
}

const Background = forwardRef<HTMLElement, BackgroundProps>(
  ({ url = DEFAULT_BACKGROUND_URL, ...rest }, ref) => (
    <Banner
      data-testid="background"
      url={`url('${url}')`}
      ref={ref}
      ratio={{ base: 2, md: 4 }}
      {...rest}
    />
  ),
);

export default Background;
