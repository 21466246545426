import AppAuthContext from 'contexts/AppAuthContext';
import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuthRedirect = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AppAuthContext);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/', { replace: true });
    }
  }, [isAuthenticated]);
};

export default useAuthRedirect;
