import React from 'react';
import Stat from 'components/atoms/Stat';
import { DuplicateIcon } from '@heroicons/react/outline';
import { NftRarity } from '@fanadise/common-consts';
import { abbreviateNumber } from '@fanadise/common-utils';
import Icon from 'components/atoms/Icon';

export interface NftSupplyProps {
  rarity: NftRarity;
  copyNumber: number;
  totalSupply?: number;
  hasIcon?: boolean;
  className?: string;
}

const NftSupply: React.FC<NftSupplyProps> = ({
  rarity,
  copyNumber,
  totalSupply,
  hasIcon = true,
  className,
}) => {
  let label = `${copyNumber}`;
  if (rarity === NftRarity.Unlimited) {
    label = `${copyNumber}/~`;
  } else if (totalSupply) {
    label = `${copyNumber}/${abbreviateNumber(totalSupply)}`;
  }

  return (
    <Stat
      icon={hasIcon ? <Icon icon={DuplicateIcon} size="sm" /> : undefined}
      label={label}
      className={className}
    />
  );
};

export default NftSupply;
