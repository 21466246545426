import React, {
  Context,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Alert,
  Button,
  Carousel,
  CarouselProps,
  EmptyStateMessage,
  Heading,
  Lightbox,
  Loading,
  NftCard,
  Panel,
  SimpleGrid,
  SnackbarContext,
  Spinner,
  Stack,
  Text,
} from '@fanadise/common-ui';
import Link from 'components/shared/Link';
import { profileApi } from '@fanadise/common-data-access';
import { NftStatus } from '@fanadise/common-consts';
import { BaseNft } from '@fanadise/common-types';
import useTranslation from 'hooks/useTranslation';
import { WalletContextValue } from 'contexts/WalletContext';
import NftDetailsLightbox from 'components/profile/NftDetailsLightbox';
import { isEnvFlagOff } from '@fanadise/common-utils';

export interface ProfileNftsListingProps {
  walletContext: Context<WalletContextValue>;
  slidesBreakpoints?: CarouselProps['slidesBreakpoints'];
}

const ProfileNftsListing: React.FC<ProfileNftsListingProps> = ({
  walletContext: WalletContext,
  slidesBreakpoints = {
    sm: 1,
    md: 2,
    lg: 4,
  },
}) => {
  const { translate } = useTranslation();
  const { addErrorAlert } = useContext(SnackbarContext)!;
  const { isConnected } = useContext(WalletContext);

  const [activeNftId, setActiveNftId] = useState<string | null>(null);
  const [isClaiming, setIsClaiming] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [nfts, setNfts] = useState<BaseNft[] | null>(null);
  const custodialNfts = useMemo(
    () =>
      nfts?.filter((nft) =>
        [NftStatus.Assigned, NftStatus.Claiming].includes(nft.status),
      ),
    [nfts],
  );
  const claimedNfts = useMemo(
    () => nfts?.filter((nft) => nft.status === NftStatus.Claimed),
    [nfts],
  );
  const activeNft =
    nfts && activeNftId !== null && nfts.find((nft) => nft.id === activeNftId);

  const hasNftToClaim = useMemo(
    () => !!nfts?.some((nft) => nft.status === NftStatus.Assigned),
    [nfts],
  );
  const hasClaimingNfts = useMemo(
    () => !!nfts?.some((nft) => nft.status === NftStatus.Claiming),
    [nfts],
  );

  const handleNftCardClick = (id: string) => {
    setActiveNftId(id);
  };

  const handleNftDetailsLightboxClose = () => {
    setActiveNftId(null);
  };

  const handleNftsClaim = async () => {
    setIsClaiming(true);
    try {
      await profileApi.claimAllProfileNfts();
      const result = await profileApi.fetchProfileNfts([
        NftStatus.Assigned,
        NftStatus.Claiming,
        NftStatus.Claimed,
      ]);
      setNfts(result.nfts);
    } catch (err: any) {
      const message = err.message || translate('error:default');
      addErrorAlert(message);
    } finally {
      setIsClaiming(false);
    }
  };

  const fetchNfts = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await profileApi.fetchProfileNfts([
        NftStatus.Assigned,
        NftStatus.Claiming,
        NftStatus.Claimed,
      ]);
      setNfts(result.nfts);
    } catch (err: any) {
      const message = err.message || translate('error:default');
      addErrorAlert(message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchNfts();
  }, []);

  useEffect(() => {
    if (!hasClaimingNfts) {
      return;
    }

    const interval = setInterval(fetchNfts, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [hasClaimingNfts, fetchNfts]);

  return (
    <>
      <Stack dir="column" gap="8">
        <Panel>
          <Stack gap="6">
            <Stack gap="1">
              <Heading fontSize="2xl">
                {translate('profile:nfts:custodialNfts')}
              </Heading>
              <Text>{translate('profile:nfts:custodialNftsInfo')}</Text>
            </Stack>
            <Stack>
              {hasClaimingNfts && (
                <Stack dir="row" gap="2">
                  <Spinner size="sm" color="success" />
                  <Alert
                    message={translate('profile:nfts:claimingInfo')}
                    type="success"
                    size="md"
                  />
                </Stack>
              )}
              <Loading isLoading={isLoading} minH={0} isOpaque>
                {custodialNfts?.length ? (
                  <Carousel
                    slidesBreakpoints={slidesBreakpoints}
                    hideOverflowOnDesktop
                  >
                    {custodialNfts.map((nft) => (
                      <NftCard
                        key={nft.id}
                        nftConfig={nft.config}
                        isDisabled={nft.status === NftStatus.Claiming}
                        onClick={() => handleNftCardClick(nft.id)}
                      />
                    ))}
                  </Carousel>
                ) : (
                  <EmptyStateMessage message={translate('common:noResults')} />
                )}
              </Loading>

              <div>
                {isConnected && hasNftToClaim && (
                  <Button
                    color="primary"
                    onClick={handleNftsClaim}
                    isLoading={isClaiming}
                    isDisabled={isEnvFlagOff(
                      process.env.NEXT_PUBLIC_NFT_CLAIMING,
                    )}
                  >
                    {translate('profile:nfts:claim')}
                  </Button>
                )}

                {!isConnected && (
                  <Link href="#connect-wallet" passHref>
                    <a>
                      <Button tag="span">
                        {translate('common:connectWallet')}
                      </Button>
                    </a>
                  </Link>
                )}
              </div>
            </Stack>
          </Stack>
        </Panel>

        <Stack gap="6">
          <Heading fontSize="2xl">
            {translate('profile:nfts:claimedNfts')}
          </Heading>

          <div>
            <Loading isLoading={isLoading} isOpaque>
              {claimedNfts?.length ? (
                <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 4 }} gap="4">
                  {claimedNfts.map((nft) => (
                    <NftCard
                      key={nft.id}
                      nftConfig={nft.config}
                      onClick={() => handleNftCardClick(nft.id)}
                    />
                  ))}
                </SimpleGrid>
              ) : (
                <EmptyStateMessage message={translate('common:noResults')} />
              )}
            </Loading>
          </div>
        </Stack>
      </Stack>

      {activeNft && (
        <Lightbox
          isOpen
          onRequestClose={handleNftDetailsLightboxClose}
          ariaHideApp={false}
        >
          <NftDetailsLightbox nftId={activeNft.id} />
        </Lightbox>
      )}
    </>
  );
};

export default ProfileNftsListing;
