import React from 'react';
import classNames from 'classnames';
import Label from 'components/atoms/Label';

import styles from './FormItem.module.css';

export interface FormItemProps {
  fieldId?: string;
  label?: string;
  error?: string;
  hint?: string;
  isRequired?: boolean;
  className?: string;
}

const FormItem: React.FC<FormItemProps> = ({
  fieldId,
  label,
  error,
  hint,
  isRequired,
  children,
  className,
}) => (
  <div data-testid="formItem" className={classNames(styles.root, className)}>
    {label && (
      <Label
        htmlFor={fieldId}
        text={label}
        isRequired={isRequired}
        className={styles.label}
      />
    )}

    <div className={styles.wrapper}>{children}</div>

    {(error || hint) && (
      <div className={styles.info}>
        {error && <span className={styles.error}>{error}</span>}
        {hint && <span className={styles.hint}>{hint}</span>}
      </div>
    )}
  </div>
);

export default FormItem;
