/* eslint-disable no-nested-ternary */
import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
} from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import Icon from 'components/atoms/Icon';
import Button from 'components/atoms/Button';
import Box from 'components/atoms/Box';
import { motion, useAnimation, useMotionValue } from 'framer-motion';
import classNames from 'classnames';
import useBreakpoint, { UseBreakpointHook } from 'hooks/useBreakpoint';

import styles from './Carousel.module.css';

export interface CarouselProps {
  slidesBreakpoints?: Parameters<UseBreakpointHook<number>>[0];
  slidesToMove?: number;
  gutter?: number;
  isOverflowHidden?: boolean;
  hideOverflowOnDesktop?: boolean;
  hasHiddenControls?: boolean;
  hideControlsOnMobile?: boolean;
  className?: string;
}

const Carousel: React.FC<CarouselProps> = ({
  slidesBreakpoints = { xs: 1 },
  slidesToMove = 1,
  gutter = 1,
  isOverflowHidden,
  hideOverflowOnDesktop,
  hasHiddenControls,
  hideControlsOnMobile,
  className,
  children,
}) => {
  const childrenCount = React.Children.count(children);
  const [activeIndex, setActiveIndex] = useState(0);
  const x = useMotionValue(0);
  const controls = useAnimation();
  const [childWidth, setChildWidth] = useState(0);
  const ref = useRef<HTMLElement | null>(null);
  const variants = useMemo(
    () =>
      new Array(childrenCount).fill(0).reduce(
        (prev, _, i) => ({
          ...prev,
          [i]: { x: i * -childWidth },
        }),
        {},
      ),
    [childWidth],
  );
  const slidesToShow = useBreakpoint(slidesBreakpoints, 1);
  const hideControls =
    hasHiddenControls || (hideControlsOnMobile && slidesToShow < 4);

  const onDragHandler = useCallback(
    (_, { offset, velocity, point }) => {
      if (point.x === 0) {
        return;
      }

      const slidesOffset = offset.x / slidesToShow;
      const slidesOffsetThreshold = 0.5;
      const velocityThreshold = 400;

      if (
        slidesOffset > childWidth * slidesOffsetThreshold ||
        velocity.x > velocityThreshold
      ) {
        setActiveIndex(Math.max(0, activeIndex - slidesToMove));
      } else if (
        slidesOffset < -childWidth * slidesOffsetThreshold ||
        velocity.x < -velocityThreshold
      ) {
        setActiveIndex(
          Math.min(childrenCount - slidesToShow, activeIndex + slidesToMove),
        );
      }
    },
    [childrenCount, slidesToShow, childWidth, activeIndex, slidesToMove],
  );

  useLayoutEffect(() => {
    controls.start(activeIndex.toString());
  }, [activeIndex]);

  useEffect(() => {
    const li = ref.current?.firstChild as HTMLElement;
    if (li && childWidth !== li.clientWidth) {
      setChildWidth(li.clientWidth);
    }
  }, [children]);

  return (
    <Box position="relative" className={classNames(styles.root, className)}>
      <Box
        overflow={
          isOverflowHidden
            ? 'hidden'
            : hideOverflowOnDesktop
            ? { base: 'visible', lg: 'hidden' }
            : undefined
        }
      >
        <Box
          // @ts-ignore
          tag={motion.ul}
          ref={ref}
          variants={variants}
          initial="0"
          animate={controls}
          display="flex"
          overflow="hidden"
          minW="full"
          w={{
            base: `calc(${100 * childrenCount}% + ${
              (childrenCount / 1) * gutter
            }rem)`,
            ...Object.entries(slidesBreakpoints).reduce(
              (prev, [size, slides]) => ({
                ...prev,
                [size]: `calc(${(100 / slides) * childrenCount}% + ${
                  (childrenCount / slides) * gutter
                }rem)`,
              }),
              {},
            ),
          }}
          drag="x"
          dragConstraints={{
            left: activeIndex * -childWidth,
            right: activeIndex * -childWidth,
          }}
          dragElastic={1 / slidesToShow}
          style={{
            // @ts-ignore
            x,
          }}
          transition={{
            type: 'spring',
            stiffness: 300,
            damping: 30,
            bounce: 0,
          }}
          // @ts-ignore
          onDragEnd={onDragHandler}
          className={styles.root}
        >
          {React.Children.map(children, (child) => (
            <Box
              tag="li"
              w={{
                base: `100%`,
                ...Object.entries(slidesBreakpoints).reduce(
                  (prev, [size, slides]) => ({
                    ...prev,
                    [size]: `${100 / slides}%`,
                  }),
                  {},
                ),
              }}
              pr={`${gutter}rem`}
            >
              {child}
            </Box>
          ))}
        </Box>
      </Box>

      {!hideControls && activeIndex > 0 && (
        <Button
          className={styles.prevButton}
          variant="icon"
          onClick={() =>
            setActiveIndex(Math.max(0, activeIndex - slidesToMove))
          }
          icon={<Icon icon={ChevronLeftIcon} size="lg" />}
        />
      )}
      {!hideControls && activeIndex + slidesToShow < childrenCount && (
        <Button
          className={styles.nextButton}
          variant="icon"
          onClick={() =>
            setActiveIndex(
              Math.min(
                childrenCount - slidesToShow,
                activeIndex + slidesToMove,
              ),
            )
          }
          icon={<Icon icon={ChevronRightIcon} size="lg" />}
        />
      )}
    </Box>
  );
};

export default Carousel;
