import React, { useEffect } from 'react';
import classNames from 'classnames';
import Overlay from 'components/atoms/Overlay';

import styles from './Drawer.module.css';

export interface DrawerProps {
  isActive: boolean;
  panel: React.ReactNode;
  position?: 'left' | 'right';
  onOverlayClick?: () => void;
}

const Drawer: React.FC<DrawerProps> = ({
  isActive,
  onOverlayClick,
  panel,
  position = 'left',
  children,
}) => {
  useEffect(() => {
    document.documentElement.classList.toggle('no-scroll', isActive);
    return () => {
      document.documentElement.classList.remove('no-scroll');
    };
  }, [isActive]);

  return (
    <div data-testid="drawer" className={styles.root}>
      {isActive && (
        <>
          <Overlay onClick={onOverlayClick} />
          <div
            className={classNames(
              styles.panel,
              styles[`position--${position}`],
            )}
          >
            <div>{panel}</div>
          </div>
        </>
      )}

      {children}
    </div>
  );
};

export default Drawer;
