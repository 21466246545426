import React from 'react';
import { Toolbar, Panel } from '@fanadise/common-ui';
import PurchasesList from 'components/purchases/PurchasesList';
import { Translate } from '@fanadise/common-logic';

const PurchasesRouteList: React.FC = () => (
  <>
    <Toolbar title={<Translate id="purchases:list:title" />} mb="8" />

    <Panel>
      <PurchasesList />
    </Panel>
  </>
);

export default PurchasesRouteList;
