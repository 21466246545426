import React from 'react';
import { Form } from 'formik';
import classNames from 'classnames';
import ConnectedMessages from 'components/force/ConnectedMessages';

import styles from './ConnectedForm.module.css';

export interface ConnectedFormProps
  extends Omit<
    React.DetailedHTMLProps<
      React.FormHTMLAttributes<HTMLFormElement>,
      HTMLFormElement
    >,
    'ref'
  > {}

const ConnectedForm: React.FC<ConnectedFormProps> = ({
  children,
  className,
  ...rest
}) => (
  <Form noValidate className={classNames(styles.root, className)} {...rest}>
    <ConnectedMessages className={styles.formMessages} />
    {children}
  </Form>
);

export default ConnectedForm;
