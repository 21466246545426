import { CryptoCurrency } from '@fanadise/common-consts';
import React from 'react';
import { formatCryptoPrice } from '@fanadise/common-utils';

export interface CryptoPriceProps {
  tag?: keyof JSX.IntrinsicElements;
  fiatValue: number | string;
  rate: number;
  currency: CryptoCurrency;
  isApproximate?: boolean;
  hasAbbreviation?: boolean;
  className?: string;
}

const CryptoPrice: React.FC<CryptoPriceProps> = ({
  tag: Tag = 'span',
  fiatValue,
  rate,
  currency,
  isApproximate = false,
  hasAbbreviation = true,
  className,
}) => (
  <Tag data-testid="cryptoPrice" className={className}>
    {isApproximate && '~'}
    {formatCryptoPrice(fiatValue, rate, currency, hasAbbreviation)}
  </Tag>
);

export default CryptoPrice;
