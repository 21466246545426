import React from 'react';
import Stack, { StackProps } from 'components/atoms/Stack';
import Box from 'components/atoms/Box';
import Heading from 'components/atoms/Heading';

export interface ToolbarProps extends Omit<StackProps, 'title'> {
  leading?: React.ReactElement;
  title: string | React.ReactNode;
  actions?: React.ReactNode;
}

const Toolbar: React.FC<ToolbarProps> = ({
  leading,
  title,
  actions,
  ...rest
}) => (
  <Stack data-testid="toolbar" dir="row" align="center" {...rest}>
    {leading && <Box tag="div">{leading}</Box>}

    <Heading fontSize="2xl">{title}</Heading>

    <Box tag="div" ml="auto">
      {actions}
    </Box>
  </Stack>
);

export default Toolbar;
