import React from 'react';

const FanadiseIcon = (props: React.ComponentProps<'svg'>) => (
  <svg
    data-testid="fanadiseIcon"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      stroke="currentColor"
      d="M22.6 29.7c-11.8.6-19.1-6.2-19.7-12.3C1.8 6.4 11.7 1 20.4 2.5 33.3 4.9 22 16.6 16.5 18c-5.3 1.3-7.2-5.2-1.4-7.3 3.8-1.4 14-1.2 14 3.5.1 3.7-10.1 6.7-6.5 15.5z"
      fill="none"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
);

export default FanadiseIcon;
