import React from 'react';
import { Stack, Text, TinyHeading } from '@fanadise/common-ui';
import { utils } from 'ethers';
import { WalletAsset } from '@fanadise/common-types';
import { CryptoCurrency, FIAT_CURRENCY_SYMBOLS } from '@fanadise/common-consts';
import useCurrency from 'hooks/useCurrency';
import useCryptoPrices from 'hooks/useCryptoPrices';
import Translate from 'components/shared/Translate';

interface WalletDetailsBalanceProps {
  assets: WalletAsset[];
}

const WalletDetailsBalance: React.FC<WalletDetailsBalanceProps> = ({
  assets,
}) => {
  const fiatCurrency = useCurrency();
  const cryptoPrices = useCryptoPrices();

  return (
    <Stack gap="1" borderRadius="sm" p="4" bg="gray.50" align="center">
      <TinyHeading>
        <Translate id="walletDetails:balance" />
      </TinyHeading>

      <Stack dir="row" gap="1.5" align="center">
        <Text fontWeight="semibold" color="gray.400">
          {FIAT_CURRENCY_SYMBOLS[fiatCurrency]}
        </Text>

        <Text fontWeight="semibold" fontSize="3xl" color="gray.800">
          {assets
            .reduce(
              (prev, asset) =>
                prev +
                cryptoPrices[asset.symbol as CryptoCurrency][fiatCurrency] *
                  parseFloat(utils.formatEther(asset.balance.toString())),
              0,
            )
            .toFixed(2)}
        </Text>

        <Text fontWeight="semibold" color="gray.400">
          {fiatCurrency.toUpperCase()}
        </Text>
      </Stack>
    </Stack>
  );
};

export default WalletDetailsBalance;
