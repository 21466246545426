import React from 'react';
import { Button, Icon, Panel, Toolbar } from '@fanadise/common-ui';
import { Link } from 'react-router-dom';
import { ArrowSmLeftIcon } from '@heroicons/react/solid';
import { Translate } from '@fanadise/common-logic';
import EditCategoryForm from 'components/categories/EditCategoryForm';

const CategoriesRouteAdd: React.FC = () => (
  <>
    <Toolbar
      title={<Translate id="categories:add:title" />}
      leading={
        <Link to="/account/categories">
          <Button
            tag="span"
            variant="icon"
            icon={<Icon icon={ArrowSmLeftIcon} size="lg" />}
          />
        </Link>
      }
      mb="8"
    />
    <Panel>
      <EditCategoryForm />
    </Panel>
  </>
);

export default CategoriesRouteAdd;
