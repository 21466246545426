import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import type { Placement, PositioningStrategy } from '@popperjs/core';

import styles from './Tooltip.module.css';

export interface TooltipProps {
  text: React.ReactNode | string;
  placement?: Placement;
  strategy?: PositioningStrategy;
}

const Tooltip: React.FC<TooltipProps> = ({
  text,
  placement = 'top',
  strategy = 'fixed',
  children,
}) => {
  const [isActive, setIsActive] = useState(false);

  const [triggerRef, setTriggerRef] = useState<HTMLElement | null>(null);
  const [popperRef, setPopperRef] = useState<HTMLSpanElement | null>(null);

  const { styles: popperStyles, attributes } = usePopper(
    triggerRef,
    popperRef,
    {
      modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
      placement,
      strategy,
    },
  );

  const triggerProps = {
    onPointerEnter: () => setIsActive(true),
    onPointerLeave: () => setIsActive(false),
    ref: setTriggerRef,
  };

  const trigger = React.cloneElement(
    children as React.DetailedReactHTMLElement<any, HTMLElement>,
    triggerProps,
  );

  return (
    <>
      {trigger}

      {isActive && (
        <span
          data-testid="tooltip"
          ref={setPopperRef}
          style={popperStyles.popper}
          className={styles.root}
          {...attributes.popper}
        >
          {text}
        </span>
      )}
    </>
  );
};

export default Tooltip;
