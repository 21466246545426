import React from 'react';
import { useField } from 'formik';
import SmartSelect, { SmartSelectProps } from 'components/shared/SmartSelect';
import { GroupBase, MultiValue } from 'react-select';

export interface ConnectedSmartSelectProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> extends Omit<SmartSelectProps<Option, IsMulti, Group>, 'isInvalid'> {
  name: string;
}

const ConnectedSmartSelect = <
  Option extends { value: string },
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  name,
  options,
  isMulti,
  ...rest
}: ConnectedSmartSelectProps<Option, IsMulti, Group>) => {
  const [field, { error, touched, value }, helpers] = useField({ name });
  const isInvalid = touched && Boolean(error);

  return (
    <SmartSelect
      options={options}
      name={field.name}
      onChange={(option): void => {
        if (!option) {
          helpers.setValue(null);
          return;
        }

        if (isMulti) {
          helpers.setValue(
            (option as MultiValue<Option>).map(
              ({ value: newValue }) => newValue,
            ),
          );
        } else {
          helpers.setValue((option as Option).value);
        }
      }}
      value={
        options?.find((option) => (option as Option).value === value) as Option
      }
      onBlur={field.onBlur}
      isInvalid={isInvalid}
      isMulti={isMulti}
      {...rest}
    />
  );
};

export default ConnectedSmartSelect;
