import React from 'react';
import { CopyableText, VCard } from '@fanadise/common-ui';
import { BaseUserWithEmail, BaseUserWithWallet } from '@fanadise/common-types';
import ResourcesList from 'components/shared/ResourcesList';
import { useTranslation } from '@fanadise/common-logic';
import { usersApi } from '@fanadise/common-data-access';
import { shortenAddress } from '@fanadise/common-utils';

const UsersList: React.FC = () => {
  const { translate } = useTranslation();

  return (
    <ResourcesList<BaseUserWithEmail & BaseUserWithWallet, 'users'>
      dataKey="users"
      fetchData={usersApi.fetchUsers}
      extraParams={{ include: 'sensitive' }}
      sortedProps={['createdAt']}
      columns={[
        {
          prop: 'id',
          label: translate('common:id'),
          renderCell: ({ id }) => (
            <CopyableText
              maxW="20"
              text={id}
              tooltipText={translate('common:copy')}
              copiedMessage={translate('common:copied')}
            />
          ),
        },
        {
          prop: 'username',
          label: translate('common:username'),
          renderCell: (user) => <VCard user={user} size="sm" />,
        },
        {
          prop: 'email',
          label: translate('common:email'),
          renderCell: ({ email }) => <a href={`mailto:${email}`}>{email}</a>,
        },
        {
          prop: 'address',
          label: translate('common:address'),
          renderCell: ({ wallet }) =>
            wallet && wallet.address ? (
              <CopyableText
                text={wallet.address!}
                tooltipText={translate('common:copy')}
                copiedMessage={translate('common:copied')}
              >
                {shortenAddress(wallet.address!, 3)}
              </CopyableText>
            ) : (
              '--'
            ),
        },
        {
          prop: 'createdAt',
          label: translate('common:createdAt'),
          renderCell: ({ createdAt }) => (
            <time dateTime={new Date(createdAt).toISOString()}>
              {new Date(createdAt).toLocaleDateString()}
            </time>
          ),
        },
      ]}
    />
  );
};

export default UsersList;
