import React, { useCallback, useContext } from 'react';
import {
  Badge,
  NftRarityIcon,
  Panel,
  CopyableText,
  Text,
  Box,
  Icon,
  MenuItem,
  ConfirmDialogContext,
  SnackbarContext,
} from '@fanadise/common-ui';
import { NftConfig } from '@fanadise/common-types';
import ResourcesList from 'components/shared/ResourcesList';
import { useTranslation } from '@fanadise/common-logic';
import { capitalize } from '@fanadise/common-utils';
import { NftConfigStatus } from '@fanadise/common-consts';
import { nftConfigsApi } from '@fanadise/common-data-access';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

const NftConfigsList: React.FC = () => {
  const { translate } = useTranslation();
  const { addSuccessAlert, addErrorAlert } = useContext(SnackbarContext)!;
  const { activateConfirmDialog } = useContext(ConfirmDialogContext);

  const handleDeleteConfig = useCallback(async (configId: string) => {
    const isConfirmed = await activateConfirmDialog();
    if (!isConfirmed) {
      return;
    }

    try {
      await nftConfigsApi.deleteNftConfig(configId);
      addSuccessAlert(translate('success:removed'));
      window.location.reload();
    } catch (err: any) {
      addErrorAlert(err.message || translate('error:default'));
    }
  }, []);

  return (
    <Panel>
      <ResourcesList<NftConfig, 'nftConfigs'>
        dataKey="nftConfigs"
        fetchData={nftConfigsApi.fetchNftConfigs}
        sortedProps={['createdAt']}
        columns={[
          {
            prop: 'id',
            label: translate('common:id'),
            renderCell: ({ id }) => (
              <CopyableText
                maxW="16"
                text={id}
                tooltipText={translate('common:copy')}
                copiedMessage={translate('common:copied')}
              />
            ),
          },
          {
            prop: 'name',
            label: translate('nftConfigs:list:name'),
            renderCell: ({ name }) => (
              <Box maxW="60">
                <Text color="gray.800" fontWeight="semibold" isTruncated>
                  {name}
                </Text>
              </Box>
            ),
          },
          {
            prop: 'type',
            label: translate('nftConfigs:list:type'),
            renderCell: ({ type }) => (
              <Badge variant="secondary">{translate(`nft:type:${type}`)}</Badge>
            ),
          },
          {
            prop: 'rarity',
            label: translate('nftConfigs:list:rarity'),
            renderCell: ({ rarity }) => <NftRarityIcon rarity={rarity} />,
            fitContent: true,
          },
          {
            prop: 'accountId',
            label: translate('nftConfigs:list:account'),
            renderCell: ({ accountId }) => (
              <CopyableText
                maxW="20"
                text={accountId}
                tooltipText={translate('common:copy')}
                copiedMessage={translate('common:copied')}
              />
            ),
          },
          {
            prop: 'totalSupply',
            label: translate('nftConfigs:list:totalSupply'),
            renderCell: ({ totalSupply }) => totalSupply,
          },
          {
            prop: 'status',
            label: translate('common:status'),
            renderCell: ({ status }) => (
              <Badge
                variant={
                  status === NftConfigStatus.Minted ? 'primary' : 'secondary'
                }
              >
                {status ? capitalize(status) : '--'}
              </Badge>
            ),
          },
          {
            prop: 'createdAt',
            label: translate('common:createdAt'),
            renderCell: ({ createdAt }) => (
              <time dateTime={new Date(createdAt).toISOString()}>
                {new Date(createdAt).toLocaleDateString()}
              </time>
            ),
          },
        ]}
        renderMenu={(item) => (
          <>
            <Link to={`/account/configs/edit/${item.id}`}>
              <MenuItem tag="span" icon={<Icon icon={PencilIcon} size="sm" />}>
                {translate('common:edit')}
              </MenuItem>
            </Link>

            {item.status === NftConfigStatus.Created && (
              <MenuItem
                tag="span"
                icon={<Icon icon={TrashIcon} size="sm" />}
                onClick={() => handleDeleteConfig(item.id)}
              >
                {translate('common:delete')}
              </MenuItem>
            )}
          </>
        )}
      />
    </Panel>
  );
};

export default NftConfigsList;
