import React from 'react';
import classNames from 'classnames';
import { NftRarity } from '@fanadise/common-consts';
import { capitalize } from '@fanadise/common-utils';
import Icon from 'components/atoms/Icon';
import { CubeTransparentIcon } from '@heroicons/react/outline';

import styles from './RarityTile.module.css';

export interface RarityTileProps {
  rarity: NftRarity;
  size?: 'md' | 'lg';
  className?: string;
}

const RarityTile: React.FC<RarityTileProps> = ({
  rarity,
  size = 'md',
  children,
  className,
  ...rest
}) => (
  <div
    data-testid="rarity-tile"
    className={classNames(
      styles.root,
      styles[`rarity--${rarity}`],
      styles[`size--${size}`],
      className,
    )}
    {...rest}
  >
    <div className={styles.icon}>
      <Icon icon={CubeTransparentIcon} size={size} />
    </div>
    <div className={styles.wrap}>
      <p className={styles.name}>{children || capitalize(rarity)}</p>
      {children && <div className={styles.inner}>{children}</div>}
    </div>
  </div>
);

export default RarityTile;
