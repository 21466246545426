import React, { HTMLAttributes, useRef } from 'react';
import classNames from 'classnames';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { DragObjectWithType, useDrag, useDrop } from 'react-dnd';
import Icon from 'components/atoms/Icon';

import styles from './DragDropItem.module.css';

export interface DragDropItemProps extends HTMLAttributes<HTMLElement> {
  id: string;
  type: string;
  index: number;
  onMove: (index: number, hoverIndex: number) => void;
  tag?: keyof JSX.IntrinsicElements;
  children: JSX.Element;
}

const DragDropItem: React.FC<DragDropItemProps> = ({
  tag: Tag = 'div',
  id,
  type,
  index,
  onMove,
  className,
  children,
  ...rest
}) => {
  const ref = useRef<HTMLElement>();

  const [, drop] = useDrop({
    accept: type,
    hover(item: DragObjectWithType & { index: number }, monitor) {
      const { current } = ref;
      const dragIndex = item.index;
      const hoverIndex = index;

      if (!current || dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onMove(dragIndex, hoverIndex);
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ dragging: isDragging }, drag] = useDrag({
    item: { type, id, index },
    collect: (monitor) => ({
      dragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    // @ts-ignore
    <Tag
      // @ts-ignore
      ref={ref}
      className={classNames(
        styles.root,
        isDragging && styles.isDragging,
        className,
      )}
      {...rest}
    >
      <Icon icon={DotsVerticalIcon} className={styles.handle} />
      {React.cloneElement(children, {
        className: classNames(styles.body, children.props.className),
      })}
    </Tag>
  );
};

export { DndProvider } from 'react-dnd';
export { HTML5Backend } from 'react-dnd-html5-backend';
export default DragDropItem;
