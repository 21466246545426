import { CryptoCurrency, Currency } from '@fanadise/common-consts';
import { api } from 'api';

export const getPrices = async (crypto: CryptoCurrency[], fiat: Currency[]) => {
  const res = await api.get<
    {
      [cryptoCurrency in CryptoCurrency]: {
        [fiatCurrency in Currency]: number;
      };
    }
  >('/crypto/prices2', {
    params: {
      crypto,
      fiat,
    },
  });

  return res.data;
};
