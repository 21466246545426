import React from 'react';
import { useLocation } from 'react-router-dom';

type Props = {
  path: string;
  children: (isActive: boolean) => React.ReactNode;
};

const ActiveRoute: React.FC<Props> = ({ path, children }) => {
  const { pathname } = useLocation();

  return <>{children(pathname.startsWith(path))}</>;
};

export default ActiveRoute;
