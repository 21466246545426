import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  ConfirmDialogContext,
  Icon,
  Panel,
  SnackbarContext,
  Toolbar,
} from '@fanadise/common-ui';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { ArrowSmLeftIcon } from '@heroicons/react/solid';
import EditNftConfigForm from 'components/nftConfigs/EditNftConfigForm';
import { NftConfig } from '@fanadise/common-types';
import { Translate, useTranslation } from '@fanadise/common-logic';
import { NftConfigStatus } from '@fanadise/common-consts';
import { nftConfigsApi } from '@fanadise/common-data-access';
import log from 'loglevel';

const NftConfigsRouteEdit: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const { translate } = useTranslation();
  const { addErrorAlert } = useContext(SnackbarContext)!;
  const [nftConfig, setNftConfig] = useState<NftConfig>();
  const [isLoading, setIsLoading] = useState(false);
  const { activateConfirmDialog } = useContext(ConfirmDialogContext);

  const isMinting = nftConfig?.status === NftConfigStatus.Minting;
  const canBeMinted = nftConfig && nftConfig.status !== NftConfigStatus.Minted;

  const handleMintNft = async () => {
    const isConfirmed = await activateConfirmDialog();
    if (!isConfirmed) {
      return;
    }

    try {
      setIsLoading(true);
      const updatedNftConfig = await nftConfigsApi.mintNftConfig(nftConfig!.id);
      setNftConfig(updatedNftConfig);
    } catch (err: any) {
      addErrorAlert(err.message || translate('error:default'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchNftConfig = async () => {
      try {
        const config = await nftConfigsApi.fetchNftConfig(id);
        setNftConfig(config);
      } catch (err: any) {
        addErrorAlert(err.message || translate('error:default'));
        navigate('/account/configs', { replace: true });
      }
    };

    fetchNftConfig();
  }, [id]);

  useEffect(() => {
    if (!isMinting) {
      return;
    }

    const fetchNftConfig = async () => {
      try {
        const config = await nftConfigsApi.fetchNftConfig(id);
        setNftConfig(config);
      } catch (err) {
        log.error(err);
      }
    };

    const interval = setInterval(fetchNftConfig, 30 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [id, isMinting]);

  return (
    <>
      <Toolbar
        title={<Translate id="nftConfigs:edit:title" />}
        leading={
          <Link to="/account/configs">
            <Button
              tag="span"
              variant="icon"
              icon={<Icon icon={ArrowSmLeftIcon} size="lg" />}
            />
          </Link>
        }
        actions={
          canBeMinted ? (
            <Button
              onClick={handleMintNft}
              isLoading={isMinting || isLoading}
              isDisabled={isMinting || isLoading}
            >
              <Translate id="nftConfigs:form:mint" />
            </Button>
          ) : undefined
        }
        mb="8"
      />

      {nftConfig && (
        <>
          <Panel>
            <EditNftConfigForm nftConfig={nftConfig} onChange={setNftConfig} />
          </Panel>
        </>
      )}
    </>
  );
};

export default NftConfigsRouteEdit;
