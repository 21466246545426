import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { DEFAULT_AVATAR_URL } from '@fanadise/common-consts';

import styles from './Avatar.module.css';

export interface AvatarProps {
  url?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl';
  alt?: string;
  isPulsing?: boolean;
  className?: string;
}

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  (
    { url, size = 'sm', alt = '', isPulsing = false, className, ...rest },
    ref,
  ) => (
    <div
      data-testid="avatar"
      className={classNames(styles.root, styles[`size--${size}`], className, {
        [styles.isPulsing]: isPulsing,
      })}
      ref={ref}
      {...rest}
    >
      <div>
        <img src={url || DEFAULT_AVATAR_URL} alt={alt} />
      </div>
    </div>
  ),
);

export default Avatar;
