import React, { useContext, useEffect, useState } from 'react';
import {
  Toolbar,
  Panel,
  Button,
  Icon,
  SnackbarContext,
  FormItem,
  Text,
  Badge,
  FiatPrice,
  Anchor,
  Stack,
  Separator,
} from '@fanadise/common-ui';
import { Translate, useTranslation } from '@fanadise/common-logic';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { purchasesApi } from '@fanadise/common-data-access';
import { Purchase } from '@fanadise/common-types';
import FormGrid from 'components/shared/FormGrid';
import { capitalize } from '@fanadise/common-utils';
import { PurchaseContentType } from '@fanadise/common-consts';
import FormSectionTitle from 'components/shared/FormSectionTitle';

const PurchasesRoutePreview: React.FC = () => {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();
  const [purchase, setPurchase] = useState<Purchase | null>(null);
  const { translate } = useTranslation();
  const { addErrorAlert } = useContext(SnackbarContext)!;

  useEffect(() => {
    const fetchPurchase = async () => {
      try {
        setPurchase(await purchasesApi.fetchPurchase(id));
      } catch (err: any) {
        addErrorAlert(err.message || translate('error:default'));
        navigate('../', { replace: true });
      }
    };

    fetchPurchase();
  }, [id]);

  return (
    <>
      <Toolbar
        title={<Translate id="purchases:preview:title" values={{ id }} />}
        leading={
          <Link to="../">
            <Button
              tag="span"
              variant="icon"
              icon={<Icon icon={ArrowLeftIcon} size="lg" />}
            />
          </Link>
        }
        mb="8"
      />

      {purchase ? (
        <Panel>
          <FormSectionTitle>{translate('common:summary')}</FormSectionTitle>

          <FormGrid>
            <FormItem label={translate('purchases:account')}>
              <Link to={`/account/accounts/edit/${purchase.accountId}`}>
                <Anchor>{purchase.accountId}</Anchor>
              </Link>
            </FormItem>

            <FormItem label={translate('purchases:username')}>
              <Stack dir="row" gap="2">
                <Text color="gray.800">#{purchase.userId}</Text>
                <Anchor href={`mailto:${purchase.user.email}`}>
                  {purchase.user.email}
                </Anchor>
              </Stack>
            </FormItem>

            <FormItem label={translate('common:status')}>
              {purchase.paid ? (
                <Badge variant="primary">
                  <Translate id="common:purchaseStatus:paid" />
                </Badge>
              ) : (
                <Badge variant="secondary">
                  <Translate id="common:purchaseStatus:unpaid" />
                </Badge>
              )}
            </FormItem>

            <FormItem label={translate('purchases:price')}>
              <Text fontWeight="semibold" color="gray.800">
                <FiatPrice
                  value={purchase.price}
                  currency={purchase.currency}
                />
              </Text>
            </FormItem>

            <FormItem label={translate('purchases:createdAt')}>
              <Text color="gray.800">
                <time dateTime={new Date(purchase.createdAt).toISOString()}>
                  {new Date(purchase.createdAt).toLocaleString()}
                </time>
              </Text>
            </FormItem>
          </FormGrid>

          <Separator />

          <FormSectionTitle>{translate('purchases:content')}</FormSectionTitle>

          <FormGrid>
            <FormItem label={translate('common:type')}>
              <Badge variant="secondary">
                {capitalize(purchase.contentType)}
              </Badge>
            </FormItem>

            <FormItem label={translate('common:id')}>
              {purchase.contentType === PurchaseContentType.NftListing ? (
                <Link
                  to={`/account/nfts/nft-listings/edit/${purchase.contentId}`}
                >
                  <Anchor>{purchase.contentId}</Anchor>
                </Link>
              ) : (
                <Text color="gray.800">{purchase.contentId}</Text>
              )}
            </FormItem>
          </FormGrid>

          <FormItem label={translate('common:description')}>
            <Text color="gray.800">{purchase.description}</Text>
          </FormItem>
        </Panel>
      ) : null}
    </>
  );
};

export default PurchasesRoutePreview;
