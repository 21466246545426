/* eslint-disable react/destructuring-assignment */
import React, { memo, forwardRef, useContext } from 'react';
import classNames from 'classnames';
import { IconProps } from 'components/atoms/Icon';
import ThemeContext from 'contexts/ThemeContext';
import { ThemeProperties } from 'types/themeProperty';
import Spinner from 'components/atoms/Spinner';

import styles from './Button.module.css';

export interface ButtonProps {
  type?: 'button' | 'submit' | 'reset';
  tag?: 'button' | 'span' | 'a';
  size?: 'md' | 'lg' | 'sm';
  w?: ThemeProperties['size'];
  variant?: 'filled' | 'outlined' | 'text' | 'icon';
  color?: 'primary' | 'secondary' | 'danger';
  href?: string;
  icon?: React.ReactElement<IconProps>;
  iconPlacement?: 'left' | 'right' | 'fixedLeft' | 'fixedRight';
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  className?: string;
  children?: React.ReactNode;
}

const Button = forwardRef<
  HTMLElement | HTMLAnchorElement | HTMLAnchorElement,
  ButtonProps
>(
  (
    {
      tag: Tag = 'button',
      size = 'md',
      w,
      variant = 'filled',
      color = 'primary',
      type = 'button',
      iconPlacement = 'left',
      icon,
      children,
      isDisabled = false,
      isLoading = false,
      onClick = () => undefined,
      className,
      ...rest
    },
    ref,
  ) => {
    const { convertSize } = useContext(ThemeContext);

    const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (isDisabled || isLoading) {
        event.preventDefault();
        return;
      }

      onClick(event);
    };

    return (
      <Tag
        data-testid="button"
        className={classNames(
          styles.root,
          styles[`variant--${variant}`],
          styles[`size--${size}`],
          styles[`color--${color}`],
          {
            [styles.isDisabled]: isDisabled || isLoading,
            [styles.isLoading]: isLoading,
            [styles[`iconPlacement--${iconPlacement}`]]: icon,
          },
          className,
        )}
        type={Tag === 'button' ? type : undefined}
        onClick={handleClick}
        style={{ width: convertSize(w) }}
        // @ts-ignore
        ref={ref}
        {...rest}
      >
        <span className={styles.wrap}>
          {icon &&
            React.cloneElement(icon, {
              className: classNames(styles.icon, icon.props.className),
            })}
          {children && <span className={styles.label}>{children}</span>}
        </span>
        {isLoading && (
          <div className={styles.spinner}>
            <Spinner
              size="sm"
              color={variant === 'filled' ? 'white' : 'primary'}
            />
          </div>
        )}
      </Tag>
    );
  },
);

export default memo(Button);
