import React from 'react';
import { Toolbar, Panel, Button, Icon } from '@fanadise/common-ui';
import UsersList from 'components/users/UsersList';
import { Translate } from '@fanadise/common-logic';
import { PlusIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

const UsersRouteList: React.FC = () => (
  <>
    <Toolbar
      title={<Translate id="users:list:title" />}
      actions={
        <Link to="add">
          <Button tag="span" icon={<Icon icon={PlusIcon} />}>
            <Translate id="users:add:title" />
          </Button>
        </Link>
      }
      mb="8"
    />

    <Panel>
      <UsersList />
    </Panel>
  </>
);

export default UsersRouteList;
