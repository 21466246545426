import React, { useCallback, useContext } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import {
  SnackbarContext,
  ConnectedLoading,
  ConnectedInput,
  ConnectedFormItem,
  ConnectedSubmit,
  Box,
  ConnectedFileInput,
  ImageWrapper,
  Ratio,
  Stack,
  FilePreview,
  ConnectedToggleSwitch,
} from '@fanadise/common-ui';
import { Account } from '@fanadise/common-types';
import { Locale, Currency } from '@fanadise/common-consts';
import FormSectionTitle from 'components/shared/FormSectionTitle';
import FormGrid from 'components/shared/FormGrid';
import FormSubmitBar from 'components/shared/FormSubmitBar';
import { useNavigate } from 'react-router-dom';
import { Translate, useTranslation } from '@fanadise/common-logic';
import ConnectedSmartSelect from 'components/shared/ConnectedSmartSelect';
import { accountsApi, usersApi } from '@fanadise/common-data-access';
import ConnectedAsyncSmartSelect from 'components/shared/ConnectedAsyncSmartSelect';

interface EditAccountFormValues {
  accountId: string;
  sourceAccountId: string;
  name: string;
  email: string;
  locale: Locale;
  currency: Currency;
  ownerId: string;
  logoFile?: File;
  iconFile?: File;
  coverFile?: File;
  active?: boolean;
}

export interface EditAccountFormProps {
  account?: Account;
  onChange?: (account: Account) => void;
}

const EditAccountForm: React.FC<EditAccountFormProps> = ({
  account,
  onChange,
}) => {
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const { addSuccessAlert, addErrorAlert } = useContext(SnackbarContext)!;

  const validationSchema = yup.object().shape({
    name: yup.string().required(translate('validation:required')),
    email: yup
      .string()
      .email(translate('validation:invalid'))
      .required(translate('validation:required')),
    ...(account
      ? {}
      : {
          accountId: yup
            .string()
            .matches(/^[a-z0-9-]+$/i, translate('validation:invalid'))
            .required(translate('validation:required')),
          sourceAccountId: yup
            .string()
            .matches(/^[a-z0-9-]+$/i, translate('validation:invalid'))
            .required(translate('validation:required')),
          ownerId: yup.string().required(translate('validation:required')),
        }),
  });

  const initialValues: EditAccountFormValues = {
    accountId: account?.id || '',
    sourceAccountId: '',
    name: account?.name || '',
    email: account?.email || '',
    locale: account?.locale || Locale.En,
    currency: account?.currency || Currency.Usd,
    ownerId: account?.ownerId || '',
    active: account?.active || false,
  };

  const handleSubmit = useCallback(
    async (
      values: EditAccountFormValues,
      { setFieldValue }: FormikHelpers<EditAccountFormValues>,
    ) => {
      try {
        let savedAccount: Account;
        let message;

        if (account) {
          savedAccount = await accountsApi.updateAccount(account.id, {
            name: values.name,
            email: values.email,
            locale: values.locale,
            currency: values.currency,
            ownerId: values.ownerId,
          });
          message = translate('success:saved');
        } else {
          savedAccount = await accountsApi.cloneAccount(values);
          message = translate('success:added');
        }

        try {
          if (values.logoFile) {
            const data = new FormData();
            data.append('file', values.logoFile);
            savedAccount = await accountsApi.uploadAccountLogo(
              savedAccount.id,
              data,
            );
            setFieldValue('logoFile', null);
          }

          if (values.iconFile) {
            const data = new FormData();
            data.append('file', values.iconFile);
            savedAccount = await accountsApi.uploadAccountIcon(
              savedAccount.id,
              data,
            );
            setFieldValue('iconFile', null);
          }

          if (values.coverFile) {
            const data = new FormData();
            data.append('file', values.coverFile);
            savedAccount = await accountsApi.uploadAccountCover(
              savedAccount.id,
              data,
            );
            setFieldValue('coverFile', null);
          }
        } catch (err) {
          if (account) {
            throw err;
          }
        }

        addSuccessAlert(message);

        if (!account) {
          navigate(`/account/accounts/edit/${savedAccount.id}`, {
            replace: true,
          });
        } else if (onChange) {
          onChange(savedAccount);
        }
      } catch (err: any) {
        addErrorAlert(err.message || translate('error:default'));
      }
    },
    [translate, addSuccessAlert, addErrorAlert, account?.id, onChange],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <ConnectedLoading>
        <Form noValidate>
          <FormSectionTitle>
            <Translate id="accounts:form:details:title" />
          </FormSectionTitle>

          <FormGrid>
            <ConnectedFormItem
              fieldId="accountId"
              fieldName="accountId"
              label={translate('accounts:form:id:label')}
              isRequired
            >
              <ConnectedInput
                name="accountId"
                placeholder={translate('accounts:form:id:placeholder')}
                isDisabled={!!account}
              />
            </ConnectedFormItem>

            {!account && (
              <ConnectedFormItem
                fieldId="sourceAccountId"
                fieldName="sourceAccountId"
                label={translate('accounts:form:sourceId:label')}
                isRequired
              >
                <ConnectedAsyncSmartSelect
                  name="sourceAccountId"
                  placeholder={translate('accounts:form:sourceId:placeholder')}
                  cacheOptions
                  loadOptions={async (value, callback) => {
                    const result = await accountsApi.fetchAccounts({
                      search: value,
                    });

                    callback(
                      result.accounts
                        ? result.accounts.map(({ id, name }) => ({
                            value: id,
                            label: `${name} (${id})`,
                          }))
                        : [],
                    );
                  }}
                />
              </ConnectedFormItem>
            )}

            {account && (
              <ConnectedFormItem
                fieldId="active"
                fieldName="active"
                label={translate('common:active')}
              >
                <ConnectedToggleSwitch name="active" />
              </ConnectedFormItem>
            )}

            <ConnectedFormItem
              fieldId="email"
              fieldName="email"
              label={translate('accounts:form:email:label')}
              isRequired
            >
              <ConnectedInput
                name="email"
                type="email"
                placeholder={translate('accounts:form:email:placeholder')}
              />
            </ConnectedFormItem>

            <ConnectedFormItem
              fieldId="name"
              fieldName="name"
              label={translate('accounts:form:accountName:label')}
              isRequired
            >
              <ConnectedInput
                name="name"
                placeholder={translate('accounts:form:accountName:placeholder')}
              />
            </ConnectedFormItem>

            <ConnectedFormItem
              fieldId="currency"
              fieldName="currency"
              label={translate('common:currency')}
              isRequired
            >
              <ConnectedSmartSelect
                name="currency"
                options={Object.values(Currency).map((curr) => ({
                  value: curr,
                  label: curr.toUpperCase(),
                }))}
                isDisabled={!!account}
              />
            </ConnectedFormItem>

            <ConnectedFormItem
              fieldId="locale"
              fieldName="locale"
              label={translate('common:locale')}
              isRequired
            >
              <ConnectedSmartSelect
                name="locale"
                options={Object.values(Locale).map((locale) => ({
                  value: locale,
                  label: locale.toUpperCase(),
                }))}
                isDisabled={!!account}
              />
            </ConnectedFormItem>

            <ConnectedFormItem
              fieldId="ownerId"
              fieldName="ownerId"
              label={translate('accounts:form:ownerId:label')}
              isRequired
            >
              <ConnectedAsyncSmartSelect
                name="ownerId"
                placeholder={translate('accounts:form:ownerId:placeholder')}
                cacheOptions
                defaultValue={
                  account
                    ? {
                        value: account.ownerId,
                        label: `@${account.owner.username}`,
                      }
                    : undefined
                }
                loadOptions={async (value, callback) => {
                  const result = await usersApi.fetchUsers({
                    search: value,
                  });

                  callback(
                    result.users
                      ? result.users.map((user) => ({
                          value: user.id,
                          label: `@${user.username}`,
                        }))
                      : [],
                  );
                }}
              />
            </ConnectedFormItem>
          </FormGrid>

          <ConnectedFormItem
            fieldId="logoFile"
            fieldName="logoFile"
            label={translate('common:logo')}
          >
            <Stack dir="row" gap="4" align="center">
              {account?.logoUrl && (
                <FilePreview url={account.logoUrl} fit="contain" />
              )}

              <ConnectedFileInput
                name="logoFile"
                size="sm"
                accept="image/png, image/jpeg, image/gif"
                label={translate('common:selectFile')}
              />
            </Stack>
          </ConnectedFormItem>

          <ConnectedFormItem
            fieldId="iconFile"
            fieldName="iconFile"
            label={translate('accounts:form:icon:label')}
          >
            <Stack dir="row" gap="4" align="center">
              {account?.iconUrl && (
                <FilePreview url={account.iconUrl} fit="cover" />
              )}

              <ConnectedFileInput
                name="iconFile"
                size="sm"
                accept="image/png, image/jpeg, image/gif"
                label={translate('common:selectFile')}
              />
            </Stack>
          </ConnectedFormItem>

          <ConnectedFormItem
            fieldId="coverFile"
            fieldName="coverFile"
            label={translate('accounts:form:cover:label')}
          >
            <Stack dir="row" gap="4" align="center">
              {account?.coverUrl && (
                <Box w="full" maxW="48" borderRadius="md" overflow="hidden">
                  <Ratio ratio={0.75}>
                    <ImageWrapper src={account.coverUrl} fit="cover" />
                  </Ratio>
                </Box>
              )}

              <ConnectedFileInput
                name="coverFile"
                size="sm"
                accept="image/png, image/jpeg, image/gif"
                label={translate('common:selectFile')}
              />
            </Stack>
          </ConnectedFormItem>

          <FormSubmitBar>
            <ConnectedSubmit>{translate('common:save')}</ConnectedSubmit>
          </FormSubmitBar>
        </Form>
      </ConnectedLoading>
    </Formik>
  );
};

export default EditAccountForm;
