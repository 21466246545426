import React from 'react';
import { Toolbar } from '@fanadise/common-ui';
import { Translate } from '@fanadise/common-logic';
import NftsList from 'components/nfts/NftsList';

const NftsRouteList: React.FC = () => (
  <>
    <Toolbar mb="8" title={<Translate id="nfts:list:title" />} />

    <NftsList />
  </>
);

export default NftsRouteList;
