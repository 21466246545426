import React from 'react';
import { Routes, Route } from 'react-router-dom';

import UsersRouteAdd from './UsersRouteAdd';
import UsersRouteEdit from './UsersRouteEdit';
import UsersRouteList from './UsersRouteList';

const UsersRoute: React.FC = () => (
  <Routes>
    <Route index element={<UsersRouteList />} />
    <Route path="add" element={<UsersRouteAdd />} />
    <Route path="edit/:id" element={<UsersRouteEdit />} />
  </Routes>
);

export default UsersRoute;
