import React, { Context, useContext } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { Stack } from '@fanadise/common-ui';
import useTranslation from 'hooks/useTranslation';
import { EmailVerificationContextValue } from 'contexts/EmailVerificationContext';

import styles from './VerifyEmail.module.css';

export interface VerifyEmailProps {
  emailVerificationContext: Context<EmailVerificationContextValue>;
}

const VerifyEmail: React.FC<VerifyEmailProps> = ({
  emailVerificationContext,
}) => {
  const { translate } = useTranslation();
  const { requestEmailVerification } = useContext(emailVerificationContext);

  return (
    <Stack dir="row" gap="1" tag="p" align="center" className={styles.root}>
      <ExclamationCircleIcon />
      {` ${translate('email:verify:label')} `}
      <button type="button" onClick={() => requestEmailVerification()}>
        {translate('email:verify:action')}
      </button>
    </Stack>
  );
};

export default VerifyEmail;
