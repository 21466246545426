import React from 'react';

export type ConfirmContent = {
  title?: string;
  text?: string;
};

export type ConfirmDialogContextValue = {
  isConfirmDialogActive: boolean;
  activateConfirmDialog: (content?: ConfirmContent) => Promise<boolean>;
};

const ConfirmDialogContext = React.createContext<ConfirmDialogContextValue>({
  isConfirmDialogActive: false,
  activateConfirmDialog: async () => true,
});

export default ConfirmDialogContext;
