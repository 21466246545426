import React from 'react';
import Text, { TextProps } from 'components/atoms/Text';
import BorderedBox from 'components/atoms/BorderedBox';
import TinyHeading from 'components/atoms/TinyHeading';

export interface AttributeStatProps {
  label: string | number | JSX.Element;
  color?: TextProps['color'];
  className?: string;
}

const AttributeStat: React.FC<AttributeStatProps> = ({
  label,
  color = 'gray.800',
  className,
  children,
}) => (
  <BorderedBox
    data-testid="attributeStat"
    px="4"
    py="3.5"
    overflow="hidden"
    className={className}
  >
    <TinyHeading isTruncated>{label}</TinyHeading>

    <Text fontSize="lg" color={color} isTruncated>
      {children}
    </Text>
  </BorderedBox>
);

export default AttributeStat;
