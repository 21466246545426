import classNames from 'classnames';
import { IconProps } from 'components/atoms/Icon';
import React, { memo } from 'react';

import styles from './Input.module.css';

export interface InputProps {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  type?:
    | 'text'
    | 'textarea'
    | 'number'
    | 'email'
    | 'password'
    | 'datetime-local';
  value?: string | number;
  step?: number;
  min?: number;
  max?: number;
  name?: string;
  rows?: number;
  autoComplete?: string;
  placeholder?: string;
  icon?: React.ReactElement<IconProps>;
  iconPlacement?: 'left' | 'right';
  isInvalid?: boolean;
  isDisabled?: boolean;
  isAutofocusable?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  className?: string;
}

const Input: React.FC<InputProps> = ({
  size = 'md',
  type = 'text',
  icon,
  iconPlacement = 'left',
  isInvalid = false,
  isDisabled = false,
  isReadOnly = false,
  isRequired = false,
  isAutofocusable = false,
  className,
  ...rest
}) => {
  const Tag = type === 'textarea' ? 'textarea' : 'input';

  const input = (
    <Tag
      data-testid="input"
      type={type}
      disabled={isDisabled}
      autoFocus={isAutofocusable}
      readOnly={isReadOnly}
      required={isRequired}
      className={classNames(
        styles.root,
        styles[`size--${size}`],
        {
          [styles.isInvalid]: isInvalid,
          [styles.isDisabled]: isDisabled,
          [styles.isReadOnly]: isReadOnly,
        },
        className,
      )}
      {...rest}
    />
  );

  return icon ? (
    <div
      className={classNames(
        styles.wrap,
        styles[`iconPlacement--${iconPlacement}`],
      )}
    >
      {React.cloneElement(icon, { className: styles.icon })}
      {input}
    </div>
  ) : (
    input
  );
};

export default memo(Input);
