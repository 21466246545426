export const NOT_FOUND = 'notFound';
export const FORBIDDEN = 'forbidden';
export const UNAVAILABLE = 'unavailable';
export const SERVER_ERROR = 'serverError';

export const HTTP_ERRORS = {
  404: NOT_FOUND,
  403: FORBIDDEN,
  500: SERVER_ERROR,
  503: UNAVAILABLE,
};
