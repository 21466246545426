/* eslint-disable no-restricted-syntax */
import React, {
  CSSProperties,
  forwardRef,
  HTMLAttributes,
  useContext,
} from 'react';
import styled from '@emotion/styled';
import { ResponsiveProperty } from 'types/responsiveProperty';
import { ThemeProperties } from 'types/themeProperty';
import ThemeContext from 'contexts/ThemeContext';

const Styled = styled.div<{
  styles: Record<string, string | number | Record<string, string | number>>;
}>((props) => {
  const styleObject: Record<
    string,
    string | number | Record<string, string | number>
  > = {};

  for (const [key, prop] of Object.entries(props.styles)) {
    if (prop) {
      if (typeof prop === 'object') {
        styleObject[key] = prop.base;

        for (const [sizeProp, sizeValue] of Object.entries(prop)) {
          if (sizeProp !== 'base') {
            const mediaKey = `@media(min-width: ${sizeProp})`;
            const mediaObject = styleObject[mediaKey] as Record<
              string,
              string | number
            >;

            if (mediaObject) {
              mediaObject[key] = sizeValue;
            } else {
              styleObject[mediaKey] = { [key]: sizeValue };
            }
          }
        }
      } else {
        styleObject[key] = prop;
      }
    }
  }

  return styleObject;
});

export interface ThemedStyledProps
  extends Omit<HTMLAttributes<HTMLElement>, 'color' | 'dir'> {
  tag?: keyof JSX.IntrinsicElements;
  display?: ResponsiveProperty<CSSProperties['display']>;
  dir?: ResponsiveProperty<CSSProperties['flexDirection']>;
  align?: ResponsiveProperty<CSSProperties['alignItems']>;
  justify?: ResponsiveProperty<CSSProperties['justifyContent']>;
  overflow?: ResponsiveProperty<CSSProperties['overflow']>;
  overflowX?: ResponsiveProperty<CSSProperties['overflowX']>;
  overflowY?: ResponsiveProperty<CSSProperties['overflowY']>;
  textAlign?: ResponsiveProperty<CSSProperties['textAlign']>;
  position?: ResponsiveProperty<CSSProperties['position']>;
  flex?: ResponsiveProperty<CSSProperties['flex']>;
  w?: ResponsiveProperty<ThemeProperties['size']>;
  h?: ResponsiveProperty<ThemeProperties['size']>;
  wrap?: ResponsiveProperty<CSSProperties['flexWrap']>;
  shrink?: ResponsiveProperty<CSSProperties['flexShrink']>;
  gap?: ResponsiveProperty<ThemeProperties['size']>;
  maxW?: ResponsiveProperty<ThemeProperties['size']>;
  minW?: ResponsiveProperty<ThemeProperties['size']>;
  maxH?: ResponsiveProperty<ThemeProperties['size']>;
  minH?: ResponsiveProperty<ThemeProperties['size']>;
  p?: ResponsiveProperty<ThemeProperties['size']>;
  px?: ResponsiveProperty<ThemeProperties['size']>;
  py?: ResponsiveProperty<ThemeProperties['size']>;
  pt?: ResponsiveProperty<ThemeProperties['size']>;
  pb?: ResponsiveProperty<ThemeProperties['size']>;
  pr?: ResponsiveProperty<ThemeProperties['size']>;
  pl?: ResponsiveProperty<ThemeProperties['size']>;
  m?: ResponsiveProperty<ThemeProperties['size']>;
  mx?: ResponsiveProperty<ThemeProperties['size']>;
  my?: ResponsiveProperty<ThemeProperties['size']>;
  ml?: ResponsiveProperty<ThemeProperties['size']>;
  mr?: ResponsiveProperty<ThemeProperties['size']>;
  mb?: ResponsiveProperty<ThemeProperties['size']>;
  mt?: ResponsiveProperty<ThemeProperties['size']>;
  bg?: ResponsiveProperty<ThemeProperties['color']>;
  color?: ResponsiveProperty<ThemeProperties['color']>;
  borderRadius?: ResponsiveProperty<ThemeProperties['borderRadius']>;
  border?: ResponsiveProperty<CSSProperties['borderWidth']>;
  borderStyle?: ResponsiveProperty<CSSProperties['borderStyle']>;
  borderColor?: ResponsiveProperty<ThemeProperties['color']>;
  boxShadow?: ResponsiveProperty<ThemeProperties['shadow']>;
  columns?: ResponsiveProperty<CSSProperties['gridTemplateColumns']>;
  fontFamily?: ResponsiveProperty<ThemeProperties['fontFamily']>;
  fontWeight?: ResponsiveProperty<ThemeProperties['fontWeight']>;
  fontSize?: ResponsiveProperty<ThemeProperties['fontSize']>;
  lineHeight?: ResponsiveProperty<ThemeProperties['lineHeight']>;
  backgroundImage?: ResponsiveProperty<CSSProperties['backgroundImage']>;
  textTransform?: ResponsiveProperty<CSSProperties['textTransform']>;
  letterSpacing?: ResponsiveProperty<CSSProperties['letterSpacing']>;
  zIndex?: ResponsiveProperty<CSSProperties['zIndex']>;
  ratio?: ResponsiveProperty<number>;
}

const ThemedStyled = forwardRef<HTMLElement, ThemedStyledProps>(
  (
    {
      tag = 'div',
      display,
      dir,
      align,
      justify,
      overflow,
      overflowX,
      overflowY,
      textAlign,
      position,
      flex,
      w,
      h,
      maxW,
      minW,
      maxH,
      minH,
      gap,
      wrap,
      shrink,
      p,
      px,
      py,
      pt,
      pb,
      pr,
      pl,
      m,
      mt,
      mb,
      ml,
      mr,
      my,
      mx,
      bg,
      color,
      borderRadius,
      border,
      borderStyle,
      borderColor,
      boxShadow,
      columns,
      fontFamily,
      fontWeight,
      fontSize,
      lineHeight,
      backgroundImage,
      textTransform,
      letterSpacing,
      zIndex,
      ratio,
      ...rest
    },
    ref,
  ) => {
    const {
      convertSize,
      convertColor,
      convertBorderRadius,
      convertShadow,
      convertFontFamily,
      convertFontSize,
      convertLineHeight,
      convertFontWeight,
      covertResponsiveProperty,
    } = useContext(ThemeContext);

    const styles: { [key: string]: any } = {
      display: covertResponsiveProperty(display),
      flexDirection: covertResponsiveProperty(dir),
      alignItems: covertResponsiveProperty(align),
      justifyContent: covertResponsiveProperty(justify),
      overflow: covertResponsiveProperty(overflow),
      overflowX: covertResponsiveProperty(overflowX),
      overflowY: covertResponsiveProperty(overflowY),
      textAlign: covertResponsiveProperty(textAlign),
      position: covertResponsiveProperty(position),
      flex: covertResponsiveProperty(flex),
      width: covertResponsiveProperty(w, convertSize),
      height: covertResponsiveProperty(h, convertSize),
      maxWidth: covertResponsiveProperty(maxW, convertSize),
      minWidth: covertResponsiveProperty(minW, convertSize),
      maxHeight: covertResponsiveProperty(maxH, convertSize),
      minHeight: covertResponsiveProperty(minH, convertSize),
      gap: covertResponsiveProperty(gap, convertSize),
      flexWrap: covertResponsiveProperty(wrap),
      flexShrink: covertResponsiveProperty(shrink),
      padding: covertResponsiveProperty(p, convertSize),
      paddingLeft: covertResponsiveProperty(pl || px, convertSize),
      paddingRight: covertResponsiveProperty(pr || px, convertSize),
      paddingTop: covertResponsiveProperty(pt || py, convertSize),
      paddingBottom: covertResponsiveProperty(pb || py, convertSize),
      margin: covertResponsiveProperty(m, convertSize),
      marginRight: covertResponsiveProperty(mr || mx, convertSize),
      marginLeft: covertResponsiveProperty(ml || mx, convertSize),
      marginTop: covertResponsiveProperty(mt || my, convertSize),
      marginBottom: covertResponsiveProperty(mb || my, convertSize),
      backgroundColor: covertResponsiveProperty(bg, convertColor),
      boxShadow: covertResponsiveProperty(boxShadow, convertShadow),
      color: covertResponsiveProperty(color, convertColor),
      borderRadius: covertResponsiveProperty(borderRadius, convertBorderRadius),
      borderWidth: covertResponsiveProperty(border),
      borderStyle: covertResponsiveProperty(borderStyle),
      borderColor: covertResponsiveProperty(borderColor, convertColor),
      gridTemplateColumns: covertResponsiveProperty(
        columns,
        (value) => `repeat(${value}, minmax(0px, 1fr))`,
      ),
      fontFamily: covertResponsiveProperty(fontFamily, convertFontFamily),
      fontWeight: covertResponsiveProperty(fontWeight, convertFontWeight),
      fontSize: covertResponsiveProperty(fontSize, convertFontSize),
      lineHeight: covertResponsiveProperty(lineHeight, convertLineHeight),
      backgroundImage: covertResponsiveProperty(backgroundImage),
      textTransform: covertResponsiveProperty(textTransform),
      letterSpacing: covertResponsiveProperty(letterSpacing),
      zIndex: covertResponsiveProperty(zIndex),
      '--aspect-ratio': covertResponsiveProperty(ratio),
    };

    Object.keys(styles).forEach(
      (key) => styles[key] === undefined && delete styles[key],
    );

    return (
      <Styled
        as={tag}
        // @ts-ignore
        ref={ref}
        styles={styles}
        {...rest}
      />
    );
  },
);

export default ThemedStyled;
