import React, { useCallback, useRef } from 'react';
import Button, { ButtonProps } from 'components/atoms/Button';

import styles from './FileInput.module.css';

export interface FileInputProps {
  label: string;
  size?: ButtonProps['size'];
  color?: ButtonProps['color'];
  icon?: ButtonProps['icon'];
  isLoading?: ButtonProps['isLoading'];
  isDisabled?: ButtonProps['isDisabled'];
  file?: File;
  accept?: string;
  onFileChange: (file: File) => void;
}

const FileInput: React.FC<FileInputProps> = ({
  file,
  accept,
  label,
  size,
  icon,
  color = 'secondary',
  isLoading,
  isDisabled,
  onFileChange,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = useCallback(() => {
    inputRef.current?.click();
  }, [inputRef]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onFileChange(e.target.files![0]);
      e.target.value = '';
    },
    [onFileChange],
  );

  return (
    <div data-testid="fileInput" className={styles.root}>
      <Button
        type="button"
        size={size}
        color={color}
        icon={icon}
        isLoading={isLoading}
        isDisabled={isDisabled}
        onClick={handleButtonClick}
      >
        {label}
      </Button>

      <input
        ref={inputRef}
        type="file"
        accept={accept}
        onChange={handleInputChange}
      />
      {file && (
        <p title={file.name} className={styles.name}>
          {file.name}
        </p>
      )}
    </div>
  );
};

export default FileInput;
