import React from 'react';
import { Routes, Route } from 'react-router-dom';

import PurchasesRouteList from './PurchasesRouteList';
import PurchasesRoutePreview from './PurchasesRoutePreview';

const PurchasesRoute: React.FC = () => (
  <Routes>
    <Route index element={<PurchasesRouteList />} />
    <Route path=":id" element={<PurchasesRoutePreview />} />
  </Routes>
);

export default PurchasesRoute;
