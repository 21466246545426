import { api } from 'api';
import type {
  BasePost,
  Post,
  SingleResultDto,
  MultiResultsDto,
} from '@fanadise/common-types';
import type { PaginatedRequestQuery } from 'types/paginatedRequestQuery';
import type { PostAccess, PostType } from '@fanadise/common-consts';
import { PostsFilter } from 'types/postsFilter';
import { PostsSorting } from 'types/postsSorting';
import { PaginatedResponseData } from 'types/paginatedResponseData';

export const fetchPosts = async (
  params: {
    filter?: PostsFilter;
    sort?: PostsSorting;
    type?: PostType | PostType[];
    access?: PostAccess | PostAccess[];
  } & PaginatedRequestQuery = {},
) => {
  const res = await api.get<
    MultiResultsDto<'posts', BasePost> & PaginatedResponseData
  >('/posts', { params });
  return res.data;
};

export const fetchPost = async (id: string) => {
  const res = await api.get<SingleResultDto<'post', Post>>(`/posts/${id}`);
  return res.data.post;
};

export const incrementPostViewsCount = async (id: string) => {
  await api.put(`/posts/${id}/view`);
};

export const createPost = async (data: { post: Post }) => {
  const res = await api.post<SingleResultDto<'post', Post>>('/posts', data);
  return res.data.post;
};

export const updatePost = async (id: string, data: { post: Partial<Post> }) => {
  const res = await api.put<SingleResultDto<'post', Post>>(
    `/posts/${id}`,
    data,
  );
  return res.data.post;
};

export const deletePost = async (id: string) => {
  await api.delete(`/posts/${id}`);
};

export const uploadPostFiles = async (id: string, data: FormData) => {
  await api.post(`/upload/post-files/${id}`, data);
};
