import React from 'react';

export type EmailVerificationContextValue = {
  requestEmailVerification: () => void;
};

export const createEmailVerificationContext = () =>
  React.createContext<EmailVerificationContextValue>(
    // @ts-ignore
    null,
  );
